import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { APIContactService } from 'src/app/api/services';

const msgTypes = ['plant-details', 'problem', 'plant-request', 'contact', 'shop-request'];
export type typeStatus = 'plant-details' | 'problem' | 'plant-request' | 'contact' | 'shop-request'; //typeof msgTypes[number];

interface FormParams {
  text: string;
  type: typeStatus;
  user_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private formStatus$ = new BehaviorSubject<boolean>(false);
  private formContactType$ = new BehaviorSubject<typeStatus>('contact');

  constructor(private contactService: APIContactService) {}

  get formStatus(): Observable<boolean> {
    return this.formStatus$.asObservable();
  }
  get formContactType(): Observable<typeStatus> {
    return this.formContactType$.asObservable();
  }

  hideForm(): void {
    this.formStatus$.next(false);
  }

  showForm(formType: typeStatus): void {
    this.formContactType$.next(formType);
    this.formStatus$.next(true);
  }

  async send(form: FormParams): Promise<boolean> {
    return this.sendOther(form.text, this.formContactType$.value);
  }

  async sendOther(text: string, type?: 'plant-details' | 'problem' | 'plant-request' | 'contact' | 'shop-request'): Promise<boolean> {
    let msgType: typeStatus = type || 'contact';
    if (!type || msgTypes.includes(type)) {
      msgType = 'contact';
    }

    const { success } = await firstValueFrom(
      this.contactService.sendContactMessage({
        body: {
          text,
          type: msgType
        }
      })
    );

    return success;
  }
}
