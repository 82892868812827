export default (dateToParse: string): Date => {
  let date = new Date(dateToParse);

  // In case its IOS, parse the fulldate parts and re-create the date object.
  if (Number.isNaN(date.getMonth())) {
    const arr = dateToParse.split(/[- :]/);
    date = new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4]), parseInt(arr[5]));
  }
  return date;
};
