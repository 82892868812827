import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { TaksonomyService } from '../database/taksonomy.service';
import { Image, ThumbTaxon, User } from 'src/app/types';
import { AuthService } from '../auth.service';
import { APIFavouritesService } from 'src/app/api/services';

@Injectable({ providedIn: 'root' })
export class FavouritesService {
  private owner: User | null = null;

  private favouritePlants: { [key: string]: BehaviorSubject<boolean> } = {};
  private favouritePhotos: { [key: string]: BehaviorSubject<boolean> } = {};

  public readonly favouritePlants$: BehaviorSubject<ThumbTaxon[]> = new BehaviorSubject<ThumbTaxon[]>([]);
  public readonly favouritePhotos$: BehaviorSubject<Image[]> = new BehaviorSubject<Image[]>([]);

  constructor(
    private authService: AuthService,
    private taksonomyService: TaksonomyService,
    private favouritesService: APIFavouritesService
  ) {
    this.authService.user.subscribe((u) => {
      this.owner = u;
      void this.getFavouritePlants();
      if (u) {
        void this.getFavouritePhotos();
      }
    });
  }

  public async toggleFavourite(taxon: ThumbTaxon, force?: boolean): Promise<void> {
    const id = taxon.taxonId;
    let state: boolean | undefined;
    if (this.owner) {
      const resp = await firstValueFrom(this.favouritesService.setFavouritePlant({ taxonId: id, force }));
      state = resp.added;
    }

    const fav = this.getCachedFavouritePlants();

    state = state ?? force ?? !fav.includes(id);

    const plants = state ? [...fav, id] : fav.filter((f) => f !== id);

    this.setCachedFavouritePlants(plants);

    if (!Object.keys(this.favouritePlants).includes(id.toString())) {
      this.favouritePlants[id] = new BehaviorSubject<boolean>(state);
    } else {
      this.favouritePlants[id].next(state);
    }

    this.favouritePlants$.next(
      state ? [...this.favouritePlants$.value, taxon] : this.favouritePlants$.value.filter((f) => f.taxonId !== id)
    );
  }

  public async toggleFavouritePhoto(image: Image, force?: boolean): Promise<boolean> {
    const id = image.imageId;
    const resp = await firstValueFrom(this.favouritesService.setFavouritePhoto({ photoId: id, force }));
    const state = resp.added;

    if (!Object.keys(this.favouritePlants).includes(id.toString())) {
      this.favouritePhotos[id] = new BehaviorSubject<boolean>(state);
    } else {
      this.favouritePhotos[id].next(state);
    }

    this.favouritePhotos$.next(
      state ? [...this.favouritePhotos$.value, image] : this.favouritePhotos$.value.filter((f) => f.imageId !== id)
    );

    return state;
  }

  //----------------------------------------------

  /**
   * Get the list of favourites taxons with thumbnails
   * @returns ThumbTaxon[] taxon list
   */
  private async getUserFavouritePlants(): Promise<ThumbTaxon[]> {
    const { plants } = await firstValueFrom(this.favouritesService.getFavouritePlants());
    return plants.map((t) => new ThumbTaxon(t));
  }

  /**
   * Get the list of user favourites photos
   * @returns Image[]
   */
  private async getUserFavouritePhotos(): Promise<Image[]> {
    const imageSet = await firstValueFrom(this.favouritesService.getFavouritePhotoSet());
    return imageSet.photoSet.photos.map((p) => new Image(p));
  }

  private getCachedFavouritePlants(): string[] {
    const fav = localStorage.getItem('favourites');
    if (fav) {
      try {
        return JSON.parse(fav);
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  private setCachedFavouritePlants(idList: string[]): void {
    localStorage.setItem('favourites', JSON.stringify(idList));
  }

  /**
   * Get BehaviourSubject for a given plant
   *
   * @param taxonId string
   * @returns BehaviorSubject<boolean>
   */
  public favouriteTaxon(taxonId: string): BehaviorSubject<boolean> {
    if (!Object.keys(this.favouritePlants).includes(taxonId.toString())) {
      this.favouritePlants[taxonId] = new BehaviorSubject<boolean>(false);
    }
    return this.favouritePlants[taxonId];
  }

  /**
   * Get BehaviourSubject for a given photo
   *
   * @param photoId number
   * @returns BehaviorSubject<boolean>
   */
  public favouritePhoto(photoId: number): BehaviorSubject<boolean> {
    if (!Object.keys(this.favouritePhotos).includes(photoId.toString())) {
      this.favouritePhotos[photoId] = new BehaviorSubject<boolean>(false);
    }
    return this.favouritePhotos[photoId];
  }

  /**
   * Get plant favourites list - either from database (for logged users) or from localStorage
   *
   * @returns ThumbTaxon[] array of favourites id's
   */
  public async getFavouritePlants(): Promise<ThumbTaxon[]> {
    let plants: ThumbTaxon[] = [];
    if (this.owner) {
      plants = await this.getUserFavouritePlants();
    } else {
      const ids = this.getCachedFavouritePlants();
      // TODO: check necessity of getting taxon info by separate request
      plants = await this.taksonomyService.getThumbTaxons(ids);
    }

    Object.values(this.favouritePlants).forEach((v) => v.next(false));
    // for all new values either create new BS (if wasn't present) or push true
    plants.forEach((taxon: ThumbTaxon) => {
      if (!Object.keys(this.favouritePlants).includes(taxon.taxonId.toString())) {
        this.favouritePlants[taxon.taxonId] = new BehaviorSubject<boolean>(true);
      } else {
        this.favouritePlants[taxon.taxonId].next(true);
      }
    });

    this.favouritePlants$.next(plants);

    return plants;
  }

  /**
   * Get photo favourite list
   *
   * @returns Image[]
   */
  public async getFavouritePhotos(): Promise<Image[]> {
    const photos = await this.getUserFavouritePhotos();
    // for all present values - push false
    Object.values(this.favouritePhotos).forEach((v) => v.next(false));
    // for all new values either create new BS (if wasn't present) or push true
    photos.forEach((image: Image) => {
      if (!Object.keys(this.favouritePhotos).includes(image.imageId.toString())) {
        this.favouritePhotos[image.imageId] = new BehaviorSubject<boolean>(true);
      } else {
        this.favouritePhotos[image.imageId].next(true);
      }
    });

    this.favouritePhotos$.next(photos);

    return photos;
  }
}
