import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SnackbarService } from './utilities/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(public snackBar: SnackbarService) {}

  private getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error?.name || error?.message || error?.toString() || 'no-error-message';
  }

  private getClientStack(error: Error): string {
    return error?.stack || 'no-stack';
  }

  private getServerMessage(error: HttpErrorResponse): string {
    return error?.message;
  }

  private getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return JSON.stringify(error);
  }

  showError(error: Error | HttpErrorResponse): void {
    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = this.getServerMessage(error);
      stackTrace = this.getServerStack(error);
      this.snackBar.showSnackbar(message, 'warning');
    } else {
      // Client Error
      message = this.getClientMessage(error);
      stackTrace = this.getClientStack(error);
    }
    console.error(stackTrace);
    this.snackBar.showSnackbar(message, 'warning');
  }

  showErrorMessage(message: string): void {
    if (message !== '' && message !== '-') {
      this.snackBar.showSnackbar(message, 'warning');
    }
  }
}
