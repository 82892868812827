<form *ngIf="(details | async) === false" [formGroup]="searchForm" [ngClass]="{searchForm:true, internal}">
  <mat-form-field appearance="outline">
    <input matInput [placeholder]="'forms.misc.search' | translate" matInput type="text" formControlName="searchValue" />
  </mat-form-field>

  <app-action-button class="search" variant="iconed" size="large" (click)="onSearch()" customIcon="search"> </app-action-button>
  <!-- <app-action-button
    class="more"
    variant="iconed"
    size="large"
    (click)="details.next(true)"
    [matBadge]="filter?.length || 0"
    matBadgeColor="accent"
    [matBadgeHidden]="filter?.length === 0"
    customIcon="filter_list"
  >
  </app-action-button> -->
</form>
