import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ContactService, typeStatus } from 'src/app/services/forms/contact.service';
import { SnackbarService } from 'src/app/services/utilities/snackbar.service';

import { slideUpDownAnimation } from 'src/app/animations/component-animations';

@Component({
  selector: 'app-contact-form',
  animations: [slideUpDownAnimation],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() formType!: typeStatus;

  formStatus?: boolean;

  @ViewChild('form', { static: false }) private form?: NgForm;

  constructor(private contactService: ContactService, private snackBar: SnackbarService) {}

  defectForm = new FormGroup({
    text: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(1000)])
  });

  ngOnInit(): void {
    this.contactService.formStatus.subscribe((status) => (this.formStatus = status));
    this.contactService.formContactType.subscribe((type) => (this.formType = type));
  }

  showForm(type: typeStatus): void {
    this.contactService.showForm(type);
  }

  hideForm(): void {
    this.form?.resetForm();
    this.contactService.hideForm();
  }

  onSubmit(): void {
    if (this.defectForm.valid) {
      this.defectForm.disable();
      const formValue = this.defectForm.value;
      this.contactService.send(formValue).then(
        () => {
          this.form?.resetForm();
          this.defectForm.enable();
          this.snackBar.showSnackbar('snackBar.response.success', 'success');
          this.contactService.hideForm();
        },
        () => {
          this.defectForm.enable();
          this.snackBar.showSnackbar('snackBar.response.error', 'warning');
        }
      );
    }
  }
}
