import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private doc: Document) {}

  public setCanonicalUrl(): void {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const url = this.doc.URL;

      if (link.getAttribute('rel') === 'canonical') {
        link.setAttribute('href', url);
      }
      if (link.getAttribute('rel') === 'alternate') {
        const lang = link.getAttribute('hreflang');
        const langUrl = url.replace(/\/(\w{2})\//, lang ? `/${lang}/` : '/');
        link.setAttribute('href', langUrl);
      }
    }
  }
}
