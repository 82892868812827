<div *ngIf="currentImage$ | async as image">
  <button mat-icon-button class="close" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content" *ngIf="listLength$ | async as length" #content>
    <button *ngIf="length > 1" mat-icon-button class="left-arrow" (click)="onBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="image">
      <figure class="caption">
        <img #img [src]="image.src | urlStorage" />
        <app-marker-layer
          [img]="img"
          [scale]="1"
          [markers]="image.markers || []"
          [taxons]="image.taxons || []"
          [routed]="true"
        ></app-marker-layer>
        <figcaption *ngIf="caption$ | async as caption">
          <div>
            <p [matTooltip]="caption.tooltip || ''">{{ caption.date }}</p>
            <p>
              <a [routerLink]="'/profile/' + caption.slug | urlLang" *ngIf="caption.slug; else noUserBlock" (click)="onClose()"
                >©&nbsp;{{ caption.author }}</a
              >
              <ng-template #noUserBlock>©&nbsp;{{ caption.author }}</ng-template>
            </p>
          </div>
          <p>
            <span *ngFor="let i of caption.description">
              <a *ngIf="i.startsWith('#')" [routerLink]="'/tags' | urlLang" [queryParams]="{ _app: 'main', tag: i.slice(1)} "> {{i}} </a>
              <ng-container *ngIf="!i.startsWith('#')">{{i}}</ng-container>
            </span>
          </p>
        </figcaption>
      </figure>
    </div>
    <button *ngIf="length > 1" mat-icon-button class="right-arrow" (click)="onNext()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
