export { User } from './user';

export { LangName, TaksoUnit } from './takso';
export { BaseTaxon, SearchTaxon, CollectionTaxon, ThumbTaxon } from './taxon';

export { Bucket } from './bucket';
export { Collection } from './collection';

export { Geolocation, Image, ImageSet } from './image';

export interface ErrorMessage {
  msg: string;
}

export function flatten(
  fields: string[],
  object: { [key: string]: any }
): {
  [key: string]: any;
} {
  let obj: { [key: string]: any } = {};
  Object.keys(object).forEach((k) => {
    if (object[k] !== null) {
      if (fields.includes(k)) {
        obj[k] = object[k];
      } else if (typeof object[k] === 'object') {
        obj = { ...obj, ...flatten(fields, object[k]) };
      }
    }
  });
  return obj;
}
