import { Injectable, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// @Directive()
@Injectable({
  providedIn: 'root'
})
export class LanguageService extends TranslateService {
  @Output() changed: EventEmitter<string> = new EventEmitter();

  constructor(public parent: TranslateService) {
    super(
      parent.store,
      parent.currentLoader,
      parent.compiler,
      parent.parser,
      parent.missingTranslationHandler,
      true,
      false,
      true,
      parent.getDefaultLang()
    );
  }

  public setLang(lang: string): void {
    this.setDefaultLang(lang);
    this.use(lang);

    localStorage.setItem('lang', lang);
    this.changed.emit(lang);
  }

  public instant(field: string): string {
    return super.instant(field);
  }
}
