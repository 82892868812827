import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

export const INFLORESCENCE_TYPES = [
  'spike',
  'raceme',
  'corymb',
  'round_umbel',
  'round_umbel_reduced',
  'flat_umbel',
  'flat_umbel_reduced',
  'capitulum',
  'thyrse',
  'single',
  'coumpaund_umbel',
  'scorpioid_cyme',
  'flat_cyme',
  'dichasium',
  'cyme'
] as const;
export type inflorescenceType = typeof INFLORESCENCE_TYPES[number];

const i18nName = (v: string) => 'features.flower.inflorescence.' + v;

export default {
  ...defaultFeatureConfig('flower.inflorescence'),
  controlType: 'option',
  selectType: {
    values: INFLORESCENCE_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,
  constraints: [{ name: 'groups.use', values: ['flowering'] }],

  renderValue: i18nName
} as FeatureConfig;
