const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M60.45,35.688
          C60.45,44.122,50,50,50,50s-9.45-6.75-9.45-14.313c0-6.636,6.075-14.875,9.45-25.813C53.375,20.813,60.45,29.051,60.45,35.688z"
        />`,
    boundingBox: [40.55, 9.875, 19.9, 40.125],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M63.568,53.176
		c-6.897,1.693-13.258-3.45-13.258-3.45s4.116-7.074,10.301-8.592c5.429-1.332,11.194,4.845,20.641,4.692
		C72.808,50.063,68.996,51.844,63.568,53.176z"
        />`,
    boundingBox: [50.311, 40.946, 30.941, 12.572],
    angle: -85
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M36.742,53.176
          C43.64,54.869,50,49.726,50,49.726s-4.116-7.074-10.301-8.592c-5.429-1.332-11.194,4.845-20.641,4.692
          C27.503,50.063,31.314,51.844,36.742,53.176z"
        />`,
    boundingBox: [19.059, 40.946, 30.941, 12.572],
    angle: 85
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M52.484,50.344
          c0,2.164-0.563,4.74-2.547,4.74c-1.985,0-2.483-2.576-2.483-4.74c0-2.165,0.53-5.906,2.515-5.906
          C51.953,44.438,52.484,48.179,52.484,50.344z"
        />`,
    boundingBox: [47.454, 44.438, 5.03, 10.646],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M59.654,58.083
          c-0.258-0.59-2.824-5.793-6.905-7.518c0.04,0.191,0.063,0.388,0.063,0.591c0,1.57-1.305,3.99-2.875,3.99
          c-1.571,0-2.813-2.42-2.813-3.99c0-0.156,0.021-0.306,0.046-0.455c-3.884,1.858-6.302,6.814-6.575,7.382
          C40.068,59.178,37.682,73.215,50,73.215C62.316,73.215,59.944,58.748,59.654,58.083z"
        />`,
    boundingBox: [40.061, 50.565, 19.964, 22.65],
    angle: 0
  }
];

export default paths;
