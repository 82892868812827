import { Injectable } from '@angular/core';

import { TaksonomyService } from '../database/taksonomy.service';
import { ThumbTaxon } from 'src/app/types';
import { AuthService } from '../auth.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { APIShoppingService } from 'src/app/api/services/api-shopping.service';

@Injectable({ providedIn: 'root' })
export class ListService {
  private plants: { [key: string]: BehaviorSubject<boolean> } = {};
  public readonly plants$: BehaviorSubject<ThumbTaxon[]> = new BehaviorSubject<ThumbTaxon[]>([]);

  constructor(private authService: AuthService, private taksonomyService: TaksonomyService, private shoppingService: APIShoppingService) {
    this.authService.user.subscribe((u) => {
      if (u) {
        void this.getShoppingList();
      }
    });
  }

  public async toggleItem(taxon: ThumbTaxon, force?: boolean): Promise<void> {
    const id = taxon.taxonId;

    const resp = await firstValueFrom(this.shoppingService.setShoppingItem({ taxonId: id, force }));
    const state = resp.added;

    if (!Object.keys(this.plants).includes(id.toString())) {
      this.plants[id] = new BehaviorSubject<boolean>(state);
    } else {
      this.plants[id].next(state);
    }

    this.plants$.next(state ? [...this.plants$.value, taxon] : this.plants$.value.filter((f) => f.taxonId !== id));
  }

  /**
   * Get the shopping list taxons with thumbnails
   * @returns ThumbTaxon[] taxon list
   */
  private async getUserShoppingList(): Promise<ThumbTaxon[]> {
    const { plants } = await firstValueFrom(this.shoppingService.getShoppingList());
    return plants.map((t) => new ThumbTaxon(t));
  }

  /**
   * Get BehaviourSubject for a given plant
   *
   * @param taxonId string
   * @returns BehaviorSubject<boolean>
   */
  public isOnList(taxonId: string): BehaviorSubject<boolean> {
    if (!Object.keys(this.plants).includes(taxonId.toString())) {
      this.plants[taxonId] = new BehaviorSubject<boolean>(false);
    }
    return this.plants[taxonId];
  }

  /**
   * Get plant favourites list - either from database (for logged users) or from localStorage
   *
   * @returns ThumbTaxon[] array of favourites id's
   */
  public async getShoppingList(): Promise<ThumbTaxon[]> {
    const plants: ThumbTaxon[] = await this.getUserShoppingList();

    Object.values(this.plants).forEach((v) => v.next(false));
    // for all new values either create new BS (if wasn't present) or push true
    plants.forEach((taxon: ThumbTaxon) => {
      if (!Object.keys(this.plants).includes(taxon.taxonId.toString())) {
        this.plants[taxon.taxonId] = new BehaviorSubject<boolean>(true);
      } else {
        this.plants[taxon.taxonId].next(true);
      }
    });

    this.plants$.next(plants);

    return plants;
  }
}
