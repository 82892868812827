const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M62.515,22.875
          C60.759,40.415,51.229,50,51.229,50s-4.063-14.204,1.25-30.534c1.082-3.325,8.838-5.686,8.838-5.686S62.871,19.314,62.515,22.875z"
        />`,
    boundingBox: [49.743, 13.78, 12.824, 36.22],
    angle: -12
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M47.282,19.48
          C52.985,35.655,48.749,50,48.749,50s-9.323-9.577-11.478-26.998c-0.439-3.549,1.413-9.127,1.413-9.127S46.125,16.196,47.282,19.48z"
        />`,
    boundingBox: [37.204, 13.875, 13.059, 36.125],
    angle: 12
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M42.542,23.971
          C44.136,27.187,50,50,50,50s-14.617-0.589-13.686-12.932c0.561-7.443-6.581-18.818-6.581-18.818S40.946,20.754,42.542,23.971z"
        />`,
    boundingBox: [29.733, 18.25, 20.267, 31.75],
    angle: 26
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M63.402,37.106
          C63.653,49.444,50,50,50,50s5.948-22.791,7.556-26c1.607-3.21,12.71-5.631,12.71-5.631S63.25,29.625,63.402,37.106z"
        />`,
    boundingBox: [50, 18.369, 20.266, 31.631],
    angle: -26
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M56.5,20.125
          C58.983,37.625,50,50,50,50s-8.625-12.375-6.5-29.875c0.433-3.563,6.5-10.25,6.5-10.25S55.996,16.571,56.5,20.125z"
        />`,
    boundingBox: [41.163, 9.875, 13.771, 40.125],
    angle: 0
  }
];

export default paths;
