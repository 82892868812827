import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: string | number): number {
    if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return Math.round(value);
    }
  }
}
