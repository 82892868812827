/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIPhoto } from '../models/api-photo';

@Injectable({
  providedIn: 'root'
})
export class APIIdentifyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getIdentificationList
   */
  static readonly GetIdentificationListPath = '/identify/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdentificationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentificationList$Response(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<StrictHttpResponse<{ photos: Array<APIPhoto> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.GetIdentificationListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photos: Array<APIPhoto> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIdentificationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentificationList(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<{ photos: Array<APIPhoto> }> {
    return this.getIdentificationList$Response(params).pipe(
      map((r: StrictHttpResponse<{ photos: Array<APIPhoto> }>) => r.body as { photos: Array<APIPhoto> })
    );
  }

  /**
   * Path part for operation getIdentificationSkipList
   */
  static readonly GetIdentificationSkipListPath = '/identify/list/skipped';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdentificationSkipList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentificationSkipList$Response(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<StrictHttpResponse<{ photos: Array<APIPhoto> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.GetIdentificationSkipListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photos: Array<APIPhoto> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIdentificationSkipList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentificationSkipList(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<{ photos: Array<APIPhoto> }> {
    return this.getIdentificationSkipList$Response(params).pipe(
      map((r: StrictHttpResponse<{ photos: Array<APIPhoto> }>) => r.body as { photos: Array<APIPhoto> })
    );
  }

  /**
   * Path part for operation getOwnIdentificationList
   */
  static readonly GetOwnIdentificationListPath = '/identify/list/own';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOwnIdentificationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnIdentificationList$Response(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<StrictHttpResponse<{ photos: Array<APIPhoto> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.GetOwnIdentificationListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photos: Array<APIPhoto> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOwnIdentificationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOwnIdentificationList(params: {
    /**
     * Page number
     */
    page: number;
  }): Observable<{ photos: Array<APIPhoto> }> {
    return this.getOwnIdentificationList$Response(params).pipe(
      map((r: StrictHttpResponse<{ photos: Array<APIPhoto> }>) => r.body as { photos: Array<APIPhoto> })
    );
  }

  /**
   * Path part for operation setPhotoToDelete
   */
  static readonly SetPhotoToDeletePath = '/identify/delete/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPhotoToDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPhotoToDelete$Response(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Reason why the photo should be deleted
     */
    body: { reason: string };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.SetPhotoToDeletePath, 'post');
    if (params) {
      rb.path('photoId', params.photoId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPhotoToDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPhotoToDelete(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Reason why the photo should be deleted
     */
    body: { reason: string };
  }): Observable<{ success: boolean }> {
    return this.setPhotoToDelete$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }

  /**
   * Path part for operation setPlantForPhoto
   */
  static readonly SetPlantForPhotoPath = '/identify/item/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPlantForPhoto()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPlantForPhoto$Response(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Taxon that should be applied to this photo
     */
    body: { taxonId: string };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.SetPlantForPhotoPath, 'post');
    if (params) {
      rb.path('photoId', params.photoId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPlantForPhoto$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPlantForPhoto(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Taxon that should be applied to this photo
     */
    body: { taxonId: string };
  }): Observable<{ success: boolean }> {
    return this.setPlantForPhoto$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }

  /**
   * Path part for operation setPhotoToSkip
   */
  static readonly SetPhotoToSkipPath = '/identify/skip/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPhotoToSkip()` instead.
   *
   * This method doesn't expect any request body.
   */
  setPhotoToSkip$Response(params: {
    /**
     * Photo Id
     */
    photoId: number;
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIIdentifyService.SetPhotoToSkipPath, 'post');
    if (params) {
      rb.path('photoId', params.photoId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPhotoToSkip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setPhotoToSkip(params: {
    /**
     * Photo Id
     */
    photoId: number;
  }): Observable<{ success: boolean }> {
    return this.setPhotoToSkip$Response(params).pipe(map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean }));
  }
}
