import { animate, style, transition, trigger } from '@angular/animations';

export const slideUpDownAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate(200, style({ transform: 'translateY(0)', opacity: 1 }))
  ]),
  transition(':leave', [animate(200, style({ transform: 'translateY(100%)', opacity: 0 }))])
]);

export const slideLeftRightAnimation = trigger('slideLeftRight', [
  transition('* => right', [
    style({
      transform: 'translateX(100%)',
      opacity: 0,
      position: 'fixed',
      zIndex: 2
    }),
    animate(200, style({ transform: 'translateX(0)', opacity: 1 }))
  ]),
  transition('* => left', [
    style({
      transform: 'translateX(-100%)',
      opacity: 0,
      position: 'fixed',
      zIndex: 2
    }),
    animate(200, style({ transform: 'translateX(0)', opacity: 1 }))
  ])
]);
