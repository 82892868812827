import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

const PLANT_TYPES = [
  'leefy_trees',
  'needle_trees',
  'leefy_bushes',
  'needle_bushes',
  'prostrate_shrubs',
  'herbaceous_plants',
  'grass',
  'creepers',
  'water_plants',
  'other'
] as const;
export type plantType = typeof PLANT_TYPES[number];

const i18nName = (v: string) => 'features.groups.type.' + v;

export default {
  ...defaultFeatureConfig('groups.type'),
  controlType: 'option',
  selectType: {
    values: PLANT_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,
  renderValue: i18nName
} as FeatureConfig;
