import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CompareService } from 'src/app/services/utilities/compare.service';
import { BaseTaxon } from 'src/app/types';

@Component({
  selector: 'app-compare-indicator',
  templateUrl: './compare-indicator.component.html',
  styleUrls: ['./compare-indicator.component.scss']
})
export class CompareIndicatorComponent {
  comparables: Observable<BaseTaxon[]>;

  constructor(private compareService: CompareService) {
    this.comparables = this.compareService.comparables;
  }

  clear(): void {
    this.compareService.removeAll();
  }
}
