import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { LanguageService } from 'src/app/services/language/language.service';
import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';
import { OptionType } from 'src/app/types/features/selectTypes';
import { optionType } from 'src/app/types/features/types';
import { FeatureConfig } from 'src/app/types/features/config';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss']
})
export class ColorSelectComponent implements OnInit, OnChanges {
  @Input() feature!: Feature<optionType>;
  @Input() saved?: BehaviorSubject<boolean>;
  @Input() hideChosenValue?: boolean;

  @Input() size = 400;

  @Output() changed = new EventEmitter<Feature<optionType>>();

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;
  optionsConfig?: OptionType;

  /** user has changed the value, and it is scheduled to save */
  modified = false;
  /** user had set the value previously */
  default = true;

  options: string[] = [];

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }
  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set value(newVal: any) {
    if (!this.group || !this.name) {
      return;
    }
    this.privateVal = newVal;
    const info = this.feature
      ? {
          featureId: this.feature.featureId,
          owner: this.feature.ownerId
        }
      : {};

    this.changed.emit({
      group: this.group,
      name: this.name,
      groupName: this.feature.groupName,
      value: newVal,
      ...info
    });

    this.modified = true;
    this.default = false;
  }

  constructor(private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.feature &&
      changes.feature.previousValue &&
      changes.feature.currentValue &&
      changes.feature.currentValue.value?.length !== changes.feature.previousValue.value?.length
    ) {
      this.privateVal = this.feature ? this.feature.value : [];
    }
  }

  ngOnInit(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);

    const feature = this.feature;

    this.default = feature ? false : true;

    this.optionsConfig = this.featureConfig.selectType as OptionType;

    this.options = [...this.optionsConfig.values];

    this.privateVal = feature ? feature.value : [];

    this.initVal = this.privateVal;
    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.modified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
  }
  toggleSelection(opt: string): void {
    if (!this.value) {
      this.value = [opt];
    } else if (this.value.includes(opt)) {
      this.value = this.value.filter((e: any) => e !== opt);
    } else {
      this.value = [...this.value, opt]; // not push because we want to invoke set()
    }
  }
}
