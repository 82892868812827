import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent {
  language: string;
  currentRoute?: string;

  langs: string[];

  constructor(private translate: LanguageService, private router: Router) {
    this.language = translate.currentLang;
    translate.changed.subscribe((lang: string) => {
      this.language = lang;
    });
    this.langs = this.translate.getLangs();
  }

  changeLang(lang: string): void {
    if (lang === this.language) {
      return;
    }

    let route = this.router.url;

    if (route.startsWith('/' + this.language)) {
      route = route.replace('/' + this.language, '/' + lang);
    } else {
      route = '/' + lang + route;
    }
    this.translate.setLang(lang);

    // navigate with full reload
    window.location.href = route;
  }
}
