import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { TaksoUnit, LangName } from 'src/app/types';

import { TaksonomyService } from 'src/app/services/database/taksonomy.service';

@Component({
  selector: 'app-local-names',
  templateUrl: './local-names.component.html',
  styleUrls: ['./local-names.component.scss']
})
export class NamesComponent implements OnInit {
  @Input() taxonId!: string;

  @Output() saved = new EventEmitter<number>();

  takson?: TaksoUnit;
  names: LangName[] = [];

  constructor(private taksonomyService: TaksonomyService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.taksonomyService
      .getDetails(this.taxonId, ['names'])
      .then((takson) => {
        this.takson = takson;
        this.names = takson.names.sort((a, b) => ((a.priority || 0) > (b.priority || 0) ? 1 : -1));
      })
      .catch((e) => console.error('TODO error', e));
  }

  addItem(item: LangName): void {
    this.names.push(item);
    this.saved.emit(this.names.length);
  }
  removeItem(index: number): void {
    this.names = this.names.filter((e, i) => i !== index);
    this.saved.emit(this.names.length);
  }
}
