<div class="canva">
  <svg
    *ngIf="path && mirrorPath"
    width="300"
    height="300"
    viewbox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
    [ngStyle]="{transform: 'scale('+scale+')'}"
  >
    <g>
      <!-- <line y2="300" x2="150" y1="0" x1="150" stroke-width="1" stroke="#aaa" fill="none" /> -->
      <path [attr.d]="path" opacity="0.5" stroke-width="1.5" stroke="#000" fill="#aaa" />
      <path [attr.d]="mirrorPath" opacity="0.5" stroke-width="1.5" stroke="#000" fill="#aaa" />
    </g>
    <!-- <g>
      <rect
        [attr.fill]="moving === 'tip' ? 'red' : '#666'"
        [attr.x]="147"
        [attr.y]="297 - 3 * value.tip"
        width="6"
        height="6"
        (click)="startMove('tip', $event)"
      ></rect>

      <rect class="base" [attr.x]="147" [attr.y]="297" width="6" height="6"></rect>

      <g *ngFor="let p of value.middle; let i = index">
        <rect
          width="6"
          height="6"
          [attr.fill]="moving === 'middle-' + i + '-point' ? 'red' : '#666'"
          (click)="startMove('middle-' + i + '-point', $event)"
          [attr.x]="147 - 3 * p.point[0]"
          [attr.y]="297 - 3 * p.point[1]"
        ></rect>

        <line
          stroke="#4F80FF"
          stroke-width="1"
          [attr.x1]="150 - 3 * p.handlePrev[0]"
          [attr.y1]="300 - 3 * p.handlePrev[1]"
          [attr.x2]="150 - 3 * p.point[0]"
          [attr.y2]="300 - 3 * p.point[1]"
        ></line>

        <line
          stroke="#4F80FF"
          stroke-width="1"
          [attr.x1]="150 - 3 * p.handleNext[0]"
          [attr.y1]="300 - 3 * p.handleNext[1]"
          [attr.x2]="150 - 3 * p.point[0]"
          [attr.y2]="300 - 3 * p.point[1]"
        ></line>

        <circle
          [attr.fill]="moving === 'middle-' + i + '-handlePrev' ? 'red' : '#fff'"
          r="3"
          stroke="#000"
          stroke-width="1"
          [attr.cx]="150 - 3 * p.handlePrev[0]"
          [attr.cy]="300 - 3 * p.handlePrev[1]"
          (click)="startMove('middle-' + i + '-handlePrev', $event)"
        ></circle>
        <circle
          [attr.fill]="moving === 'middle-' + i + '-handleNext' ? 'red' : '#fff'"
          r="3"
          stroke="#000"
          stroke-width="1"
          [attr.cx]="150 - 3 * p.handleNext[0]"
          [attr.cy]="300 - 3 * p.handleNext[1]"
          (click)="startMove('middle-' + i + '-handleNext', $event)"
        ></circle>
      </g>

      <line
        stroke="#4F80FF"
        stroke-width="1"
        [attr.x1]="150 - 3 * value.tipHandle[0]"
        [attr.y1]="300 - 3 * value.tipHandle[1]"
        [attr.x2]="150"
        [attr.y2]="300 - 3 * value.tip"
      ></line>
      <circle
        [attr.fill]="moving === 'tipHandle' ? 'red' : '#fff'"
        r="3"
        stroke="#000"
        stroke-width="1"
        [attr.cx]="150 - 3 * value.tipHandle[0]"
        [attr.cy]="300 - 3 * value.tipHandle[1]"
        (click)="startMove('tipHandle', $event)"
      ></circle>

      <line
        stroke="#4F80FF"
        stroke-width="1"
        [attr.x1]="150 - 3 * value.baseHandle[0]"
        [attr.y1]="300 - 3 * value.baseHandle[1]"
        [attr.x2]="150"
        [attr.y2]="300"
      ></line>
      <circle
        [attr.fill]="moving === 'baseHandle' ? 'red' : '#fff'"
        r="3"
        stroke="#000"
        stroke-width="1"
        [attr.cx]="150 - 3 * value.baseHandle[0]"
        [attr.cy]="300 - 3 * value.baseHandle[1]"
        (click)="startMove('baseHandle', $event)"
      ></circle>
    </g> -->
  </svg>
</div>
