import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, flattenReduce } from '../config';

const DECORATION_TYPES = ['flowers', 'scent', 'leaves', 'bark', 'fruits', 'shape'] as const;
export type decorativeType = typeof DECORATION_TYPES[number];

const i18nName = (v: string) => 'features.characteristics.decorativeness.' + v;

export default {
  ...defaultFeatureConfig('characteristics.decorativeness'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: DECORATION_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  reduce: flattenReduce,
  renderValue: i18nName,
  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
