import { Pipe, PipeTransform } from '@angular/core';
import { getDefaultLang } from '../helpers/lang';

@Pipe({
  name: 'urlLang'
})
export class UrlLangPipe implements PipeTransform {
  transform(url: string): string {
    const lang = getDefaultLang();

    return '/' + lang + url;
  }
}
