import { NgModule } from '@angular/core';
import { HasRoleDirective } from './hasRole.directive';
import { IsLoggedDirective } from './isLogged.directive';
import { IsPremiumDirective } from './isPremium.directive';
import { IsFreeDirective } from './isFree.directive';
import { PageLinkDirective } from './pageLink.directive';

@NgModule({
  declarations: [IsLoggedDirective, HasRoleDirective, IsPremiumDirective, IsFreeDirective, PageLinkDirective],
  exports: [IsLoggedDirective, HasRoleDirective, IsPremiumDirective, IsFreeDirective, PageLinkDirective]
})
export class DirectivesModule {}
