<div class="data">
  <h2>{{ 'buckets.edit.header' | translate }}</h2>
  <div class="editContent">
    <section class="description">
      <h4>{{ 'buckets.edit.add-description' | translate }}</h4>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'buckets.edit.description' | translate }}</mat-label>
        <textarea
          matInput
          #description
          placeholder="{{ 'buckets.edit.header' | translate }}"
          maxlength="250"
          rows="10"
          type="text"
          [value]="setDescription()"
        ></textarea>
        <mat-hint align="end">{{ description.value.length }} / 250</mat-hint>
      </mat-form-field>
    </section>
  </div>
  <div class="buttons">
    <app-action-button type="accept" variant="filled" (click)="type === 'edit' ? onEditFormSave() : newFormSave()" [loading]="loading">
      {{ 'buttons.save' | translate }}
    </app-action-button>
  </div>
</div>
