const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M62.325,36.125
          c0.121,5.187-2.813,9.158-6.105,11.479c3.718-11.479-3.783-30.235-6.658-36.643c0.368-0.609,0.613-0.918,0.613-0.918
          S62.135,27.328,62.325,36.125z"
        />`,
    boundingBox: [49.562, 10.043, 12.767, 37.562],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M56.22,47.604
          c-2.076,1.471-4.332,2.316-5.986,2.438H49.87c-0.43,0-0.861-0.061-1.347-0.182c5.25-8.853,2.199-29.609,0.609-38.167
          c-0.064-0.06,0-0.06,0-0.06c0.181-0.242,0.305-0.488,0.426-0.668C52.437,17.374,59.938,36.125,56.22,47.604z"
        />`,
    boundingBox: [48.523, 10.967, 8.71, 39.076],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M48.523,49.861
          c-0.856-0.243-1.771-0.612-2.746-1.232c-3.848-2.188-7.878-6.963-7.878-11.839c0-5.869,6.41-17.284,10.075-23.14
          c0.243-0.43,0.492-0.856,0.732-1.223c0.125-0.243,0.243-0.489,0.426-0.734C50.722,20.244,53.773,41.009,48.523,49.861z"
        />`,
    boundingBox: [37.899, 11.692, 13.569, 38.169],
    angle: 0
  }
];

export default paths;
