<app-lightbox></app-lightbox>
<footer class="page-footer" [ngClass]="{ increaseHeight: increaseHeight }">
  <!-- Copyright -->
  <div class="footer-copyright">
    <app-language-select></app-language-select>
    <div>© {{ year }} Copyright:&nbsp;<a href="https://viridis.zone">viridis.zone</a></div>
    <div class="docs">
      <a class="privacy" [routerLink]="'/docs/privacy' | urlLang" [queryParams]="{ _app: 'main' }">{{ 'navigation.privacy' | translate }}</a
      >&nbsp;|&nbsp;<a class="terms" routerLink="/assets/docs/terms.pdf" target="_blank">{{ 'navigation.terms' | translate }}</a>
    </div>
  </div>
  <!-- Copyright -->
</footer>
