import { APIImageParent, APIPhoto, APIUser, APIGeolocation, APIPlantPhotoSet, APIMarker } from '../api/models';
import { BaseTaxon } from './taxon';

export class Geolocation implements APIGeolocation {
  longitude: number;
  latitude: number;
  altitude?: number;

  constructor(data: APIGeolocation | string) {
    if (typeof data === 'object' && 'latitude' in data && 'longitude' in data) {
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      this.altitude = data.altitude;
    } else if (typeof data === 'string') {
      const coords = data.split(':');
      if (coords.length < 2) {
        throw "Illegal init arguments. Expected numbers separated with ':'";
      }

      this.longitude = parseFloat(coords[0]);
      this.latitude = parseFloat(coords[1]);
      this.altitude = coords.length > 2 ? parseFloat(coords[2]) : undefined;
    } else {
      throw 'Illegal init arguments. Expected Geolocation object or string';
    }
  }

  toString(): string {
    if (this.longitude && this.latitude) {
      return `${this.longitude}:${this.latitude}${this.altitude ? `:${this.altitude}` : ''}`;
    } else {
      return '';
    }
  }
}

// function transform(url: string) {
//   if (url?.includes('http://') || url?.includes('https://')) {
//     return url;
//   }
//   return environment.storageUrl + 'fff' + url;
// }

export class Marker implements APIMarker {
  markerId?: number;
  taxonId?: string;
  positionX: number;
  positionY: number;
  radius: number;

  scale: number;

  constructor(data: APIMarker, scale = 1) {
    this.markerId = data.markerId;
    this.taxonId = data.taxonId;
    this.positionX = data.positionX;
    this.positionY = data.positionY;
    this.radius = data.radius;
    this.scale = scale;
  }

  drawCircle(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = 'rgb(0, 0, 0)';
    ctx.beginPath();
    ctx.arc(this.positionX, this.positionY, 1, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  }

  increaseRadius(ctx: CanvasRenderingContext2D): void {
    ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
    ctx.beginPath();
    ctx.arc(this.positionX, this.positionY, this.radius, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();
  }
}

export class Image implements APIPhoto {
  imageId: number;
  public: boolean;
  src: string;
  thumb: string;
  mini: string;
  date: string;

  width: number;
  height: number;

  location?: Geolocation;
  description?: string;
  extra?: string;
  updatedAt: string;

  author: APIUser;
  parent?: APIImageParent;
  collectionId?: string;

  markers?: Marker[];
  taxons?: BaseTaxon[];

  favourite?: boolean;

  /**@deprecated */
  type = 'image';

  detailsLink: string;
  editLink: string;

  constructor(data: APIPhoto) {
    this.imageId = data.imageId;
    this.public = data.public || false;
    this.src = data.src; //transform(data.src);
    this.thumb = data.thumb; //transform(data.thumb);
    this.mini = data.mini || data.thumb;
    this.date = data.date ?? new Date().toISOString();

    this.width = data.width;
    this.height = data.height;

    this.location = data.location ? new Geolocation(data.location) : undefined;

    this.description = data.description;
    this.updatedAt = data.updatedAt ?? '';
    this.author = data.author;
    this.parent = data.parent;
    this.collectionId = data.collectionId ?? undefined;
    this.extra = data.extra;

    this.markers = data.markers?.map((t) => new Marker(t));
    this.taxons = data.taxons?.map((t) => new BaseTaxon(t));

    this.detailsLink = `/photos/item/${data.author?.userId}/${data.imageId}`;
    this.editLink = `/photos/edit/${data.imageId}`;
  }
}

export class ImageSet implements APIPlantPhotoSet {
  photos: Array<Image>;
  thumb?: string;
  total: number;
  taxon?: BaseTaxon;
  collectionId?: string;

  detailsLink: string;

  constructor(data: APIPlantPhotoSet, collectionId?: string) {
    this.photos = data.photos.map((p) => new Image(p));
    this.thumb = data.thumb;
    this.total = data.total;
    this.taxon = data.taxon ? new BaseTaxon(data.taxon) : undefined;
    this.collectionId = collectionId;

    const variety = data.taxon?.variety ? '/' + data.taxon.variety : '';

    this.detailsLink = data.taxon ? `/photos/plant/${data.taxon.latinName || ''}${variety}` : `/photos/set/TODO`;
  }
}
