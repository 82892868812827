import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getShape } from 'src/app/helpers/shapes';
import { colorType, COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';
import { Pattern, Shape } from 'src/app/types/features/selectTypes';
import { flowerShape } from 'src/app/types/features/types';

import slipperPath from './shapes/slipper';
import urnPath from './shapes/urn';
import peaPath from './shapes/pea';
import budPath from './shapes/bud';
import fullPath from './shapes/full';

import bellSinglePath from './shapes/bell-single';
import bellDoublePath from './shapes/bell-double';
import bellFullPath from './shapes/bell-full';
import { CanvasService } from 'src/app/services/canvas.service';

const defaultPath = { tip: 100, tipHandle: [20, 80], middle: [], baseHandle: [20, 20] } as Shape;

@Component({
  selector: 'app-flower-shapes',
  templateUrl: './flower-shapes.component.html',
  styles: [
    `
      :host {
        display: flex;
      }
      .floweShape {
      }
    `
  ]
})
export class FlowerShapesComponent implements OnInit, AfterViewInit {
  @Input() shape?: flowerShape;
  @Input() path?: Shape;
  @Input() parameter?: number;
  @Input() pattern?: Pattern;
  @Input() color?: colorType = 'dark-red';

  @Input() width = 120;
  @Input() height = 120;
  @Output() created = new EventEmitter<string>();

  bezier = '';
  bezierMirror = '';
  angles: number[] = [];

  readonly id = Math.random().toString(36);

  componentPaths: any;

  paths: SafeHtml[] = [];
  boxes: { x: number; y: number; w: number; h: number; a: number }[] = [];

  customPath = false;

  @ViewChild('silhouette', { static: false }) silhouette?: ElementRef;

  constructor(private sanitizer: DomSanitizer, private canvasService: CanvasService) {}

  ngOnInit(): void {
    let simplePath = budPath;
    switch (this.shape) {
      case 'slipper':
        simplePath = slipperPath;
        break;
      case 'urn':
        simplePath = urnPath;
        break;
      case 'pea':
        simplePath = peaPath;
        break;
      case 'full':
        simplePath = fullPath;
        break;
      case 'bell-single':
        simplePath = bellSinglePath;
        break;
      case 'bell-double':
        simplePath = bellDoublePath;
        break;
      case 'bell-full':
        simplePath = bellFullPath;
        break;
      case 'stellate-single':
      case 'stellate-double':
        this.customPath = true;
        break;
      default:
    }

    if (this.customPath) {
      this.bezier = getShape(this.path || defaultPath, 40, false);
      this.bezierMirror = getShape(this.path || defaultPath, 40, true);

      if (this.parameter == undefined) {
        this.parameter = 5;
      }
      for (let i = 0; i < this.parameter; i++) {
        this.angles.push((i * 360) / this.parameter);
      }

      const html = `
      <path  fill="#FFFFFF" stroke="black" stroke-width="1" stroke-miterlimit="10"  d="${this.bezier} ${this.bezierMirror}"/>
    `;
      this.paths = this.angles.map(() => this.sanitizer.bypassSecurityTrustHtml(html));
      this.boxes = this.angles.map((a) => ({ x: 0, y: 0, w: 40, h: 40, a }));
    } else {
      this.paths = simplePath.map((f, idx) => this.sanitizer.bypassSecurityTrustHtml(f.path('#fff', true, `sp${idx}`)));
      this.boxes = simplePath.map((f) => {
        const clockwise = f.angle < 0;
        const [x, y, w, h] = f.boundingBox;
        const a = (Math.abs(f.angle) * 2 * Math.PI) / 360;
        const W = Math.abs(w * Math.cos(a) + h * Math.sin(a));
        const H = Math.abs(w * Math.sin(a) + h * Math.cos(a));
        const dx = clockwise ? h * Math.sin(a) * Math.cos(a) : w * Math.sin(a) * Math.sin(a);
        const dy = clockwise ? h * Math.sin(a) * Math.sin(a) : w * Math.sin(a) * Math.cos(a);

        return { x: x + (clockwise ? -1 : 1) * dx, y: y + (clockwise ? 1 : -1) * dy, w: W, h: H, a: f.angle };
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.created.observers.length > 0 && this.silhouette) {
      this.canvasService
        .svgToDataUrl(this.silhouette.nativeElement.outerHTML, this.width, this.height)
        .then((src) => this.created.next(src))
        .catch((e) => console.error('TODO error', e));
    }
  }

  getColor(name: string): string {
    const col = COLOR_VALUES[name];
    if (!col) {
      return '#ba5b7c';
    }
    return `hsl(${col.h} , ${col.s}%, ${col.l}%)`;
  }
}
