import { ColorType } from './selectTypes';

import { FeatureConfig, defaultFeatureConfig } from './config';
import { Feature } from '.';

export const COLOR_NAMES = [
  'red',
  'orange',
  'yellow',
  'yellowgreen',
  'green',
  'aquamarine',
  'aqua',
  'steelblue',
  'blue',
  'violet',
  'pink',
  'purple',
  'white',
  'default'
];
export interface HSL {
  h: number;
  s: number;
  l: number;
}
export const COLOR_VALUES: { [key: string]: HSL } = {
  'dark-red': { h: 0, s: 80, l: 35 },
  red: { h: 0, s: 80, l: 50 },
  'light-red': { h: 0, s: 80, l: 75 },
  'dark-orange': { h: 30, s: 80, l: 35 },
  orange: { h: 30, s: 80, l: 50 },
  'light-orange': { h: 30, s: 80, l: 75 },
  'dark-yellow': { h: 60, s: 90, l: 40 },
  yellow: { h: 60, s: 80, l: 50 },
  'light-yellow': { h: 60, s: 80, l: 75 },
  'dark-yellowgreen': { h: 75, s: 80, l: 35 },
  yellowgreen: { h: 75, s: 80, l: 50 },
  'light-yellowgreen': { h: 75, s: 80, l: 75 },
  'dark-green': { h: 120, s: 70, l: 25 },
  green: { h: 120, s: 70, l: 40 },
  'light-green': { h: 120, s: 70, l: 80 },
  'dark-aquamarine': { h: 150, s: 80, l: 35 },
  aquamarine: { h: 150, s: 80, l: 50 },
  'light-aquamarine': { h: 150, s: 80, l: 75 },
  'dark-aqua': { h: 180, s: 80, l: 35 },
  aqua: { h: 180, s: 80, l: 50 },
  'light-aqua': { h: 180, s: 80, l: 75 },
  'dark-steelblue': { h: 210, s: 80, l: 35 },
  steelblue: { h: 210, s: 80, l: 50 },
  'light-steelblue': { h: 210, s: 80, l: 75 },
  'dark-blue': { h: 240, s: 80, l: 35 },
  blue: { h: 240, s: 80, l: 50 },
  'light-blue': { h: 240, s: 80, l: 75 },
  'dark-violet': { h: 270, s: 80, l: 35 },
  violet: { h: 270, s: 80, l: 50 },
  'light-violet': { h: 270, s: 80, l: 75 },
  'dark-pink': { h: 300, s: 80, l: 35 },
  pink: { h: 300, s: 80, l: 50 },
  'light-pink': { h: 300, s: 80, l: 75 },
  'dark-purple': { h: 330, s: 80, l: 35 },
  purple: { h: 330, s: 80, l: 50 },
  'light-purple': { h: 330, s: 80, l: 75 },
  white: { h: 0, s: 0, l: 99 }
  // default: { h: 0, s: 0, l: 50 }
};
export const COLOR_TYPES = [
  'dark-red',
  'red',
  'light-red',
  'dark-orange',
  'orange',
  'light-orange',
  'dark-yellow',
  'yellow',
  'light-yellow',
  'dark-yellowgreen',
  'yellowgreen',
  'light-yellowgreen',
  'dark-green',
  'green',
  'light-green',
  'dark-aquamarine',
  'aquamarine',
  'light-aquamarine',
  'dark-aqua',
  'aqua',
  'light-aqua',
  'dark-steelblue',
  'steelblue',
  'light-steelblue',
  'dark-blue',
  'blue',
  'light-blue',
  'dark-violet',
  'violet',
  'light-violet',
  'dark-pink',
  'pink',
  'light-pink',
  'dark-purple',
  'purple',
  'light-purple',
  'white'
] as const;
export type colorType = typeof COLOR_TYPES[number];

const i18nName = (v: any) => `colors.${String(v)}`;

interface sortedVal {
  v: colorType[];
  idx: number;
}
const unique = (el: any, idx: number, arr: any[]) => arr.indexOf(el) === idx;

export default {
  ...defaultFeatureConfig(''),
  controlType: 'color',
  multipleValues: true,
  multiplePredefined: [
    { label: 'start', val: 0 },
    { label: 'middle', val: 0.4 },
    { label: 'end', val: 0.8 }
  ],
  selectType: {
    values: COLOR_TYPES,
    renderValue: i18nName
  } as ColorType,
  reduce: (featureArray: Feature<colorType[]>[]): { start: colorType[]; middle: colorType[]; end: colorType[]; all: colorType[] } => {
    if (!featureArray || featureArray.length === 0) {
      return {
        start: [],
        middle: [],
        end: [],
        all: []
      };
    }
    const valuesObject = featureArray.reduce((prev, curr: Feature<colorType[]>) => {
      if (!curr || !curr.ownerId || !curr.value) {
        return prev;
      }
      const val = { v: curr.value, idx: curr.min } as sortedVal;
      if (prev[curr.ownerId] && (curr.min || 0) < 1) {
        prev[curr.ownerId].push(val);
        prev[curr.ownerId] = prev[curr.ownerId].sort((a, b) => a.idx - b.idx);
      } else {
        prev[curr.ownerId] = [val];
      }
      return prev;
    }, {} as { [key: number]: sortedVal[] });
    const valuesArray = Object.values(valuesObject)
      .filter((f: sortedVal[]) => f.length < 4) // eliminate those which are too long - limit is 3 entries per user
      .map((f: sortedVal[]) => f.map((e) => e.v));

    const start: colorType[] = [];
    const middle: colorType[] = [];
    const end: colorType[] = [];
    valuesArray.forEach((arr) => {
      if (arr.length === 1) {
        // if there is only one set of colors - assign them to all stages
        start.push(...arr[0]);
        middle.push(...arr[0]);
        end.push(...arr[0]);
      } else if (arr.length === 2) {
        // if there are two sets of colors - assign them to appriopriate stage
        start.push(...arr[0]);
        middle.push(...arr[0], ...arr[1]);
        end.push(...arr[1]);
      } else if (arr.length === 3) {
        // if there are three sets of colors - assign them to appriopriate stage
        start.push(...arr[0]);
        middle.push(...arr[1]);
        end.push(...arr[2]);
      }
    });

    return {
      start: start.filter(unique),
      middle: middle.filter(unique),
      end: end.filter(unique),
      all: [...start, ...middle, ...end].filter(unique)
    };
  },
  renderValue: i18nName
} as FeatureConfig;
