import TypeConfig, { plantType } from './groups/type';
import LastingConfig, { lastingType } from './groups/lasting';
import UseConfig, { useType } from './groups/use';
import SilhouetteConfig, { silhouetteType } from './groups/silhouette';

import InsolationConfig, { insolationType } from './position/insolation';
import HumidityConfig, { humidityType } from './position/humidity';
import PHNameConfig, { phType } from './position/ph_name';
// import PHRangeConfig from './position/ph_range';
import SoilConfig, { soilType } from './position/soil';
import FrostConfig, { frostType } from './position/frost_resistance';

import MaxHeightConfig from './appearance/max_height';
// import TenHeightConfig from './appearance/ten_height';
import MaxWidthConfig from './appearance/max_width';
// import TenWidthConfig from './appearance/ten_width';
import GrowthStrengthConfig, { growthType } from './appearance/grow_strength';
import SortConfig, { sortType } from './appearance/sort';
import CuttingRatioConfig, { cuttingType } from './appearance/cutting_ratio';

import InflorescenceConfig, { inflorescenceType } from './flower/inflorescence';
import FlowerColorConfig from './flower/color';
import BloomTimeConfig from './flower/time';
import PetalShapeConfig from './flower/petal_shape';
import PetalPatternConfig from './flower/pattern';
import FlowerShapeConfig, { flowerShape } from './flower/flower_shape';

import LeafColorConfig from './foliage/color';
import LeafTimeConfig from './foliage/time';
import LeafShapeConfig from './foliage/leaf_shape';
import LeafPatternConfig from './foliage/pattern';

import DecorativenessConfig, { decorativeType } from './characteristics/decorativeness';
import EndureConfig, { endureType } from './characteristics/endure';
import FragileConfig, { fragileType } from './characteristics/fragile';
import CareConfig, { careType } from './characteristics/care';

import { colorType } from './ColorConfig.type';

import { FeatureConfig } from './config';
const FEATURE_CONFIG: {
  [key: string]: { [key: string]: FeatureConfig };
} = {
  groups: {
    type: TypeConfig,
    lasting: LastingConfig,
    use: UseConfig,
    silhouette: SilhouetteConfig
  },
  position: {
    insolation: InsolationConfig,
    humidity: HumidityConfig,
    ph_name: PHNameConfig,
    // ph_range: PHRangeConfig,
    soil: SoilConfig,
    frost_resistance: FrostConfig
  },
  appearance: {
    max_height: MaxHeightConfig,
    // ten_height: TenHeightConfig,
    max_width: MaxWidthConfig,
    // ten_width: TenWidthConfig,
    growth_strength: GrowthStrengthConfig,
    sort: SortConfig,
    cutting_ratio: CuttingRatioConfig
  },
  flower: {
    petal_shape: PetalShapeConfig,
    flower_shape: FlowerShapeConfig,
    color: FlowerColorConfig,
    pattern: PetalPatternConfig,
    inflorescence: InflorescenceConfig,
    time: BloomTimeConfig
  },
  foliage: {
    leaf_shape: LeafShapeConfig,
    pattern: LeafPatternConfig,
    color: LeafColorConfig,
    time: LeafTimeConfig
  },
  characteristics: {
    decorativeness: DecorativenessConfig,
    endure: EndureConfig,
    fragile: FragileConfig,
    care: CareConfig
  }
};

export class FeaturesConfig {
  config: {
    [key: string]: { [key: string]: FeatureConfig };
  };
  constructor(role?: string) {
    if (role) {
      this.config = this.filterRoleConfig(FEATURE_CONFIG, role);
    } else {
      this.config = FEATURE_CONFIG;
    }
  }

  get(group: string, name = ''): FeatureConfig {
    if (name === '') {
      [group, name] = group.split('.');
    }
    return this.config[group][name];
  }
  getGroups(): string[] {
    return Object.keys(this.config);
  }
  getGroupNames(group: string): string[] {
    return Object.keys(this.config[group]);
  }

  private filterRoleConfig(
    config: {
      [key: string]: { [key: string]: FeatureConfig };
    },
    role: string
  ): {
    [key: string]: { [key: string]: FeatureConfig };
  } {
    const filteredConfig: {
      [key: string]: { [key: string]: FeatureConfig };
    } = {};
    Object.keys(config).forEach((k) => {
      const group: { [key: string]: FeatureConfig } = {};
      Object.keys(config[k]).forEach((l) => {
        if (config[k][l].contributorRoles.includes(role)) {
          group[l] = config[k][l];
        }
      });
      if (Object.keys(group).length > 0) {
        filteredConfig[k] = group;
      }
    });
    return filteredConfig;
  }
}

export const GENERAL_FEATURE_CONFIG = new FeaturesConfig();

export interface Range {
  min: number;
  max: number;
}

type optionType =
  | plantType
  | lastingType
  | useType
  | silhouetteType
  | insolationType
  | humidityType
  | phType
  | soilType
  | frostType
  | growthType
  | sortType
  | cuttingType
  | decorativeType
  | endureType
  | fragileType
  | careType
  | inflorescenceType
  | flowerShape;

export {
  plantType,
  lastingType,
  useType,
  silhouetteType,
  insolationType,
  humidityType,
  phType,
  soilType,
  frostType,
  growthType,
  sortType,
  cuttingType,
  decorativeType,
  endureType,
  fragileType,
  careType,
  inflorescenceType,
  flowerShape,
  colorType,
  optionType
};
