import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

export const SORT_TYPES = [
  'irregular',
  'columnar',
  'roundish',
  'halfround',
  'oval',
  'umbrella',
  'creeping',
  'cushiony',
  'hanging',
  'extensive',
  'conical',
  'tapered_oval',
  'erected'
] as const;
export type sortType = typeof SORT_TYPES[number];

const i18nName = (v: string) => 'features.appearance.sort.' + v;

export default {
  ...defaultFeatureConfig('appearance.sort'),
  controlType: 'option',
  selectType: {
    values: SORT_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,

  renderValue: i18nName
} as FeatureConfig;
