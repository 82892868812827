import { generateLog, RangeType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const render = (v: number) => (v < 1 ? `${Math.round(v * 100)}cm` : `${v}m`);

export default {
  ...defaultFeatureConfig('appearance.max_width'),
  controlType: 'range',
  contributorRoles: ['admin', 'contributor', 'user', 'company'],
  selectType: {
    min: 0,
    max: 10,
    log: true,
    default: 0,
    label: (v) => render(v),
    renderValue: render,
    ticks: generateLog(0.05, 10, [0.5, 1, 1.2, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 8, 10])
  } as RangeType,
  reduce: countedFrequencyReduce,
  renderValue: render
} as FeatureConfig;
