<div *ngFor="let name of names; index as i" [ngClass]="{ main: i === 0, name: true }">
  <app-local-name-form
    *ngIf="name && takson?.taxonId"
    [taxonId]="takson?.taxonId || ''"
    [name]="name"
    [priority]="i"
    (removed)="removeItem(i)"
  >
  </app-local-name-form>
</div>

<div class="name new">
  <app-local-name-form *ngIf="takson" [taxonId]="takson.taxonId" [priority]="names.length + 1" (saved)="addItem($event)">
  </app-local-name-form>
</div>
