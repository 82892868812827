<div class="data">
  <h2>{{ 'buckets.map.header' | translate }}</h2>
  <p>{{ (markerPosition ? 'buckets.map.text.location' : 'buckets.map.text.no-location') | translate }}</p>
  <div class="editContent">
    <section class="map">
      <div>
        <h4>{{ 'buckets.map.location' | translate }}</h4>
        <app-action-button
          [disabled]="
            markerPosition ? markerPosition?.lat === image?.location?.latitude || markerPosition?.lng === image?.location?.longitude : true
          "
          variant="filled"
          customIcon="undo"
          type="action"
          (click)="undoClick()"
        >
          {{ 'buttons.undo' | translate }}
        </app-action-button>
      </div>

      <google-map
        *ngIf="mapPosition"
        (mapClick)="mapClicked($event)"
        [center]="mapPosition"
        [width]="mobile ? '230px' : '300px'"
        [height]="mobile ? '230px' : '300px'"
      >
        <map-marker *ngIf="markerPosition && markerOptions" [position]="markerPosition" [options]="markerOptions"></map-marker>
      </google-map>
    </section>
  </div>
  <div class="buttons">
    <app-action-button
      type="deny"
      variant="filled"
      customIcon="delete_forever"
      (click)="onRemove()"
      [disabled]="markerPosition === undefined"
      >{{ 'buttons.remove' | translate }}</app-action-button
    >

    <app-action-button
      type="accept"
      variant="filled"
      (click)="type === 'edit' ? onEditFormSave() : newFormSave()"
      [loading]="loading"
      [disabled]="
        markerPosition?.lat === image?.location?.latitude ||
        markerPosition?.lng === image?.location?.longitude ||
        (!markerPosition && !image?.location?.longitude)
      "
    >
      {{ 'buttons.save' | translate }}
    </app-action-button>
  </div>
</div>
