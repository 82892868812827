import { Activity } from './activity';
import { APIUser, APIBaseUser } from 'src/app/api/models';

export class UserRank {
  score = 0;
  badge = '';
  constructor(score: string) {
    if (score) {
      const sc = score ? parseInt(score, 10) : 0;
      this.score = sc;

      this.badge = sc < 10 ? 'new' : sc < 50 ? 'beginner' : sc < 200 ? 'guest' : sc < 1000 ? 'regular' : sc < 5000 ? 'advanced' : 'master';
    }
  }
}

export type Role = 'admin' | 'user' | 'contributor' | 'company' | 'manager';
export class User implements APIUser {
  userId = 0;
  email = '';
  name = '';
  lang = 'en';
  role: Role = 'user';
  avatar = '';

  rank?: UserRank;
  activities?: Activity[];
  plan: 'FREE' | 'FULL' = 'FREE';
  type: 'free' | 'paid' = 'free';

  constructor(data: APIUser | APIBaseUser) {
    if (!data) {
      return;
    }
    if (typeof data === 'string') {
      this.userId = parseInt(data, 10);
      return;
    }

    this.userId = data.userId || 0;
    this.email = data.email || '';
    this.name = data.name || '';
    this.role = (data.role as Role) || 'user';

    this.lang = (data as APIUser)?.lang || 'en';
    this.avatar = (data as APIUser)?.avatar || '';
    this.plan = data.plan;
    this.type = data.plan == 'FREE' ? 'free' : 'paid';

    // if (data.subscription && data.subscription_end) {
    //   const d = new Date(data.subscription_end);
    //   const active = d.getTime() - new Date().getTime();
    //   this.type = active > 0 ? 'paid' : 'free';
    // } else {
    //   this.type = data.type || 'free';
    // }

    // this.rank = new UserRank(data.score);
    // if (data.activities && Array.isArray(data.activities)) {
    //   this.activities = data.activities.map((a: any) => new Activity(a));
    // }
  }
}
