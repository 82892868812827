<ng-container *ngIf="image">
  <app-paper>
    <img [src]="image.thumb | urlStorage" (click)="openLightbox()" [alt]="image.description" />
    <div>
      <h3>
        <div class="description">
          <span *ngFor="let i of description">
            <a *ngIf="i.startsWith('#')" [routerLink]="'/tags' | urlLang" [queryParams]="{ _app: 'main', tag: i.slice(1)} "> {{i}} </a>
            <ng-container *ngIf="!i.startsWith('#')">{{i}}</ng-container>
          </span>
        </div>

        <app-action-button
          *ngIf="editable"
          variant="iconed"
          customIcon="more_horiz"
          [matMenuTriggerFor]="menu"
          (click)="more($event)"
        ></app-action-button>
      </h3>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openForm = 'details'">
          <mat-icon class="menu-icon">edit</mat-icon>
          {{ 'imageControl.edit' | translate }}
        </button>
        <button mat-menu-item (click)="openForm = 'map'">
          <mat-icon class="menu-icon">location_on</mat-icon>
          {{ 'imageControl.location' | translate }}
        </button>
        <button *ngIf="image.parent?.type === 'bucket'" mat-menu-item (click)="openForm = 'transfer'">
          <mat-icon class="menu-icon">swap_horiz</mat-icon>
          {{ 'imageControl.transfer' | translate }}
        </button>
        <button *ngIf="image.parent?.type === 'composition'" mat-menu-item (click)="openForm = 'markers'">
          <mat-icon class="menu-icon">location_on</mat-icon>
          {{ 'imageControl.markers' | translate }}
        </button>
        <button mat-menu-item class="delete" (click)="openForm = 'delete'">
          <mat-icon class="menu-icon delete">close</mat-icon>
          {{ 'imageControl.delete' | translate }}
        </button>
      </mat-menu>
    </div>
    <ng-content></ng-content>
    <mat-icon class="location" *ngIf="image.location">location_on</mat-icon>
  </app-paper>

  <app-popup *ngIf="openForm === 'details'" (closed)="close()">
    <app-image-details-form [image]="image" (saved)="close()" (cancel)="close()" type="edit"></app-image-details-form>
  </app-popup>

  <app-popup *ngIf="openForm === 'map'" (closed)="close()">
    <app-image-map-form [image]="image" (saved)="close()" (cancel)="close()" type="edit"></app-image-map-form>
  </app-popup>

  <app-popup *ngIf="openForm === 'transfer'" (closed)="close()">
    <app-image-transfer-form [image]="image" (saved)="close()" (cancel)="close()"></app-image-transfer-form>
  </app-popup>

  <!-- <app-popup *ngIf="isCompositionImage(image) && openForm === 'markers'" (closed)="close()">
    <app-image-markers-form [image]="image" (saved)="close()" (cancel)="close()"></app-image-markers-form>
  </app-popup> -->

  <app-popup *ngIf="openForm === 'delete'" (closed)="close()">
    <app-image-delete-form [image]="image" (saved)="close()" (cancel)="close()"></app-image-delete-form>
  </app-popup>
</ng-container>
