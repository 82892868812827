<div class="options">
  <mat-form-field appearance="outline" [class]="value !== undefined && value.length > 0 ? 'selected' : ''">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-select [multiple]="multiple" [value]="value" (valueChange)="changed.emit($event)">
      <mat-option *ngFor="let val of options" [value]="val">
        {{ render(val) | translate }}
        <app-sort-silhouette
          *ngIf="isOptionSort(val) && label === 'features.appearance.sort.'"
          [width]="0.2"
          [height]="0.2"
          type="other"
          [sort]="val"
        ></app-sort-silhouette>
        <app-inflorescence-silhouette
          *ngIf="isOptionInflorescence(val) && label === 'features.flower.inflorescence.'"
          [width]="30"
          [height]="30"
          [type]="val"
        ></app-inflorescence-silhouette>
        <span class="flower_shape" *ngIf="isOptionFlowerShape(val) && label === 'features.flower.flower_shape.'">
          <app-inflorescence-silhouette [width]="50" [height]="50" type="single" [flowerShape]="val"></app-inflorescence-silhouette>
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="value !== undefined && value.length > 0" (click)="changed.emit([])">cancel</mat-icon>
</div>
