import { Component, OnInit, Input } from '@angular/core';

import { COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';
import { Pattern } from 'src/app/types/features/selectTypes';

@Component({
  selector: 'app-pattern-display',
  templateUrl: './pattern-display.component.html',
  styleUrls: ['./pattern-display.component.scss']
})
export class PatternDisplayComponent implements OnInit {
  @Input() value!: Pattern;
  @Input() size = 300;

  scale = 1;

  ngOnInit(): void {
    this.scale = this.size / 300;
  }
  getColor(name: string): string {
    const col = COLOR_VALUES[name];
    return `hsl(${col.h} , ${col.s}%, ${col.l}%)`;
  }
}
