import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { getArc } from 'src/app/helpers/arcs';
import { colorType, COLOR_NAMES, COLOR_TYPES, COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';

interface ColorArc {
  arc: string;
  color: string;
  value: number;
  included: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-color-indicator',
  templateUrl: './color-indicator.component.html',
  styleUrls: ['./color-indicator.component.scss']
})
export class ColorIndicatorComponent implements OnChanges {
  @Input() colors: colorType[] = [];
  @Input() availbleColors: colorType[] = [...COLOR_TYPES];
  @Input() size = 400;
  @Input() hideChosenValue?: boolean;

  @Output() clicked = new EventEmitter<string>();

  readonly wheel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  darkArcs: ColorArc[] = [];
  mediumArcs: ColorArc[] = [];
  lightArcs: ColorArc[] = [];
  white: ColorArc = {
    arc: '',
    color: '',
    value: 0,
    included: true,
    disabled: false
  };

  hovered?: any;

  readonly lines = this.wheel.map((v) => ({ x: 1.1 * Math.cos((2 * Math.PI * v) / 12), y: 1.1 * Math.sin((2 * Math.PI * v) / 12) }));

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colors) {
      if (this.availbleColors == null) {
        this.availbleColors = [...COLOR_TYPES];
      }

      const included = this.colors || [];

      this.darkArcs = this.wheel.map((v: number) => {
        const col = COLOR_VALUES['dark-' + COLOR_NAMES[v]];
        return {
          arc: getArc(v / 12, (v + 1) / 12, 1.1),
          color: `hsl(${col.h} , ${col.s}%, ${col.l}%)`,
          included: included.map((c) => c.toString()).includes('dark-' + COLOR_NAMES[v]),
          value: v,
          disabled: !this.availbleColors.includes(('dark-' + COLOR_NAMES[v]) as colorType)
        };
      });
      this.mediumArcs = this.wheel.map((v) => {
        const col = COLOR_VALUES[COLOR_NAMES[v]];
        return {
          arc: getArc(v / 12, (v + 1) / 12, 0.8),
          color: `hsl(${col.h} , ${col.s}%, ${col.l}%)`,
          included: included.map((c) => c.toString()).includes(COLOR_NAMES[v]),
          value: v,
          disabled: !this.availbleColors.includes(COLOR_NAMES[v] as colorType)
        };
      });
      this.lightArcs = this.wheel.map((v) => {
        const col = COLOR_VALUES['light-' + COLOR_NAMES[v]];
        return {
          arc: getArc(v / 12, (v + 1) / 12, 0.5),
          color: `hsl(${col.h} , ${col.s}%, ${col.l}%)`,
          included: included.map((c) => c.toString()).includes('light-' + COLOR_NAMES[v]),
          value: v,
          disabled: !this.availbleColors.includes(('light-' + COLOR_NAMES[v]) as colorType)
        };
      });
      this.white = {
        arc: '',
        color: '',
        value: 0,
        included: included.map((c) => c.toString()).includes('white'),
        disabled: !this.availbleColors.includes('white')
      };
    }
  }

  click(value: number, ring: string): void {
    this.clicked.emit(ring + COLOR_NAMES[value]);
  }
  hover(i: number, ring: number): void {
    this.hovered = {
      x: this.x(i + 0.5, 1.2) + (i >= 3 && i < 9 ? -0.5 : 0.5),
      y: this.y(i + 0.5, 1.2) + 0.08,
      points:
        String(this.x(i + 0.5, ring)) +
        ',' +
        String(this.y(i + 0.5, ring)) +
        ' ' +
        String(this.x(i + 0.5, 1.2)) +
        ',' +
        String(this.y(i + 0.5, 1.2)) +
        ' ' +
        String(this.x(i + 0.5, 1.2) + (i >= 3 && i < 9 ? -0.45 : 0.45)) +
        ',' +
        String(this.y(i + 0.5, 1.2)),
      i,
      type: ring > 1 ? 'dark-' : ring < 0.1 ? '' : ring < 0.6 ? 'light-' : '',
      ring
    };
  }

  label(v: number, type: string): string {
    return 'colors.' + type + COLOR_NAMES[v];
  }
  x(v: number, radius: number): number {
    return radius * Math.cos((2 * Math.PI * v) / 12);
  }
  y(v: number, radius: number): number {
    return radius * Math.sin((2 * Math.PI * v) / 12);
  }
}
