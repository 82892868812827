<div>
  <!-- contact form -->
  <ng-template #contactForm>
    <mat-card class="md-form-bottom-right" [@slideInOut]>
      <mat-card-header class="md-form-header">
        <mat-card-title>{{ 'contactForm.header.' + formType | translate }}</mat-card-title>
        <div class="md-form-header-icon">
          <button
            mat-icon-button
            [attr.aria-label]="'contactForm.close' | translate"
            [matTooltip]="'contactForm.close' | translate"
            matTooltipPosition="left"
            (click)="hideForm()"
          >
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <!-- <mat-card-subtitle>{{ 'contactForm.required-fields' | translate }}</mat-card-subtitle> -->
      <mat-card-content>
        <form [formGroup]="defectForm" #form="ngForm">
          <mat-form-field appearance="fill">
            <mat-label [attr.aria-label]="'contactForm.fields.content' | translate"
              >{{ 'contactForm.fields.content' | translate }}*</mat-label
            >
            <textarea
              #text
              matInput
              [placeholder]="'contactForm.errors.empty-content' | translate"
              [attr.aria-label]="'contactForm.errors.empty-content' | translate"
              minlength="10"
              maxlength="1000"
              rows="10"
              formControlName="text"
            ></textarea>
            <mat-hint>{{ text.value.length }} / 1000</mat-hint>
            <mat-error
              *ngIf="
                !defectForm.controls['text'].hasError('required') &&
                (defectForm.controls['text'].hasError('minlength') || defectForm.controls['text'].hasError('maxlength'))
              "
              >{{ 'contactForm.errors.incorrect-content' | translate }}
            </mat-error>
            <mat-error *ngIf="defectForm.controls['text'].hasError('required')"
              >{{ 'contactForm.errors.empty-content' | translate }}</mat-error
            >
          </mat-form-field>
          <div class="mat-form-button-div">
            <button
              [disabled]="defectForm.disabled"
              mat-flat-button
              type="submit"
              color="accent"
              class="mat-form-button"
              [attr.aria-label]="'contactForm.send-problem' | translate"
              [matTooltip]="'contactForm.send-problem' | translate"
              matTooltipPosition="left"
              (click)="onSubmit()"
            >
              {{ !defectForm.disabled ? ('contactForm.send' | translate) : null }}
              <mat-icon *ngIf="!defectForm.disabled" inline="true" class="mat-form-button-icon">send</mat-icon>
              <mat-spinner *ngIf="defectForm.disabled" class="mat-spinner-params" diameter="25"></mat-spinner>
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <!-- form button -->
  <button
    *ngIf="!formStatus; else contactForm"
    [@slideInOut]
    mat-fab
    [matTooltip]="'contactForm.header.contact' | translate"
    matTooltipPosition="left"
    color="accent"
    class="md-fab-bottom-right"
    [attr.aria-label]="'contactForm.header.contact' | translate"
    (click)="showForm('contact')"
  >
    <mat-icon>announcement</mat-icon>
  </button>
</div>
