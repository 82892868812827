<div *ngIf="featureConfig" [ngClass]="{ header: true, modified: featureModified }">
  <div class="value">
    <ng-container [ngSwitch]="featureConfig.controlType">
      <span *ngSwitchCase="'option'">
        {{ value.length === 0 ? ' -- ' : '' }}
        <ng-container *ngIf="isArray(value); else singleBlock">
          <span class="array" *ngFor="let v of value">{{ featureConfig.renderValue(v) | translate }}</span>
        </ng-container>
        <ng-template #singleBlock>
          <span class="array">{{ featureConfig.renderValue(value) | translate }}</span>
        </ng-template>
      </span>
      <span *ngSwitchCase="'range'">
        <ng-container *ngIf="!isRange(value); else rangeBlock">
          <strong>{{ featureConfig.renderValue(value) | translate }}</strong>
        </ng-container>
        <ng-template #rangeBlock>
          {{ 'forms.features.from' | translate }}: <strong>{{ featureConfig.renderValue(value.min) | translate }}</strong
          ><em class="clear"></em> {{ 'forms.features.to' | translate }}:
          <strong>{{ featureConfig.renderValue(value.max) | translate }}</strong>
        </ng-template>
      </span>
      <span *ngSwitchCase="'color'">
        <span class="array" *ngFor="let v of value">{{ featureConfig.renderValue(v) | translate }}</span>
      </span>
      <span *ngSwitchDefault> -*- </span>
    </ng-container>
  </div>
</div>
