import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Image as _Image } from 'src/app/types';
import { IFile, ImageService } from 'src/app/services/database/image.service';
import { SnackbarService } from 'src/app/services/utilities/snackbar.service';
import date from 'src/app/helpers/date';

@Component({
  selector: 'app-image-details-form',
  templateUrl: './image-details-form.component.html',
  styleUrls: ['./image-details-form.component.scss']
})
export class ImageDetailsFormComponent implements AfterViewInit {
  @Input() image!: IFile | _Image;
  @Input() type!: 'edit' | 'new';
  @Input() description?: string;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() saved: EventEmitter<string> = new EventEmitter();

  loading = false;

  @ViewChild('description') typedDescription?: ElementRef;

  constructor(private imageService: ImageService, private snackbar: SnackbarService) {}

  ngAfterViewInit(): void {
    if (!this.type) {
      this.type = 'new';
    }
  }

  private isImage(img: any): img is _Image {
    return (img as _Image).imageId !== undefined;
  }

  setDescription(): string | undefined {
    if (this.description) {
      return this.description;
    } else if (this.image?.description) {
      return this.image.description;
    }
    return undefined;
  }

  onEditFormSave(): void {
    if (!this.isImage(this.image)) {
      return;
    }

    this.loading = true;
    this.imageService
      .editImage({
        imageId: this.image.imageId,
        description: this.typedDescription?.nativeElement.value,
        public: false,
        date: (this.image.date ? date(this.image.date) : new Date()).toISOString()
      })
      .then((newImage: _Image) => {
        this.imageService.update(newImage);
        this.loading = false;
        this.snackbar.showSnackbar('imageControl.edited', 'success');
        this.saved.emit(this.typedDescription?.nativeElement.value);
      })
      .catch(() => {
        this.snackbar.showSnackbar('snackBar.response.error', 'warning');
        this.loading = false;
      });
  }

  newFormSave(): void {
    this.saved.emit(this.typedDescription?.nativeElement.value);
  }
}
