import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const PH_TYPES = ['very_acid', 'acid', 'neutral', 'alkaline', 'very_alkaline'] as const;
export type phType = typeof PH_TYPES[number];

const i18nName = (v: string) => 'features.position.ph_name.' + v;

export default {
  ...defaultFeatureConfig('position.ph_name'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: PH_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  reduce: countedFrequencyReduce,
  renderValue: i18nName,

  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
