import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';
import { APIUploadService } from 'src/app/api/services';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() fileName!: string;
  @Input() src!: string;
  @Input() thumb!: string;
  @Input() bucket!: string;
  @Input() withMiniature = false;
  @Output() finished = new EventEmitter<{
    url: string;
    thumb: string;
    width: number;
    height: number;
  }>();

  // task: AngularFireUploadTask;

  percentage?: Observable<number>;
  snapshot?: Observable<any>;
  downloadURL?: string;

  constructor(private uploadService: APIUploadService) {}

  ngOnInit(): void {
    void this.startUpload().then();
  }

  async startUpload(): Promise<void> {
    // The storage path
    const body = {
      fileName: this.fileName,
      src: this.src,
      thumb: this.thumb,
      bucket: this.bucket,
      miniature: this.withMiniature
    };

    const { src, thumb, width, height } = await firstValueFrom(this.uploadService.uploadFile({ body }));
    this.finished.emit({ url: src, thumb, width, height });
  }

  // isActive(snapshot: any): boolean {
  //   return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  // }
}
