import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContributorGuard } from 'src/app/guards/role.guard';
import { getDefaultLang } from 'src/app/helpers/lang';

import { AppMainComponent } from './app.component';

const routes: Routes = [
  { path: '', redirectTo: '/' + getDefaultLang(), pathMatch: 'full' },
  {
    path: ':lang',
    component: AppMainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule)
      },
      {
        path: 'favourites',
        loadChildren: () => import('./pages/favourites/favourites.module').then((m) => m.FavouritesPageModule)
      },
      {
        path: 'compare',
        loadChildren: () => import('./pages/compare/compare.module').then((m) => m.ComparePageModule)
      },
      {
        path: 'catalog',
        loadChildren: () => import('./pages/catalog/catalog.module').then((m) => m.CatalogPageModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchPageModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./pages/partners/partners.module').then((m) => m.PartnersPageModule)
      },
      {
        path: 'edit',
        loadChildren: () => import('src/app/pages/takson-edit/takson-edit.module').then((m) => m.TaksonEditModule),
        canActivate: [ContributorGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'takson',
        loadChildren: () => import('./pages/takson/takson.module').then((m) => m.TaksonModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule)
      },

      // for backward compatibility from Feb2021
      {
        path: 'knowledgebase/takson',
        loadChildren: () => import('./pages/takson/takson.module').then((m) => m.TaksonModule)
      },
      // for backward compatibility from Feb2021
      {
        path: 'knowledgebase/catalog',
        loadChildren: () => import('./pages/catalog/catalog.module').then((m) => m.CatalogPageModule)
      },
      {
        path: 'docs',
        loadChildren: () => import('src/app/pages/docs/docs.module').then((m) => m.DocsPageModule)
      },
      {
        path: '404',
        loadChildren: () => import('src/app/pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
      },
      {
        path: '401',
        loadChildren: () => import('src/app/pages/not-authorized/not-authorized.module').then((m) => m.NotAuthorizedPageModule)
      },
      {
        path: 'accountsCompare',
        loadChildren: () => import('./pages/accounts-compare/accounts-compare.module').then((m) => m.AccountsComparePageModule)
      },
      {
        path: 'tags',
        loadChildren: () => import('./pages/tags/tags.module').then((m) => m.TagsPageModule)
      }
      // { path: '**', redirectTo: '/404' }
    ]
  },
  { path: '**', redirectTo: '/' + getDefaultLang() + '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
