import { noReduce } from '../config';
import PatternConfig from '../PatternConfig.type';
import { Pattern } from '../selectTypes';

const PATTERNS: { [key: string]: Pattern } = {
  colored_base: {
    base: 'blue',
    spots: [
      {
        center: [50, 0],
        r: 60,
        w: 100,
        h: 100,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [50, 100],
        r: 50,
        w: 100,
        h: 100,
        angle: 0,
        color: 'dark-blue'
      }
    ]
  },
  stripes: {
    base: 'blue',
    spots: [
      {
        center: [20, 50],
        r: 100,
        w: 10,
        h: 200,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [35, 50],
        r: 100,
        w: 10,
        h: 200,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [50, 50],
        r: 100,
        w: 10,
        h: 200,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [65, 50],
        r: 100,
        w: 10,
        h: 200,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [80, 50],
        r: 100,
        w: 10,
        h: 100,
        angle: 0,
        color: 'light-blue'
      }
    ]
  },
  dots: {
    base: 'blue',
    spots: [
      {
        center: [50, 10],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [50, 40],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [50, 70],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [35, 25],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [35, 55],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [35, 85],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [65, 25],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [65, 55],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      },
      {
        center: [65, 85],
        r: 10,
        w: 10,
        h: 10,
        angle: 0,
        color: 'light-blue'
      }
    ]
  }
};

export default {
  ...PatternConfig,
  name: 'flower.pattern',
  selectType: {
    ...PatternConfig.selectType,
    values: Object.values(PATTERNS),
    renderValue: (): string => ''
  },
  reduce: noReduce,
  constraints: [{ name: 'groups.use', values: ['flowering'] }],
  renderName: (): string => 'features.flower.pattern.'
};
