import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  template: `
    <mat-progress-spinner mode="determinate" [value]="percentage" color="primary"></mat-progress-spinner>
    <span>{{ percentage | round }}%</span>
  `,
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
  @Input() percentage!: number;
}
