export function getArc(start: number, end: number, radius: number, toStart = true): string {
  const startX = radius * getCoordinatesForPercent(start)[0];
  const startY = radius * getCoordinatesForPercent(start)[1];
  const endX = radius * getCoordinatesForPercent(end)[0];
  const endY = radius * getCoordinatesForPercent(end)[1];

  const largeArcFlag = start < end ? (Math.abs(end - start) > 0.5 ? 1 : 0) : Math.abs(end + 1 - start) > 0.5 ? 1 : 0;

  return [`M ${startX} ${startY}`, `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, ...(toStart ? [`L 0 0`] : [])].join(' ');
}

function getCoordinatesForPercent(percent: number): [number, number] {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);

  return [x, y];
}
