import { Image as _Image } from 'src/app/types';
import { Injectable } from '@angular/core';
import { HttpService } from '../httpService';

@Injectable({ providedIn: 'root' })
export class TagService {
  constructor(private http: HttpService) {}

  splitDescription(text?: string | null): string[] {
    const regex = /(#\w+)/;
    return text ? text.split(regex) : [];
  }

  public async showByTag(tag: string): Promise<_Image[]> {
    const images = await this.http.get('tags/tags/show', { tag });
    return images.map((i: any) => new _Image(i));
  }
}
