export const defaultLocation = (lang: string): { lat: number; lng: number } => {
  switch (lang) {
    case 'pl':
      return {
        lat: 52.2330653,
        lng: 20.9211126
      };
    default:
      return {
        lat: 37.425713,
        lng: -122.1703694
      };
  }
};
