import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { User } from 'src/app/types';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
  @Input() main = false;
  showBigSearch = false;
  closedPopup = true;
  home = false;

  user: Observable<User | null>;

  constructor(private authService: AuthService, private router: Router) {
    this.user = this.authService.user;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url.split('/').length <= 2) {
          // homepage, only one '/' in the url
          this.home = true;
        } else {
          this.home = false;
        }
      }
    });
  }

  logout(): void {
    this.authService.logout();
    const currentUrl = this.router.url;
    void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));
  }

  login(): void {
    this.authService.redirectToLogin();
  }
}
