import axios from 'axios';
import { environment } from 'src/environments/environment';
import { getDefaultLang } from './lang';

const API = environment.apiUrl;

function showCookie(name: string) {
  if (document.cookie !== '') {
    const cookies = document.cookie.split(/; */);

    for (const cookie of cookies) {
      const cookiePart = cookie.split('=');
      const cookieName = cookiePart[0];
      const cookieVal = cookiePart[1];
      if (cookieName === decodeURIComponent(name)) {
        return decodeURIComponent(cookieVal);
      }
    }
  }
  return false;
}

export interface Meta {
  page: number;
  pageSize: number;
}

const escape = (p: unknown) => (typeof p === 'object' ? JSON.stringify(p) : p);

const authHeader = () => {
  const token = showCookie('authToken');
  const storedData = showCookie('authUser');
  const lang = localStorage.getItem('lang');
  if (storedData && token) {
    const data = JSON.parse(storedData);
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Language': lang || data.lang || getDefaultLang()
      }
    };
  } else {
    return {
      headers: {
        'X-Language': lang || getDefaultLang()
      }
    };
  }
};

export const get = async (url: string, params: any = {}): Promise<any> => {
  const queryString = Object.keys(params)
    .map((k) => `${k}=${String(escape(params[k]))}`)
    .join('&');

  try {
    const { data } = await axios.get(API + url + '?' + queryString, authHeader());
    return data;
  } catch (e: any) {
    console.error('API error', e);
    return Promise.reject(e?.response?.data || e || 'error.unknown');
  }
};

export const getPaged = async (url: string, params: any = {}, pageMeta: { [key: string]: any }): Promise<{ data: any; total: number }> => {
  const queryString = Object.keys(params)
    .map((k: string) => `${k}=${String(escape(params[k]))}`)
    .join('&');
  const pagination = Object.keys(pageMeta)
    .map((k: string) => `${k}=${String(escape(params[k]))}`)
    .join('&');

  try {
    const { data } = await axios.get(API + url + '?' + pagination + '&' + queryString, authHeader());
    return { data: data.data, total: parseInt(data.total, 10) };
  } catch (e: any) {
    console.error('API error', e);
    return Promise.reject(e?.response?.data || e || 'error.unknown');
  }
};

export const post = async (url: string, params: any = {}): Promise<any> => {
  const formData = new FormData();
  Object.keys(params).forEach((k) => {
    if (typeof params[k] === 'boolean') {
      formData.append(k, params[k] ? '1' : '0');
    } else if (typeof params[k] !== 'object') {
      formData.append(k, params[k]);
    } else {
      formData.append(k, JSON.stringify(params[k]));
    }
  });

  try {
    const { data } = await axios.post(API + url, formData, authHeader());
    if (typeof data === 'string' && data.includes('errors.')) {
      return Promise.reject(data);
    }
    return data;
  } catch (e: any) {
    console.error('API error', e);
    return Promise.reject(e?.response?.data || e || 'error.unknown');
  }
};
