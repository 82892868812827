/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIErrorMessage } from '../models/api-error-message';
import { APISubscription } from '../models/api-subscription';
import { APISubscriptionPlans } from '../models/api-subscription-plans';

@Injectable({
  providedIn: 'root'
})
export class APISubscriptionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getUserSubscriptions
   */
  static readonly GetUserSubscriptionsPath = '/user/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSubscriptions$Response(params?: {}): Observable<StrictHttpResponse<{ subscriptions: Array<APISubscription> }>> {
    const rb = new RequestBuilder(this.rootUrl, APISubscriptionService.GetUserSubscriptionsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ subscriptions: Array<APISubscription> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSubscriptions(params?: {}): Observable<{ subscriptions: Array<APISubscription> }> {
    return this.getUserSubscriptions$Response(params).pipe(
      map((r: StrictHttpResponse<{ subscriptions: Array<APISubscription> }>) => r.body as { subscriptions: Array<APISubscription> })
    );
  }

  /**
   * Path part for operation setUserSubscription
   */
  static readonly SetUserSubscriptionPath = '/user/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserSubscription$Response(params: {
    /**
     * New name for a user
     */
    body: { type: APISubscriptionPlans; amount?: number; currency?: string };
  }): Observable<StrictHttpResponse<{ subscription?: APISubscription; error?: APIErrorMessage }>> {
    const rb = new RequestBuilder(this.rootUrl, APISubscriptionService.SetUserSubscriptionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ subscription?: APISubscription; error?: APIErrorMessage }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserSubscription(params: {
    /**
     * New name for a user
     */
    body: { type: APISubscriptionPlans; amount?: number; currency?: string };
  }): Observable<{ subscription?: APISubscription; error?: APIErrorMessage }> {
    return this.setUserSubscription$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ subscription?: APISubscription; error?: APIErrorMessage }>) =>
          r.body as { subscription?: APISubscription; error?: APIErrorMessage }
      )
    );
  }

  /**
   * Path part for operation cancelUserSubscription
   */
  static readonly CancelUserSubscriptionPath = '/user/subscription/{subscriptionId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelUserSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelUserSubscription$Response(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APISubscriptionService.CancelUserSubscriptionPath, 'post');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelUserSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelUserSubscription(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<{ success: boolean }> {
    return this.cancelUserSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }

  /**
   * Path part for operation subscriptionPaymentDone
   */
  static readonly SubscriptionPaymentDonePath = '/user/subscription/{subscriptionId}/paid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPaymentDone()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPaymentDone$Response(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<StrictHttpResponse<{ success?: boolean; error?: APIErrorMessage }>> {
    const rb = new RequestBuilder(this.rootUrl, APISubscriptionService.SubscriptionPaymentDonePath, 'post');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success?: boolean; error?: APIErrorMessage }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionPaymentDone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPaymentDone(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<{ success?: boolean; error?: APIErrorMessage }> {
    return this.subscriptionPaymentDone$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ success?: boolean; error?: APIErrorMessage }>) => r.body as { success?: boolean; error?: APIErrorMessage }
      )
    );
  }
}
