import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const CARE_TYPES = ['demanding', 'easy'] as const;
export type careType = typeof CARE_TYPES[number];

const i18nName = (v: string) => 'features.characteristics.care.' + v;

export default {
  ...defaultFeatureConfig('characteristics.care'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: CARE_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,
  reduce: countedFrequencyReduce,
  renderValue: i18nName
} as FeatureConfig;
