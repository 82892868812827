import { Component, OnInit, Input } from '@angular/core';

import { getShape } from 'src/app/helpers/shapes';
import { Shape } from 'src/app/types/features/selectTypes';

@Component({
  selector: 'app-shape-display',
  templateUrl: './shape-display.component.html',
  styleUrls: ['./shape-display.component.scss']
})
export class ShapeDisplayComponent implements OnInit {
  @Input() value!: Shape;
  @Input() size = 100;

  path?: string;
  mirrorPath?: string;
  scale = 1;

  ngOnInit(): void {
    this.scale = this.size / 300;
    this.calculatePath(this.value);
  }

  calculatePath(val: Shape): void {
    this.path = getShape(val, 300);
    this.mirrorPath = getShape(val, 300, true);
  }
}
