import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { APIFeatureGroup, APIFeatureName } from 'src/app/api/models';
import { SearchService } from 'src/app/services/utilities/search.service';

import { Feature, Features } from 'src/app/types/features';
import { GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features/types';

const SEARCH_GROUPS = [
  'flower.color',

  'groups.type',
  'groups.use',
  'appearance.sort',

  'position.insolation',
  'position.humidity',
  'position.soil',

  'groups.lasting',
  'position.ph_name',
  'position.frost_resistance',

  'characteristics.decorativeness',
  'characteristics.endure',
  'characteristics.care',

  'appearance.max_height',
  'appearance.max_width',

  'flower.inflorescence',
  'flower.flower_shape'
];

@Component({
  selector: 'app-detailed-search',
  templateUrl: './detailed-search.component.html',
  styleUrls: ['./detailed-search.component.scss', '../search.scss']
})
export class DetailedSearchComponent {
  SEARCH_GROUPS = SEARCH_GROUPS.map((g) => GFT.get(g));

  details: BehaviorSubject<boolean>;

  searchForm = this.fb.group({
    searchValue: ['']
  });

  filter: Features | undefined = new Features([]);

  constructor(private fb: FormBuilder, private searchService: SearchService) {
    this.details = this.searchService.detailed;

    this.searchService.searchString.subscribe((text) => {
      this.searchForm = this.fb.group({
        searchValue: [text ? text : '']
      });
    });

    this.searchService.filter.subscribe((filter) => {
      this.filter = filter;
    });
  }

  onSearch(): void {
    this.searchService.doSearch(this.searchForm.value.searchValue);
  }

  getFeature(groupName: string): Feature<any>[] {
    const [group, name] = groupName.split('.');
    return this.filter?.get(groupName) || [Feature.create(group as APIFeatureGroup, name as APIFeatureName, undefined, 0)];
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setFilter(groupName: string, values: any): void {
    this.searchService.setFilter(groupName, values);
  }
}
