import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';

import { inflorescenceType, flowerShape } from 'src/app/types/features/types';
import { colorType, COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';
import { Pattern, Shape } from 'src/app/types/features/selectTypes';
import { CanvasService } from 'src/app/services/canvas.service';

interface HSL {
  h: number;
  s: number;
  l: number;
}

@Component({
  selector: 'app-inflorescence-silhouette',
  templateUrl: './inflorescence-silhouette.component.html',
  styleUrls: ['./inflorescence-silhouette.component.scss']
})
export class InflorescenceSilhouetteComponent implements OnInit, AfterViewInit {
  @Input() width = 100;
  @Input() height = 100;
  @Input() colors: colorType[] = [];
  @Input() type?: inflorescenceType;
  @Input() flowerShape: flowerShape = 'bell-single';
  @Input() parameter = 5;
  @Input() petalShape?: Shape;
  @Input() patterns?: Pattern[];

  @Output() created = new EventEmitter<string>();

  gradientId = Math.random().toString(36);

  STROKE_COLOR = '#0a0a0a';
  FILL_COLOR = '#0a0a0a';
  opacity = [0.3, 0.4, 0.6, 0.7, 0.8];
  shapeMask?: string;

  colorMap: string[] = [];
  @ViewChild('silhouette', { static: false }) silhouette?: ElementRef;

  constructor(private canvasService: CanvasService) {}

  ngOnInit(): void {
    // const sin = (a: number, b: number) => Math.sin(2 * Math.PI * a / 360) - Math.sin(2 * Math.PI * b / 360);
    // const d = (a: HSL, b: HSL) => Math.sqrt(sin(a.h, b.h) * sin(a.h, b.h) + (a.s - b.s) * (a.s - b.s) + (a.l - b.l) * (a.l - b.l))

    if (this.colors) {
      switch (this.colors.length) {
        case 0:
          this.colorMap = [];
          break;
        case 1:
        case 2:
          this.colorMap = this.colors.map((c: string) => COLOR_VALUES[c]).map((c: HSL) => `hsl(${c.h},${c.s}%,${c.l}%)`);
          break;
        default:
          this.colorMap = this.colors
            .map((c: string) => COLOR_VALUES[c])
            .sort((a: HSL, b: HSL) => ((270 + a.h) % 360 > (270 + b.h) % 360 ? -1 : 1))
            .reduce((a: HSL[], b: HSL, idx, array) => {
              if (array.length < 4) {
                return array;
              }
              if (a.length === 0) {
                return [b];
              } else if (a[a.length - 1].h === b.h) {
                const copy = [...a];
                const last = copy[a.length - 1];
                copy[a.length - 1] = { h: last.h, s: (last.s + b.s) / 2, l: (last.l + b.l) / 2 };
                return copy;
              } else {
                return [...a, b];
              }
            }, [])
            .map((c: HSL) => `hsl(${c.h},${c.s}%,${c.l}%)`);
      }
    }

    const shapes = this.flowerShape;
    if (!shapes || typeof shapes !== 'string') {
      this.shapeMask = 'circle';
      return;
    }

    this.shapeMask = this.flowerShape;
  }

  ngAfterViewInit(): void {
    if (this.created.observers.length > 0) {
      this.canvasService
        .svgToDataUrl(this.silhouette?.nativeElement.outerHTML, this.width, this.height)
        .then((src) => this.created.next(src))
        .catch((e) => console.error('TODO error', e));
    }
  }

  getArray(count: number): number[] {
    const a = [];
    if (count % 2 === 0) {
      for (let i = 1; i < count; i += 2) {
        a.push(i);
        a.push(-i);
      }
    } else {
      a.push(0);
      for (let i = 1; i < count / 2; i += 1) {
        a.push(i);
        a.push(-i);
      }
    }

    return a.sort();
  }
}
