const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M51.158,42.251
          c0.943-7.832,0.019-17.881-1.635-22.852c-0.709-2.119,3.498-2.049,2.988-1.148c-0.517,0.897,6.199-4.534,6.787,5.055
          c0.675,10.773-10.396,30.29-10.396,30.29S49.825,49.183,51.158,42.251z"
        />`,
    boundingBox: [48.902, 17.518, 10.426, 36.079],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M55.067,16.041
          c0.146,0.479,0.291,0.991,0.395,1.513c-1.676,0.017-3.239,1.183-2.951,0.706c0.514-0.91-3.697-0.994-3.005,1.129
          c1.68,4.984,2.152,16.438,1.645,22.848l-0.124,0.617c-1.112-6.316-0.24-18.979-6.413-28.147c-1.223-1.845,2.893-2.837,2.642-1.858
          C46.954,13.835,52.102,6.914,55.067,16.041z"
        />`,
    boundingBox: [44.386, 11.257, 11.076, 31.597],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M50.318,36.892
          c-3.218-5.626-9.677-5.256-18.149-9.345c-5.826-2.811,0.598-9.099,1.005-8.839c0.406,0.27,0.579-6.411,8.313-0.425
          c7.73,5.986,7.734,8.474,7.734,8.474S50.512,31.864,50.318,36.892z"
        />`,
    boundingBox: [29.738, 15.784, 60.6, 21.108],
    angle: 60
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M48.778,53.627
          c0,0,11.005-19.184,10.569-29.193c-0.432-9.506-2.628-6.479-1.628-10.76c1.009-4.283,2.979-6.47,5.441-3.505
          c2.444,2.964,5.402,9.5,4.196,14.411c-1.199,4.918-7.146,10.628-8.333,13.172c-1.168,2.542-3.518,6.648-3.518,6.648L48.778,53.627z"
        />`,
    boundingBox: [48.778, 8.805, 18.857, 44.823],
    angle: 20
  }
];

export default paths;
