<div class="canva" *ngIf="value">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="300"
    [attr.height]="300"
    viewbox="0 0 300 300"
    class="svg"
    [ngStyle]="{transform: 'scale('+scale+')'}"
  >
    <!-- <rect fill="#ffff00" width="300" height="300" [attr.opacity]="moving ? 0.5 : 1" /> -->
    <!-- <defs>
      <radialGradient
        *ngFor="let g of value.spots; let idx = index"
        [id]="'pattern-' + id + '-grad-' + idx"
        [attr.cx]="0.5"
        [attr.cy]="0.5"
        [attr.r]="0.5"
      >
        <stop offset="0" [attr.stop-color]="getColor(g.color)" />
        <stop offset="1" [attr.stop-color]="getColor(g.color)" stop-opacity="0" />
      </radialGradient>
    </defs> -->
    <rect [attr.fill]="getColor(value.base)" width="300" height="300" />

    <g *ngFor="let g of value.spots; let idx = index">
      <!-- <rect
        class="grad"
        [attr.fill]="'url(#pattern-' + id + '-grad-' + idx + ')'"
        [attr.width]="3 * g.w"
        [attr.height]="3 * g.h"
        [attr.x]="3 * g.center[0] - (3 * g.w) / 2"
        [attr.y]="3 * g.center[1] - (3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
        
      /> -->
      <ellipse
        [attr.fill]="getColor(g.color)"
        [attr.cx]="3 * g.center[0]"
        [attr.cy]="3 * g.center[1]"
        [attr.rx]="(3 * g.w) / 2"
        [attr.ry]="(3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
      ></ellipse>
    </g>
  </svg>
</div>
