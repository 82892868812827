import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

const LASTING_TYPES = ['annual', 'biennial', 'perennials', 'longlasting'] as const;
export type lastingType = typeof LASTING_TYPES[number];

const i18nName = (v: string) => 'features.groups.lasting.' + v;

export default {
  ...defaultFeatureConfig('groups.lasting'),
  controlType: 'option',
  selectType: {
    values: LASTING_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,

  renderValue: i18nName
} as FeatureConfig;
