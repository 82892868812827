import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { getDefaultLang } from './helpers/lang';
import { ErrorHandlerService } from 'src/app/services/errorHandler.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandlerService) {}

  authHeader = (): {
    'X-Language': string;
  } => {
    const lang = localStorage.getItem('lang');

    return {
      'X-Language': lang || getDefaultLang()
    };
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: this.authHeader()
    });

    // Also handle errors globally
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && (event.body.error || !event.body.data)) {
            throwError(() => event.body.error || 'error.no-data');
          } else {
            return event.clone({ body: event.body.data });
          }
        }

        if (event instanceof HttpErrorResponse) {
          console.error('intercept error', event);
          throwError(() => event.error || 'error.no-data');
          // return event.clone({ body: event.error });
        }
        return event;
      }),
      catchError((event: HttpEvent<any>) => {
        let body = {};
        if (event instanceof HttpErrorResponse) {
          // There is an error object so that we could show it to a user
          if (event.error?.error?.type === 'RESOURCE_NOT_FOUND') {
            body = event.error?.error?.description;
          } else if (event.error?.error && event.error.error.message) {
            body = event.error.error?.details || event.error.error.message;
            this.errorHandler.showErrorMessage(event.error.error.message);
          } else {
            body = event?.message || 'error.unknown';
            this.errorHandler.showError(event);
          }
        } else {
          throwError(() => event);
        }
        return of(new HttpResponse({ body }));
      })
      // catchError((error: HttpErrorResponse) => { console.log('catching error', error);
      //   if (error.status === 401) {
      //     // TODO  refresh token
      //     return throwError(() => error);
      //   } else {
      //     return error.error;
      //   }
      // })
      // map( event => {
      //     if (event instanceof HttpResponse && event.body && (event.body.error || !event.body.data)) {
      //       return throwError(()=> event.body.error || 'error.no-data');
      //     }
      //     return event;
      //   // } catch (e: any) {
      //   //   console.log('newAPI error', e);
      //   //   return Promise.reject(e?.response?.data || e?.response?.error || 'error.unknown');
      //   // }
      //   // },
      //   // error: e => {
      //   //   // Handle this err
      //   //   console.log('newAPI error', e);
      //   //   return Promise.reject(e?.response?.data || e?.response?.error || 'error.unknown');
      //   // }
      // }),
    );
  }
}
