import { Injectable } from '@angular/core';

export interface Credentials {
  email: string;
  password: string;
  name?: string;
}

@Injectable({ providedIn: 'root' })
export class CanvasService {
  resampleSingle(canvas: HTMLCanvasElement, width: number, height: number, resizeCanvas: boolean): void {
    const widthSource = canvas.width;
    const heightSource = canvas.height;
    width = Math.round(width);
    height = Math.round(height);

    const ratioW = widthSource / width;
    const ratioH = heightSource / height;
    const ratioWHalf = Math.ceil(ratioW / 2);
    const ratioHalf = Math.ceil(ratioH / 2);

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const img = ctx.getImageData(0, 0, widthSource, heightSource);
    const img2 = ctx.createImageData(width, height);
    const data = img.data;
    const data2 = img2.data;

    for (let j = 0; j < height; j++) {
      for (let i = 0; i < width; i++) {
        const x2 = (i + j * width) * 4;
        let weight = 0;
        let weights = 0;
        let weightsAlpha = 0;
        let gxR = 0;
        let gxG = 0;
        let gxB = 0;
        let gxA = 0;
        const centerY = (j + 0.5) * ratioH;
        const yyStart = Math.floor(j * ratioH);
        const yyStop = Math.ceil((j + 1) * ratioH);
        for (let yy = yyStart; yy < yyStop; yy++) {
          const dy = Math.abs(centerY - (yy + 0.5)) / ratioHalf;
          const centerX = (i + 0.5) * ratioW;
          const w0 = dy * dy; // pre-calc part of w
          const xxStart = Math.floor(i * ratioW);
          const xxStop = Math.ceil((i + 1) * ratioW);
          for (let xx = xxStart; xx < xxStop; xx++) {
            const dx = Math.abs(centerX - (xx + 0.5)) / ratioWHalf;
            const w = Math.sqrt(w0 + dx * dx);
            if (w >= 1) {
              // pixel too far
              continue;
            }
            // hermite filter
            weight = 2 * w * w * w - 3 * w * w + 1;
            const posX = 4 * (xx + yy * widthSource);
            // alpha
            gxA += weight * data[posX + 3];
            weightsAlpha += weight;
            // colors
            if (data[posX + 3] < 255) {
              weight = (weight * data[posX + 3]) / 250;
            }
            gxR += weight * data[posX];
            gxG += weight * data[posX + 1];
            gxB += weight * data[posX + 2];
            weights += weight;
          }
        }
        data2[x2] = gxR / weights;
        data2[x2 + 1] = gxG / weights;
        data2[x2 + 2] = gxB / weights;
        data2[x2 + 3] = gxA / weightsAlpha;
      }
    }
    // clear and resize canvas
    if (resizeCanvas === true) {
      canvas.width = width;
      canvas.height = height;
    } else {
      ctx.clearRect(0, 0, widthSource, heightSource);
    }

    // draw
    ctx.putImageData(img2, 0, 0);
  }

  svgToDataUrl(html: string, width: number, height: number): Promise<string> {
    // remove foreign objects
    html = html.replace(/<foreignObject.*<app-flower-shapes[^>]*>/gi, '').replace(/<\/app-flower-shapes><\/foreignObject>/gi, '');

    const blob = new Blob([html], { type: 'image/svg+xml;charset=utf-8' });

    function blobToDataURL(blobData: any, callback: (a: any) => any) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => callback(e.target ? e.target.result : null);
      fileReader.readAsDataURL(blobData);
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
    if (!context) {
      return Promise.reject('');
    }

    return new Promise((resolve) => {
      blobToDataURL(blob, (dataurl: string) => {
        const image = new Image();
        image.onload = () => {
          context.drawImage(image, 0, 0, width, height);
          const rasterDataUrl = canvas.toDataURL();
          resolve(rasterDataUrl);
        };
        image.src = dataurl;
      });
    });
  }
}
