import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  private user$?: Subscription;
  @Input() appHasRole!: string | string[];

  constructor(private templateRef: TemplateRef<Node>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

  ngOnInit(): void {
    this.user$ = this.authService.user.subscribe((u) => {
      if (u) {
        if ((Array.isArray(this.appHasRole) && this.appHasRole.includes(u.role)) || this.appHasRole === u.role) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }
}
