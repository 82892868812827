import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { LangName } from 'src/app/types';

import { TaksonomyService } from 'src/app/services/database/taksonomy.service';

@Component({
  selector: 'app-local-name-form',
  templateUrl: './local-name-form.component.html',
  styleUrls: ['./local-name-form.component.scss']
})
export class NamesFormComponent implements OnInit {
  @Input() taxonId!: string;
  @Input() priority = 0;
  @Input() name?: LangName;

  @Output() saved = new EventEmitter<LangName>();
  @Output() removed = new EventEmitter<boolean>();

  nameForm?: FormGroup;
  edit = true;

  constructor(private taksonomyService: TaksonomyService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.nameForm = this.fb.group({
      name: [this.name ? this.name.name : '']
    });
    this.edit = this.name ? false : true;
  }

  async save(): Promise<void> {
    if (!this.nameForm) {
      return;
    }

    const vals = this.nameForm.value;
    if (this.name?.nameId) {
      await this.taksonomyService.editLocalName(this.name.nameId, vals.name, this.priority);
      const newName = { ...this.name };
      newName.name = vals.name;
      this.saved.emit(newName);
    } else {
      const newName = await this.taksonomyService.addLocalName(this.taxonId, vals.name, this.priority);
      this.nameForm = this.fb.group({
        name: ['']
      });
      this.saved.emit(newName);
    }
  }

  async remove(): Promise<void> {
    if (this.name?.nameId) {
      await this.taksonomyService.editLocalName(this.name.nameId, '', this.priority);
      this.removed.emit(true);
    }
  }
}
