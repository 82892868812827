import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { getDefaultLang } from '../helpers/lang';
import { firstValueFrom } from 'rxjs';

const API = environment.apiUrl;

function showCookie(name: string) {
  if (document.cookie !== '') {
    const cookies = document.cookie.split(/; */);

    for (const cookie of cookies) {
      const cookiePart = cookie.split('=');
      const cookieName = cookiePart[0];
      const cookieVal = cookiePart[1];
      if (cookieName === decodeURIComponent(name)) {
        return decodeURIComponent(cookieVal);
      }
    }
  }
  return false;
}

const escape = (p: unknown) => (typeof p === 'object' ? JSON.stringify(p) : p);

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private injector: Injector) {}

  private get http(): HttpClient {
    return this.injector.get(HttpClient);
  }

  private authHeader() {
    const token = showCookie('authToken');
    const storedData = showCookie('authUser');
    const lang = localStorage.getItem('lang');
    if (storedData && token) {
      const data = JSON.parse(storedData);
      return {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
          'X-Language': lang || data.lang || getDefaultLang()
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'X-Language': lang || getDefaultLang()
        })
      };
    }
  }

  async get(url: string, params: any = {}): Promise<any> {
    const queryString = Object.keys(params)
      .map((k) => `${k}=${String(escape(params[k]))}`)
      .join('&');

    try {
      return firstValueFrom(this.http.get<any>(API + url + '?' + queryString, this.authHeader()));
    } catch (e: any) {
      console.error('API error', e);
      return Promise.reject(e?.response?.data || e || 'error.unknown');
    }
  }

  async getPaged(url: string, params: any = {}, pageMeta: { [key: string]: any }): Promise<{ data: any; total: number }> {
    const queryString = Object.keys(params)
      .map((k: string) => `${k}=${String(escape(params[k]))}`)
      .join('&');
    const pagination = Object.keys(pageMeta)
      .map((k: string) => `${k}=${String(escape(params[k]))}`)
      .join('&');

    try {
      const resp = await firstValueFrom(this.http.get<any>(API + url + '?' + pagination + '&' + queryString, this.authHeader()));
      return { data: resp.data, total: parseInt(resp.total, 10) };
    } catch (e: any) {
      console.error('API error', e);
      return Promise.reject(e?.response?.data || e || 'error.unknown');
    }
  }

  async post(url: string, params: any = {}): Promise<any> {
    const formData = new FormData();
    Object.keys(params).forEach((k) => {
      if (typeof params[k] === 'boolean') {
        formData.append(k, params[k] ? '1' : '0');
      } else if (typeof params[k] !== 'object') {
        formData.append(k, params[k]);
      } else {
        formData.append(k, JSON.stringify(params[k]));
      }
    });

    try {
      const resp = await firstValueFrom(this.http.post<any>(API + url, formData, this.authHeader()));
      if (typeof resp === 'string' && resp.includes('errors.')) {
        return Promise.reject(resp);
      }
      return resp;
    } catch (e: any) {
      console.error('API error', e);
      return Promise.reject(e?.response?.data || e || 'error.unknown');
    }
  }
}
