<div [ngClass]="{'svg-container':true, empty:colorSets.all.length ===0}">
  <app-color-indicator [colors]="colorSets[currentColorSetName]"></app-color-indicator>

  <ng-container *ngIf="colorChanging">
    <div [ngClass]="{ img: true, start: true, enabled: currentColorSetName === 'all' || currentColorSetName === 'start' }">
      <img
        src="assets/images/flower-start-stage.svg"
        (click)="setColorSet(0)"
        [alt]="'pages.knowledgebase.features.flower-start-stage' | translate"
        [matTooltip]="'pages.knowledgebase.features.flower-start-stage' | translate"
      />
    </div>
    <div [ngClass]="{ img: true, middle: true, enabled: currentColorSetName === 'all' || currentColorSetName === 'middle' }">
      <img
        src="assets/images/flower-middle-stage.svg"
        (click)="setColorSet(1)"
        [alt]="'pages.knowledgebase.features.flower-middle-stage' | translate"
        [matTooltip]="'pages.knowledgebase.features.flower-middle-stage' | translate"
      />
    </div>
    <div [ngClass]="{ img: true, end: true, enabled: currentColorSetName === 'all' || currentColorSetName === 'end' }">
      <img
        src="assets/images/flower-end-stage.svg"
        (click)="setColorSet(2)"
        [alt]="'pages.knowledgebase.features.flower-end-stage' | translate"
        [matTooltip]="'pages.knowledgebase.features.flower-end-stage' | translate"
      />
    </div>
  </ng-container>
</div>
