/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { APIAdminService } from './services/api-admin.service';
import { APIAssortmentService } from './services/api-assortment.service';
import { APIAuthService } from './services/api-auth.service';
import { APICatalogService } from './services/api-catalog.service';
import { APICollectionService } from './services/api-collection.service';
import { APIContactService } from './services/api-contact.service';
import { APIFavouritesService } from './services/api-favourites.service';
import { APIIdentifyService } from './services/api-identify.service';
import { APIImageService } from './services/api-image.service';
import { APIPartnerService } from './services/api-partner.service';
import { APIPaymentService } from './services/api-payment.service';
import { APIPhotoFeatureService } from './services/api-photo-feature.service';
import { APIPhotoService } from './services/api-photo.service';
import { APISearchService } from './services/api-search.service';
import { APIShoppingService } from './services/api-shopping.service';
import { APITaxonService } from './services/api-taxon.service';
import { APIUploadService } from './services/api-upload.service';
import { APIProfileService } from './services/api-profile.service';
import { APISubscriptionService } from './services/api-subscription.service';
import { APIUserService } from './services/api-user.service';
import { APIZoneService } from './services/api-zone.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    APIAdminService,
    APIAssortmentService,
    APIAuthService,
    APICatalogService,
    APICollectionService,
    APIContactService,
    APIFavouritesService,
    APIIdentifyService,
    APIImageService,
    APIPartnerService,
    APIPaymentService,
    APIPhotoFeatureService,
    APIPhotoService,
    APISearchService,
    APIShoppingService,
    APITaxonService,
    APIUploadService,
    APIProfileService,
    APISubscriptionService,
    APIUserService,
    APIZoneService,
    ApiConfiguration
  ]
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
