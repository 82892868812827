import { Component, OnInit, Input } from '@angular/core';

import { plantType, sortType } from 'src/app/types/features/types';

@Component({
  selector: 'app-sort-silhouette',
  templateUrl: './sort-silhouette.component.html',
  styleUrls: ['./sort-silhouette.component.scss']
})
export class SortSilhouetteComponent implements OnInit {
  @Input() width = 100;
  @Input() height = 100;
  @Input() type!: plantType;
  @Input() sort?: sortType;

  group?: string;

  ngOnInit(): void {
    this.group = (this.type.includes('trees') ? 'trees' : this.type.includes('bushes') ? 'bushes' : 'other') + '_' + (this.sort ?? 'oval');
  }
}
