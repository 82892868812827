<form *ngIf="details | async" [formGroup]="searchForm" class="searchForm">
  <mat-form-field appearance="outline">
    <input matInput [placeholder]="'forms.misc.search' | translate" matInput type="text" formControlName="searchValue" />
  </mat-form-field>

  <app-action-button class="search" variant="iconed" size="large" (click)="onSearch()" customIcon="search"> </app-action-button>
  <app-action-button
    class="more"
    variant="iconed"
    size="large"
    (click)="details.next(false)"
    [matBadge]="filter?.length"
    matBadgeColor="accent"
    [matBadgeHidden]="filter?.length === 0"
    customIcon="filter_list"
  >
  </app-action-button>
</form>

<div *ngIf="details | async" class="details">
  <div *ngFor="let config of SEARCH_GROUPS; let i = index" [class]="i === 0 ? 'color' : ''">
    <ng-container [ngSwitch]="config.controlType">
      <app-search-select
        class="options"
        *ngSwitchCase="'option'"
        [config]="config"
        [value]="getFeature(config.name)[0].value"
        (changed)="setFilter(config.name, $event)"
      >
      </app-search-select>
      <app-search-select
        class="options"
        *ngSwitchCase="'range'"
        [config]="config"
        [value]="getFeature(config.name)[0].value"
        (changed)="setFilter(config.name, $event)"
      >
      </app-search-select>
      <ng-container *ngSwitchCase="'color'">
        <span>
          {{ config.renderName() | translate }}
          <!-- <mat-icon class="clear" (click)="setFilter(config.name,undefined)">cancel</mat-icon> -->
        </span>
        <app-color-select class="options" [feature]="getFeature(config.name)[0]" (changed)="setFilter(config.name, $event.value)">
        </app-color-select>
      </ng-container>
    </ng-container>
  </div>

  <app-action-button class="search" size="large" type="accept" variant="filled" (click)="onSearch()" customIcon="search"
    >{{ 'buttons.apply' | translate }}
  </app-action-button>
</div>
