/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIPhotoSet } from '../models/api-photo-set';
import { APIThumbTaxon } from '../models/api-thumb-taxon';

@Injectable({
  providedIn: 'root'
})
export class APIFavouritesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getFavouritePhotoSet
   */
  static readonly GetFavouritePhotoSetPath = '/favourites/photos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouritePhotoSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritePhotoSet$Response(params?: {}): Observable<StrictHttpResponse<{ photoSet: APIPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APIFavouritesService.GetFavouritePhotoSetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFavouritePhotoSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritePhotoSet(params?: {}): Observable<{ photoSet: APIPhotoSet }> {
    return this.getFavouritePhotoSet$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPhotoSet }>) => r.body as { photoSet: APIPhotoSet })
    );
  }

  /**
   * Path part for operation getFavouritePlants
   */
  static readonly GetFavouritePlantsPath = '/favourites/plants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFavouritePlants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritePlants$Response(params?: {}): Observable<StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIFavouritesService.GetFavouritePlantsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFavouritePlants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFavouritePlants(params?: {}): Observable<{ plants: Array<APIThumbTaxon> }> {
    return this.getFavouritePlants$Response(params).pipe(
      map((r: StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>) => r.body as { plants: Array<APIThumbTaxon> })
    );
  }

  /**
   * Path part for operation setFavouritePhoto
   */
  static readonly SetFavouritePhotoPath = '/favourites/photo/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setFavouritePhoto()` instead.
   *
   * This method doesn't expect any request body.
   */
  setFavouritePhoto$Response(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<StrictHttpResponse<{ added: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIFavouritesService.SetFavouritePhotoPath, 'post');
    if (params) {
      rb.path('photoId', params.photoId, {});
      rb.query('force', params.force, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ added: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setFavouritePhoto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setFavouritePhoto(params: {
    /**
     * Photo Id
     */
    photoId: number;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<{ added: boolean }> {
    return this.setFavouritePhoto$Response(params).pipe(map((r: StrictHttpResponse<{ added: boolean }>) => r.body as { added: boolean }));
  }

  /**
   * Path part for operation setFavouritePlant
   */
  static readonly SetFavouritePlantPath = '/favourites/plant/{taxonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setFavouritePlant()` instead.
   *
   * This method doesn't expect any request body.
   */
  setFavouritePlant$Response(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<StrictHttpResponse<{ added: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIFavouritesService.SetFavouritePlantPath, 'post');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
      rb.query('force', params.force, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ added: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setFavouritePlant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setFavouritePlant(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<{ added: boolean }> {
    return this.setFavouritePlant$Response(params).pipe(map((r: StrictHttpResponse<{ added: boolean }>) => r.body as { added: boolean }));
  }
}
