import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const ENDURE_TYPES = ['cut', 'pollute', 'wind', 'salt', 'replant', 'drought', 'disease', 'pests'] as const;
export type endureType = typeof ENDURE_TYPES[number];

const i18nName = (v: string) => 'features.characteristics.endure.' + v;

export default {
  ...defaultFeatureConfig('characteristics.endure'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: ENDURE_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  reduce: countedFrequencyReduce,
  renderValue: i18nName,

  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
