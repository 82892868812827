<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  [attr.width]="100 * (width ? width : 1)"
  [attr.height]="100 * (height ? height : 1)"
  viewBox="0 0 100 100"
  preserveAspectRatio="none"
  enable-background="new 0 0 100 100"
  xml:space="preserve"
>
  <g [ngSwitch]="group" fill="rgb(124, 159, 106)">
    <g *ngSwitchCase="'trees_columnar'">
      <path
        d="M51.375,79.73c0.238-1.227,0.635-2.5,1.2-3.734c0.195-0.454-1.217-0.895-3.048-0.895H34.442
			c-1.834,0-3.249,0.439-3.048,0.895c0.583,1.275,0.978,2.568,1.214,3.83c-12.329-2.428-11.021,0.189,0.164,4.084
			c-0.579,5.408-4.256,10.74-10.861,15.84h40.125c-6.688-5.1-10.369-10.548-10.876-16.023C62.238,79.904,63.526,77.33,51.375,79.73z
			"
      />
      <g>
        <path
          d="M24.043,80.201C13.118,78.059-9.621,48.849,5.018,34.929c6.833-6.838,17.568-13.432,27.326-19.782
				c7.809-4.64,11.714-10.99,24.399-14.897C74.31,4.158,77.24,12.949,82.118,18.322c7.81,7.327,18.546,14.896,17.57,22.468
				c-1.96,8.304-12.208,17.046-19.037,25.104C76.74,70.777,69.1,80.996,44.704,81.973C44.708,81.973,34.969,82.344,24.043,80.201z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_irregular'">
      <path
        d="M79.506,51.639c0.466-0.389,0.466-1.021,0.009-1.41l-0.111-0.095l-0.111-0.095c-0.456-0.388-1.199-0.388-1.656,0.008
			c-6.18,5.375-12.381,10.227-18.608,14.413c0.121-2.137,0.324-4.176,0.612-6.328c0.102-0.792-0.622-1.374-1.562-1.374h-7.735
			c-0.938,0-1.663,0.582-1.561,1.374c0.297,2.224,0.503,4.388,0.623,6.587c-6.322-4.234-12.634-9.185-18.916-14.653
			c-0.457-0.396-1.199-0.409-1.655-0.021l-0.112,0.088l-0.112,0.092c-0.456,0.386-0.456,1.02,0.009,1.407
			c8.078,6.735,15.134,13.533,20.87,20.324c-0.297,9.435-2.184,18.899-5.568,27.795h20.571c-3.43-9.637-5.316-18.584-5.576-28.136
			C64.597,64.949,71.557,58.262,79.506,51.639z"
      />
      <g>
        <path
          d="M26.069,61.417c-8.688,0.296-17.03,2.367-25.718,1.774c-1.044-5.325,6.256-10.651,10.426-13.315
				c8.688-5.918,9.731-5.03,3.823-13.316c-3.823-5.03-9.036-14.203-5.56-20.713c3.823-6.806,15.986-1.184,23.634-2.368
				c6.256-1.183,8.341-5.622,12.511-9.765c3.475-4.143,4.171-4.734,8.688-0.888c6.604,5.918,11.123,11.836,22.244,10.357
				C84.11,12,91.409,7.562,99.75,8.45c-0.695,3.55-3.476,7.101-6.256,9.468c-3.824,2.959-12.858,4.439-15.294,7.99
				c-3.823,5.622,12.513,14.795,16.684,18.938c4.866,4.734,6.256,9.173-2.084,10.652c-5.908,1.184-13.556-0.296-19.116-1.775
				c-3.128-0.888-12.859-6.214-15.639-5.326c-4.866,1.479,6.438,12.878,3.311,16.429c-2.78,2.959-14.107,6.439-17.931,5.849
				c-7.3-1.185-11.1-5.114-15.619-9.554"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_tapered_oval'">
      <path
        d="M91.458,61.761c0.768-0.306,0.768-0.803,0.017-1.108l-0.183-0.073l-0.183-0.075c-0.751-0.305-1.982-0.305-2.73,0.006
			c-10.2,4.229-20.43,8.045-30.707,11.338c0.202-1.681,0.542-3.505,1.014-5.197c0.168-0.623-1.027-1.303-2.574-1.303H43.348
			c-1.549,0-2.744,0.68-2.577,1.303c0.49,1.748,0.828,3.562,1.027,5.291c-10.428-3.33-20.843-7.17-31.209-11.471
			c-0.754-0.312-1.981-0.295-2.729,0.012l-0.183,0.082l-0.186,0.08c-0.75,0.304-0.75,0.805,0.016,1.108
			c13.327,5.299,24.969,10.72,34.432,16.062c-0.488,7.422-3.603,14.356-9.186,21.938h33.938c-5.658-7.58-8.771-14.689-9.199-22.204
			C66.859,72.305,78.345,66.973,91.458,61.761z"
      />
      <g>
        <path
          d="M1.867,43.604c-4.21,8.792-0.602,19.539,15.643,26.621c9.025,4.152,26.471,1.953,39.708,0.979
				c13.236-0.979,27.675-2.688,33.694-7.814c10.83-9.039,10.229-20.271,6.014-29.798c-3.009-7.328-9.021-13.921-15.642-21.004
				c-3.01-3.419-4.813-6.594-13.238-8.548C59.623,1.841,47.471-1.66,40.943,1.537c-6.095,2.984-13.948,8.967-15.096,10.892
				C24.695,14.351,5.473,36.277,1.867,43.604z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_roundish'">
      <path
        d="M80.692,54.486c0.542-0.364,0.542-0.961,0.013-1.325l-0.131-0.089l-0.129-0.09c-0.528-0.364-1.395-0.364-1.923,0.008
			c-7.184,5.056-14.387,9.619-21.624,13.559c0.142-2.011,0.38-3.928,0.714-5.953c0.118-0.744-0.724-1.293-1.814-1.293h-8.986
			c-1.091,0-1.932,0.549-1.815,1.293c0.346,2.092,0.583,4.127,0.724,6.197c-7.344-3.984-14.678-8.641-21.977-13.785
			c-0.529-0.373-1.394-0.385-1.923-0.02l-0.129,0.082l-0.13,0.087c-0.53,0.364-0.53,0.959,0.011,1.323
			c9.386,6.336,17.583,12.731,24.247,19.119c-0.344,8.877-2.537,17.781-6.468,26.149h23.903c-3.986-9.065-6.18-17.481-6.481-26.47
			C63.37,67.011,71.458,60.719,80.692,54.486z"
      />
      <g>
        <path
          d="M2.164,21.021C12.122,4.253,43.65-4.906,66.469,3.304c21.025,7.444,41.204,21.371,30.153,37.81
				C88.35,53.673,75.758,57.186,57.554,60.132C12.51,67.424-6.121,35.148,2.164,21.021z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_halfround'">
      <path
        d="M80.692,54.486c0.542-0.364,0.542-0.961,0.013-1.325l-0.131-0.089l-0.129-0.09c-0.528-0.364-1.395-0.364-1.923,0.008
			c-7.184,5.056-14.387,9.619-21.624,13.559c0.142-2.011,0.38-3.928,0.714-5.953c0.118-0.744-0.724-1.293-1.814-1.293h-8.986
			c-1.091,0-1.932,0.549-1.815,1.293c0.346,2.092,0.583,4.127,0.724,6.197c-7.344-3.984-14.678-8.641-21.977-13.785
			c-0.529-0.373-1.394-0.385-1.923-0.02l-0.129,0.082l-0.13,0.087c-0.53,0.364-0.53,0.959,0.011,1.323
			c9.386,6.336,17.583,12.731,24.247,19.119c-0.344,8.877-2.537,17.781-6.468,26.149h23.903c-3.986-9.065-6.18-17.481-6.481-26.47
			C63.37,67.011,71.458,60.719,80.692,54.486z"
      />
      <g>
        <path
          d="M2.164,21.021C12.122,4.253,43.65-4.906,66.469,3.304c21.025,7.444,41.204,21.371,30.153,37.81
				C88.35,53.673,75.758,57.186,57.554,60.132C12.51,67.424-6.121,35.148,2.164,21.021z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_umbrella'">
      <path
        d="M75.363,34.038c0.532-0.533,0.532-1.405,0.013-1.939l-0.129-0.13l-0.128-0.13c-0.52-0.534-1.371-0.534-1.892,0.01
			c-7.069,7.397-14.162,14.075-21.287,19.836c0.14-2.94,0.402-7.451,0.732-10.414c0.118-1.09-0.712-2.146-1.784-2.146H42.04
			c-1.074,0-1.901,1.057-1.785,2.146c0.34,3.061,0.543,7.615,0.681,10.644C33.707,46.087,26.487,39.338,19.3,31.812
			c-0.521-0.545-1.372-0.531-1.894,0.003l-0.127,0.137l-0.127,0.134c-0.521,0.533-0.521,1.407,0.01,1.94
			c9.239,9.271,17.309,18.375,23.869,27.721c-0.34,12.985-2.498,25.761-6.368,38.004h23.529c-3.923-12.243-6.08-25.323-6.379-38.472
			C58.312,52.105,66.273,43.155,75.363,34.038z"
      />
      <g>
        <path
          d="M21.615,43.602c6.143-1.325,12.286-2.32,18.429-2.65c9.053-0.331,18.107,0.663,26.837,0.663
				c7.112,0,26.092,7.282,30.567,1.638c8.091-10.203-6.966-32.776-16.989-37.745c-17.136-8.282-37.244-5.97-54.64,1.325
				C10.438,13.282-2.02,39.384,0.6,43.933C3.219,48.482,15.795,44.265,21.615,43.602z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_hanging'">
      <path
        d="M76.744,50.322c0.509-0.399,0.509-1.049,0.011-1.447l-0.122-0.098l-0.122-0.097c-0.497-0.399-1.313-0.399-1.808,0.007
			c-6.753,5.521-13.526,10.504-20.33,14.807c0.133-2.195,0.302-5.12,0.618-7.332c0.11-0.813-0.682-1.412-1.707-1.412h-8.45
			c-1.025,0-1.816,0.6-1.705,1.412c0.325,2.283,0.6,5.338,0.732,7.599c-6.904-4.351-13.801-9.436-20.664-15.054
			c-0.499-0.407-1.312-0.42-1.809-0.021l-0.122,0.09l-0.123,0.095c-0.497,0.397-0.497,1.047,0.011,1.445
			c8.823,6.92,16.531,13.903,22.797,20.879c-0.325,9.693-2.385,19.416-6.083,28.556h22.474c-3.746-9.899-5.808-19.092-6.092-28.905
			C60.456,63.998,68.062,57.127,76.744,50.322z"
      />
      <g>
        <path
          d="M30.031,79.029c-4.632,7.671-19.964,7.578-23.979,0.676C0.186,68.968-1.717,63.73,2.606,51.076
				c3.398-9.972,10.484-25.963,17.279-32.482c7.103-6.902,11.489-12.135,20.444-15.587C58.857-4.663,75.223,4.541,89.12,19.88
				c11.116,12.656,15.331,51.917,3.684,59.563c-11.376,7.468-13.238,1.865-22.193-10.789c-6.2-8.103-10.617-14.047-21.877-13.29
				C36.054,56.216,37.75,65.607,30.031,79.029z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_extensive'">
      <path
        d="M62.188,60.846c0.254-0.313,0.254-0.828,0.004-1.143l-0.061-0.076l-0.062-0.078c-0.249-0.314-0.658-0.314-0.908,0.008
			c-3.395,4.358-6.799,8.293-10.218,11.688c0.066-1.733,0.179-3.446,0.337-5.192c0.056-0.643-0.343-1.175-0.858-1.175h-4.247
			c-0.515,0-0.913,0.532-0.857,1.175c0.163,1.805,0.276,3.59,0.342,5.373c-3.47-3.435-6.936-7.435-10.385-11.869
			c-0.25-0.32-0.658-0.324-0.909-0.01l-0.062,0.075l-0.06,0.076c-0.25,0.313-0.25,0.827,0.004,1.142
			c4.435,5.463,8.309,10.917,11.458,16.426c-0.163,7.652-1.199,15.271-3.057,22.484h11.295c-1.883-7.215-2.92-15.014-3.063-22.762
			C54.001,71.583,57.823,66.221,62.188,60.846z"
      />
      <g>
        <path
          d="M20.067,68.587c-3.259,3.349-5.882,4.004-9.452,5.009c-2.173,1.004-7.604,3.014-9.623-0.337
				c-2.638-4.353,2.528-29.65,3.46-32.329c2.172-5.022,12.687-25.45,16.411-26.455c9.236-3.623,22.626-13.628,32.275-14.209
				c4.299-0.259,6.829,2.678,10.397,5.357c8.07,6.696,23.688,8.739,26.637,27.49c0.777,5.022,13.067,26.655,8.604,38.479
				c-1.023,2.715-17.589,5.482-19.916,3.809c-3.415-2.678-14.918-9.889-18.642-9.219C57.271,66.518,25.809,62.227,20.067,68.587z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_conical'">
      <path
        d="M79.496,67.79c0.641-0.258,0.641-0.681,0.01-0.938l-0.151-0.063l-0.152-0.063c-0.631-0.26-1.656-0.26-2.282,0.006
			c-8.518,3.58-17.064,6.813-25.653,9.603c0.166-1.424,0.45-2.831,0.85-4.267c0.142-0.526-0.859-0.965-2.154-0.965H39.3
			c-1.293,0-2.292,0.438-2.153,0.965c0.411,1.481,0.693,2.949,0.86,4.414c-8.713-2.82-17.413-6.106-26.072-9.75
			c-0.629-0.265-1.655-0.268-2.281-0.008L9.5,66.786L9.347,66.85c-0.63,0.258-0.63,0.68,0.012,0.938
			c11.135,4.488,20.861,8.969,28.767,13.492c-0.411,6.287-3.01,12.546-7.675,18.473h28.354c-4.725-5.927-7.329-12.333-7.686-18.699
			C58.944,76.609,68.539,72.204,79.496,67.79z"
      />
      <g>
        <path
          d="M58.783,70.715c-16.464-0.031-36.95,6.742-53.152,2.479c-13.018-3.03,1.266-20.459,4.481-25.295
				c8.03-10.575,15.899-21.364,23.77-32.153c2.249-3.083,8.782-17.203,19.814-15.323c5.097,0.76,10.117,8.295,11.389,10.002
				c3.568,3.285,5.424,6.551,6.924,10.097c2.125,4.399,2.699,8.993,6.387,13.197c3.208,3.563,8.698,6.374,13.304,9.528
				c8.539,6.16,10.94,11.691,4.008,18.396c-5.448,4.885-12.741,11.866-27.766,10.723L58.783,70.715z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_erected'">
      <path
        d="M70.223,64.92c0.444-0.279,0.444-0.738,0.01-1.02l-0.104-0.068l-0.108-0.068c-0.437-0.281-1.146-0.281-1.586,0.006
			c-5.919,3.893-11.855,7.405-17.819,10.438c0.115-1.547,0.311-3.032,0.587-4.592c0.099-0.573-0.598-1.004-1.496-1.004h-7.408
			c-0.897,0-1.591,0.431-1.495,1.004c0.286,1.611,0.481,3.183,0.598,4.775c-6.053-3.066-12.097-6.649-18.114-10.611
			c-0.437-0.285-1.149-0.295-1.585-0.014l-0.104,0.065l-0.107,0.065c-0.436,0.28-0.436,0.737,0.007,1.02
			c7.735,4.878,14.491,9.793,19.984,14.711c-0.286,6.833-2.092,13.682-5.333,20.123h19.7c-3.283-6.441-5.091-13.451-5.339-20.37
			C55.944,74.553,62.61,69.719,70.223,64.92z"
      />
      <g>
        <path
          d="M38.137,75.749c-1.447-0.884-10.905-5.185-14.755-7.866c-3.85-3.039-8.979-6.438-10.904-9.654
				c-3.207-4.826-2.567-9.833-7.056-14.661c-2.567-3.397-4.493-6.794-5.134-10.37c-0.641-5.543,7.058-11.264,9.621-16.807
				C12.476,12.1,7.345,4.055,16.326,0.658c23.642-1.648,34,2.324,57.734,0.894c15.396-0.894,13.473,0.715,14.755,4.291
				c1.283,3.218,5.134,6.615,7.698,9.833c10.263,11.8-6.415,21.097-14.756,32.361c-4.488,6.436-5.771,12.158-12.829,18.236
				c-1.926,1.608-3.205,3.396-5.775,5.006c-2.564,1.968-10.45,5.031-12.829,5.363C47.948,76.977,39.582,76.634,38.137,75.749z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_creeping'">
      <path d="M52.607,3.522c-0.191,38.473-1.414,57.852-3.6,94.835h13.29c-2.216-37.436-3.432-57.267-3.6-96.223L52.607,3.522z" />
      <g>
        <path
          d="M27.005,44.705C21.591,49.239,3.208,79.552,0.287,94.958c-0.974,4.778,17.642-9.065,21.531-9.03
				c2.852,0.026,8.767,7.971,13.149,3.721c8.765-7.438,11.688-21.246,20.452-28.149c6.332,6.373,9.253,34.524,22.402,30.806
				c3.908-1.299,20.079,11.6,21.483,6.055c2.619-10.344-6.987-32.113-12.093-39.295c-6.001-8.439-14.912-33.599-19.78-46.349
				C64.022,4.748,55.481-8.286,46.715,8.18C46.715,8.18,28.865,43.146,27.005,44.705z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_cushiony'">
      <path
        d="M73.304,42.107c0.422-0.467,0.422-1.229,0.01-1.697l-0.102-0.113l-0.101-0.114c-0.412-0.468-1.085-0.468-1.497,0.009
			c-5.597,6.473-11.207,12.314-16.845,17.356c0.11-2.573,0.295-5.178,0.556-7.771c0.092-0.953-0.564-1.804-1.413-1.804H46.91
			c-0.849,0-1.505,0.851-1.414,1.804c0.271,2.679,0.455,5.359,0.564,8.009c-5.721-5.101-11.435-11.023-17.123-17.609
			c-0.413-0.476-1.085-0.474-1.498-0.006l-0.101,0.115l-0.101,0.115c-0.412,0.466-0.412,1.229,0.009,1.697
			c7.31,8.11,13.698,16.149,18.888,24.326c-0.27,11.362-1.976,22.612-5.039,33.325h18.619c-3.105-10.713-4.813-22.229-5.048-33.735
			C59.809,57.989,66.109,50.085,73.304,42.107z"
      />
      <g>
        <path
          d="M43.269,50.354c-7.675,0.457-14.14-2.896-21.409-6.1c-4.848-2.287-20.328-9.972-21.539-15.92
				c-1.617-7.32,25.034-24.102,32.178-25.887c8.93-2.232,22.562-3.153,31.259-0.893c14.315,3.72,35.629,18.675,35.986,25.293
				c0.452,8.372-22.452,19.417-32.551,20.332c-1.615,0-3.23,0-4.441,0L43.269,50.354z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'trees_oval'">
      <path
        d="M92.782,59.354c0.746-0.326,0.746-0.86,0.013-1.188l-0.179-0.079l-0.179-0.081c-0.729-0.327-1.926-0.327-2.657,0.007
			c-9.926,4.536-19.885,8.631-29.894,12.164c0.198-1.804,0.524-3.629,0.984-5.445c0.166-0.668-1-1.266-2.506-1.266H45.938
			c-1.507,0-2.67,0.598-2.506,1.266c0.478,1.877,0.804,3.756,0.999,5.612c-10.153-3.573-20.292-7.726-30.385-12.341
			c-0.733-0.334-1.926-0.332-2.658-0.005l-0.179,0.081l-0.179,0.08c-0.733,0.326-0.733,0.861,0.017,1.189
			c12.973,5.685,24.306,11.317,33.519,17.049c-0.476,7.963-3.507,15.847-8.944,23.354h33.042
			c-5.505-7.508-8.539-15.579-8.956-23.643C68.83,70.482,80.016,64.943,92.782,59.354z"
      />
      <g>
        <path
          d="M74.76,79.252c5.584-1.094,15.983-16.049,20.385-22.299c6.047-8.931,6.047-21.731,0.546-30.959
				C91.293,17.659,84.696,6.942,68.752,3.073c-21.994-5.656-45.637-2.977-56.083,9.526C6.621,19.148,2.221,28.078,0.574,35.521
				c-1.65,8.632,3.296,15.181,8.246,23.516c4.399,7.441,8.247,12.205,20.345,16.67c9.348,3.572,25.817,5.954,31.89,5.953
				C66.294,81.659,69.178,80.348,74.76,79.252z"
        />
      </g>
    </g>

    <g *ngSwitchCase="'bushes_columnar'">
      <g>
        <path
          d="M24.044,94.254C13.115,91.734-9.62,57.393,5.016,41.025c6.831-8.04,17.569-15.793,27.331-23.259
				C40.154,12.31,44.056,4.845,56.744,0.25C74.31,4.845,77.237,15.181,82.123,21.499c7.807,8.614,18.541,17.516,17.565,26.417
				c-1.952,9.762-12.202,20.042-19.033,29.516c-3.903,5.742-11.551,17.756-35.948,18.904C44.708,96.336,34.974,96.771,24.044,94.254
				z"
        />
      </g>
      <path d="M33.761,80.92c-0.578,6.359-4.262,12.334-10.86,18.83h40.123c-6.685-6.496-10.369-12.557-10.873-18.996L33.761,80.92z" />
    </g>
    <g *ngSwitchCase="'bushes_irregular'">
      <g>
        <path
          d="M26.07,79.021c-8.689,0.381-17.031,3.049-25.719,2.287C-0.693,74.449,6.606,67.59,10.778,64.16
				c8.688-7.621,9.731-6.479,3.822-17.148c-3.822-6.478-9.036-18.291-5.562-26.675c3.823-8.765,15.988-1.524,23.634-3.049
				c6.255-1.524,8.341-7.24,12.512-12.575c3.476-5.335,4.17-6.097,8.687-1.144c6.604,7.622,11.122,15.243,22.245,13.338
				c7.994-1.525,15.293-7.241,23.633-6.098c-0.695,4.573-3.477,9.146-6.256,12.194c-3.823,3.811-12.859,5.717-15.293,10.29
				c-3.821,7.241,12.513,19.055,16.684,24.389c4.865,6.098,6.255,11.813-2.086,13.719c-5.908,1.524-13.555-0.381-19.114-2.285
				c-3.128-1.145-12.859-8.004-15.641-6.859c-4.865,1.904,6.439,16.584,3.312,21.156c-2.78,3.811-14.107,8.295-17.93,7.531
				c-7.299-1.523-11.1-6.586-15.618-12.303"
        />
      </g>
      <path d="M50.072,63.918c-0.297,12.15-2.185,24.377-5.568,35.832h20.57c-3.43-12.41-5.316-23.919-5.576-36.223L50.072,63.918z" />
    </g>
    <g *ngSwitchCase="'bushes_tapered_oval'">
      <g>
        <path
          d="M1.867,57.951c-4.211,11.701-0.602,26.003,15.643,35.43c9.025,5.526,26.473,2.6,39.708,1.301
				c13.235-1.301,27.679-3.576,33.694-10.4c10.831-12.028,10.228-26.979,6.016-39.657c-3.008-9.751-9.023-18.527-15.64-27.954
				c-3.01-4.551-4.815-8.777-13.239-11.377c-8.421-2.925-20.574-7.585-27.104-3.331C34.85,5.934,26.996,13.897,25.847,16.457
				C24.699,19.018,5.477,48.199,1.867,57.951z"
        />
      </g>
      <path d="M36.673,69.585c-0.51,10.243-3.737,20.508-9.525,30.165h35.197c-5.87-9.657-9.097-20.143-9.541-30.516L36.673,69.585z" />
    </g>
    <g *ngSwitchCase="'bushes_roundish'">
      <g>
        <path
          d="M2.165,32.227C12.124,6.414,43.651-7.687,66.471,4.951c21.025,11.46,41.203,32.901,30.151,58.209
				c-8.271,19.334-20.863,24.742-39.067,29.277C12.512,103.663-6.122,53.975,2.165,32.227z"
        />
      </g>
      <path d="M40.845,59.508C40.5,73.173,38.308,86.867,34.376,99.75h23.901c-3.984-12.883-6.178-26.872-6.479-40.709L40.845,59.508z" />
    </g>
    <g *ngSwitchCase="'bushes_halfround'">
      <g>
        <path
          d="M2.165,32.227C12.124,6.414,43.651-7.687,66.471,4.951c21.025,11.46,41.203,32.901,30.151,58.209
				c-8.271,19.334-20.863,24.742-39.067,29.277C12.512,103.663-6.122,53.975,2.165,32.227z"
        />
      </g>
      <path d="M40.845,59.508C40.5,73.173,38.308,86.867,34.376,99.75h23.901c-3.984-12.883-6.178-26.872-6.479-40.709L40.845,59.508z" />
    </g>
    <g *ngSwitchCase="'bushes_umbrella'">
      <g>
        <path
          d="M21.616,85.621c6.143-2.609,12.285-4.566,18.429-5.219c9.053-0.651,18.106,1.305,26.836,1.305
				c7.111,0,26.092,14.34,30.567,3.227c8.091-20.093-6.967-64.545-16.989-74.331C63.323-5.707,43.214-1.153,25.818,13.212
				C10.438,25.913-2.019,77.314,0.599,86.271C3.218,95.229,15.795,86.926,21.616,85.621z"
        />
      </g>
      <path d="M44.604,25.02c-0.341,25.574-2.499,50.621-6.369,74.73h23.53c-3.924-24.109-6.082-49.999-6.38-75.895L44.604,25.02z" />
    </g>
    <g *ngSwitchCase="'bushes_hanging'">
      <g>
        <path
          d="M30.03,92.922c-4.631,9.021-19.964,8.915-23.978,0.794C0.186,81.085-1.716,74.925,2.606,60.038
				c3.398-11.73,10.484-30.541,17.278-38.209C26.986,13.71,31.374,7.554,40.329,3.494c18.528-9.024,34.895,1.804,48.79,19.847
				c11.117,14.888,15.333,61.072,3.686,70.066c-11.376,8.782-13.238,2.194-22.193-12.69c-6.198-9.531-10.616-16.525-21.876-15.635
				C36.055,66.084,37.751,77.134,30.03,92.922z"
        />
      </g>
      <path d="M45.818,64.904c-0.326,11.402-2.386,22.828-6.083,33.578h22.474c-3.746-10.75-5.81-22.422-6.092-33.967L45.818,64.904z" />
    </g>
    <g *ngSwitchCase="'bushes_extensive'">
      <g>
        <path
          d="M20.066,90.119c-3.259,4.402-5.882,5.266-9.452,6.586c-2.173,1.32-7.604,3.964-9.622-0.441
				c-2.639-5.725,2.527-38.992,3.459-42.516c2.173-6.604,12.686-33.469,16.411-34.791c9.236-4.764,22.626-17.921,32.276-18.686
				c4.298-0.34,6.828,3.523,10.397,7.046c8.07,8.806,23.688,11.493,26.637,36.152c0.776,6.605,13.065,35.053,8.604,50.604
				c-1.023,3.568-17.59,7.211-19.917,5.009c-3.414-3.522-14.918-13.005-18.643-12.125C57.27,87.396,25.809,81.754,20.066,90.119z"
        />
      </g>
      <path d="M44.073,70.012c-0.164,10.064-1.199,19.975-3.057,29.463H52.31c-1.882-9.488-2.918-19.703-3.061-29.895L44.073,70.012z" />
    </g>
    <g *ngSwitchCase="'bushes_conical'">
      <g>
        <path
          d="M58.785,91.566c-16.465-0.041-36.952,8.736-53.153,3.211c-13.021-3.928,1.266-26.512,4.479-32.78
				c8.028-13.705,15.901-27.687,23.77-41.668c2.252-3.995,8.786-22.293,19.817-19.857c5.098,0.984,10.12,10.75,11.387,12.962
				c3.57,4.257,5.429,8.49,6.919,13.085c2.132,5.701,2.703,11.654,6.391,17.102c3.208,4.618,8.698,8.26,13.306,12.348
				c8.537,7.983,10.939,15.151,4.006,23.84c-5.447,6.33-12.74,15.379-27.763,13.896L58.785,91.566z"
        />
      </g>
      <path d="M39.821,75.725c-0.41,8.147-3.011,16.346-7.675,24.025h28.356c-4.728-8.32-7.331-16.038-7.689-24.287L39.821,75.725z" />
    </g>
    <g *ngSwitchCase="'bushes_erected'">
      <path d="M41.367,74.088c-0.292,8.715-2.146,17.447-5.469,25.662h20.205c-3.369-8.215-5.222-17.136-5.479-25.96L41.367,74.088z" />
      <g>
        <path
          d="M38.133,94.118c-1.445-1.101-10.905-6.446-14.754-9.78c-3.849-3.779-8.979-8.003-10.903-12.004
				C9.27,66.332,9.907,60.107,5.419,54.105c-2.567-4.224-4.49-8.447-5.131-12.893c-0.642-6.891,7.057-14.004,9.621-20.895
				c2.568-5.334-2.564-15.337,6.416-19.561c23.644-2.049,34,2.89,57.734,1.111c15.396-1.111,13.473,0.889,14.755,5.335
				c1.282,4.001,5.132,8.225,7.699,12.226c10.263,14.671-6.417,26.229-14.755,40.234c-4.49,8.003-5.771,15.116-12.83,22.673
				c-1.924,2.002-3.207,4.225-5.773,6.225c-2.566,2.446-10.451,6.256-12.829,6.67C47.944,95.643,39.58,95.217,38.133,94.118z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'bushes_creeping'">
      <path d="M51.568,28.491c-0.191,38.473-0.937,34.175-3.123,71.159h13.289c-2.216-37.438-3.907-33.59-4.075-72.547L51.568,28.491z" />
      <g>
        <path
          d="M27.004,45.996C21.591,50.53,3.208,80.844,0.287,96.25c-0.975,4.779,17.642-9.064,21.531-9.029
				c2.852,0.025,8.767,7.971,13.149,3.719c8.766-7.437,11.688-21.246,20.452-28.149c6.333,6.373,9.254,34.524,22.402,30.806
				c3.909-1.299,20.079,11.6,21.484,6.055c2.619-10.344-6.987-32.113-12.094-39.295c-6.001-8.439-14.911-33.6-19.78-46.349
				c-3.409-7.967-11.95-21-20.717-4.534C46.715,9.472,28.864,44.438,27.004,45.996z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'bushes_cushiony'">
      <g>
        <path
          d="M43.268,89.662c-7.675,0.813-14.139-5.168-21.408-10.885c-4.848-4.084-20.329-17.795-21.54-28.41
				C-1.296,37.304,25.354,7.358,32.499,4.171c8.93-3.981,22.562-5.626,31.259-1.592c14.314,6.639,35.629,33.325,35.986,45.135
				c0.449,14.938-22.453,34.651-32.551,36.284c-1.617,0-3.232,0-4.443,0L43.268,89.662z"
        />
      </g>
      <path d="M45.019,40.04c-0.271,20.276-1.977,40.594-5.041,59.71H58.6c-3.105-19.116-4.813-39.872-5.049-60.401L45.019,40.04z" />
    </g>
    <g *ngSwitchCase="'bushes_oval'">
      <path
        d="M90.997,59.354c0.636-0.326,0.636-0.862,0.017-1.188l-0.154-0.08l-0.151-0.08c-0.622-0.327-1.64-0.327-2.259,0.007
			c-8.44,4.535-16.904,8.631-25.408,12.164c0.166-1.805,0.442-3.629,0.837-5.445c0.138-0.668-0.852-1.266-2.132-1.266H51.184
			c-1.282,0-2.27,0.598-2.13,1.266c0.405,1.877,0.683,3.754,0.848,5.611C41.272,66.768,32.657,62.615,24.077,58
			c-0.621-0.333-1.638-0.331-2.258-0.004l-0.153,0.08l-0.153,0.082c-0.62,0.324-0.62,0.861,0.014,1.188
			c11.028,5.687,20.66,11.318,28.493,17.049c-0.406,7.963-2.98,15.849-7.603,23.354h28.085c-4.681-7.507-7.258-15.579-7.615-23.642
			C70.641,70.484,80.146,64.943,90.997,59.354z"
      />
      <g>
        <path
          d="M74.762,93.202c5.582-1.287,15.983-18.884,20.383-26.237c6.046-10.508,6.046-25.568,0.549-36.425
				C91.296,20.734,84.699,8.124,68.756,3.572C46.76-3.082,23.115,0.07,12.671,14.78C6.621,22.485,2.224,32.992,0.576,41.749
				c-1.655,10.156,3.293,17.86,8.243,27.668c4.397,8.757,8.248,14.36,20.342,19.615c9.351,4.2,25.822,7.004,31.895,7.004
				C66.293,96.034,69.176,94.488,74.762,93.202z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_columnar'">
      <g>
        <path
          d="M24.044,97.564C13.117,94.956-9.623,59.404,5.018,42.46c6.828-8.323,17.567-16.349,27.328-24.078
				C40.153,12.734,44.054,5.006,56.744,0.25c17.563,4.756,20.487,15.457,25.374,21.997c7.811,8.918,18.541,18.133,17.57,27.348
				c-1.95,10.106-12.203,20.748-19.032,30.556c-3.902,5.943-11.553,18.382-35.945,19.57C44.708,99.721,34.972,100.172,24.044,97.564
				z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_irregular'">
      <g>
        <path
          d="M26.071,86.59c-8.69,0.417-17.032,3.34-25.72,2.506C-0.691,81.577,6.607,74.059,10.778,70.3
				c8.688-8.354,9.731-7.1,3.823-18.796c-3.823-7.1-9.036-20.048-5.562-29.237c3.824-9.607,15.989-1.671,23.634-3.341
				c6.256-1.67,8.341-7.936,12.512-13.783c3.476-5.848,4.172-6.683,8.688-1.253c6.604,8.354,11.121,16.707,22.244,14.62
				c7.992-1.671,15.292-7.937,23.632-6.684c-0.693,5.012-3.476,10.024-6.256,13.365c-3.823,4.177-12.858,6.266-15.292,11.278
				C74.381,44.404,90.716,57.354,94.887,63.2c4.865,6.682,6.255,12.948-2.086,15.034c-5.907,1.673-13.555-0.418-19.115-2.504
				c-3.129-1.256-12.86-8.772-15.64-7.52C53.18,70.3,64.484,86.391,61.357,91.4c-2.781,4.177-14.108,9.092-17.931,8.256
				c-7.299-1.672-11.1-7.221-15.618-13.484"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_tapered_oval'">
      <g>
        <path
          d="M1.868,59.822C-2.345,71.902,1.265,86.668,17.51,96.4c9.026,5.705,26.474,2.684,39.709,1.343
				c13.236-1.343,27.677-3.691,33.695-10.738c10.828-12.418,10.227-27.854,6.014-40.943c-3.01-10.068-9.023-19.128-15.642-28.86
				c-3.012-4.699-4.815-9.062-13.24-11.746c-8.42-3.02-20.572-7.831-27.101-3.438c-6.096,4.101-13.951,12.321-15.1,14.965
				C24.7,19.626,5.477,49.753,1.868,59.822z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_roundish'">
      <g>
        <path
          d="M2.165,34.101C12.123,6.613,43.651-8.401,66.472,5.056C87.495,17.26,107.675,40.09,96.621,67.04
				c-8.27,20.587-20.861,26.346-39.066,31.175C12.511,110.169-6.121,57.259,2.165,34.101z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_halfround'">
      <g>
        <path
          d="M2.165,34.101C12.123,6.613,43.651-8.401,66.472,5.056C87.495,17.26,107.675,40.09,96.621,67.04
				c-8.27,20.587-20.861,26.346-39.066,31.175C12.511,110.169-6.121,57.259,2.165,34.101z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_umbrella'">
      <g>
        <path
          d="M21.616,85.621c6.143-2.609,12.285-4.566,18.429-5.219c9.053-0.651,18.106,1.305,26.836,1.305
				c7.111,0,26.092,14.34,30.567,3.227c8.091-20.093-6.967-64.545-16.989-74.331C63.323-5.707,43.214-1.153,25.818,13.212
				C10.438,25.913-2.019,77.314,0.599,86.271C3.218,95.229,15.795,86.926,21.616,85.621z"
        />
      </g>
      <path d="M44.604,25.02c-0.341,25.574-2.499,50.621-6.369,74.73h23.53c-3.924-24.109-6.082-49.999-6.38-75.895L44.604,25.02z" />
    </g>
    <g *ngSwitchCase="'other_hanging'">
      <g>
        <path
          d="M30.03,92.922c-4.631,9.021-19.964,8.915-23.978,0.794C0.186,81.085-1.716,74.925,2.606,60.038
				c3.398-11.73,10.484-30.541,17.278-38.209C26.986,13.71,31.374,7.554,40.329,3.494c18.528-9.024,34.895,1.804,48.79,19.847
				c11.117,14.888,15.333,61.072,3.686,70.066c-11.376,8.782-13.238,2.194-22.193-12.69c-6.198-9.531-10.616-16.525-21.876-15.635
				C36.055,66.084,37.751,77.134,30.03,92.922z"
        />
      </g>
      <path d="M45.818,64.904c-0.326,11.402-2.386,22.828-6.083,33.578h22.474c-3.746-10.75-5.81-22.422-6.092-33.967L45.818,64.904z" />
    </g>
    <g *ngSwitchCase="'other_extensive'">
      <g>
        <path
          d="M29.098,99.325c-4.241-0.258-16.979-0.081-20.348-2.545C4.027,93.325-3.593,71.325,2.535,53.823
				c2.283-6.52,12.792-33.469,16.549-34.791C28.396,14.268,41.899,1.111,51.63,0.346c5.791-0.455,8.947,2.276,11.605,2.979
				c9.197,2.433,14.631,9.75,22.86,25.75c2.646,5.143,17.301,35.199,12.802,50.75C97.866,83.394,67.829,98.32,64.073,99.2
				C60.296,99.761,38.958,99.925,29.098,99.325z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_conical'">
      <g>
        <path
          d="M57.771,99.047c-15.384-0.042-34.068,2.334-52.014-1.219C-7.492,95.205,6.94,70.45,9.942,63.978
				c7.501-14.153,14.856-28.591,22.208-43.029c2.103-4.125,8.206-23.021,18.513-20.505C55.427,1.46,60.117,11.544,61.3,13.829
				c3.337,4.396,5.071,8.767,6.467,13.512c1.99,5.887,2.524,12.035,5.971,17.661c2.998,4.768,7.885,8.918,10.75,13.545
				c5.937,9.583,12.722,13.501,14.984,24.417c1.478,7.141-3.131,14.755-8.905,14.833C84.79,97.875,57.771,99.047,57.771,99.047z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_erected'">
      <g>
        <path
          d="M38.136,98.426c-1.446-1.15-10.907-6.742-14.754-10.229c-3.851-3.953-8.98-8.37-10.906-12.555
				C9.27,69.365,9.91,62.855,5.419,56.579c-2.566-4.418-4.49-8.836-5.131-13.485C-0.354,35.887,7.344,28.448,9.91,21.24
				c2.566-5.58-2.564-16.042,6.415-20.459c23.644-2.143,33.999,3.021,57.733,1.162c15.396-1.163,13.473,0.93,14.755,5.58
				c1.283,4.185,5.133,8.602,7.7,12.787c10.263,15.344-6.417,27.433-14.756,42.081c-4.489,8.37-5.772,15.811-12.83,23.713
				c-1.925,2.095-3.208,4.418-5.773,6.511c-2.566,2.559-10.451,6.543-12.83,6.976C47.948,100.02,39.582,99.574,38.136,98.426z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_creeping'">
      <g>
        <path
          d="M27.005,44.703C21.591,49.237,3.208,79.55,0.287,94.956c-0.974,4.779,17.642-9.064,21.531-9.029
				c2.852,0.025,8.767,7.971,13.149,3.719c10.875-9.226,13.025,7.765,19.854,8.395c6.481,1,9.852-2.018,23.001-5.736
				c3.908-1.3,20.079,11.598,21.483,6.055c2.619-10.344-6.987-32.114-12.093-39.297c-6.001-8.438-14.912-33.598-19.78-46.347
				c-3.41-7.967-11.951-21-20.718-4.534C46.715,8.179,28.865,43.145,27.005,44.703z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_cushiony'">
      <g>
        <path
          d="M43.269,99.667c-7.675,0.903-14.139-5.746-21.409-12.103c-4.848-4.541-20.328-19.787-21.539-31.59
				C-1.296,41.45,25.354,8.154,32.499,4.61c8.93-4.427,22.562-6.255,31.258-1.771c14.316,7.382,35.63,37.054,35.986,50.185
				c0.45,16.61-22.452,38.528-32.55,40.345c-1.616,0-3.232,0-4.443,0L43.269,99.667z"
        />
      </g>
    </g>
    <g *ngSwitchCase="'other_oval'">
      <g display="inline">
        <path
          d="M74.763,96.809c5.583-1.338,15.982-19.615,20.38-27.256c6.048-10.916,6.048-26.56,0.55-37.838
				C91.295,21.528,84.697,8.429,68.754,3.7C46.76-3.212,23.116,0.062,12.671,15.342C6.623,23.346,2.224,34.26,0.576,43.357
				C-1.079,53.908,3.868,61.911,8.82,72.098c4.396,9.096,8.246,14.918,20.34,20.377c9.35,4.362,25.82,7.274,31.893,7.274
				C66.293,99.749,69.177,98.143,74.763,96.809z"
        />
      </g>
    </g>
  </g>
</svg>
