const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M58.313,19.122
          c0-3.059,4.572-3.713,4.572-5.059s-2.047-2.438-4.572-2.438c-1.852,0-3.438,0.589-4.156,1.433
          c-0.719-0.844-2.305-1.433-4.156-1.433s-3.438,0.589-4.156,1.434c-0.719-0.844-2.305-1.434-4.156-1.434
          c-2.525,0-4.572,1.091-4.572,2.438c0,1.335,4.509,2.368,4.509,5.059s-6.375,8.174-6.375,13.972c0,9.372,6.59,16.969,14.719,16.969
          s14.719-7.597,14.719-16.969C64.688,27.296,58.313,22.181,58.313,19.122z"
        />`,
    boundingBox: [35.25, 11.625, 29.438, 38.438],
    angle: 0
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M35.404,35.421
          c0.042,0.35,0.078,0.702,0.139,1.045c0.045,0.257,0.109,0.506,0.165,0.759c0.062,0.281,0.115,0.566,0.188,0.842
          c0.076,0.287,0.171,0.563,0.26,0.842c0.073,0.229,0.137,0.463,0.218,0.688c0.112,0.311,0.243,0.609,0.37,0.909
          c0.077,0.181,0.146,0.367,0.228,0.544c0.151,0.327,0.32,0.642,0.489,0.956c0.074,0.137,0.141,0.279,0.218,0.413
          c0.194,0.338,0.404,0.663,0.619,0.984c0.064,0.097,0.124,0.198,0.19,0.293c0.239,0.343,0.493,0.67,0.754,0.991
          c0.051,0.062,0.098,0.128,0.15,0.189c0.285,0.339,0.583,0.661,0.891,0.972c0.034,0.034,0.066,0.071,0.1,0.105
          c0.33,0.326,0.672,0.634,1.025,0.926c0.017,0.013,0.032,0.028,0.048,0.042c0.37,0.303,0.753,0.587,1.147,0.85
          c0.001,0.001,0.002,0.002,0.004,0.002c1.998,1.331,4.293,2.137,6.743,2.255h0c0.207,0.01,0.411,0.036,0.62,0.036
          c4.501,0,8.524-2.336,11.225-6.006c0.579-1.289,0.984-2.826,1.119-4.681c0.75-10.313-10.337-20.328-20.586-19.926
          c-0.055,0.026-0.102,0.054-0.156,0.081c-0.549,2.811-6.32,8.042-6.32,13.564c0,0.578,0.026,1.149,0.075,1.712
          C35.343,35.014,35.38,35.215,35.404,35.421z"
        />`,
    boundingBox: [35.25, 19.438, 27.099, 30.625],
    angle: 0
  }
];

export default paths;
