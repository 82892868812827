const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M42.552,20.348
          C44.146,23.563,50,50,50,50s-14.617-0.589-13.686-12.932c0.561-7.443-4.139-23.016-4.139-23.016S40.956,17.131,42.552,20.348z"
        />`,
    boundingBox: [31.175, 14.052, 17.825, 35.948],
    angle: 22
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M63.402,37.106
          C63.653,49.444,50,50,50,50s5.958-26.414,7.565-29.623c1.607-3.21,10.401-6.254,10.401-6.254S63.25,29.625,63.402,37.106z"
        />`,
    boundingBox: [50, 14.123, 17.967, 35.877],
    angle: -22
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M56.5,20.125
          C58.983,37.625,50,50,50,50s-8.625-12.375-6.5-29.875c0.433-3.563,6.5-10.25,6.5-10.25S55.996,16.571,56.5,20.125z"
        />`,
    boundingBox: [43.163, 9.875, 13.771, 40.125],
    angle: 0
  }
];

export default paths;
