import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { getDefaultLang } from 'src/app/helpers/lang';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  increaseHeight?: boolean;

  constructor(private router: Router) {
    const date = new Date();
    this.year = date.getFullYear();
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.onResize());
  }

  @HostListener('window:resize')
  onResize(): void {
    if (
      this.router['location']._platformLocation.location.href === environment.protocol + environment.userDomain + '/' + getDefaultLang() &&
      window.innerWidth < 1149
    ) {
      this.increaseHeight = true;
    } else {
      this.increaseHeight = false;
    }
  }
}
