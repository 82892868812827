import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

export const UNIQUE_ID = new InjectionToken<string>('UNIQUE_ID');

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    readonly router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) readonly document: Document,
    @Inject(UNIQUE_ID) readonly app: 'main' | 'user' | 'admin' | 'partner'
  ) {}

  /** The Window object from Document defaultView */
  get window(): Window | null {
    return this.document.defaultView;
  }

  /** Redirects to the specified external link with the mediation of the router */
  private redirect(url: string, target = '_self'): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.window) {
        try {
          resolve(!!this.window.open(url, target));
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  // /** Navigates to the given url, redirecting when necessary
  //  * @param url An absolute URL. The function does not apply any delta to the current URL.
  //  * When starting with 'http(s)://' triggers the external redirection.
  //  * @param extras (optional). An object containing properties that modify the navigation strategy.
  //  * The function ignores any properties that would change the provided URL.
  //  */
  // public navigate(url: string, extras?: RedirectionExtras): Promise<boolean> {
  //   return extras && extras.queryParams && extras.queryParams.app
  //     ? // Redirects to external link
  //       this.redirect(url, extras && extras.target)
  //     : // Navigates with the router otherwise
  //       this.router.navigateByUrl(url, extras);
  // }

  navigateToApp(base: string, app: string): Promise<boolean> {
    // there is no app, so int's internal link
    if (app === this.app) {
      return this.router.navigateByUrl(base);
    } else {
      let domain = '';
      switch (app) {
        case 'main':
          domain = environment.mainDomain;
          break;
        case 'user':
          domain = environment.userDomain;
          break;
        case 'partner':
          domain = environment.partnerDomain;
          break;
        case 'admin':
          domain = environment.adminDomain;
          break;
        default:
          domain = environment.mainDomain;
      }
      const route = environment.protocol + domain + base;
      this.redirect(route)
        .then(() => false)
        .catch((e) => console.error('TODO error', e));
      this.router.navigate(this.route.snapshot.url).catch((e) => console.error('TODO error', e));
      return Promise.resolve(true);
    }
  }
}
