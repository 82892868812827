import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appIsLogged]'
})
export class IsLoggedDirective implements OnInit, OnDestroy {
  private user$?: Subscription;

  constructor(private templateRef: TemplateRef<Node>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

  ngOnInit(): void {
    this.user$ = this.authService.user.subscribe((u) => {
      if (u) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }
}
