export const environment = {
  production: true,
  apiUrl: 'https://viridis.zone/api/',
  newApiUrl: 'https://api.viridis.zone',
  storageUrl: 'https://storage.viridis.zone',
  protocol: 'https://',
  mainDomain: 'viridis.zone',
  userDomain: 'app.viridis.zone',
  adminDomain: 'admin.viridis.zone',
  partnerDomain: 'partner.viridis.zone',
  googleClientId: '179594247147-5001j6sur31jirughd302vnk22b4tc2c.apps.googleusercontent.com',
  facebookClientId: '876962989530676',
  keycloakConfig: {
    config: {
      clientId: 'frontend-',
      realm: 'viridis',
      url: 'https://sso.viridis.zone/auth'
    },
    initOptions: {
      messageReceiveTimeout: 5000,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-sso-check.html'
    }
  },
  paymentServiceUrl: 'https://secure.przelewy24.pl/trnRequest/'
};
