export function getDefaultLang(): string {
  const supportedLangs = ['pl', 'en'];

  const selectedLang = localStorage.getItem('lang');
  // if there is already selected language - it will be the default one
  if (selectedLang) {
    if (supportedLangs.includes(selectedLang)) {
      return selectedLang;
    } else {
      return 'en';
    }
  }

  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  // if there are no browser languages - select en to be default
  if (!browserLocales) {
    return 'en';
  }

  // if there are browser languages - select first from the list
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    const lang = trimmedLocale.split(/-|_/)[0];
    if (supportedLangs.includes(lang)) {
      return lang;
    } else {
      return 'en';
    }
  })[0];
}
