import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

const SILHOUETTE_TYPES = ['flower', 'inflorescence', 'leaf', 'branch', 'fruit'] as const;
export type silhouetteType = typeof SILHOUETTE_TYPES[number];

const i18nName = (v: string) => 'features.groups.silhouette.' + v;

export default {
  ...defaultFeatureConfig('groups.silhouette'),
  controlType: 'option',
  selectType: {
    values: SILHOUETTE_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,

  renderValue: i18nName
} as FeatureConfig;
