/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class APIProfileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation setUserProfileLang
   */
  static readonly SetUserProfileLangPath = '/user/profile/lang';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserProfileLang()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserProfileLang$Response(params: {
    /**
     * New lang for a user
     */
    body: { lang: string };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIProfileService.SetUserProfileLangPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserProfileLang$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserProfileLang(params: {
    /**
     * New lang for a user
     */
    body: { lang: string };
  }): Observable<{ success: boolean }> {
    return this.setUserProfileLang$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }

  /**
   * Path part for operation setUserProfileName
   */
  static readonly SetUserProfileNamePath = '/user/profile/name';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserProfileName()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserProfileName$Response(params: {
    /**
     * New name for a user
     */
    body: { name: string };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIProfileService.SetUserProfileNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserProfileName$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserProfileName(params: {
    /**
     * New name for a user
     */
    body: { name: string };
  }): Observable<{ success: boolean }> {
    return this.setUserProfileName$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }
}
