<div class="canva" *ngIf="value; else noBlock">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="300"
    viewbox="0 0 300 300"
    (mousemove)="mouseMoving($event)"
    (click)="stopMoving($event)"
    [ngClass]="{ shifted: shifted }"
  >
    <!-- <rect fill="#ffff00" width="300" height="300" [attr.opacity]="moving ? 0.5 : 1" /> -->
    <!-- <defs>
      <radialGradient
        *ngFor="let g of value.spots; let idx = index"
        [id]="'pattern-' + id + '-grad-' + idx"
        [attr.cx]="0.5"
        [attr.cy]="0.5"
        [attr.r]="0.5"
      >
        <stop offset="0" [attr.stop-color]="getColor(g.color)" />
        <stop offset="1" [attr.stop-color]="getColor(g.color)" stop-opacity="0" />
      </radialGradient>
    </defs> -->
    <rect [attr.fill]="getColor(value.base)" width="300" height="300" />
    <line y2="300" x2="150" y1="0" x1="150" stroke-width="1" stroke="#aaa" fill="none" />
    <g *ngFor="let g of value.spots; let idx = index">
      <!-- <rect
        class="grad"
        [attr.fill]="'url(#pattern-' + id + '-grad-' + idx + ')'"
        [attr.width]="3 * g.w"
        [attr.height]="3 * g.h"
        [attr.x]="3 * g.center[0] - (3 * g.w) / 2"
        [attr.y]="3 * g.center[1] - (3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
        
      /> -->
      <ellipse
        [attr.fill]="getColor(g.color)"
        stroke="#aaa"
        stroke-width="1"
        [attr.cx]="3 * g.center[0]"
        [attr.cy]="3 * g.center[1]"
        [attr.rx]="(3 * g.w) / 2"
        [attr.ry]="(3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
        (click)="startMove('center-' + idx, $event)"
      ></ellipse>
      <circle
        class="center"
        [attr.r]="selected === idx ? 7 : 3"
        stroke="#000"
        [attr.stroke-width]="selected === idx ? 3 : 1"
        fill="#fff"
        [attr.cx]="3 * g.center[0]"
        [attr.cy]="3 * g.center[1]"
        (click)="select(idx)"
      ></circle>
      <circle
        class="pointer"
        r="3"
        stroke="#000"
        stroke-width="1"
        [attr.fill]="moving === 'focalX-' + idx ? 'red' : '#000'"
        [attr.cx]="focals[idx][0][0]"
        [attr.cy]="focals[idx][0][1]"
        (click)="startMove('focalX-' + idx, $event)"
      ></circle>

      <circle
        class="pointer"
        [attr.fill]="moving === 'focalY-' + idx ? 'red' : '#fff'"
        r="3"
        stroke="#000"
        stroke-width="1"
        [attr.cx]="focals[idx][1][0]"
        [attr.cy]="focals[idx][1][1]"
        (click)="startMove('focalY-' + idx, $event)"
      ></circle>
    </g>
  </svg>
</div>
<div class="options">
  <svg
    *ngFor="let p of predefined; let i = index"
    width="100"
    height="100"
    viewbox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
    (click)="selectTemplate(i)"
  >
    <!-- <defs>
      <radialGradient
        *ngFor="let g of p.spots; let idx = index"
        [id]="'temp-' + id + '-grad-' + idx + '-' + i"
        [attr.cx]="0.5"
        [attr.cy]="0.5"
        [attr.r]="0.5"
      >
        <stop offset="0" [attr.stop-color]="getColor(g.color)" />
        <stop offset="1" [attr.stop-color]="getColor(g.color)" stop-opacity="0" />
      </radialGradient>
    </defs> -->
    <rect [attr.fill]="getColor(p.base)" width="100" height="100"></rect>
    <g *ngFor="let g of p.spots; let idx = index" transform="scale(0.3)">
      <ellipse
        [attr.fill]="getColor(g.color)"
        stroke="#aaa"
        stroke-width="1"
        [attr.cx]="3 * g.center[0]"
        [attr.cy]="3 * g.center[1]"
        [attr.rx]="(3 * g.w) / 2"
        [attr.ry]="(3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
      ></ellipse>
    </g>
  </svg>
</div>
<ng-container *ngIf="value">
  <div *ngIf="selected !== undefined; else baseColorBlock">
    <span>Color for {{ selected }}</span>
    <app-color-indicator
      [colors]="[value.spots[selected].color]"
      [availbleColors]="availbleColors || []"
      (clicked)="selectColor($event)"
    ></app-color-indicator>
    <app-action-button type="deny" variant="filled" (click)="delete()">{{ 'misc.delete' | translate }}</app-action-button>
  </div>
  <ng-template #baseColorBlock>
    <span>Base color</span>
    <app-color-indicator
      [colors]="[value.base]"
      [availbleColors]="availbleColors || []"
      (clicked)="selectColor($event)"
    ></app-color-indicator>
  </ng-template>
</ng-container>

<ng-template #noBlock>
  <div>Select predefined template to start</div>
</ng-template>
