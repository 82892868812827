import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { LightboxModule } from 'ngx-lightbox';

import { CustomMaterialModule } from 'src/app/components/custom.material.module';

import { ImageCropperModule } from 'ngx-image-cropper';
import { PipeModule } from 'src/app/pipes/pipe.module';

import { LayoutModule } from '@angular/cdk/layout';
import { ComponentsModule } from 'src/app/components/components.module';

import { ResultsComponent } from './results/results.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [ResultsComponent, DoughnutChartComponent],
  exports: [ResultsComponent, DoughnutChartComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    CustomMaterialModule,
    RouterModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    PipeModule,
    DirectivesModule,
    LightboxModule,
    LayoutModule,
    ChartsModule
  ]
})
export class MainComponentsModule {}
