import { OptionType } from '../selectTypes';

import { FeatureConfig, multipleOptionFeatureCompare, defaultFeatureConfig, flattenReduce } from '../config';

const USE_TYPES = [
  'inside',
  'balcony',
  'garden',
  'park',
  'hedge',
  'covering',
  'flowering',
  'fruits',
  'eatable',
  'wild',
  'water',
  'city'
] as const;
export type useType = typeof USE_TYPES[number];

const i18nName = (v: string) => 'features.groups.use.' + v;

export default {
  ...defaultFeatureConfig('groups.use'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: USE_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  reduce: flattenReduce,
  renderValue: i18nName,
  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
