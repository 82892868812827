<h2>{{ 'imageControl.transfer' | translate }}</h2>
<h4>{{ 'imageControl.transfer-text' | translate }}</h4>
<mat-form-field appearance="fill" class="chooseBucket">
  <mat-label>{{ 'bucket' | translate }}</mat-label>
  <mat-select [(value)]="selectedBucket">
    <mat-option *ngFor="let bucket of buckets" [value]="bucket.bucketId">
      {{ bucket.takson?.localName ? bucket.takson?.localName : bucket.takson?.latinName }} {{ bucket.bucketName.startsWith('buckets.') ? ''
      : ' | ' + bucket.bucketName | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
<section class="delete-buttons">
  <app-action-button type="cancel" (click)="onCancel()"> {{ 'imageControl.back' | translate }} </app-action-button>
  <app-action-button type="accept" variant="filled" (click)="onTransfer()" [disabled]="selectedBucket === bucketId" [loading]="loading">
    {{ 'imageControl.confirm' | translate }}
  </app-action-button>
</section>
