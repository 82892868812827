import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FavouritesService } from 'src/app/services/user/favourites.service';
import { Image, ThumbTaxon } from 'src/app/types';

@Component({
  selector: 'app-favourites-icon',
  template: `<div (click)="toggle($event)">
    <mat-icon
      [ngClass]="{ fav: (isFavourite | async) }"
      [matTooltip]="((isFavourite | async) ? 'forms.favourites.remove' : 'forms.favourites.add') | translate"
      matTooltipPosition="above"
    >
      {{ (isFavourite | async) ? 'favorite' : 'favorite_border' }}
    </mat-icon>
    <span *ngIf="menuItem">{{ ((isFavourite | async) ? 'misc.favourite.remove' : 'misc.favourite.add') | translate }}</span>
  </div>`,
  styles: [
    `
      :host {
        position: relative;
        text-align: left;
      }
      div {
        display: contents;
      }
      mat-icon {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        font-size: 1.2em !important;
        width: 1em !important;
        height: 1em !important;
        color: #ebe6e1;
      }
      span {
        margin-left: calc(2em + 20px);
      }
      mat-icon.fav {
        background-image: linear-gradient(45deg, #b50a22, #ff3b73);
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    `
  ]
})
export class FavouriteIconComponent implements OnInit {
  @Input() taxon?: ThumbTaxon;
  @Input() photo?: Image;
  @Input() menuItem = false;
  @Output() favouriteSet = new EventEmitter<boolean>();

  isFavourite?: BehaviorSubject<boolean>;

  constructor(private favouritesService: FavouritesService) {}

  ngOnInit(): void {
    this.isFavourite = this.photo
      ? this.favouritesService.favouritePhoto(this.photo.imageId)
      : this.taxon
      ? this.favouritesService.favouriteTaxon(this.taxon.taxonId)
      : undefined;
  }

  toggle(e: Event): boolean {
    if (this.taxon) void this.favouritesService.toggleFavourite(this.taxon);
    if (this.photo) {
      void this.favouritesService.toggleFavouritePhoto(this.photo).then((state) => this.isFavourite?.next(state));
    }

    e.stopPropagation();
    return false;
  }
}
