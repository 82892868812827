/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class APIPhotoFeatureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addPhotoFeature
   */
  static readonly AddPhotoFeaturePath = '/photo/feature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPhotoFeature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPhotoFeature$Response(params: {
    /**
     * Data of the photo to be added
     */
    body: { photoId: number; featureName: string; value: Array<string> };
  }): Observable<StrictHttpResponse<{ photoFeatureId: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoFeatureService.AddPhotoFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoFeatureId: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPhotoFeature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPhotoFeature(params: {
    /**
     * Data of the photo to be added
     */
    body: { photoId: number; featureName: string; value: Array<string> };
  }): Observable<{ photoFeatureId: number }> {
    return this.addPhotoFeature$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoFeatureId: number }>) => r.body as { photoFeatureId: number })
    );
  }

  /**
   * Path part for operation deletePhotoFeature
   */
  static readonly DeletePhotoFeaturePath = '/photo/feature/{featureId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePhotoFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePhotoFeature$Response(params: {
    /**
     * Id of the photo feature
     */
    featureId: number;
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoFeatureService.DeletePhotoFeaturePath, 'delete');
    if (params) {
      rb.path('featureId', params.featureId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePhotoFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePhotoFeature(params: {
    /**
     * Id of the photo feature
     */
    featureId: number;
  }): Observable<{ success: boolean }> {
    return this.deletePhotoFeature$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }

  /**
   * Path part for operation getPhotoFeatures
   */
  static readonly GetPhotoFeaturesPath = '/photo/features/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPhotoFeatures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoFeatures$Response(params: {
    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<StrictHttpResponse<{ taxons?: Array<{ featureId: number; featureName: string; value: string }> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoFeatureService.GetPhotoFeaturesPath, 'get');
    if (params) {
      rb.path('photoId', params.photoId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons?: Array<{ featureId: number; featureName: string; value: string }> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPhotoFeatures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoFeatures(params: {
    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<{ taxons?: Array<{ featureId: number; featureName: string; value: string }> }> {
    return this.getPhotoFeatures$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ taxons?: Array<{ featureId: number; featureName: string; value: string }> }>) =>
          r.body as { taxons?: Array<{ featureId: number; featureName: string; value: string }> }
      )
    );
  }
}
