import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from 'src/app/services/language/language.service';
import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

import { FeatureConfig } from 'src/app/types/features/config';

@Component({
  selector: 'app-text-display',
  templateUrl: './text-display.component.html',
  styleUrls: ['./text-display.component.scss']
})
export class TextDisplayComponent implements OnInit {
  @Input() feature!: Feature<any>;
  @Input() saved?: BehaviorSubject<boolean>;
  @Input() modified?: BehaviorSubject<Feature<any> | null>;
  @Input() styleClass: 'left' | 'center' = 'left';

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;
  featureModified = false;

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);

    const feature = this.feature;

    this.privateVal = feature.value;
    this.initVal = this.privateVal;

    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.featureModified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
    if (this.modified) {
      this.modified.subscribe((newFeature: Feature<any> | null) => {
        if (!newFeature) {
          return;
        }
        if (this.group && this.name && newFeature.groupName === this.group + '.' + this.name) {
          this.privateVal = newFeature.value;
          this.featureModified = true;
        }
      });
    }
  }

  isArray(val: unknown): boolean {
    return Array.isArray(val);
  }
  isRange(val: { [key: string]: any }): boolean {
    return val.min !== undefined;
  }
}
