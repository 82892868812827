import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Range } from 'src/app/types/utilities';

@Component({
  selector: 'app-range-field',
  templateUrl: './range-field.component.html',
  styleUrls: ['./range-field.component.scss']
})
export class RangeFieldComponent implements OnInit {
  @Input() value!: Range;
  @Input() label!: string;
  @Input() options!: number[];
  @Input() free = false;
  @Input() focused = false;

  @Output() changed = new EventEmitter<any>();
  range: { min?: number; max?: number } = {};

  @Input() render: (val: any) => string = (val: any) => val.toString();

  ngOnInit(): void {
    if (this.value && (this.value.max || this.value.min)) {
      this.focused = true;
      this.range = this.value;
    }
  }

  setRange(value: number | undefined, type: 'min' | 'max'): void {
    this.range[type] = value;
    this.changed.emit(this.range);
  }
}
