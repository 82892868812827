import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const CUTTING_TYPES = ['tolerant', 'dontLike', 'needs'] as const;
export type cuttingType = typeof CUTTING_TYPES[number];

const i18nName = (v: string) => 'features.appearance.cutting_ratio.' + v;

export default {
  ...defaultFeatureConfig('appearance.cutting_ratio'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor', 'company'],
  selectType: {
    values: CUTTING_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,
  reduce: countedFrequencyReduce,
  renderValue: i18nName
} as FeatureConfig;
