import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const HUMIDITY_TYPES = ['wet', 'mild', 'dry'] as const;
export type humidityType = typeof HUMIDITY_TYPES[number];

const i18nName = (v: string) => 'features.position.humidity.' + v;

export default {
  ...defaultFeatureConfig('position.humidity'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor', 'company'],
  selectType: {
    values: HUMIDITY_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  constraints: [
    {
      name: 'groups.type',
      values: [
        'leefy_trees',
        'needle_trees',
        'leefy_bushes',
        'needle_bushes',
        'prostrate_shrubs',
        'herbaceous_plants',
        'grass',
        'creepers',
        'other'
      ]
    }
  ],
  reduce: countedFrequencyReduce,
  renderValue: i18nName,

  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
