/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class APIZoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getZoneStats
   */
  static readonly GetZoneStatsPath = '/zone/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getZoneStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZoneStats$Response(params?: {}): Observable<StrictHttpResponse<{ species: number; varieties: number; photos: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIZoneService.GetZoneStatsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ species: number; varieties: number; photos: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getZoneStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZoneStats(params?: {}): Observable<{ species: number; varieties: number; photos: number }> {
    return this.getZoneStats$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ species: number; varieties: number; photos: number }>) =>
          r.body as { species: number; varieties: number; photos: number }
      )
    );
  }
}
