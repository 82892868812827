import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar, private languageService: LanguageService) {}

  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  showSnackbar(
    title: string | string[],
    color: 'default' | 'warning' | 'success' | 'error' = 'default',
    closeText = 'snackBar.close'
  ): void {
    const text = Array.isArray(title) ? title.map((t) => this.languageService.instant(t)).join(', ') : this.languageService.instant(title);
    const close = this.languageService.instant(closeText);

    const style = color + '-snackbar';
    this.snackBar.open(text, close, {
      duration: 12000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [style]
    });
  }

  showStatusSnackbar(success: boolean, successMsg?: string, errorMsg?: string): void {
    if (success) {
      this.showSnackbar(successMsg || 'snackBar.msg.success', 'success');
    } else {
      this.showSnackbar(errorMsg || 'snackBar.msg.error', 'warning');
    }
  }
}
