import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

const GROWTH_TYPES = ['fast', 'modarate', 'slowly'] as const;
export type growthType = typeof GROWTH_TYPES[number];

const i18nName = (v: string) => 'features.appearance.growth_strength.' + v;

export default {
  ...defaultFeatureConfig('appearance.growth_strength'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: GROWTH_TYPES,
    multiple: false,
    renderValue: i18nName
  } as OptionType,

  renderValue: i18nName
} as FeatureConfig;
