import { Component, OnInit, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

import { FeatureConfig } from 'src/app/types/features/config';

@Component({
  selector: 'app-form-display',
  templateUrl: './form-display.component.html',
  styleUrls: ['./form-display.component.scss']
})
export class FormDisplayComponent implements OnInit {
  @Input() feature!: Feature<any>;
  @Input() saved!: BehaviorSubject<boolean>;
  @Input() modified!: BehaviorSubject<Feature<any> | null>;

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;
  featureModified = false;

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }

  ngOnInit(): void {
    if (!this.feature) {
      return;
    }
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);

    const feature = this.feature;

    this.privateVal = feature.value;
    this.initVal = this.privateVal;

    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.featureModified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
    if (this.modified) {
      this.modified.subscribe((newFeature: Feature<any> | null) => {
        if (!newFeature) {
          return;
        }
        if (newFeature.featureId === this.feature.featureId) {
          this.privateVal = newFeature.value;
          this.featureModified = true;
        }
      });
    }
  }

  isArray(val: unknown): boolean {
    return Array.isArray(val);
  }
  isRange(val: { [key: string]: any }): boolean {
    return val.min !== undefined;
  }
}
