<svg
  #silhouette
  version="1.1"
  id="Layer_2"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 200 200"
  enable-background="new 0 0 200 200"
  xml:space="preserve"
  [ngSwitch]="type"
>
  <defs>
    <mask height="1" width="1" y="0" x="0" id="mask-circle">
      <g fill="white" transform="scale(1.5)">
        <circle id="circle" opacity="0.9" cx="9.89" cy="13.188" r="7.5" />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-bud">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.9"
          d="M14.38,15.493c0.043,1.837-0.996,3.244-2.162,4.066c1.316-4.066-1.34-10.706-2.358-12.975
			c0.13-0.216,0.217-0.325,0.217-0.325S14.313,12.378,14.38,15.493z"
        />
        <path
          opacity="0.8"
          d="M12.218,19.559c-0.735,0.518-1.535,0.82-2.12,0.863H9.969c-0.152,0-0.305-0.021-0.477-0.064
			c1.859-3.135,0.779-10.488,0.216-13.516c-0.023-0.021,0-0.021,0-0.021c0.064-0.086,0.108-0.173,0.151-0.237
			C10.878,8.853,13.534,15.493,12.218,19.559z"
        />
        <path
          opacity="0.7"
          d="M9.492,20.357c-0.303-0.086-0.627-0.217-0.972-0.434c-1.363-0.777-2.79-2.466-2.79-4.195
			c0-2.075,2.27-6.118,3.568-8.194c0.086-0.152,0.174-0.303,0.259-0.433c0.044-0.086,0.086-0.173,0.151-0.26
			C10.271,9.869,11.352,17.223,9.492,20.357z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-urn">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.9"
          d="M16.98,13.894c-0.033,1.106-0.593,2.431-1.498,3.601
			c0.421-4.334,0.141-5.003-2.602-9.414c-2.135-3.444-4.723-3.583-6.171-3.289C6.179,4.604,5.058,4.714,4.886,4.215
			c-0.265-0.717,0.218-0.981,1.59-1.371c1.355-0.375,1.807,0.873,1.807,0.873s0.375-1.731,2.356-1.435
			c1.962,0.296,2.118,1.854,2.118,1.854s3.552-1.355,3.771-0.233c0.236,1.137-2.043,0.078-2.274,1.746
			C14.035,7.316,17.244,6.396,16.98,13.894z"
        />
        <path
          opacity="0.8"
          d="M15.482,17.494c-0.312,0.42-0.684,0.809-1.073,1.166
			c-1.263,1.156-2.853,1.934-4.537,1.811c-4.752-0.346-7.823-4.426-6.498-9.913C4.23,7.005,5.353,6.647,6.179,6.116
			c0.297-0.202,0.545-0.421,0.749-0.81C7.068,5.025,6.943,4.87,6.709,4.792c1.449-0.295,4.037-0.156,6.171,3.289
			C15.623,12.491,15.903,13.16,15.482,17.494z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-pea">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M11.019,15.493c0.41-3.405,0.009-7.774-0.712-9.936
			c-0.308-0.921,1.521-0.891,1.3-0.499c-0.223,0.39,2.696-1.971,2.952,2.198c0.293,4.684-4.52,13.17-4.52,13.17
			S10.439,18.507,11.019,15.493z"
        />
        <path
          opacity="0.7"
          d="M12.719,4.097c0.062,0.208,0.127,0.431,0.172,0.658
			c-0.729,0.007-1.408,0.514-1.284,0.307C11.831,4.666,10,4.63,10.301,5.553c0.73,2.167,0.936,7.147,0.715,9.934l-0.054,0.268
			c-0.484-2.746-0.104-8.252-2.788-12.238C7.642,2.715,9.432,2.283,9.322,2.709C9.191,3.138,11.43,0.129,12.719,4.097z"
        />
        <path
          opacity="0.6"
          d="M10.654,13.163c-1.399-2.446-4.207-2.285-7.891-4.063
			c-2.533-1.222,0.26-3.956,0.437-3.843C3.377,5.374,3.452,2.47,6.815,5.072c3.361,2.603,3.362,3.685,3.362,3.685
			S10.739,10.977,10.654,13.163z"
        />
        <path
          opacity="0.9"
          d="M9.984,20.44c0,0,4.786-8.342,4.596-12.694
			c-0.187-4.133-1.143-2.817-0.708-4.678c0.439-1.862,1.295-2.813,2.367-1.524c1.064,1.289,2.348,4.131,1.824,6.266
			c-0.52,2.138-3.108,4.621-3.623,5.727c-0.508,1.105-1.53,2.891-1.53,2.891L9.984,20.44z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-slipper">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M18.639,20.42c-0.201,0.181-1.843,1.653-3.618,1.653
			c-1.887,0-4.146-2.18-4.146-2.18S9.93,23.713,9.065,19.96c-5.023,4.214-7.72,0.81-8.506-0.651
			c1.562-0.248,2.764-1.888,4.427-2.405c1.494-0.45,3.146,0.36,3.495,0.54c0.022,0.012,0.034,0.021,0.045,0.034
			c1.09,0.967,1.393,1.809,1.393,1.809s0.146-1.169,1.619-1.843c0,0,0,0,0.011,0c0.304-0.058,2.651-0.349,4.188,1.663
			C17.213,21.027,18.47,20.501,18.639,20.42z"
        />
        <path
          opacity="0.6"
          d="M11.604,17.41c-0.057,0.021-0.09,0.033-0.09,0.033
			s0.01,0,0.022,0c-1.473,0.674-1.616,1.843-1.616,1.843s-0.304-0.842-1.394-1.809c-0.011-0.013-0.022-0.022-0.044-0.034
			c-0.101-0.09-0.214-0.18-0.326-0.27c-0.733-0.664-2.126-2.215-1.193-3.933c1.372-2.529,2.45-6.35,2.45-6.35s3.28,3.08,4.012,6.968
			C13.885,16.286,12.021,17.23,11.604,17.41z"
        />
        <path
          opacity="0.9"
          d="M9.06,19.96c-0.764,0.628-2.557,1.947-2.646,3.565
			c-0.107,2.017-0.136,4.113,3.244,4.113c3.381,0,3.729-1.198,3.694-3.707c-0.021-1.508-2.476-4.045-2.476-4.045
			S10.069,22.569,9.06,19.96z"
        />
      </g>
    </mask>

    <mask height="1" width="1" y="0" x="0" id="mask-stellate-single">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.7"
          d="M12.067,13.933c-0.122,1.346-1.344,2.672-2.077,3.37
			C9.676,17.58,9.45,17.759,9.45,17.759s-0.087-0.071-0.245-0.192c-0.593-0.487-2.008-1.848-2.094-3.752v-0.019
			c-0.035-0.118-0.018-0.259-0.018-0.4c0.174-2.617,2.514-7.137,2.514-7.137s1.869,4.118,2.338,6.544
			C12.05,13.256,12.084,13.641,12.067,13.933z"
        />
        <path
          opacity="0.8"
          d="M17.895,23.291c-3.053-0.992-2.527,0.438-5.284-0.82
			c-0.14-0.068-0.28-0.139-0.403-0.207c-1.271-0.732-1.935-1.926-2.267-2.898c-0.296-0.837-0.35-1.502-0.35-1.502
			s0.402-0.176,1.029-0.314c0.019,0.018,0.019,0.018,0.035,0c0.456-0.086,1.05-0.158,1.712-0.121c0.247,0,0.526,0.035,0.782,0.089
			C15.087,17.859,17.704,20.623,17.895,23.291z"
        />
        <path
          opacity="0.8"
          d="M9.712,19.416c0.018,0.872-0.228,1.936-1.17,2.76
			c-0.349,0.313-0.751,0.555-1.205,0.713H7.319c-1.988,0.766-4.729,0.365-6.787-0.051c1.832-1.379,2.513-3.598,4.031-4.713
			c0.262-0.19,0.524-0.346,0.838-0.473c1.641-0.61,3.106-0.189,3.717,0.051c0.208,0.072,0.332,0.141,0.332,0.141
			S9.712,18.525,9.712,19.416z"
        />
        <path
          opacity="0.9"
          d="M16.816,15.803c-1.067,1.013-2.953,1.447-4.451,1.625
			c-0.666-0.037-1.259,0.035-1.711,0.121c-0.019,0.018-0.019,0.018-0.036,0c-0.417,0.018-0.664,0.018-0.664,0.018
			c0-0.086,0.018-0.176,0.036-0.262c0.733-0.696,1.955-2.022,2.077-3.368c0.017-0.296-0.018-0.681-0.122-1.133
			c0.262-0.299,0.558-0.525,0.89-0.716c1.711-1.013,4.543-0.385,5.445-2.006C18.719,11.702,18.82,13.883,16.816,15.803z"
        />
        <path
          opacity="0.9"
          d="M9.205,17.566v0.089c0,0-0.035,0.017-0.087,0.052
			c-0.611-0.244-2.076-0.662-3.717-0.052c-0.314,0.124-0.576,0.279-0.838,0.47c-0.489-0.207-0.96-0.54-1.396-1.046
			c-1.536-1.711-2.583-5.918-2.583-5.918s5.393,2.078,6.528,2.635v0.019C7.197,15.719,8.612,17.078,9.205,17.566z"
        />
        <path
          opacity="0.9"
          d="M12.555,23.816c0.28,3.053-2.022,4.592-2.791,6.02
			c-0.419-2.529-2.985-2.967-2.531-6.545c0.018-0.119,0.052-0.26,0.086-0.398h0.019c0.453-0.158,0.855-0.402,1.205-0.717
			c0.942-0.822,1.188-1.887,1.17-2.759c0.086-0.087,0.122-0.121,0.122-0.121s0.036,0.017,0.104,0.071
			c0.333,0.975,0.996,2.164,2.27,2.896C12.382,22.73,12.503,23.24,12.555,23.816z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-stellate-double">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.7"
          d="M12.059,13.937c0,0.05-0.018,0.121-0.035,0.175
			c-0.455,0.141-0.873,0.368-1.204,0.715c-0.872,0.942-1.065,2.218-1.1,2.724c-0.174,0.121-0.279,0.208-0.279,0.208
			s-0.035-0.035-0.103-0.087c0-0.821-0.054-1.745-1.117-2.6c-0.281-0.259-0.628-0.471-1.013-0.628
			c-0.052-0.191-0.086-0.4-0.086-0.627V13.8c-0.036-0.122-0.036-0.263-0.036-0.402C7.276,10.779,9.598,6.26,9.598,6.26
			s1.885,4.119,2.337,6.544C12.059,13.256,12.077,13.641,12.059,13.937z"
        />
        <path
          opacity="0.8"
          d="M17.889,23.291c-3.056-0.992-2.514,0.438-5.272-0.818
			c-0.141-0.07-0.278-0.141-0.4-0.209c-0.261-0.141-0.489-0.316-0.699-0.508c0.122-0.576,0.179-1.1,0.122-1.431
			c-0.261-1.745-1.919-2.286-1.919-2.286s-0.036,0-0.085,0.017c-0.036-0.121-0.036-0.192-0.036-0.192s0.069-0.037,0.192-0.088h0.018
			c0.382,0.107,1.831,0.457,3.193,0.139c0.209-0.051,0.383-0.139,0.562-0.295h0.015C15.463,18.266,17.731,20.814,17.889,23.291z"
        />
        <path
          opacity="0.8"
          d="M7.922,20.656c0.017,0.418,0.298,0.875,0.646,1.486
			c-0.018,0.016-0.018,0.016-0.035,0.033C8.202,22.49,7.8,22.734,7.346,22.893H7.329c-1.99,0.766-4.748,0.361-6.79-0.055
			c1.816-1.377,2.514-3.594,4.033-4.713c0.261-0.19,0.505-0.346,0.838-0.47c0.069-0.021,0.121-0.054,0.192-0.054
			c0.227,0.211,0.489,0.402,0.802,0.576c0.943,0.558,2.531-0.139,2.934-0.365c0-0.018,0-0.018,0-0.037
			c0.068,0.051,0.103,0.071,0.103,0.071s0.037,0.088,0.088,0.261C9.075,18.266,7.87,18.893,7.922,20.656z"
        />
        <path
          opacity="0.9"
          d="M16.823,15.805c-0.68,0.646-1.693,1.049-2.725,1.293
			c0.945-1.205,1.521-3.265,1.537-3.597c-1.152,0.351-2.512,0.279-3.612,0.611c0.021-0.052,0.037-0.122,0.037-0.175
			c0.018-0.296,0-0.682-0.123-1.134c0.28-0.296,0.576-0.523,0.891-0.716c1.712-1.012,4.555-0.383,5.464-2.006
			C18.725,11.703,18.813,13.884,16.823,15.805z"
        />
        <path
          opacity="0.9"
          d="M3.263,13.99c0.663,0.993,1.064,2.497,2.338,3.611
			c-0.071,0-0.123,0.036-0.192,0.054c-0.333,0.12-0.577,0.279-0.838,0.47c-0.507-0.207-0.978-0.54-1.414-1.046
			c-1.519-1.711-2.583-5.916-2.583-5.916s5.411,2.076,6.546,2.633v0.02c0,0.226,0.034,0.437,0.086,0.628
			C6.177,13.99,4.816,13.851,3.263,13.99z"
        />
        <path
          opacity="0.9"
          d="M12.565,23.816c0.259,3.053-2.024,4.592-2.793,6.02
			c-0.436-2.529-2.984-2.967-2.548-6.545c0.035-0.119,0.069-0.26,0.104-0.398h0.017c0.454-0.158,0.856-0.402,1.187-0.717
			c0.017-0.018,0.017-0.018,0.035-0.037c0.453,0.73,1.012,1.656,1.396,2.916c0.646-0.383,1.274-1.992,1.552-3.299
			c0.21,0.191,0.438,0.365,0.699,0.506C12.372,22.734,12.512,23.24,12.565,23.816z"
        />
        <path
          opacity="0.4"
          d="M9.337,17.672v0.107c0,0.016,0,0.016,0,0.035
			c-0.402,0.227-1.991,0.924-2.934,0.365c-0.313-0.176-0.575-0.365-0.802-0.576c-1.274-1.116-1.675-2.618-2.338-3.613
			c1.553-0.139,2.914,0,3.943,0.457c0.385,0.157,0.732,0.366,1.013,0.627C9.283,15.927,9.337,16.851,9.337,17.672z"
        />
        <path
          opacity="0.4"
          d="M15.636,13.5c-0.017,0.333-0.592,2.392-1.537,3.597
			c-0.153,0.209-0.331,0.383-0.521,0.522h-0.015c-0.18,0.159-0.353,0.245-0.562,0.298c-1.362,0.314-2.811-0.035-3.193-0.139H9.791
			c-0.053,0-0.089-0.021-0.089-0.021s0-0.087,0.018-0.208c0.035-0.506,0.229-1.781,1.1-2.724c0.331-0.348,0.75-0.575,1.204-0.715
			C13.124,13.779,14.483,13.851,15.636,13.5z"
        />
        <path
          opacity="0.6"
          d="M11.517,21.756c-0.277,1.309-0.906,2.916-1.552,3.301
			c-0.385-1.258-0.943-2.184-1.396-2.914c-0.349-0.611-0.629-1.064-0.646-1.486c-0.052-1.764,1.153-2.391,1.606-2.547
			c0.052-0.019,0.07-0.038,0.105-0.054c0.05-0.017,0.085-0.017,0.085-0.017s1.659,0.541,1.919,2.286
			C11.693,20.656,11.639,21.18,11.517,21.756z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-stellate-full">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.6"
          d="M11.936,14.145c-0.419,0.142-0.804,0.351-1.116,0.682
			c-0.854,0.906-1.047,2.147-1.1,2.671c-0.052,0.053-0.085,0.068-0.085,0.068c0-0.783-0.105-1.676-1.119-2.495
			c-0.243-0.245-0.558-0.438-0.907-0.576c-0.035-0.122-0.035-0.26-0.052-0.4v-0.019c-0.018-0.104,0-0.227,0-0.348
			c0.052-0.751,0.28-1.658,0.592-2.549c0.681-1.902,1.642-3.752,1.642-3.752s0.976,2.183,1.607,4.153
			c0.188,0.594,0.349,1.152,0.435,1.641C11.919,13.589,11.954,13.903,11.936,14.145z"
        />
        <path
          opacity="0.6"
          d="M17.052,22.49c-1.73-0.561-2.148-0.244-2.88-0.244
			c-0.404,0-0.91-0.088-1.783-0.49c-0.12-0.053-0.242-0.121-0.348-0.191c-0.175-0.088-0.33-0.209-0.472-0.33
			c0.088-0.475,0.124-0.893,0.069-1.172c-0.241-1.604-1.655-2.18-1.884-2.268c0-0.018,0-0.018,0-0.018c0.018,0,0.018,0,0.036,0
			h0.018c0.382,0.105,1.831,0.455,3.193,0.139c0.21-0.051,0.383-0.139,0.562-0.296h0.015c0.857,0.384,1.78,1.204,2.442,2.181
			C16.597,20.623,16.998,21.564,17.052,22.49z"
        />
        <path
          opacity="0.6"
          d="M7.922,20.395c0.017,0.386,0.245,0.785,0.559,1.328
			c-0.227,0.156-0.471,0.297-0.75,0.383H7.712c-0.767,0.297-1.656,0.385-2.565,0.367c-1.188-0.02-2.391-0.227-3.42-0.438
			c0.942-0.697,1.536-1.641,2.146-2.512c0.437-0.629,0.873-1.205,1.431-1.625c0.175-0.14,0.35-0.243,0.559-0.332
			c0.017,0.02,0.017,0.02,0.035,0.035c0.227,0.211,0.488,0.402,0.802,0.576c0.681,0.402,1.71,0.141,2.375-0.122
			C8.55,18.389,7.887,19.086,7.922,20.395z"
        />
        <path
          opacity="0.7"
          d="M16.126,15.891c-0.106,0.107-0.226,0.211-0.366,0.28
			c-0.438,0.316-0.96,0.542-1.518,0.716c0.87-1.223,1.377-3.071,1.394-3.386c-1.152,0.349-2.512,0.279-3.612,0.611
			c-0.035,0.018-0.052,0.018-0.088,0.033c0.019-0.242-0.017-0.556-0.104-0.923c0.245-0.262,0.507-0.472,0.785-0.629
			c0.437-0.261,0.945-0.383,1.466-0.488c1.328-0.244,2.775-0.244,3.353-1.274C17.816,12.262,17.906,14.199,16.126,15.891z"
        />
        <path
          opacity="0.7"
          d="M3.559,13.988c0.663,0.98,1.065,2.463,2.304,3.578
			c-0.209,0.089-0.384,0.192-0.559,0.332c-0.436-0.191-0.856-0.489-1.24-0.942c-0.173-0.209-0.365-0.452-0.505-0.731
			c-1.065-1.729-1.746-4.486-1.746-4.486s1.064,0.418,2.286,0.908c1.362,0.541,2.933,1.169,3.457,1.43v0.019
			c0.017,0.14,0.017,0.279,0.052,0.4l-0.105-0.052C6.474,13.988,5.112,13.849,3.559,13.988z"
        />
        <path
          opacity="0.7"
          d="M12.339,22.943c0.07,0.732-0.035,1.363-0.246,1.922
			c-0.523,1.498-1.746,2.479-2.234,3.383c-0.207-1.273-1.046-1.955-1.639-2.895c-0.437-0.699-0.75-1.537-0.577-2.881
			c0.017-0.123,0.035-0.244,0.069-0.367H7.73c0.279-0.086,0.523-0.227,0.75-0.383c0.018,0.051,0.053,0.104,0.088,0.156
			c0.453,0.732,1.012,1.66,1.396,2.916c0.646-0.383,1.274-1.992,1.552-3.301c0.02-0.086,0.037-0.174,0.053-0.26
			c0.142,0.121,0.297,0.242,0.472,0.33C12.182,21.984,12.284,22.438,12.339,22.943z"
        />
        <path
          opacity="0.9"
          d="M12.093,24.865c0.211-0.559,0.316-1.189,0.246-1.922
			c-0.055-0.506-0.157-0.959-0.298-1.379c0.105,0.07,0.228,0.139,0.348,0.191c0.873,0.402,1.379,0.49,1.783,0.49
			c0.452,2.145,0.538,4.711,0.538,4.711S13.403,25.98,12.093,24.865z"
        />
        <path
          opacity="0.9"
          d="M5.304,17.898c-0.558,0.42-0.994,0.996-1.431,1.625
			c-1.153-0.42-2.251-1.152-2.881-2.131c1.711-0.35,1.99-0.803,2.567-1.168c0.14,0.279,0.332,0.522,0.505,0.731
			C4.448,17.409,4.868,17.707,5.304,17.898z"
        />
        <path
          opacity="0.9"
          d="M14.57,10.272c-0.26,0.593-0.398,1.221-0.487,1.833
			c-0.521,0.105-1.029,0.227-1.466,0.488c-0.278,0.157-0.54,0.367-0.785,0.629c-0.086-0.489-0.246-1.047-0.435-1.641
			C12.426,11.004,13.577,10.603,14.57,10.272z"
        />
        <path
          opacity="0.8"
          d="M8.22,25.354c-0.926,0.713-1.851,1.377-1.851,2.389
			c-1.029-1.064-2.042-2.705-1.326-5.043c0.017-0.07,0.051-0.158,0.104-0.227c0.909,0.018,1.798-0.07,2.565-0.367
			c-0.034,0.123-0.052,0.244-0.069,0.367C7.469,23.816,7.783,24.654,8.22,25.354z"
        />
        <path
          opacity="0.8"
          d="M19.563,19.313c0,0-1.831,0.262-3.543,0.488
			c-0.662-0.977-1.585-1.797-2.442-2.181c0.19-0.141,0.368-0.313,0.521-0.522c0.055-0.072,0.107-0.142,0.144-0.211
			c0.558-0.174,1.079-0.399,1.518-0.716C17.539,17.148,19.563,19.313,19.563,19.313z"
        />
        <path
          opacity="0.8"
          d="M8.148,11.179c-0.313,0.891-0.541,1.798-0.592,2.549
			c0,0.121-0.018,0.244,0,0.348c-0.524-0.261-2.095-0.889-3.457-1.43c-0.173-1.467,0.367-2.811,0.349-3.805
			C5.496,10.167,6.98,10.08,8.148,11.179z"
        />
        <path
          opacity="0.5"
          d="M9.634,17.566v0.211c0,0.018,0,0.018,0,0.018s0,0,0,0.018
			c-0.018,0-0.036,0.017-0.054,0.017c0,0-0.035,0-0.052,0.018c-0.052,0.019-0.123,0.052-0.191,0.088
			c-0.089,0.034-0.176,0.069-0.262,0.121C8.411,18.318,7.381,18.58,6.7,18.178c-0.314-0.174-0.575-0.365-0.802-0.576
			c-0.018-0.016-0.018-0.016-0.035-0.035c-1.239-1.115-1.642-2.598-2.304-3.578c1.553-0.139,2.915,0,3.944,0.456l0.105,0.052
			c0.349,0.139,0.664,0.332,0.907,0.576C9.528,15.891,9.634,16.783,9.634,17.566z"
        />
        <path
          opacity="0.5"
          d="M15.636,13.5c-0.017,0.315-0.523,2.164-1.394,3.386
			c-0.036,0.069-0.089,0.139-0.144,0.211c-0.153,0.209-0.331,0.382-0.521,0.522h-0.015c-0.179,0.157-0.352,0.245-0.562,0.296
			c-1.362,0.316-2.811-0.033-3.193-0.139H9.791c-0.018,0-0.018,0-0.036,0c-0.017,0-0.017,0-0.035,0
			c-0.018-0.019-0.018-0.019-0.018-0.019s0-0.087,0.018-0.208v-0.053c0.053-0.523,0.246-1.765,1.1-2.671
			c0.313-0.332,0.697-0.541,1.116-0.682c0.036-0.015,0.053-0.015,0.088-0.033C13.124,13.779,14.483,13.849,15.636,13.5z"
        />
        <path
          opacity="0.5"
          d="M11.569,21.234c-0.016,0.086-0.033,0.174-0.053,0.26
			c-0.277,1.309-0.906,2.918-1.552,3.301c-0.385-1.256-0.943-2.184-1.396-2.916c-0.035-0.053-0.07-0.105-0.088-0.156
			c-0.314-0.543-0.542-0.942-0.559-1.328c-0.035-1.309,0.628-2.006,1.153-2.339c0.086-0.052,0.172-0.087,0.262-0.121
			c0.068-0.036,0.14-0.069,0.191-0.088c0.017-0.018,0.052-0.018,0.052-0.018c0.018-0.017,0.036-0.034,0.054-0.034
			c0.018,0,0.018,0,0.033,0c0.035-0.018,0.052-0.018,0.052-0.018s0.018,0,0.035,0.018c0.229,0.088,1.643,0.663,1.884,2.268
			C11.693,20.342,11.657,20.76,11.569,21.234z"
        />
      </g>
    </mask>

    <mask height="1" width="1" y="0" x="0" id="mask-bell-single">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M15.048,5.4c0-0.041,0-0.081-0.018-0.121
				c-0.028-0.109-0.068-0.232-0.134-0.34c-0.126-0.298-0.313-0.61-0.52-0.882c-0.23-0.339-0.487-0.638-0.707-0.84
				c-0.23-0.259-0.394-0.435-0.394-0.435s-0.841,0.339-1.765,0.854c-0.434,0.231-0.855,0.475-1.235,0.747
				C9.842,4.75,9.476,5.103,9.313,5.482C9.278,5.604,9.238,5.74,9.238,5.862v0.367c-0.068,1.872-0.042,5.687-0.042,5.687
				s0-0.041-0.013-0.122c0,0,0.204,7.995,0.435,8.618c0.121,0.014,0.245,0.029,0.379,0.041c0.123,0.014,0.231,0.014,0.354,0.014
				c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11c0.027-0.161,0.053-0.325,0.082-0.475
				c0.041-0.354,0.094-0.692,0.161-1.031c0.028-0.068,0.028-0.123,0.041-0.191c0.027-0.203,0.068-0.407,0.108-0.583
				c0.017-0.189,0.069-0.38,0.109-0.557c0.051-0.352,0.123-0.651,0.191-0.909c0.014-0.122,0.054-0.231,0.081-0.312
				c0.014-0.082,0.04-0.191,0.04-0.299C15.114,5.632,15.087,5.523,15.048,5.4z"
        />
        <path
          opacity="0.9"
          d="M18.845,5.753c-2.698,0.489-2.428,5.335-2.86,8.075
				c-0.408,2.62-2.567,6.556-5.634,6.638c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11
				c0.068-0.339,0.147-0.706,0.214-1.045c0.055-0.203,0.096-0.393,0.139-0.583c0.2-0.977,0.436-1.873,0.625-2.321
				c0.04-0.082,0.093-0.15,0.178-0.217c0.146-0.122,0.352-0.23,0.609-0.312c0.107-0.041,0.202-0.082,0.342-0.109c0,0,0,0,0.012,0
				c0,0,0-0.013,0.027-0.013c0,0,0.014,0,0.042,0c0.147-0.041,0.297-0.068,0.46-0.082C16.98,5.4,17.02,5.4,17.035,5.4
				c0.374-0.041,0.753-0.067,1.093-0.067C19.377,5.292,20.232,5.51,18.845,5.753z"
        />
        <path
          opacity="0.7"
          d="M9.618,20.412c-2.906-0.326-3.014-1.859-4.032-3.719
				c-1.058-1.941-0.543-8.999-0.679-9.88C4.785,5.93,3.169,3.392,3.169,3.392s0.692,0.176,1.615,0.435
				C5.382,3.99,6.034,4.193,6.645,4.45c0.272,0.109,0.53,0.218,0.761,0.313C7.568,4.858,7.744,4.939,7.88,5.021
				c0.57,0.312,0.949,0.651,0.991,1.032c0,0.04,0,0.108,0.026,0.149c0.069,1.33,0.258,4.874,0.286,5.592
				C9.183,11.794,9.387,19.789,9.618,20.412z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-bell-double">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M15.048,5.4c0-0.041,0-0.081-0.018-0.121
				c-0.028-0.109-0.068-0.232-0.134-0.34c-0.126-0.298-0.313-0.61-0.52-0.882c-0.23-0.339-0.487-0.638-0.707-0.84
				c-0.23-0.259-0.394-0.435-0.394-0.435s-0.841,0.339-1.765,0.854c-0.434,0.231-0.855,0.475-1.235,0.747
				C9.842,4.75,9.476,5.103,9.313,5.482C9.278,5.604,9.238,5.74,9.238,5.862v0.367c-0.068,1.872-0.042,5.687-0.042,5.687
				s0-0.041-0.013-0.122c0,0,0.204,7.995,0.435,8.618c0.121,0.014,0.245,0.029,0.379,0.041c0.123,0.014,0.231,0.014,0.354,0.014
				c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11c0.027-0.161,0.053-0.325,0.082-0.475
				c0.041-0.354,0.094-0.692,0.161-1.031c0.028-0.068,0.028-0.123,0.041-0.191c0.027-0.203,0.068-0.407,0.108-0.583
				c0.017-0.189,0.069-0.38,0.109-0.557c0.051-0.352,0.123-0.651,0.191-0.909c0.014-0.122,0.054-0.231,0.081-0.312
				c0.014-0.082,0.04-0.191,0.04-0.299C15.114,5.632,15.087,5.523,15.048,5.4z"
        />
        <path
          opacity="0.9"
          d="M18.845,5.753c-2.698,0.489-2.428,5.335-2.86,8.075
				c-0.408,2.62-2.567,6.556-5.634,6.638c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11
				c0.068-0.339,0.147-0.706,0.214-1.045c0.055-0.203,0.096-0.393,0.139-0.583c0.2-0.977,0.436-1.873,0.625-2.321
				c0.04-0.082,0.093-0.15,0.178-0.217c0.146-0.122,0.352-0.23,0.609-0.312c0.107-0.041,0.202-0.082,0.342-0.109c0,0,0,0,0.012,0
				c0,0,0-0.013,0.027-0.013c0,0,0.014,0,0.042,0c0.147-0.041,0.297-0.068,0.46-0.082C16.98,5.4,17.02,5.4,17.035,5.4
				c0.374-0.041,0.753-0.067,1.093-0.067C19.377,5.292,20.232,5.51,18.845,5.753z"
        />
        <path
          opacity="0.7"
          d="M9.618,20.412c-2.906-0.326-3.014-1.859-4.032-3.719
				c-1.058-1.941-0.543-8.999-0.679-9.88C4.785,5.93,3.169,3.392,3.169,3.392s0.692,0.176,1.615,0.435
				C5.382,3.99,6.034,4.193,6.645,4.45c0.272,0.109,0.53,0.218,0.761,0.313C7.568,4.858,7.744,4.939,7.88,5.021
				c0.57,0.312,0.949,0.651,0.991,1.032c0,0.04,0,0.108,0.026,0.149c0.069,1.33,0.258,4.874,0.286,5.592
        C9.183,11.794,9.387,19.789,9.618,20.412z"
        />
        <path
          opacity="0.85"
          d="M15.07,6.054c0.025-0.085,0.044-0.191,0.044-0.294
				c0-0.126-0.021-0.232-0.065-0.359c0-0.044,0-0.083-0.021-0.127c-0.021-0.105-0.065-0.23-0.129-0.335
				c-0.128-0.296-0.313-0.612-0.528-0.885c0.316-0.191,0.611-0.36,0.888-0.465c0.569-0.274,1.009-0.399,1.009-0.399
				s0.295,0.399,0.506,1.01c0.127,0.339,0.231,0.761,0.255,1.202c-0.023,0-0.064,0-0.085,0.021c-0.17,0.021-0.314,0.043-0.466,0.086
				c-0.022,0-0.04,0-0.04,0c-0.022,0-0.022,0.021-0.022,0.021c-0.023,0-0.023,0-0.023,0c-0.126,0.023-0.231,0.064-0.334,0.107
				c-0.255,0.084-0.468,0.19-0.611,0.314c-0.086,0.064-0.127,0.128-0.167,0.212c-0.192,0.442-0.426,1.347-0.634,2.318
				c-0.043,0.188-0.087,0.379-0.13,0.59c-0.085,0.379-0.168,0.779-0.255,1.16c0.042-0.193,0.066-0.401,0.106-0.592
				c0.042-0.358,0.105-0.696,0.166-1.031c0.025-0.063,0.025-0.126,0.045-0.192c0.02-0.208,0.064-0.4,0.104-0.589
				c0.021-0.189,0.063-0.38,0.104-0.547c0.063-0.359,0.13-0.652,0.189-0.907C15.009,6.246,15.049,6.138,15.07,6.054z"
        />
        <path
          opacity="0.75"
          d="M10.282,4.383c-0.435,0.367-0.8,0.72-0.963,1.099
				c-0.04,0.123-0.081,0.258-0.081,0.38v0.367c-0.068,1.872-0.042,5.687-0.042,5.687s0-0.041-0.013-0.122
				c-0.027-0.719-0.217-4.262-0.286-5.592C8.871,6.161,8.871,6.093,8.871,6.053C8.829,5.672,8.45,5.333,7.88,5.021
				C7.744,4.939,7.568,4.858,7.405,4.763C7.174,4.668,6.917,4.559,6.645,4.45c0.108-0.312,0.23-0.61,0.34-0.868
				C7.256,2.971,7.5,2.604,7.5,2.604C7.757,2.7,8.327,2.931,8.911,3.31C9.387,3.582,9.862,3.949,10.282,4.383z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-bell-full">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M15.048,5.4c0-0.041,0-0.081-0.018-0.121
				c-0.028-0.109-0.068-0.232-0.134-0.34c-0.126-0.298-0.313-0.61-0.52-0.882c-0.23-0.339-0.487-0.638-0.707-0.84
				c-0.23-0.259-0.394-0.435-0.394-0.435s-0.841,0.339-1.765,0.854c-0.434,0.231-0.855,0.475-1.235,0.747
				C9.842,4.75,9.476,5.103,9.313,5.482C9.278,5.604,9.238,5.74,9.238,5.862v0.367c-0.068,1.872-0.042,5.687-0.042,5.687
				s0-0.041-0.013-0.122c0,0,0.204,7.995,0.435,8.618c0.121,0.014,0.245,0.029,0.379,0.041c0.123,0.014,0.231,0.014,0.354,0.014
				c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11c0.027-0.161,0.053-0.325,0.082-0.475
				c0.041-0.354,0.094-0.692,0.161-1.031c0.028-0.068,0.028-0.123,0.041-0.191c0.027-0.203,0.068-0.407,0.108-0.583
				c0.017-0.189,0.069-0.38,0.109-0.557c0.051-0.352,0.123-0.651,0.191-0.909c0.014-0.122,0.054-0.231,0.081-0.312
				c0.014-0.082,0.04-0.191,0.04-0.299C15.114,5.632,15.087,5.523,15.048,5.4z"
        />
        <path
          opacity="0.9"
          d="M18.845,5.753c-2.698,0.489-2.428,5.335-2.86,8.075
				c-0.408,2.62-2.567,6.556-5.634,6.638c3.596-0.285,3.532-8.035,3.925-10.234c0.014-0.04,0.014-0.081,0.029-0.108V10.11
				c0.068-0.339,0.147-0.706,0.214-1.045c0.055-0.203,0.096-0.393,0.139-0.583c0.2-0.977,0.436-1.873,0.625-2.321
				c0.04-0.082,0.093-0.15,0.178-0.217c0.146-0.122,0.352-0.23,0.609-0.312c0.107-0.041,0.202-0.082,0.342-0.109c0,0,0,0,0.012,0
				c0,0,0-0.013,0.027-0.013c0,0,0.014,0,0.042,0c0.147-0.041,0.297-0.068,0.46-0.082C16.98,5.4,17.02,5.4,17.035,5.4
				c0.374-0.041,0.753-0.067,1.093-0.067C19.377,5.292,20.232,5.51,18.845,5.753z"
        />
        <path
          opacity="0.7"
          d="M9.618,20.412c-2.906-0.326-3.014-1.859-4.032-3.719
				c-1.058-1.941-0.543-8.999-0.679-9.88C4.785,5.93,3.169,3.392,3.169,3.392s0.692,0.176,1.615,0.435
				C5.382,3.99,6.034,4.193,6.645,4.45c0.272,0.109,0.53,0.218,0.761,0.313C7.568,4.858,7.744,4.939,7.88,5.021
				c0.57,0.312,0.949,0.651,0.991,1.032c0,0.04,0,0.108,0.026,0.149c0.069,1.33,0.258,4.874,0.286,5.592
        C9.183,11.794,9.387,19.789,9.618,20.412z"
        />
        <path
          opacity="0.85"
          d="M15.07,6.054c0.025-0.085,0.044-0.191,0.044-0.294
				c0-0.126-0.021-0.232-0.065-0.359c0-0.044,0-0.083-0.021-0.127c-0.021-0.105-0.065-0.23-0.129-0.335
				c-0.128-0.296-0.313-0.612-0.528-0.885c0.316-0.191,0.611-0.36,0.888-0.465c0.569-0.274,1.009-0.399,1.009-0.399
				s0.295,0.399,0.506,1.01c0.127,0.339,0.231,0.761,0.255,1.202c-0.023,0-0.064,0-0.085,0.021c-0.17,0.021-0.314,0.043-0.466,0.086
				c-0.022,0-0.04,0-0.04,0c-0.022,0-0.022,0.021-0.022,0.021c-0.023,0-0.023,0-0.023,0c-0.126,0.023-0.231,0.064-0.334,0.107
				c-0.255,0.084-0.468,0.19-0.611,0.314c-0.086,0.064-0.127,0.128-0.167,0.212c-0.192,0.442-0.426,1.347-0.634,2.318
				c-0.043,0.188-0.087,0.379-0.13,0.59c-0.085,0.379-0.168,0.779-0.255,1.16c0.042-0.193,0.066-0.401,0.106-0.592
				c0.042-0.358,0.105-0.696,0.166-1.031c0.025-0.063,0.025-0.126,0.045-0.192c0.02-0.208,0.064-0.4,0.104-0.589
				c0.021-0.189,0.063-0.38,0.104-0.547c0.063-0.359,0.13-0.652,0.189-0.907C15.009,6.246,15.049,6.138,15.07,6.054z"
        />
        <path
          opacity="0.75"
          d="M10.282,4.383c-0.435,0.367-0.8,0.72-0.963,1.099
				c-0.04,0.123-0.081,0.258-0.081,0.38v0.367c-0.068,1.872-0.042,5.687-0.042,5.687s0-0.041-0.013-0.122
				c-0.027-0.719-0.217-4.262-0.286-5.592C8.871,6.161,8.871,6.093,8.871,6.053C8.829,5.672,8.45,5.333,7.88,5.021
				C7.744,4.939,7.568,4.858,7.405,4.763C7.174,4.668,6.917,4.559,6.645,4.45c0.108-0.312,0.23-0.61,0.34-0.868
        C7.256,2.971,7.5,2.604,7.5,2.604C7.757,2.7,8.327,2.931,8.911,3.31C9.387,3.582,9.862,3.949,10.282,4.383z"
        />
        <path
          opacity="0.7"
          d="M8.918,3.314c0.76-0.675,1.623-0.949,1.623-0.949
				s0.527,0.549,0.97,1.266c-0.423,0.229-0.843,0.483-1.223,0.758C9.867,3.945,9.381,3.588,8.918,3.314z"
        />
        <path
          opacity="0.7"
          d="M13.684,3.208c0.526-0.316,1.008-0.485,1.008-0.485
				s0.273,0.361,0.57,0.865c-0.275,0.105-0.57,0.273-0.885,0.465C14.143,3.715,13.891,3.419,13.684,3.208z"
        />
        <path
          opacity="0.6"
          d="M6.984,3.582C6.875,3.84,6.753,4.138,6.645,4.45
				c-0.611-0.257-1.263-0.46-1.86-0.624C5.002,3.27,5.355,2.89,5.355,2.89S6.264,3.08,6.984,3.582z"
        />
        <path
          opacity="0.7"
          d="M16.776,4.199c0.699-0.337,1.457-0.125,1.457-0.125
				s0.082,0.696-0.108,1.264c-0.336,0-0.713,0.019-1.095,0.063C17.009,4.958,16.905,4.536,16.776,4.199z"
        />
      </g>
    </mask>

    <mask height="1" width="1" y="0" x="0" id="mask-cross-single">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.8"
          d="M9.348,17.316l0.618,1.434c0,0-2.413-0.808-3.574-1.617
			c-2.017-1.67-5.506-2.729-5.937-2.848l-0.044-0.017c0,0,5.023-0.724,6.364,0.162C7.957,15.202,9.084,16.902,9.348,17.316z"
        />
        <path
          opacity="0.7"
          d="M9.923,18.977L8.588,19.6l-0.01,0.004
			c-0.253,0.164-1.859,1.154-3.621,0.145c-1.917-1.084-4.545-5.48-4.545-5.48l0.043,0.017c0.431,0.119,3.921,1.178,5.937,2.848
			l0.004,0.012C6.435,17.491,9.923,18.977,9.923,18.977z"
        />
        <path
          opacity="0.9"
          d="M13.109,7.459c-0.179,0.3-3.012,5.159-2.179,7.625
			c0.044,1.428-0.966,3.665-0.966,3.665l-0.619-1.434c0.015,0.03,0.034,0.047,0.04,0.057c-0.173-0.299-1.32-2.574-0.165-4.999
			C10.43,9.852,12.925,7.617,13.109,7.459z"
        />
        <path
          opacity="0.7"
          d="M14.959,13.721c-0.438,1.624-3.287,4.104-3.287,4.104
			l-1.499,1.037c0,0,1.101-3.157,0.757-3.777c-0.833-2.467,1.999-7.326,2.177-7.625l0.006-0.015
			C13.114,7.445,15.402,12.107,14.959,13.721z"
        />
        <path
          opacity="0.7"
          d="M19.638,23.281c0,0-3.22,1.395-5.862,0.082
			c-1.251-0.609-1.949-1.227-2.352-1.713l-0.009-0.02l-0.286-0.602l-0.952-1.95c0,0,2.783,0.068,3.529,0.907
			C14.364,21.161,16.152,22.386,19.638,23.281z"
        />
        <path
          opacity="0.6"
          d="M19.638,23.281c-3.485-0.896-5.273-2.12-5.931-3.293
			c-0.451-0.939-3.53-1.126-3.53-1.126l1.498-1.037c0,0,1.608-1.038,4.304,0.202C18.765,19.31,19.638,23.281,19.638,23.281z"
        />
        <path
          opacity="0.6"
          d="M9.923,18.977c0,0-0.157,2.214-0.856,3.641l-0.124,0.287
			c-0.68,1.445-2.083,2.314-2.629,6.557c0,0-1.103-3.295-0.587-5.258c0.491-1.832,2.568-4.264,2.852-4.6l0.01-0.004L9.923,18.977z"
        />
        <path
          opacity="0.8"
          d="M11.415,21.631l0.009,0.02
			c0.226,0.627,0.365,1.572,0.032,2.844c-0.663,2.516-5.143,4.965-5.143,4.965c0.545-4.242,1.949-5.109,2.629-6.556l0.124-0.288
			c0.453,0.061,1.11-3.537,1.11-3.537l0.952,1.951L11.415,21.631z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-cross-double">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.7"
          d="M9.295,17.945c-0.361-0.121-2.543-0.964-3.592-0.738
			c-0.137,0.034-0.292,0.102-0.464,0.204c-1.203-1.959-1.752-6.668-1.752-6.668s3.711,1.083,5.224,2.27
			c-0.379,0.636-0.654,1.375-0.62,2.165C8.161,17.034,9.072,17.791,9.295,17.945z"
        />
        <path
          opacity="0.7"
          d="M16.961,16.277c-0.104,0.12-0.224,0.242-0.359,0.346
			c-0.945-0.447-2.081-0.827-2.957-0.637c-0.913,0.189-1.462,0.584-1.771,0.91c0.154-0.41,0.307-1.049,0.307-2.027
			c0-0.619-0.17-1.187-0.392-1.684c0.137-0.171,0.324-0.343,0.514-0.516c2.218-1.856,5.586-2.87,5.586-2.87
			S18.026,14.989,16.961,16.277z"
        />
        <path
          opacity="0.7"
          d="M17.234,26.916c0,0-3.504-0.07-5.36-2.355
			c-0.053-0.053-0.085-0.102-0.12-0.154c0.465-0.945,0.926-2.027,0.962-2.785c0.085-1.565-1.29-2.269-1.29-2.269
			s2.305,0.617,3.729,0.359c0.312,0.241,0.637,0.55,0.946,0.909C18.096,22.947,17.234,26.916,17.234,26.916z"
        />
        <path
          opacity="0.7"
          d="M8.763,22.17c-0.051,0.5,0.327,1.361,0.756,2.168
			c-0.068,0.086-0.138,0.189-0.224,0.293c-1.65,2.01-6.735,2.373-6.735,2.373s0.36-3.453,1.648-5.019
			c0.241-0.292,0.534-0.567,0.877-0.824c0.533,0.086,1.065,0.138,1.599,0.119c1.924-0.051,2.99-1.528,3.111-1.72
			C9.69,19.732,8.882,21.107,8.763,22.17z"
        />
        <path
          opacity="0.9"
          d="M18.44,17.654c0,0-1.738,1.752-3.146,2.044
			c-0.053,0-0.086,0.017-0.139,0.017c-1.425,0.258-3.729-0.359-3.729-0.359s1.375,0.704,1.29,2.269
			c-0.036,0.759-0.497,1.839-0.962,2.784c-0.518,1.016-1.051,1.857-1.051,1.857s-0.634-0.908-1.184-1.922
			c-0.429-0.809-0.807-1.67-0.756-2.168c0.12-1.065,0.927-2.441,1.032-2.613c-0.121,0.191-1.187,1.67-3.113,1.719
			c-0.532,0.02-1.065-0.031-1.598-0.119c-1.495-0.255-2.784-0.771-2.784-0.771s1.719-2.27,2.938-2.976
			c0.172-0.106,0.327-0.174,0.464-0.208c1.048-0.226,3.23,0.617,3.592,0.738c-0.224-0.154-1.134-0.911-1.203-2.768
			c-0.034-0.791,0.241-1.529,0.62-2.165c0.636-1.1,1.547-1.839,1.547-1.839s0.962,0.808,1.53,2.011
			c0.222,0.498,0.392,1.064,0.392,1.684c0,0.979-0.152,1.617-0.307,2.027c0.307-0.325,0.857-0.721,1.771-0.909
			c0.876-0.19,2.01,0.188,2.957,0.635C17.629,17.103,18.44,17.654,18.44,17.654z"
        />
      </g>
    </mask>
    <mask height="1" width="1" y="0" x="0" id="mask-cross-full">
      <g fill="white" transform="scale(1.5)">
        <path
          opacity="0.6"
          d="M8.11,15.383c-0.637-0.223-1.238-0.36-1.238-0.36
			s0.24,1.357,0.637,2.354c-0.653-0.154-1.34-0.275-1.805-0.17c-0.138,0.034-0.292,0.102-0.465,0.204
			c-1.203-1.959-1.752-6.668-1.752-6.668s3.711,1.083,5.224,2.27c-0.379,0.636-0.654,1.375-0.62,2.165
			C8.093,15.246,8.093,15.315,8.11,15.383z"
        />
        <path
          opacity="0.6"
          d="M16.961,16.277c-0.104,0.12-0.224,0.242-0.359,0.346
			c-0.945-0.447-2.081-0.827-2.957-0.637c-0.019,0-0.052,0.018-0.068,0.018c0.053-0.859-0.035-1.822-0.035-1.822
			s-0.635,0.242-1.36,0.67c0-0.602-0.169-1.169-0.392-1.667c0.138-0.171,0.325-0.343,0.517-0.516c2.217-1.856,5.586-2.87,5.586-2.87
			S18.026,14.989,16.961,16.277z"
        />
        <path
          opacity="0.6"
          d="M17.234,26.916c0,0-3.504-0.07-5.36-2.355
			c-0.053-0.053-0.085-0.102-0.12-0.154c0.377-0.773,0.772-1.648,0.909-2.354c0.601,0.136,1.083,0.116,1.083,0.116
			s0.209-1.406-0.393-2.456c0.62,0.069,1.27,0.103,1.806,0c0.309,0.241,0.635,0.55,0.943,0.909
			C18.096,22.947,17.234,26.916,17.234,26.916z"
        />
        <path
          opacity="0.6"
          d="M9.519,24.338c-0.068,0.086-0.138,0.189-0.224,0.293
			c-1.65,2.01-6.735,2.373-6.735,2.373s0.36-3.453,1.648-5.019c0.241-0.292,0.534-0.567,0.877-0.824
			c0.533,0.086,1.065,0.138,1.599,0.119c0.155,0,0.292-0.017,0.429-0.051c-0.171,0.706-0.207,1.409-0.207,1.409
			s0.963-0.136,1.857-0.498c0,0.018,0,0.018,0,0.032C8.711,22.67,9.088,23.529,9.519,24.338z"
        />
        <path
          opacity="0.5"
          d="M15.155,19.713c-0.532,0.105-1.186,0.071-1.803,0
			c0.602,1.051,0.394,2.457,0.394,2.457s-0.483,0.02-1.084-0.118c-0.137,0.707-0.531,1.583-0.908,2.356
			c-0.808-1.031-1.153-1.838-1.308-2.409c-0.036,0.601-0.258,1.427-0.928,2.339c-0.431-0.807-0.807-1.668-0.757-2.168
			c0-0.016,0-0.016,0-0.033c-0.894,0.365-1.856,0.499-1.856,0.499s0.035-0.702,0.207-1.407c-0.137,0.035-0.276,0.051-0.43,0.051
			c-0.532,0.018-1.065-0.033-1.598-0.121c1.375-1.133,3.352-2.026,3.643-2.164c-0.308,0.034-2.183,0.274-3.368-1.375
			c-0.051-0.07-0.086-0.137-0.12-0.207c0.171-0.104,0.326-0.171,0.463-0.205c0.464-0.105,1.153,0.016,1.804,0.17
			c-0.395-0.996-0.636-2.354-0.636-2.354s0.601,0.137,1.237,0.36c-0.018-0.069-0.018-0.138-0.018-0.207
			c-0.034-0.79,0.241-1.529,0.62-2.165c0.223,0.172,0.413,0.344,0.515,0.515c0.652,1.032,0.962,2.681,1.083,3.42
			c0,0.019,0,0.019,0,0.019c0.034,0.221,0.051,0.36,0.051,0.36s-0.017-0.208,0-0.531c0.018-0.792,0.258-2.322,1.429-3.611
			c0.222,0.498,0.392,1.064,0.392,1.667c0.721-0.43,1.36-0.67,1.36-0.67s0.085,0.963,0.034,1.821c0.018,0,0.051-0.017,0.069-0.017
			c0.876-0.189,2.009,0.189,2.957,0.635c-1.361,1.135-4.332,2.029-4.332,2.029S13.593,18.459,15.155,19.713z"
        />
        <path
          opacity="0.8"
          d="M9.552,18.717c0,0-1.478,0.758-2.028,1.531
			c-0.189,0.258-0.326,0.617-0.413,0.98c-0.137,0.035-0.276,0.051-0.429,0.051c-0.532,0.018-1.065-0.033-1.598-0.121
			C3.589,20.903,2.3,20.387,2.3,20.387s1.718-2.27,2.938-2.975c0.174-0.104,0.328-0.171,0.465-0.205
			c0.464-0.105,1.153,0.016,1.804,0.17c0.121,0.325,0.276,0.602,0.43,0.807C8.591,18.977,9.552,18.717,9.552,18.717z"
        />
        <path
          opacity="0.8"
          d="M12.181,14.852c-0.378,0.207-0.788,0.498-1.15,0.824
			c-0.378,0.361-0.567,0.756-0.67,1.118c-0.017,0.05-0.034,0.102-0.052,0.153c0,0.019,0,0.019,0,0.019
			c-0.12,0.515-0.051,0.928-0.051,0.928s-0.205-1.116-0.635-1.735c-0.223-0.311-0.895-0.566-1.512-0.774
			c-0.017-0.069-0.017-0.139-0.017-0.207c-0.034-0.791,0.241-1.529,0.62-2.165c0.636-1.1,1.547-1.839,1.547-1.839
			s0.962,0.808,1.53,2.011C12.011,13.682,12.181,14.249,12.181,14.852z"
        />
        <path
          opacity="0.8"
          d="M18.44,17.654c0,0-1.738,1.752-3.146,2.044
			c-0.053,0-0.086,0.017-0.139,0.017c-0.532,0.104-1.186,0.07-1.803,0c-0.089-0.155-0.191-0.308-0.328-0.446
			c-0.961-0.996-1.838-0.807-1.838-0.807s1.648-0.603,2.114-1.237c0.17-0.241,0.258-0.703,0.274-1.221
			c0.018,0,0.051-0.016,0.069-0.016c0.876-0.189,2.009,0.189,2.957,0.635C17.629,17.103,18.44,17.654,18.44,17.654z"
        />
        <path
          opacity="0.8"
          d="M12.663,22.052c-0.137,0.706-0.532,1.581-0.909,2.354
			c-0.518,1.014-1.051,1.855-1.051,1.855s-0.634-0.91-1.184-1.924C9.09,23.531,8.712,22.67,8.763,22.17c0-0.016,0-0.016,0-0.033
			c0.481-0.188,0.946-0.443,1.203-0.805c0.377-0.515,0.464-0.961,0.464-1.289c0.069,0.275,0.273,0.687,0.738,1.187
			C11.599,21.69,12.166,21.934,12.663,22.052z"
        />
        <path
          opacity="0.9"
          d="M13.746,22.17c0,0-0.483,0.02-1.084-0.118
			c-0.498-0.116-1.065-0.358-1.495-0.822c-0.464-0.5-0.668-0.911-0.739-1.187c0,0.328-0.085,0.774-0.463,1.289
			c-0.256,0.361-0.721,0.621-1.203,0.805c-0.894,0.365-1.856,0.499-1.856,0.499s0.035-0.702,0.207-1.407
			c0.086-0.363,0.225-0.723,0.413-0.98c0.55-0.773,2.027-1.531,2.027-1.531s-0.961,0.26-1.614-0.531
			c-0.155-0.207-0.309-0.482-0.429-0.807c-0.396-0.996-0.637-2.354-0.637-2.354s0.601,0.139,1.238,0.361
			c0.618,0.208,1.29,0.463,1.512,0.772c0.431,0.621,0.638,1.736,0.638,1.736s-0.071-0.412,0.05-0.928c0,0,0,0,0-0.018
			c0.017-0.053,0.034-0.104,0.051-0.154c0.104-0.362,0.293-0.757,0.67-1.117c0.362-0.327,0.772-0.62,1.15-0.826
			c0.721-0.429,1.36-0.67,1.36-0.67s0.088,0.963,0.035,1.822c-0.018,0.516-0.105,0.979-0.274,1.221
			c-0.466,0.635-2.115,1.236-2.115,1.236s0.877-0.189,1.839,0.807c0.137,0.139,0.241,0.292,0.327,0.445
			C13.954,20.764,13.746,22.17,13.746,22.17z"
        />
      </g>
    </mask>
    <radialGradient [id]="gradientId" cx="0.5" cy="0.66" r="0.4">
      <stop
        *ngFor="let col of colorMap; let i = index"
        [attr.offset]="colorMap.length > 1 ? i / (colorMap.length - 1) : 0.8"
        [attr.stop-color]="col"
      />
    </radialGradient>
    <!-- <mask [id]="'mask-'+shapeMask">
			<image width="30px" height="45px"  [attr.xlink:href]="'/assets/images/flower-shapes/'+shapeMask+'.svg'"></image>
		</mask> -->
  </defs>
  <g id="spike" *ngSwitchCase="'spike'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="50" x2="100" y2="200" />
    <g>
      <g transform="rotate(45, 100, 80)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 80, length: 0, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 112)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 112, length: 0, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 144)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 144, length: 0, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 176)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 176, length: 0, side: 'right' }"></ng-container>
      </g>
    </g>
    <g>
      <g transform="rotate(-55, 100, 64)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 64, length: 0, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 96)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 96, length: 0, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 128)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 128, length: 0, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 160)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 160, length: 0, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 192)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 192, length: 0, side: 'left' }"></ng-container>
      </g>
    </g>
  </g>

  <g id="raceme" *ngSwitchCase="'raceme'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="50" x2="100" y2="200" />
    <g>
      <g transform="rotate(45, 100, 80)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 80, length: 12, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 112)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 112, length: 16, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 144)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 144, length: 20, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(45, 100, 176)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 176, length: 24, side: 'right' }"></ng-container>
      </g>
    </g>
    <g>
      <g transform="rotate(-55, 100, 64)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 64, length: 10, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 96)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 96, length: 14, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 128)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 128, length: 18, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 160)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 160, length: 22, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-55, 100, 192)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 192, length: 26, side: 'left' }"></ng-container>
      </g>
    </g>
  </g>
  <g id="corymb" *ngSwitchCase="'corymb'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="50" x2="100" y2="200" />
    <g transform="rotate(40, 100, 150)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 150, length: 90, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(30, 100, 130)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 130, length: 75, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(20, 100, 110)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 110, length: 60, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(0, 100, 50)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 50, length: 0 }"></ng-container>
    </g>
    <g transform="rotate(-25, 100, 100)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 100, length: 52, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(-35, 100, 120)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 120, length: 68, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(-45, 100, 140)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 140, length: 82, side: 'left' }"></ng-container>
    </g>
  </g>
  <g id="round_umbel" *ngSwitchCase="'round_umbel'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="100" x2="100" y2="200" />
    <g [attr.transform]="'rotate(' + r * 25 + ', 100, 100)'" *ngFor="let r of [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]">
      <ng-container
        *ngTemplateOutlet="stem; context: { x: 100, y: 100, length: 50, side: r === -5 ? 'left' : r === 5 ? 'right' : undefined }"
      ></ng-container>
    </g>
  </g>
  <g id="round_umbel_reduced" *ngSwitchCase="'round_umbel_reduced'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="100" x2="100" y2="200" />
    <g [attr.transform]="'rotate(' + r * 25 + ', 100, 100)'" *ngFor="let r of [-5, -4, -3, 3, 4, 5]">
      <ng-container
        *ngTemplateOutlet="stem; context: { x: 100, y: 100, length: 50, side: r === -5 ? 'left' : r === 5 ? 'right' : undefined }"
      ></ng-container>
    </g>
    <g [attr.transform]="'rotate(' + r * 25 + ', 100, 100)'" *ngFor="let r of [-2, -1, 0, 1, 2]">
      <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 100, length: 20, scale: 0.5 }"></ng-container>
    </g>
  </g>
  <g id="flat_umbel" *ngSwitchCase="'flat_umbel'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100.573" y1="50" x2="100.573" y2="199.624" />
    <g>
      <g>
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.373,94.62
				c-35.321,0-63.955-18.66-63.955-41.688"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.588,95.039
				c-22.79,0-41.263-19.348-41.263-43.228"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.573,94.511
				c-10.726,0-19.421-18.939-19.421-42.317"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.651,95.803
				c32.271,0,58.434-19.269,58.434-43.057"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.436,95.034
				c22.788,0,41.263-18.047,41.263-40.315"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.45,94.539
				c10.727,0,19.422-18.282,19.422-40.847"
        />
      </g>
      <g>
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M118.315,101.982
				c-9.909,0-17.943-3.928-17.943-8.778"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M82.701,101.982
				c9.882,0,17.887-3.928,17.887-8.778"
        />
      </g>
      <g>
        <ng-container *ngTemplateOutlet="stem; context: { x: 36, y: 52, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 59, y: 52, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 80, y: 52, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 51, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 120, y: 54, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 142, y: 54, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 160, y: 53, length: 0 }"></ng-container>
      </g>
    </g>
  </g>
  <g id="flat_umbel_reduced" *ngSwitchCase="'flat_umbel_reduced'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100.573" y1="50" x2="100.573" y2="199.624" />
    <g>
      <g>
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.373,94.62
				c-35.321,0-63.955-18.66-63.955-41.688"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.588,95.039
				c-22.79,0-41.263-19.348-41.263-43.228"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M100.573,94.511
				c-10.726,0-19.421-18.939-19.421-42.317"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.651,95.803
				c32.271,0,58.434-19.269,58.434-43.057"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.436,95.034
				c22.788,0,41.263-18.047,41.263-40.315"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M101.45,94.539
				c10.727,0,19.422-18.282,19.422-40.847"
        />
      </g>
      <g>
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M118.315,101.982
				c-9.909,0-17.943-3.928-17.943-8.778"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.5"
          stroke-miterlimit="10"
          d="M82.701,101.982
				c9.882,0,17.887-3.928,17.887-8.778"
        />
      </g>
      <g>
        <ng-container *ngTemplateOutlet="stem; context: { x: 36, y: 52, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 59, y: 52, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 80, y: 52, length: 0, scale: 0.5 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 51, length: 0, scale: 0.5 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 120, y: 54, length: 0, scale: 0.5 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 142, y: 54, length: 0 }"></ng-container>
        <ng-container *ngTemplateOutlet="stem; context: { x: 160, y: 53, length: 0 }"></ng-container>
      </g>
    </g>
  </g>
  <g id="capitulum" *ngSwitchCase="'capitulum'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="101.23" y1="57.723" x2="101.23" y2="199.624" />
    <g>
      <g>
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M120.553,66.126
				c-9.912,0-17.944-3.719-17.944-8.307"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M81.798,66.24
				c9.881,0,17.886-3.72,17.886-8.308"
        />
        <path
          fill="none"
          [attr.stroke]="STROKE_COLOR"
          stroke-width="0.75"
          stroke-miterlimit="10"
          d="M40.608,81.668
				C53.045,66.936,74.814,57.172,99.593,57.17c24.16,0,45.461,9.285,58.041,23.407"
        />
      </g>
      <g>
        <g [attr.transform]="'rotate(' + r * 10 + ', 100, 150)'" *ngFor="let r of [-4, -3, -2, -1, 0, 1, 2, 3, 4]">
          <ng-container *ngTemplateOutlet="bud; context: { x: 100, y: 55 }"></ng-container>
        </g>
      </g>
    </g>
  </g>
  <g id="thyrse" *ngSwitchCase="'thyrse'">
    <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 200, length: 150 }"></ng-container>
    <g>
      <g transform="rotate(45, 100, 180)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 180, length: 75, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(90, 110, 170)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 110, y: 170, length: 30, side: 'right' }"></ng-container>
      </g>
      <g transform="rotate(90, 140, 140)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 140, y: 140, length: 10, side: 'right' }"></ng-container>
      </g>
      <ng-container *ngTemplateOutlet="stem; context: { x: 125, y: 155, length: 20, side: 'right' }"></ng-container>
    </g>
    <g>
      <g transform="rotate(-45, 100, 140)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 140, length: 50, side: 'left' }"></ng-container>
      </g>
      <g transform="rotate(-90, 90, 130)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 90, y: 130, length: 20, side: 'left' }"></ng-container>
      </g>
      <ng-container *ngTemplateOutlet="stem; context: { x: 80, y: 120, length: 20, side: 'right' }"></ng-container>
    </g>
    <g>
      <g transform="rotate(45, 100, 100)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 100, length: 30, side: 'right' }"></ng-container>
      </g>
    </g>
  </g>
  <g id="single" *ngSwitchCase="'single'">
    <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 200, length: 70, scale: 2 }"></ng-container>
  </g>
  <g id="coumpaund_umbel" *ngSwitchCase="'compound_umbel'">
    <line fill="none" [attr.stroke]="STROKE_COLOR" stroke-miterlimit="10" x1="100" y1="90" x2="100" y2="200" />
    <g [attr.transform]="'rotate(' + r * 65 + ', 100, 90)'" *ngFor="let r of [-2, -1, 0, 1, 2]">
      <ng-container *ngTemplateOutlet="group; context: { x: 100, y: 90, count: 4, angle: 13 }"></ng-container>
    </g>
  </g>
  <g id="scorpioid_cyme" *ngSwitchCase="'scorpioid_cyme'">
    <g>
      <ng-container *ngTemplateOutlet="stem; context: { x: 10, y: 200, length: 160 }"></ng-container>
    </g>
    <g transform="rotate(30, 10, 120)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 10, y: 120, length: 80, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(60, 21, 100)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 21, y: 100, length: 60, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(90, 40, 90)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 40, y: 90, length: 45, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(130, 60, 90)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 60, y: 90, length: 20, side: 'right' }"></ng-container>
    </g>
  </g>
  <g id="flat_cyme" *ngSwitchCase="'flat_cyme'">
    <g transform="rotate(-40, 150, 200)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 150, y: 200, length: 160 }"></ng-container>
    </g>
    <g transform="rotate(40, 107, 150)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 107, y: 150, length: 94, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(-40, 120, 136)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 120, y: 136, length: 77, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(40,107, 122)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 107, y: 122, length: 58, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(-40, 120, 108)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 120, y: 108, length: 40, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(40, 107, 94)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 107, y: 94, length: 22, side: 'right' }"></ng-container>
    </g>
  </g>
  <g id="dichasium" *ngSwitchCase="'dichasium'">
    <ng-container *ngTemplateOutlet="stem; context: { x: 100, y: 200, length: 90 }"></ng-container>
    <g [attr.transform]="'rotate(' + r * 40 + ', 100, 120)'" *ngFor="let r of [-3, -1, 1, 3]">
      <ng-container *ngTemplateOutlet="doubleGroup; context: { x: 100, y: 120 }"></ng-container>
    </g>
  </g>
  <g id="cyme" *ngSwitchCase="'cyme'">
    <g transform="rotate(-30, 140, 200)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 140, y: 200, length: 60 }"></ng-container>
    </g>
    <g transform="rotate(30, 125, 175)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 125, y: 175, length: 60, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(-30, 140, 150)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 140, y: 150, length: 60, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(30,125,125)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 125, y: 125, length: 60, side: 'right' }"></ng-container>
    </g>
    <g transform="rotate(-30, 140, 100)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 140, y: 100, length: 60, side: 'left' }"></ng-container>
    </g>
    <g transform="rotate(15, 125, 75)">
      <ng-container *ngTemplateOutlet="stem; context: { x: 125, y: 75, length: 45, side: 'right' }"></ng-container>
    </g>
  </g>
</svg>

<ng-template #group let-x="x" let-y="y" let-count="count" let-angle="angle">
  <svg [attr.x]="x - 25" [attr.y]="y - 50" width="50px" height="50px" viewBox="0 0 50 50">
    <g id="stem">
      <line [attr.stroke]="STROKE_COLOR" [attr.x1]="25" [attr.y1]="50" [attr.x2]="25" [attr.y2]="30" fill="none" />
      <g [attr.transform]="'rotate(' + r * angle + ', 25, 30)'" *ngFor="let r of getArray(count)">
        <ng-container *ngTemplateOutlet="stem; context: { x: 25, y: 30, length: 15, scale: 0.5 }"></ng-container>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #doubleGroup let-x="x" let-y="y">
  <svg [attr.x]="x - 35" [attr.y]="y - 70" width="70px" height="70px" viewBox="0 0 70 70">
    <g>
      <ng-container *ngTemplateOutlet="stem; context: { x: 35, y: 70, length: 30, scale: 0.8 }"></ng-container>
      <g [attr.transform]="'rotate(' + r * 40 + ', 35, 47)'" *ngFor="let r of getArray(2)">
        <ng-container *ngTemplateOutlet="group; context: { x: 35, y: 47, count: 3, angle: 25 }"></ng-container>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #stem let-x="x" let-y="y" let-length="length" let-side="side" let-scale="scale">
  <svg
    *ngIf="(scale > 1 ? scale*30:30);let sw"
    [attr.x]="x - sw"
    [attr.y]="y - sw - length"
    [attr.width]="2*sw"
    [attr.height]="sw + (length > sw ? length : sw)"
    [attr.viewBox]="'0 0 '+2*sw+' ' + (sw + (length > sw ? length : sw))"
  >
    <g id="stem">
      <line [attr.stroke]="STROKE_COLOR" [attr.x1]="sw" [attr.y1]="sw" [attr.x2]="sw" [attr.y2]="sw + length" fill="none" />
      <path
        *ngIf="side === 'right'"
        stroke="#000"
        id="svg_1"
        [attr.d]="'m30,' + (sw + length) + 'c0.05361,-0.68262 0.10721,-3.48895 3.53809,-3.48895'"
        opacity="0.5"
        fill="none"
      />
      <path
        *ngIf="side === 'left'"
        stroke="#000"
        id="svg_1"
        [attr.d]="'m30,' + (sw + length) + 'c-0.06031,-0.68262 -0.12062,-3.48895 -3.98034,-3.48895'"
        opacity="0.5"
        fill="none"
      />
      <ng-container *ngTemplateOutlet="bud; context: { x: sw, y: sw, scale: scale }"></ng-container>
    </g>
  </svg>
</ng-template>

<ng-template #bud let-x="x" let-y="y" let-rotate="rotate" let-scale="scale">
  <svg
    [attr.x]="x - (scale ? 30 * scale : 30)"
    [attr.y]="y - (scale ? 30 * scale : 30)"
    [attr.width]="(scale ? 60 * scale : 60) + 'px'"
    [attr.height]="(scale ? 60 * scale : 60) + 'px'"
    viewBox="0 0 60 60"
  >
    <g [attr.transform]="rotate ? 'rotate(' + rotate + ')' : ''" transform-origin="center">
      <!-- <rect
        x="0"
        y="0"
        [attr.mask]="'url(#mask-' + shapeMask + ')'"
        [attr.fill]="'url(#' + gradientId + ')'"
        [attr.width]="scale ? 30 * scale : 30"
        [attr.height]="scale ? 45 * scale : 45"
      /> -->
      <foreignObject x="0" y="0" width="100%" height="100%">
        <xhtml:app-flower-shapes
          [shape]="flowerShape"
          [path]="petalShape"
          [parameter]="parameter"
          [pattern]="patterns ? patterns[0] : undefined"
          [color]="colors? colors[0] : 'white'"
          [width]="60"
          [height]="60"
        ></xhtml:app-flower-shapes>
      </foreignObject>
    </g>
  </svg>
</ng-template>
