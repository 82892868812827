<mat-toolbar [ngClass]="{ logged: user | async, home: home && main }">
  <a [routerLink]="'/' | urlLang" [queryParams]="{ _app: 'main' }" [attr.aria-label]="'errorPage.info.mainPage'|translate">
    <div alt="Viridis logo"></div>
  </a>
  <span class="fill-space"></span>
  <div class="nav-links">
    <a
      mat-button
      [routerLink]="'/catalog/A' | urlLang"
      [queryParams]="{ _app: 'main' }"
      routerLinkActive="active"
      [attr.aria-label]="'aria.catalog' | translate"
    >
      {{ 'navigation.catalog' | translate }}
    </a>
    <a
      mat-button
      class="hideOnMobile"
      [routerLink]="'/accountsCompare' | urlLang"
      [queryParams]="{ _app: 'main' }"
      routerLinkActive="active"
      [attr.aria-label]="'aria.compare.plants' | translate"
    >
      {{ 'navigation.accountsCompare' | translate }}
    </a>
    <a
      mat-button
      class="hideOnMobile"
      [routerLink]="'/partners' | urlLang"
      [queryParams]="{ _app: 'main' }"
      routerLinkActive="active"
      [attr.aria-label]="'aria.partners' | translate"
    >
      {{ 'navigation.partners' | translate }}
    </a>
  </div>
  <span class="fill-space"></span>
  <!-- <app-bar-search *ngIf="!showBigSearch"></app-bar-search> -->
  <app-compare-indicator class="hideOnMobile" [matTooltip]="'navigation.compare' | translate"></app-compare-indicator>
  <button
    class="name"
    *ngIf="user | async; else unlogged"
    mat-button
    [matMenuTriggerFor]="userMenu"
    [attr.aria-label]="'navigation.sign'|translate"
  >
    <mat-icon>account_circle</mat-icon>
    <span class="hideOnMobile">{{ (user | async)?.name }}</span>
  </button>
  <ng-template #unlogged>
    <button mat-icon-button [matTooltip]="'navigation.sign' | translate" (click)="login()" [attr.aria-label]="'navigation.sign'|translate">
      <mat-icon>account_circle</mat-icon>
    </button>
  </ng-template>

  <button mat-icon-button [matMenuTriggerFor]="appMenu" [attr.aria-label]="'buttons.select'|translate">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <a
      *appHasRole="'admin'"
      mat-menu-item
      [routerLink]="'/' | urlLang"
      [queryParams]="{ _app: 'admin' }"
      [attr.aria-label]="'aria.admin.panel' | translate"
    >
      <mat-icon>assessment</mat-icon>
      {{ 'navigation.dashboard.admin' | translate }}
    </a>
    <a
      *appIsLogged
      mat-menu-item
      [routerLink]="'/' | urlLang"
      [queryParams]="{ _app: 'user' }"
      [attr.aria-label]="'aria.dashboard' | translate"
    >
      <mat-icon>dashboard</mat-icon>
      {{ 'navigation.dashboard' | translate }}
    </a>

    <ng-container *ngIf="user | async; else unloggedMenu">
      <a mat-menu-item [routerLink]="'/identify' | urlLang" [queryParams]="{ _app: 'user' }">
        <mat-icon></mat-icon>
        <strong>{{ 'sidemenu.identifier' | translate }}</strong>
      </a>
    </ng-container>
    <ng-template #unloggedMenu>
      <a mat-menu-item [routerLink]="'/favourites' | urlLang" [queryParams]="{ _app: 'main' }">
        <mat-icon>favorite_border</mat-icon>
        {{ 'navigation.favourites' | translate }}
      </a>
    </ng-template>

    <a
      mat-menu-item
      [routerLink]="'/compare' | urlLang"
      [queryParams]="{ _app: 'main' }"
      [attr.aria-label]="'aria.compare.plants' | translate"
    >
      <mat-icon>compare_arrows</mat-icon>
      {{ 'navigation.compare' | translate }}
    </a>
    <a mat-menu-item [routerLink]="'/catalog/A' | urlLang" [queryParams]="{ _app: 'main' }">
      <mat-icon>perm_media</mat-icon>
      {{ 'navigation.catalog' | translate }}
    </a>
  </ng-template>
  <a
    mat-menu-item
    class="showOnMobile"
    [routerLink]="'/accountsCompare' | urlLang"
    [queryParams]="{ _app: 'main' }"
    routerLinkActive="active"
    [attr.aria-label]="'aria.compare.plants' | translate"
    ><mat-icon></mat-icon>
    {{ 'navigation.accountsCompare' | translate }}
  </a>
  <a
    mat-menu-item
    class="showOnMobile"
    [routerLink]="'/partners' | urlLang"
    [queryParams]="{ _app: 'main' }"
    routerLinkActive="active"
    [attr.aria-label]="'aria.partners' | translate"
    ><mat-icon></mat-icon>
    {{ 'navigation.partners' | translate }}
  </a>
</mat-menu>

<mat-menu #userMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item [routerLink]="'/' | urlLang" [queryParams]="{ _app: 'user' }">
      <mat-icon>settings</mat-icon>
      {{ 'navigation.application' | translate }}
    </a>
    <a mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      {{ 'navigation.logout' | translate }}
    </a>
  </ng-template>
</mat-menu>
