import { Component } from '@angular/core';
import { slideLeftRightAnimation } from 'src/app/animations/component-animations';
import { AppLangComponent } from 'src/app/app-lang.component';

@Component({
  selector: 'app-main',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideLeftRightAnimation]
})
export class AppMainComponent extends AppLangComponent {}
