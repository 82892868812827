import { Component, Input, OnInit } from '@angular/core';
import { getShape } from 'src/app/helpers/shapes';
import { Feature } from 'src/app/types/features';
import { COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';
import { Pattern, Shape } from 'src/app/types/features/selectTypes';
import { colorType, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features/types';

@Component({
  selector: 'app-leaf-group-form',
  templateUrl: './leaf-group-form.component.html',
  styleUrls: ['./leaf-group-form.component.scss']
})
export class LeafGroupFormComponent implements OnInit {
  @Input() features?: { [key: string]: Feature<any>[] };

  leafShapeFeature: Feature<Shape>[] = [];
  colorFeature: Feature<colorType>[] = [];
  leafPatternFeature: Feature<Pattern>[] = [];
  timeFeature: Feature<Range>[] = [];

  leafShape?: Shape;
  colors: colorType[] = [];
  leafPatterns: Pattern[] = [];
  times: Range[] = [];

  readonly id = Math.random().toString(36);

  ngOnInit(): void {
    if (!this.features) {
      return;
    }

    this.leafShapeFeature = this.features['leaf_shape'];
    this.colorFeature = this.features['color'];
    this.leafPatternFeature = this.features['pattern'];
    this.timeFeature = this.features['time'];

    this.leafShape = GFT.get('foliage.leaf_shape').reduce(this.leafShapeFeature);
    this.colors = GFT.get('foliage.color').reduce(this.colorFeature);
    this.leafPatterns = GFT.get('foliage.pattern').reduce(this.leafPatternFeature);
    this.times = GFT.get('foliage.time').reduce(this.timeFeature);
  }

  getColor(name: string): string {
    const col = COLOR_VALUES[name];
    return `hsl(${col.h} , ${col.s}%, ${col.l}%)`;
  }
  getPath(p: Shape, mirror: number): string {
    return getShape(p, 300, mirror === 1);
  }
}
