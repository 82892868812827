const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M42.554,33.919C49.929,38.011,50,50,50,50
          s-10.486,4.99-17.1,1.321C27.098,48.102,24.634,50.14,17.059,30C27.763,35.743,36.751,30.7,42.554,33.919z"
        />`,
    boundingBox: [17.059, 30, 32.941, 22.668],
    angle: 58
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M67.535,52.563C60.076,56.499,50,50,50,50
          s1.56-11.508,8.249-15.037c5.869-3.098,15.503,2.007,26.251-3.16C76.762,51.533,73.404,49.467,67.535,52.563z"
        />`,
    boundingBox: [50, 31.803, 34.5, 22.026],
    angle: -58
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M54.783,32.936C57.734,40.837,50,50,50,50
          s-11.214-3.018-13.859-10.103C33.819,33.681,36.189,19.872,35.5,15C42.741,18.865,52.462,26.719,54.783,32.936z"
        />`,
    boundingBox: [35.095, 15, 20.36, 35],
    angle: 22
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M64.897,40.403C61.865,48.272,50,50,50,50
          s-6.391-9.696-3.671-16.753c2.386-6.192,11.329-14.35,18.667-18.184C64.185,19.932,67.283,34.21,64.897,40.403z"
        />`,
    boundingBox: [45.655, 15.063, 20.164, 34.937],
    angle: -22
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M66.356,46.428C62.161,52.672,53.513,50,53.513,50
          s-1.82-11.35,1.941-16.949c3.301-4.914,13.997-10.785,22.745-11.389C73.889,22.736,69.658,41.515,66.356,46.428z"
        />`,
    boundingBox: [53.073, 21.662, 25.127, 28.097],
    angle: -42
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M45.25,32.371C49.428,38.638,46.592,50,46.592,50
          s-8.539,1.309-12.284-4.312c-3.286-4.932-7.936-22.961-12.208-24.038C30.826,22.259,41.964,27.439,45.25,32.371z"
        />`,
    boundingBox: [22.099, 21.652, 25.433, 28.481],
    angle: 42
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M37.77,39.649
          c7.974,0.896,12.218,9.882,12.218,9.882s-7.567,6.844-14.717,6.039c-6.273-0.706-13.298-6.44-23.215-10.303
          C22.82,43.729,31.497,38.944,37.77,39.649z"
        />`,
    boundingBox: [12.057, 39.579, 37.932, 16.057],
    angle: 80
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M65.381,55.436
          c-7.874,1.386-15.393-5.904-15.393-5.904s4.466-8.577,11.527-9.821c6.194-1.091,15.068,2.355,25.934,3.224
          C77.895,47.399,71.577,54.345,65.381,55.436z"
        />`,
    boundingBox: [49.988, 39.499, 37.461, 16.112],
    angle: -80
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M59.211,35.688c0,8.434-9.149,14.313-9.149,14.313
          s-8.273-6.75-8.273-14.313c0-6.636,5.318-14.875,8.273-25.813C53.017,20.813,59.211,29.051,59.211,35.688z"
        />`,
    boundingBox: [41.789, 9.875, 17.422, 40.125],
    angle: 0
  }
];

export default paths;
