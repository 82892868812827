import { Component, Input, OnChanges } from '@angular/core';

import { Features } from 'src/app/types/features';

import { colorType } from 'src/app/types/features/types';

@Component({
  selector: 'app-flower-display',
  templateUrl: './flower-display.component.html',
  styleUrls: ['./flower-display.component.scss']
})
export class FlowerDisplayComponent implements OnChanges {
  @Input() features!: Features;

  colorSets: { [key: string]: colorType[] } = {};
  currentColorSetName = 'all';
  colorChanging = false;

  ngOnChanges(): void {
    this.colorSets = this.features.getReducedValues('flower.color');

    this.currentColorSetName = 'all';

    this.colorChanging =
      this.colorSets.all?.length !== this.colorSets.end?.length ||
      this.colorSets.all?.length !== this.colorSets.middle?.length ||
      this.colorSets.all?.length !== this.colorSets.start?.length;
  }

  setColorSet(idx: number): void {
    const name = ['start', 'middle', 'end', 'all'][idx];

    this.currentColorSetName = this.currentColorSetName === name ? 'all' : name;
  }
}
