<ng-container *ngIf="optionsConfig && options && optionName">
  <div class="options" *ngIf="multiple">
    <label>{{ 'forms.features.chooseMultiple' | translate }}:</label>
    <mat-chip-list id="chipList">
      <mat-chip *ngFor="let opt of options[optionName]" [ngClass]="{ selected: value?.includes(opt) }" (click)="toggleSelection(opt)">
        {{ optionsConfig.renderValue(opt) | translate }}
        <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="options" *ngIf="!multiple">
    <label>{{ 'forms.features.chooseSingle' | translate }}:</label>

    <div class="option-list" *ngIf="name === 'inflorescence'">
      <div *ngFor="let opt of options[optionName]" [ngClass]="{ option: true, selected: value?.includes(opt) }" (click)="value = [opt]">
        {{ optionsConfig.renderValue(opt) | translate }}
        <app-inflorescence-silhouette [width]="100" [height]="100" [type]="opt" [colors]="['pink']"></app-inflorescence-silhouette>
      </div>
    </div>

    <div class="option-list" *ngIf="name === 'flower_shape'">
      <div *ngFor="let opt of options[optionName]" [ngClass]="{ option: true, selected: value?.includes(opt) }" (click)="value = [opt]">
        {{ optionsConfig.renderValue(opt) | translate }}
        <app-flower-shapes [shape]="opt" [parameter]="5" class="silhouette-image"></app-flower-shapes>
      </div>
    </div>

    <div class="option-list" *ngIf="name === 'sort'">
      <div *ngFor="let opt of options[optionName]" [ngClass]="{ option: true, selected: value?.includes(opt) }" (click)="value = [opt]">
        {{ optionsConfig.renderValue(opt) | translate }}
        <app-sort-silhouette [width]="0.2" [height]="0.2" type="leefy_bushes" [sort]="opt"></app-sort-silhouette>
      </div>
    </div>

    <mat-chip-list *ngIf="name !== 'sort' && name !== 'flower_shape' && name !== 'inflorescence'">
      <mat-chip *ngFor="let opt of options[optionName]" [ngClass]="{ selected: opt === value?.[0] }" (click)="value = [opt]">
        <!-- {{ (name === 'frost_resistance' ? '':featureName)+opt | translate}} -->
        {{ optionsConfig.renderValue(opt) | translate }}
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-container>
