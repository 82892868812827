import ShapeConfig from '../ShapeConfig.type';

const SHAPES = {
  first: {
    tip: 100,
    tipHandle: [15, 80],
    middle: [
      {
        point: [25, 40],
        handleNext: [25, 15],
        handlePrev: [25, 55]
      }
    ],
    baseHandle: [20, 0]
  },
  second: {
    tip: 80,
    tipHandle: [15, 80],
    middle: [{ point: [23.3, 50.7], handlePrev: [23.7, 65.7], handleNext: [22, 37.3] }],
    baseHandle: [20, 20]
  },
  third: {
    tip: 80,
    tipHandle: [35.3, 79],
    middle: [{ point: [22.7, 35], handlePrev: [31.4, 51.7], handleNext: [16.7, 23.7] }],
    baseHandle: [14.3, 19]
  },
  fourth: {
    tip: 92.7,
    tipHandle: [16.3, 89.7],
    middle: [{ point: [21.7, 54.7], handlePrev: [21, 70], handleNext: [21.7, 40] }],
    baseHandle: [20, 20]
  },
  rosa: {
    tip: 83.7,
    tipHandle: [48.7, 81.7],
    middle: [{ point: [42.7, 43.7], handlePrev: [50.4, 53.7], handleNext: [35.4, 35.4] }],
    baseHandle: [11.7, 7.7]
  }
};

export default {
  ...ShapeConfig,
  name: 'flower.petal_shape',
  selectType: {
    ...ShapeConfig.selectType,
    values: Object.values(SHAPES),
    renderValue: (): string => ''
  },
  constraints: [{ name: 'groups.use', values: ['flowering'] }],
  renderName: (): string => 'features.flower.petal_shape.'
};
