import { defaultFeatureConfig, FeatureConfig } from './config';
import { PatternType } from './selectTypes';

export default {
  ...defaultFeatureConfig(''),
  controlType: 'pattern',
  multipleValues: true,
  selectType: {
    values: [],
    renderValue: (): string => ''
  } as PatternType
} as FeatureConfig;
