import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AppLangComponent } from './app-lang.component';

@NgModule({
  declarations: [AppLangComponent],
  exports: [AppLangComponent],
  imports: [CommonModule, RouterModule, TranslateModule.forChild()]
})
export class AppLangModule {}
