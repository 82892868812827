import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { Features, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/featuresV2';

import { BaseTaxon, TaksoUnit } from 'src/app/types';

import { TaksonomyService } from '../database/taksonomy.service';

import { Features, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

export type DiffType = 'equal' | 'missing' | 'differ';

const COMPARABLE_MAX = 3;

export interface FeatureDiff {
  group: string;
  name: string;
  type: DiffType[];
}

@Injectable({ providedIn: 'root' })
export class CompareService {
  private comparablesList$: BehaviorSubject<BaseTaxon[]> = new BehaviorSubject<BaseTaxon[]>([]);

  get comparables(): Observable<BaseTaxon[]> {
    return this.comparablesList$.asObservable();
  }

  constructor(private taksonomyService: TaksonomyService) {
    this.getComparablesDetails()
      .then((list) => {
        this.comparablesList$.next(list);
      })
      .catch((e) => console.error('TODO error', e));
  }

  private async getComparablesDetails(): Promise<TaksoUnit[]> {
    const comp = this.getLocalComparable();
    return await this.taksonomyService.getDetailsMulti(comp, ['features']);
  }

  private getLocalComparable(): string[] {
    const comp = localStorage.getItem('comparable');
    if (!comp) {
      return [];
    }
    try {
      const list = JSON.parse(comp);
      return list as string[];
    } catch (e) {
      return [];
    }
  }

  private setLocalComparable(list: BaseTaxon[]): void {
    const comp = list.map((t) => t.taxonId).slice(0, 3);
    localStorage.setItem('comparable', JSON.stringify(comp));
    this.comparablesList$.next(list);
  }

  addToCompare(takso: BaseTaxon): void {
    const comp = this.comparablesList$.getValue();
    if (comp.length === COMPARABLE_MAX) {
      comp.shift();
    }
    comp.push(takso);
    this.setLocalComparable(comp);
  }

  removeFromFCompare(takso: BaseTaxon): void {
    const comp = this.comparablesList$.getValue();
    const filtered = comp.filter((f) => f.taxonId !== takso.taxonId);
    this.setLocalComparable(filtered);
  }

  removeAll(): void {
    this.setLocalComparable([]);
  }

  checkComparable(taxonId: string): boolean {
    const comp = this.comparablesList$.getValue().map((t) => t.taxonId);

    return comp.includes(taxonId);
  }

  compare(taksoList: TaksoUnit[]): FeatureDiff[] {
    const list = taksoList.map((t) => t.features || new Features([]));

    const diffs: FeatureDiff[] = [];
    Features.getGroups().forEach((group) => {
      Features.getFeatureNames(group).forEach((name) => {
        const type: DiffType[] = ['missing', 'missing', 'missing'];

        const features = list.map((f) => f.get(group + '.' + name));

        features.forEach((f, i) => {
          if (f) {
            type[i] = 'differ';
          }
        });
        const [a, b, c] = features;

        if (a && b && GFT.get(group, name).featureCompare(a, b)) {
          type[0] = 'equal';
          type[1] = 'equal';
        }
        if (a && c && GFT.get(group, name).featureCompare(a, c)) {
          type[0] = 'equal';
          type[2] = 'equal';
        }
        if (c && b && GFT.get(group, name).featureCompare(c, b)) {
          type[2] = 'equal';
          type[1] = 'equal';
        }
        if (type[0] !== 'missing' || type[1] !== 'missing' || type[2] !== 'missing') {
          diffs.push({
            group,
            name,
            type
          });
        }
      });
    });
    return diffs;
  }
}
