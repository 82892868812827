import { TaksoUnit } from './takso';
import { User } from './user';
import { Image } from './image';

/** @deprecated */
export class Bucket {
  bucketId = '';
  bucketName = '';
  takson?: TaksoUnit;
  owner?: User;
  public = true;
  mainImage: Image | undefined;
  images: Image[] = [];

  // links for navigation by bucket
  pageLink = '';
  editLink = '';

  static sort(a: Bucket, b: Bucket): number {
    if (typeof a.takson === 'undefined' || typeof a.takson.variety === 'undefined') {
      return 1;
    }
    if (typeof b.takson === 'undefined' || typeof b.takson.variety === 'undefined') {
      return -1;
    }
    return a.bucketName > b.bucketName ? 1 : -1;
  }

  // TEMP till removal
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: any) {
    if (!data) {
      return;
    }
    if (typeof data === 'object' && data.bucketName) {
      this.copy(data);
      return;
    }

    this.bucketId = data.bucketId;
    this.bucketName = data.name;
    this.takson = data.takson ? new TaksoUnit(data.takson) : undefined;
    this.owner = new User(data.owner);
    this.public = data.public;
    this.mainImage = data.mainImage ? new Image(data.mainImage) : undefined;
    this.images = data.images ? data.images.map((i: any) => new Image(i)) : [];

    this.pageLink = this.getPageLink();
    this.editLink = this.getEditLink();
  }

  private copy(data: Bucket) {
    this.bucketId = data.bucketId;
    this.bucketName = data.bucketName;
    this.takson = data.takson;
    this.owner = data.owner;
    this.public = data.public;
    this.mainImage = data.mainImage;
    this.images = data.images || [];

    this.pageLink = this.getPageLink();
    this.editLink = this.getEditLink();
  }

  private getPageLink() {
    return '/buckets/' + this.bucketId + '/' + (this.bucketName !== 'buckets.unknown' ? this.bucketName : '-');
  }
  private getEditLink() {
    return '/buckets/edit/' + this.bucketId + '/' + (this.bucketName !== 'buckets.unknown' ? this.bucketName : '-');
  }
}
