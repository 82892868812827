<div *ngIf="config.controlType === 'option' && options">
  <app-option-field
    [value]="value"
    [label]="config.renderName()"
    (changed)="changed.emit($event)"
    [options]="options"
    [render]="config.renderValue"
  ></app-option-field>
</div>

<div *ngIf="config.controlType === 'range' && rangeOptions" class="range">
  <app-range-field
    [value]="value"
    [label]="config.renderName()"
    (changed)="changed.emit($event)"
    [options]="rangeOptions"
    [render]="config.renderValue"
  ></app-range-field>
</div>
