import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Image } from 'src/app/types';
import { ImageService } from 'src/app/services/database/image.service';
import { SnackbarService } from 'src/app/services/utilities/snackbar.service';

@Component({
  selector: 'app-image-delete-form',
  templateUrl: './image-delete-form.component.html',
  styleUrls: ['./image-delete-form.component.scss']
})
export class ImageDeleteFormComponent {
  @Input() image!: Image;

  @Output() saved: EventEmitter<Image | null> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  loading = false;

  mobile = false;

  @ViewChild('description') typedDescription?: ElementRef;

  constructor(private imageService: ImageService, private snackbar: SnackbarService) {}

  onCancel(): void {
    this.cancel.emit(true);
  }

  onDelete(): void {
    this.loading = true;
    this.imageService
      .removeImage(this.image.imageId)
      .then(() => {
        this.snackbar.showSnackbar('imageControl.deleted', 'success');
        this.loading = false;
        this.saved.emit(this.image);
      })
      .catch(() => {
        this.loading = false;
        this.snackbar.showSnackbar('snackBar.response.error', 'warning');
        this.saved.emit(null);
      });
  }
}
