import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { Image as _Image } from 'src/app/types';
import { Geolocation } from 'src/app/types/image';
import { IFile, ImageService } from 'src/app/services/database/image.service';
import { SnackbarService } from 'src/app/services/utilities/snackbar.service';
import { getDefaultLang } from 'src/app/helpers/lang';
import { defaultLocation } from 'src/app/data/defaultLocations';
import date from 'src/app/helpers/date';

@Component({
  selector: 'app-image-map-form',
  templateUrl: './image-map-form.component.html',
  styleUrls: ['./image-map-form.component.scss']
})
export class ImageMapFormComponent implements AfterViewInit, OnInit {
  @Input() image?: IFile | _Image;
  @Input() type?: 'edit' | 'new';
  @Input() location?: Geolocation | string;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() saved: EventEmitter<Geolocation> = new EventEmitter();

  markerOptions?: google.maps.MarkerOptions;
  markerPosition?: google.maps.LatLngLiteral;
  mapPosition?: google.maps.LatLngLiteral;

  loading = false;

  mobile = false;

  constructor(private imageService: ImageService, private snackbar: SnackbarService) {}

  ngOnInit(): void {
    const location = this.location ? this.location : this.image?.location;
    this.setLocation(location);
  }

  ngAfterViewInit(): void {
    if (!this.type) {
      this.type = 'new';
    }

    this.onResize();
  }

  @HostListener('window:resize')
  onResize(): void {
    if (window.innerWidth < 380) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  private isImage(img: any): img is _Image {
    return (img as _Image).imageId !== undefined;
  }

  undoClick(): void {
    if (
      this.markerPosition
        ? this.markerPosition?.lat !== this.image?.location?.latitude || this.markerPosition?.lng !== this.image.location.longitude
        : false
    ) {
      this.markerPosition = undefined;
      const location = this.location ? this.location : this.image?.location;
      this.setLocation(location);
    }
  }

  private setLocation(location: string | Geolocation | undefined) {
    if (location) {
      if (typeof location === 'string') {
        location = new Geolocation(location);
      }
      if (location.longitude && location.latitude) {
        this.markerPosition = {
          lat: location.latitude,
          lng: location.longitude
        };
      }
      this.mapPosition = this.markerPosition;
    } else {
      this.mapPosition = defaultLocation(getDefaultLang());
    }
  }

  mapClicked(event: google.maps.MapMouseEvent): void {
    this.markerPosition = event.latLng.toJSON();
  }

  onEditFormSave(): void {
    if (!this.isImage(this.image)) {
      return;
    }

    if (
      !(
        this.markerPosition?.lat === this.image?.location?.latitude ||
        this.markerPosition?.lng === this.image?.location?.longitude ||
        (!this.markerPosition && !this.image?.location?.longitude)
      ) &&
      this.image
    ) {
      const imageLocation = !this.image?.location?.longitude || !isNaN(this.image?.location?.longitude) ? '' : this.image?.location;
      const currentLocation = new Geolocation(imageLocation);

      let location: Geolocation | undefined;
      if (this.markerPosition?.lat) {
        if (currentLocation.longitude !== this.markerPosition.lng || currentLocation.latitude !== this.markerPosition.lat) {
          location = new Geolocation(`${this.markerPosition?.lng}:${this.markerPosition?.lat}`);
        }
      }

      this.loading = true;
      this.imageService
        .editImage({
          imageId: this.image.imageId,
          location,
          public: false,
          date: (this.image.date ? date(this.image.date) : new Date()).toISOString()
        })
        .then(() => {
          this.loading = false;
          this.snackbar.showSnackbar('imageControl.location-edited', 'success');
          this.saved.emit(location);
        })
        .catch(() => {
          this.snackbar.showSnackbar('snackBar.response.error', 'warning');
          this.loading = false;
        });
    }
  }

  onRemove(): void {
    if (this.markerPosition) {
      this.markerPosition = undefined;
    }
  }

  newFormSave(): void {
    if (
      !(
        this.markerPosition?.lat === this.image?.location?.latitude ||
        this.markerPosition?.lng === this.image?.location?.longitude ||
        (!this.markerPosition && !this.image?.location?.longitude)
      ) &&
      this.image
    ) {
      let location;

      if (this.image.location?.longitude !== this.markerPosition?.lng || this.image.location?.latitude !== this.markerPosition?.lat) {
        if (this.markerPosition?.lng) {
          location = new Geolocation(`${this.markerPosition.lng}:${this.markerPosition.lat}`);
        } else {
          location = undefined;
        }
      } else {
        if (this.image.location?.longitude) {
          location = new Geolocation(
            `${this.image.location.longitude}:${this.image.location.latitude || ''}:${this.image.location.altitude || ''}`
          );
        } else {
          location = undefined;
        }
      }

      this.saved.emit(location);
    }
  }
}
