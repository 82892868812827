import { colorType, optionType } from './types';

export interface FeatureType {
  renderValue: (v: any) => string;
}

export interface OptionType extends FeatureType {
  readonly values: readonly optionType[];
  multiple?: boolean;
}
export interface ParametrizedOptionType extends OptionType {
  parameter?: {
    [key: string]: number[];
  };
}

export const isOptionType = (tbd: unknown): tbd is OptionType => {
  if ('values' in (tbd as OptionType)) {
    return true;
  }
  return false;
};

export interface RangeType extends FeatureType {
  min: number;
  max: number;
  default: number;
  step: number;
  range?: boolean;
  log?: boolean;
  months?: boolean;
  label: (v: number, detailed?: boolean) => string;
  ticks: (conf?: any) => number[];
}

export const generateLinear = (min: number, max: number, defaultStep = 1) => {
  return (val?: number): number[] => {
    const step = val ? val : defaultStep;
    const options = [];
    for (let i = min; i <= max; i += step) {
      options.push(i);
    }
    return options;
  };
};

export const generateLog = (min: number, max: number, defaultTenth: number[]) => {
  return (arr?: number[]): number[] => {
    const tenth = arr ? arr : defaultTenth;
    const first = tenth.map((e) => (e * max) / 1000);
    const second = tenth.map((e) => (e * max) / 100);
    const third = tenth.map((e) => (e * max) / 10);
    return [...first, ...second, ...third].filter((e) => e >= min && e <= max).filter((e, idx, self) => self.indexOf(e) === idx);
  };
};

export const isRangeType = (tbd: unknown): tbd is RangeType => {
  if ('min' in (tbd as RangeType) && 'max' in (tbd as RangeType)) {
    return true;
  }
  return false;
};

export interface ColorType extends FeatureType {
  readonly values: readonly string[];
}

// for shape type
export interface Point {
  point: [number, number];
  handlePrev?: [number, number];
  handleNext?: [number, number];
}

export interface Shape {
  tip: number;
  tipHandle: [number, number];
  middle: Point[];
  baseHandle: [number, number];
}
export interface ShapeType extends FeatureType {
  readonly values: readonly Shape[];
}

export interface Spot {
  center: [number, number];
  w: number;
  h: number;
  r: number;
  angle: number;
  color: colorType;
}

export interface Pattern {
  base: colorType;
  spots: Spot[];
}

export interface PatternType extends FeatureType {
  readonly values: readonly Pattern[];
}
