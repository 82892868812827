import { Component, Output, EventEmitter, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-popup',
  template: `<div class="overlay"></div>
    <div class="popup">
      <div>
        <mat-icon (click)="close()">close</mat-icon>
      </div>
      <ng-content></ng-content>
    </div>`,
  styleUrls: ['popup.component.scss']
})
export class PopupComponent {
  @HostBinding('class.full') @Input() full = false;
  @HostBinding('class.min') @Input() min = false;

  @Output() closed = new EventEmitter();

  close(): void {
    this.closed.emit(true);
  }
}
