<div *ngIf="featureConfig" [ngClass]="{ header: true, modified: featureModified }">
  <div [class]="'value '+styleClass">
    <ng-container [ngSwitch]="featureConfig.controlType">
      <span *ngSwitchCase="'option'">
        <ng-container *ngIf="isArray(value); else singleBlock">
          <span class="array" *ngFor="let v of value">{{ featureConfig.renderValue(v) | translate }}</span>
        </ng-container>
        <ng-template #singleBlock>
          <span class="array">{{ featureConfig.renderValue(value) | translate }}</span>
        </ng-template>
      </span>
      <span *ngSwitchCase="'range'">
        <ng-container *ngIf="!isRange(value); else rangeBlock"> {{ featureConfig.renderValue(value) | translate }} </ng-container>
        <ng-template #rangeBlock>
          {{ featureConfig.renderValue(value.min) | translate }}: {{ featureConfig.renderValue(value.max) | translate }}
        </ng-template>
      </span>
      <app-color-indicator *ngSwitchCase="'color'" [colors]="value" [size]="120"></app-color-indicator>
      <app-pattern-display *ngSwitchCase="'pattern'" [value]="value" [size]="120"></app-pattern-display>
      <app-shape-display *ngSwitchCase="'shape'" [value]="value" [size]="120"></app-shape-display>
      <span *ngSwitchDefault> -*- </span>
    </ng-container>
  </div>
</div>
