import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const slideLeftRightAnimation = trigger('routeAnimations', [
  transition('TaksonSV => TaksonS', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'white' })),
    group([
      query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))]),
      query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))])
    ])
  ]),
  transition('TaksonS => TaksonSV', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%', backgroundColor: 'white' })),
    group([
      query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))]),
      query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))])
    ])
  ])
]);
