import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { GoogleMapsModule } from '@angular/google-maps';

import { TranslateModule } from '@ngx-translate/core';
import { LightboxModule } from 'ngx-lightbox';
// import { Ng5SliderModule } from 'ng5-slider';
import { ImageCropperModule } from 'ngx-image-cropper';

import { CustomMaterialModule } from './custom.material.module';

import { ActionButtonComponent } from './action-button/action-button.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { PaperComponent } from './paper/paper.component';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';

import { CropperComponent } from './cropper/cropper.component';
import { FooterComponent } from './footer/footer.component';
import { PipeModule } from '../pipes/pipe.module';
import { PopupComponent } from './popup/popup.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { SpeciesSelectComponent } from './species-select/species-select.component';
import { TaksonomySelectComponent } from './taksonomy-select/taksonomy-select.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { ProgressComponent } from './progress/progress.component';
import { ImageComponent } from './image/image.component';
import { ImageListComponent } from './image-list/image-list.component';
import { SeasonIndicatorComponent } from './season-indicator/season-indicator.component';
import { VarietySelectComponent } from './variety-select/variety-select.component';
import { TaksonComponent } from './takson/takson.component';
import { BlockLoaderComponent } from './block-loader/block-loader.component';
import { RankComponent } from './rank/rank.component';
import { ImageSelectComponent } from './image-select/image-select.component';
import { LoaderComponent } from './loader/loader.component';
import { TimeRangeComponent } from './time-range/time-range.component';
import { ColorIndicatorComponent } from './color-indicator/color-indicator.component';
import { CompareIndicatorComponent } from './compare-indicator/compare-indicator.component';
import { QuickFilterComponent } from './quick-filter/quick-filter.component';
import { QRCodeComponent } from './qrcode/qrcode.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { CookiesBarComponent } from './cookies-bar/cookies-bar.component';
import { VerticalOverflowBlockComponent } from './vertical-overflow-block/vertical-overflow-block.component';
import { DirectivesModule } from '../directives/directives.module';
import { PremiumIconComponent } from './premium-icon/premium-icon.component';
import { DescriptionWithTagsComponent } from './description-with-tags/description-with-tags.component';
import { PlantSelectComponent } from './plant-select/plant-select.component';
import { PlantThumbComponent } from './plant-thumb/plant-thumb.component';
import * as allImage from './image';
import * as allSilhouettes from './silhouette';
import * as allIcons from './icon';
import * as allTaksonDetails from './takson-details';
import * as allFeatures from './features';
import * as allForms from './forms';
import * as allFormFields from './form-fields';
import * as allSearch from './search';
import { EditableFieldComponent } from './editable-field/editable-field.component';
import { ShopListComponent } from './shop-list/shop-list.component';

@NgModule({
  declarations: [
    ActionButtonComponent,
    LanguageSelectComponent,
    PaperComponent,
    ControlMessagesComponent,
    ErrorMessagesComponent,
    NavigationBarComponent,
    CropperComponent,
    FooterComponent,
    TaksonComponent,
    ImageComponent,
    ImageListComponent,
    PopupComponent,
    ThumbnailComponent,
    TaksonomySelectComponent,
    SpeciesSelectComponent,
    VarietySelectComponent,
    UploadTaskComponent,
    ProgressComponent,
    SeasonIndicatorComponent,
    BlockLoaderComponent,
    RankComponent,
    ImageSelectComponent,
    LoaderComponent,
    TimeRangeComponent,
    ColorIndicatorComponent,
    CompareIndicatorComponent,
    VerticalOverflowBlockComponent,
    QuickFilterComponent,
    QRCodeComponent,
    ContactFormComponent,
    PremiumIconComponent,
    LightboxComponent,
    CookiesBarComponent,
    DescriptionWithTagsComponent,
    PlantSelectComponent,
    EditableFieldComponent,
    PlantThumbComponent,
    ShopListComponent,
    ...allImage.components,
    ...allSilhouettes.components,
    ...allIcons.components,
    ...allTaksonDetails.components,
    ...allFeatures.components,
    ...allForms.components,
    ...allFormFields.components,
    ...allSearch.components
  ],
  exports: [
    ActionButtonComponent,
    LanguageSelectComponent,
    PaperComponent,
    ControlMessagesComponent,
    ErrorMessagesComponent,
    NavigationBarComponent,
    CropperComponent,
    FooterComponent,
    TaksonComponent,
    ImageComponent,
    ImageListComponent,
    PopupComponent,
    ThumbnailComponent,
    TaksonomySelectComponent,
    SpeciesSelectComponent,
    VarietySelectComponent,
    UploadTaskComponent,
    ProgressComponent,
    SeasonIndicatorComponent,
    BlockLoaderComponent,
    RankComponent,
    ImageSelectComponent,
    LoaderComponent,
    TimeRangeComponent,
    ColorIndicatorComponent,
    CompareIndicatorComponent,
    VerticalOverflowBlockComponent,
    QuickFilterComponent,
    QRCodeComponent,
    ContactFormComponent,
    PremiumIconComponent,
    LightboxComponent,
    CookiesBarComponent,
    DescriptionWithTagsComponent,
    PlantSelectComponent,
    EditableFieldComponent,
    PlantThumbComponent,
    ShopListComponent,
    ...allImage.components,
    ...allSilhouettes.components,
    ...allIcons.components,
    ...allTaksonDetails.components,
    ...allFeatures.components,
    ...allForms.components,
    ...allFormFields.components,
    ...allSearch.components
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    RouterModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    PipeModule,
    DirectivesModule,
    LightboxModule,
    // Ng5SliderModule,
    GoogleMapsModule,
    LayoutModule
  ]
})
export class ComponentsModule {}
