import { generateLinear, RangeType } from './selectTypes';

import { FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from './config';

const i18nName = (v: string) => `months.range.${parseInt(v, 10)}`;

export default {
  ...defaultFeatureConfig(''),
  controlType: 'range',
  selectType: {
    min: 0,
    max: 12,
    default: 3,
    step: 0.5,
    months: true,
    range: true,
    label: (v, detailed = false) => `months.range.${v}${detailed ? '.' : ''}`,
    renderValue: i18nName,
    ticks: generateLinear(0, 12, 0.5)
  } as RangeType,
  reduce: countedFrequencyReduce,
  renderValue: i18nName
} as FeatureConfig;
