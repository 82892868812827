import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { Options } from 'ng5-slider';

import { LanguageService } from 'src/app/services/language/language.service';
import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';
import { RangeType } from 'src/app/types/features/selectTypes';
import { FeatureConfig } from 'src/app/types/features/config';

interface Options {
  floor: number;
  ceil: number;
}

@Component({
  selector: 'app-range-select',
  templateUrl: './range-select.component.html',
  styleUrls: ['./range-select.component.scss']
})
export class RangeSelectComponent implements OnInit {
  @Input() feature!: Feature<any>;
  @Input() saved?: BehaviorSubject<boolean>;

  @Output() changed = new EventEmitter<Feature<any>>();

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;
  rangeConfig?: RangeType;

  sliderOptions: Options = {
    floor: 0,
    ceil: 200
  };

  /** user has changed the value, and it is scheduled to save */
  modified = false;
  /** user had set the value previously */
  default = true;

  featureName = '';

  options: any[] = [];
  labels: any = {};
  label?: (value: any, detail?: boolean) => string;

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }
  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set value(newVal: any) {
    if (!this.group || !this.name) {
      return;
    }

    const info = this.feature
      ? {
          featureId: this.feature.featureId,
          owner: this.feature.ownerId
        }
      : {};

    this.privateVal = newVal;

    this.changed.emit({
      group: this.group,
      name: this.name,
      groupName: this.feature.groupName,
      value: this.privateVal,
      ...info
    });

    this.modified = true;
    this.default = false;
  }

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);

    const feature = this.feature;

    this.default = feature ? false : true;

    this.rangeConfig = this.featureConfig.selectType as RangeType;

    this.label = this.rangeConfig.label;

    const defaultValue = this.rangeConfig.range ? { min: this.rangeConfig.min, max: this.rangeConfig.max } : this.rangeConfig.default;
    this.privateVal = feature ? feature.value : defaultValue;

    this.options = this.rangeConfig.ticks();

    this.options.map((e) => {
      this.labels[e] = this.rangeConfig?.months
        ? this.languageService.instant(this.rangeConfig.label(e, true))
        : this.rangeConfig?.label(e);
      return e.value;
    });

    this.initVal = this.privateVal;
    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.modified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
  }

  isArray(val: unknown): boolean {
    return Array.isArray(val);
  }

  // remove(opt: string) {
  //   this.value = this.value.filter(e => e !== opt);
  // }

  toggleSelection(opt: string): void {
    if (this.value.includes(opt)) {
      this.value = this.value.filter((e: any) => e !== opt);
    } else {
      this.value = [...this.value, opt]; // not push because we want to invoke set()
    }
  }

  ren(labels: { [key: string]: any }): (v: string) => any {
    return (value: string) => {
      return labels[value];
    };
  }
}
