const paths = [
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M58.196,21.785
          C57.269,39.38,49.892,47.74,49.892,47.74s-3.64-11.956,0.17-28.61c0.775-3.391,6.926-6.314,6.926-6.314
          S58.385,18.212,58.196,21.785z"
        />`,
    boundingBox: [48.323, 12.815, 9.891, 34.925],
    angle: -9
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M49.688,19.082
          c4.327,16.483,0.704,28.599,0.704,28.599s-7.329-8.264-8.792-25.754c-0.297-3.563,1.274-8.995,1.274-8.995
          S48.809,15.735,49.688,19.082z"
        />`,
    boundingBox: [41.562, 12.9331, 10.25, 34.75],
    angle: 9
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M64.639,27.75
          c-3.144,17.184-11.965,20.514-11.965,20.514s-1.508-9.608,4.338-25.148c1.191-3.163,10.396-4.561,10.396-4.561
          S65.276,24.261,64.639,27.75z"
        />`,
    boundingBox: [52.499, 18.554, 14.909, 29.709],
    angle: -27
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M43.741,23.074
          c5.975,15.439,3.586,25.176,3.586,25.176s-7.967-3.489-11.231-20.562c-0.666-3.477-2.504-9.205-2.504-9.205
          S42.529,19.939,43.741,23.074z"
        />`,
    boundingBox: [33.592, 18.483, 14.206, 29.767],
    angle: 27
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M61.574,22.873
          c-1.625,18.134-10.35,25.237-10.35,25.237s-3.939-13.974,1.373-24.86c1.082-2.216,8.473-8.123,8.473-8.123
          S61.904,19.19,61.574,22.873z"
        />`,
    boundingBox: [49.819, 15.127, 11.832, 32.983],
    angle: -18
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M47.401,23.26
          c5.703,10.783,1.344,24.851,1.344,24.851s-8.54-7.095-10.534-25.106c-0.406-3.669,0.721-7.844,0.721-7.844
          S46.244,21.07,47.401,23.26z"
        />`,
    boundingBox: [38.123, 15.16, 12.216, 32.95],
    angle: 18
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M43.519,28.25
          C44.903,34.998,50,50,50,50s-14.617-0.589-13.686-12.932c0.561-7.443-6.836-13.312-6.836-13.312S42.132,21.5,43.519,28.25z"
        />`,
    boundingBox: [29.478, 23.394, 20.522, 26.606],
    angle: 5
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M63.402,37.106
          C63.653,49.444,50,50,50,50s5.166-14.955,6.563-21.688c1.396-6.736,13.447-4.438,13.447-4.438S63.25,29.625,63.402,37.106z"
        />`,
    boundingBox: [50, 23.497, 20.01, 26.503],
    angle: 27
  },
  {
    path: (fill: string, stroke: boolean, id: string): string => `<path
          id="${id}"
          fill="${fill}"
          ${
            stroke
              ? `stroke="black"
          stroke-width="1"
          stroke-miterlimit="10"`
              : ``
          }
          d="M55.373,25.125
          C57.426,45.474,50,50,50,50s-7.129-4.526-5.373-24.875C44.985,20.981,50,9.875,50,9.875S54.956,20.992,55.373,25.125z"
        />`,
    boundingBox: [44.349, 9.875, 11.383, 40.125],
    angle: -27
  }
];

export default paths;
