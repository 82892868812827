import { Component, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-bar',
  templateUrl: 'cookies-bar.component.html',
  styleUrls: ['cookies-bar.component.scss']
})
export class CookiesBarComponent {
  lang?: string;

  @Output() closed = new EventEmitter<boolean>();

  constructor(private cookieService: CookieService) {}

  onClose(): void {
    this.cookieService.set('cookies_accepted', 'true', 365);
    this.closed.emit(true);
  }
}
