<svg
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="!hideChosenValue ? size : 200"
  [attr.height]="!hideChosenValue ? size / 2 : 200"
  version="1.1"
  viewBox="-1.5 -1.5 3 3"
>
  <circle [attr.cx]="0" [attr.cy]="0" r="1.15" fill="white" />
  <path
    *ngFor="let c of darkArcs"
    [attr.d]="c.arc"
    [attr.fill]="c.disabled ? '#eee' : c.color"
    [attr.opacity]="c.included ? 0.95 : 0.15"
    (click)="c.disabled ? null : click(c.value, 'dark-')"
    (mouseenter)="c.disabled ? null : hover(c.value, 1.1)"
    (mouseleave)="hovered = undefined"
  ></path>
  <circle [attr.cx]="0" [attr.cy]="0" r="0.85" fill="white" />

  <path
    *ngFor="let c of mediumArcs"
    [attr.d]="c.arc"
    [attr.fill]="c.disabled ? '#eee' : c.color"
    [attr.opacity]="c.included ? 0.95 : 0.15"
    (click)="c.disabled ? null : click(c.value, '')"
    (mouseenter)="c.disabled ? null : hover(c.value, 0.8)"
    (mouseleave)="hovered = undefined"
  ></path>
  <circle [attr.cx]="0" [attr.cy]="0" r="0.55" fill="white" />

  <path
    *ngFor="let c of lightArcs"
    [attr.d]="c.arc"
    [attr.fill]="c.disabled ? '#eee' : c.color"
    [attr.opacity]="c.included ? 0.95 : 0.15"
    (click)="c.disabled ? null : click(c.value, 'light-')"
    (mouseenter)="c.disabled ? null : hover(c.value, 0.5)"
    (mouseleave)="hovered = undefined"
  ></path>

  <line *ngFor="let l of lines" [attr.x1]="0" [attr.y1]="0" [attr.x2]="l.x" [attr.y2]="l.y" style="stroke: white; stroke-width: 0.05" />

  <circle [attr.cx]="0" [attr.cy]="0" r="0.25" fill="white" />

  <circle
    [attr.cx]="0"
    [attr.cy]="0"
    r="0.2"
    [attr.fill]="white.disabled ? '#eee' : 'white'"
    [attr.opacity]="white.disabled ? 0.15 : 1"
    stroke="#afafaf"
    [attr.stroke-width]="white.included ? 0.01 : 0"
    (click)="white.disabled ? null : click(12, '')"
    (mouseenter)="white.disabled ? null : hover(12, 0)"
    (mouseleave)="hovered = undefined"
  />
  <text
    *ngIf="hovered && !hideChosenValue"
    [attr.x]="hovered.x"
    [attr.y]="hovered.y"
    class="small"
    [attr.text-anchor]="hovered.i >= 3 && hovered.i < 9 ? 'end' : 'start'"
  >
    {{ label(hovered.i, hovered.type) | translate }}
  </text>

  <polyline *ngIf="hovered && !hideChosenValue" [attr.points]="hovered.points" stroke="#a5a6b1" stroke-width="0.01" fill="none"></polyline>
</svg>
