import { Injectable } from '@angular/core';

import { load } from 'exifreader';
import { encode } from 'base64-arraybuffer';
import { DateTime } from 'luxon';

import { Image as _Image, flatten } from 'src/app/types';

import { CanvasService } from '../canvas.service';
import { Geolocation } from 'src/app/types/image';

import { optionType } from 'src/app/types/features/types';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { HttpService } from '../httpService';
import { APIPhotoFeatureService } from 'src/app/api/services';
import date from 'src/app/helpers/date';

const MAX_WIDTH = 1500;
const THUMB_SIZE = 200;

export interface IFile {
  // urlData: string;
  data: string;
  name: string;
  type: string;
  lastModified: string;
  thumb: string;
  location?: Geolocation;
  description?: string;
}
export interface IUpload {
  file: IFile;
  uploading: boolean;
  descriptionEdited?: boolean;
  locationEdited?: boolean;
  cropped?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ImageService {
  private registeredImages: { [key: string]: BehaviorSubject<_Image> | undefined } = {};

  getImageObservable(img: _Image): Observable<_Image> | undefined {
    if (this.registeredImages[img.imageId]) {
      return this.registeredImages[img.imageId]?.asObservable();
    } else {
      const subject = new BehaviorSubject<_Image>(img);
      this.registeredImages[img.imageId] = subject;
      return subject.asObservable();
    }
  }

  constructor(private canvasService: CanvasService, private http: HttpService, private photoFeatureService: APIPhotoFeatureService) {}

  public async addTaksonImage(image: Partial<_Image>): Promise<_Image> {
    const imageData = { ...image };
    const data = await this.http.post('image/create', {
      ...flatten(['public', 'src', 'thumb', 'taxonId', 'date', 'description'], imageData),
      location: imageData.location?.toString()
    });

    return new _Image(data);
  }

  public async editImage(image: Partial<_Image>): Promise<_Image> {
    const imageData = { ...image };
    const data = await this.http.post('image/update', {
      ...flatten(['imageId', 'public', 'date', 'description'], imageData),
      location: imageData.location?.toString()
    });
    // await this.bucketService.refresh();
    return new _Image(data);
  }

  public async removeImage(imageId: number): Promise<void> {
    await this.http.post('image/remove', { imageId });
    // await this.bucketService.refresh();
  }

  public async setMain(imageId: number, taxonId: string): Promise<boolean> {
    return this.http.post('image/set/main', { imageId, taxonId });
  }

  getImageThumb(image: string): Promise<{ data: string; thumb: string }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = MAX_WIDTH;
        canvas.height = (img.height * MAX_WIDTH) / img.width;

        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) {
          return;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.canvas.toDataURL('image/jpeg', 0.92);

        const portrait = img.height > img.width;
        const cropSize = Math.min(img.height, img.width);
        let x: number;
        let y: number;
        if (portrait) {
          x = 0;
          y = (img.height - img.width) / 2;
        } else {
          x = (img.width - img.height) / 2;
          y = 0;
        }

        const thumbCanvas = document.createElement('canvas');
        thumbCanvas.width = cropSize;
        thumbCanvas.height = cropSize;
        const thumbCtx: CanvasRenderingContext2D | null = thumbCanvas.getContext('2d');
        if (!thumbCtx) {
          return;
        }
        thumbCtx.drawImage(img, x, y, cropSize, cropSize, 0, 0, cropSize, cropSize);
        this.canvasService.resampleSingle(thumbCanvas, THUMB_SIZE, THUMB_SIZE, true);
        const thumbData = thumbCtx.canvas.toDataURL('image/jpeg', 0.98);

        resolve({ data: imageData, thumb: thumbData });
      };

      img.onerror = (err) => reject(err);
    });
  }

  async prepareImage(result: ArrayBuffer, file: File): Promise<IUpload> {
    // Load exif information from array buffer containing image
    const tags = load(result);

    // Create base64 string to make thumbnail
    const base64 = 'data:' + file.type + ';base64,' + encode(result);
    const { data, thumb } = await this.getImageThumb(base64);

    // Set last modified date: either from image data or current time
    let lastModified: DateTime;
    try {
      lastModified =
        tags && tags.DateTimeOriginal
          ? DateTime.fromJSDate(date(tags.DateTimeOriginal.description))
          : DateTime.fromMillis(file.lastModified);
    } catch (_) {
      try {
        lastModified = DateTime.fromMillis(file.lastModified);
      } catch (_) {
        lastModified = DateTime.now();
      }
    }

    // Return IUpload object
    return {
      file: {
        name: file.name,
        lastModified: lastModified.toFormat('yyyy-MM-dd HH:mm:ss'),
        type: file.type,
        data,
        thumb,
        ...(tags && {
          location: new Geolocation({
            longitude: parseFloat(tags.GPSLongitude?.description || '0'),
            latitude: parseFloat(tags.GPSLatitude?.description || '0'),
            altitude: parseFloat(tags.GPSAltitude?.description || '0')
          })
        }),
        description: ''
      },
      uploading: false
    };
  }

  public async setFeature(
    featureName: 'flower' | 'foliage' | 'fruit' | 'bark' | 'sort' | 'insolation' | 'soil',
    value: ('start' | 'middle' | 'end' | optionType)[],
    photoId: number
  ): Promise<number> {
    const { photoFeatureId } = await firstValueFrom(this.photoFeatureService.addPhotoFeature({ body: { photoId, featureName, value } }));
    return photoFeatureId;
  }

  public register(img: _Image): void {
    const subject = new BehaviorSubject<_Image>(img);
    this.registeredImages[img.imageId] = subject;
  }
  public unregister(img: _Image): void {
    this.registeredImages[img.imageId] = undefined;
  }
  public update(img: _Image): void {
    if (img && img.imageId && this.registeredImages[img.imageId]) {
      this.registeredImages[img.imageId]?.next(img);
    }
  }

  // public async getLatest(page: number): Promise<_Image[]> {
  //   const images = await get('image/get/unknown', { page });
  //   return images.map((i: any) => new _Image(i));
  // }

  // public async identify(imageId: number, taxonId: string): Promise<void> {
  //   const resp = await this.http.post('image/mark/identify', { imageId, taxonId });
  //   if (resp !== 'ok') {
  //     throw resp;
  //   }
  // }
  // public async markDelete(imageId: number, reason: string): Promise<void> {
  //   const resp = await this.http.post('image/mark/delete', { imageId, reason });
  //   if (resp !== 'ok') {
  //     throw resp;
  //   }
  // }
  // public async markSkip(imageId: number): Promise<void> {
  //   const resp = await this.http.post('image/mark/skip', { imageId });
  //   if (resp !== 'ok') {
  //     throw resp;
  //   }
  // }
}
