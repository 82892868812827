import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ListService } from 'src/app/services/utilities/list.service';

import { ThumbTaxon } from 'src/app/types';

@Component({
  selector: 'app-basket-icon',
  template: `<div (click)="toggle()">
    <mat-icon
      [ngClass]="{ onList: (isOnList | async) }"
      [matTooltip]="((isOnList | async) ? 'forms.basket.remove' : 'forms.basket.add') | translate"
      matTooltipPosition="above"
    >
      shopping_basket</mat-icon
    >
    <span *ngIf="menuItem">{{ ((isOnList | async) ? 'misc.basket.remove' : 'misc.basket.add') | translate }}</span>
  </div>`,
  styles: [
    `
      :host {
        position: relative;
        text-align: left;
      }
      div {
        display: contents;
      }
      mat-icon {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        font-size: 1.2em !important;
        width: 1em !important;
        height: 1em !important;
        color: #ebe6e1;
      }
      span {
        margin-left: calc(2em + 20px);
      }
      mat-icon.onList {
        background-image: linear-gradient(45deg, #06b62c, #0d7b25);
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    `
  ]
})
export class BasketIconComponent implements OnInit {
  @Input() taxon!: ThumbTaxon;
  @Input() menuItem = false;

  isOnList?: BehaviorSubject<boolean>;

  constructor(private listService: ListService) {}

  ngOnInit(): void {
    this.isOnList = this.listService.isOnList(this.taxon.taxonId);
  }

  toggle(): void {
    void this.listService.toggleItem(this.taxon);
  }
}
