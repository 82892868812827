import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SearchService } from 'src/app/services/utilities/search.service';

import { Features } from 'src/app/types/features';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss', '../search.scss']
})
export class SimpleSearchComponent implements OnInit {
  @Input() internal = false;

  details: BehaviorSubject<boolean>;

  searchForm = this.fb.group({
    searchValue: ['']
  });

  filter: Features | undefined = new Features([]);

  constructor(private fb: FormBuilder, private searchService: SearchService, private route: ActivatedRoute) {
    this.details = this.searchService.detailed;
    this.searchService.searchString.subscribe((text) => {
      this.searchForm = this.fb.group({
        searchValue: [text ? text : '']
      });
    });

    this.searchService.filter.subscribe((filter) => {
      this.filter = filter;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({ search }) => {
      this.searchForm = this.fb.group({
        searchValue: [search]
      });
    });
  }

  onSearch(): void {
    this.searchService.doSearch(this.searchForm.value.searchValue);
  }
}
