<svg
  #silhouette
  *ngIf="shape;else noShapeBlock"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  [attr.width]="width+'px'"
  [attr.height]="height+'px'"
  viewBox="0 0 100 100"
  xml:space="preserve"
  class="floweShape"
>
  <!-- <svg  class="defs-only" xmlns="http://www.w3.org/2000/svg" width="0" height="0" preserveAspectRatio="none"> -->
  <symbol [id]="'pattern' + id" width="100" height="100">
    <g *ngIf="pattern" [attr.clip-path]="'url(#id-' + id + '-shape)'">
      <rect [attr.fill]="getColor(pattern.base)" width="100" height="100"></rect>
      <g *ngFor="let g of pattern.spots; let idx = index">
        <ellipse
          [attr.fill]="getColor(g.color)"
          stroke-width="1"
          [attr.cx]="g.center[0]"
          [attr.cy]="g.center[1]"
          [attr.rx]="g.w / 2"
          [attr.ry]="g.h / 2"
          [attr.transform]="'rotate(' + g.angle + ')'"
          [attr.transform-origin]="g.center[0] + ' ' + g.center[1]"
        ></ellipse>
      </g>
    </g>
    <g *ngIf="!pattern && color" [attr.clip-path]="'url(#id-' + id + '-shape)'">
      <rect [attr.fill]="getColor(color)" width="100" height="100"></rect>
    </g>

    <!-- <rect [attr.fill]="'red'" width="50" height="100"></rect>

    <rect [attr.fill]="'green'" x="50" width="50" height="100"></rect> -->
  </symbol>
  <!-- </svg> -->
  <defs>
    <mask [id]="'clippath' + '-' + id">
      <ng-container *ngIf="customPath; else clipBlock">
        <g *ngFor="let p of paths; let i = index" [attr.transform]="'rotate(' + boxes[i].a + ') '" transform-origin="20 40">
          <g [innerHTML]="p"></g>
        </g>
        <ng-container *ngIf="shape === 'stellate-double'">
          <g
            *ngFor="let p of paths; let i = index"
            [attr.transform]="'scale(0.7) rotate(' + (boxes[i].a + angles[1] / 2) + ')'"
            transform-origin="20 40"
          >
            <g [innerHTML]="p"></g>
          </g>
        </ng-container>
      </ng-container>
      <ng-template #clipBlock>
        <g *ngFor="let p of paths; let i = index" [innerHTML]="p"></g>
      </ng-template>
    </mask>
    <mask *ngFor="let p of paths; let i = index" [id]="'clippath' + i + '-' + id">
      <g [innerHTML]="p"></g>
    </mask>
  </defs>
  <ng-container *ngIf="customPath; else templatesBlock">
    <g [attr.mask]="'url(#clippath' + '-' + id + ')'" transform="translate(30, 10)">
      <g *ngFor="let p of paths; let i = index" [attr.transform]="'rotate(' + boxes[i].a + ') '" transform-origin="20 40">
        <g [attr.mask]="'url(#clippath' + i + '-' + id + ')'">
          <svg
            viewBox="0 0 100 100"
            [attr.width]="boxes[i].w"
            [attr.height]="boxes[i].h"
            [attr.x]="0"
            [attr.y]="0"
            preserveAspectRatio="none"
          >
            <use [attr.href]="'#pattern' + id" />
          </svg>
        </g>
      </g>

      <ng-container *ngIf="shape === 'stellate-double'">
        <g
          *ngFor="let p of paths; let i = index"
          [attr.transform]="'scale(0.7) rotate(' + (boxes[i].a + angles[1] / 2) + ')'"
          transform-origin="20 40"
        >
          <g [attr.mask]="'url(#clippath' + i + '-' + id + ')'">
            <svg
              viewBox="0 0 100 100"
              [attr.width]="boxes[i].w"
              [attr.height]="boxes[i].h"
              [attr.x]="0"
              [attr.y]="0"
              preserveAspectRatio="none"
            >
              <use [attr.href]="'#pattern' + id" />
            </svg>
          </g>
        </g>
      </ng-container>
    </g>
  </ng-container>
  <ng-template #templatesBlock>
    <g [attr.mask]="'url(#clippath' + '-' + id + ')'">
      <g *ngFor="let p of paths; let i = index" [attr.mask]="'url(#clippath' + i + '-' + id + ')'">
        <!-- <g *ngFor="let p of paths; let i = index" > -->
        <g [attr.transform]="'translate(' + boxes[i].x + ' ' + boxes[i].y + ') rotate(' + boxes[i].a + ') '">
          <svg
            viewBox="0 0 100 100"
            [attr.width]="boxes[i].w"
            [attr.height]="boxes[i].h"
            [attr.x]="0"
            [attr.y]="0"
            preserveAspectRatio="none"
          >
            <use [attr.href]="'#pattern' + id" />
          </svg>
        </g>
        <!-- <rect fill="yellow" width="100" height="100" opacity="0.5" [attr.mask]="'url(#clippath' + i + ')'"></rect> -->
      </g>
    </g>
  </ng-template>
</svg>
<ng-template #noShapeBlock> {{'There is no flower shape defined' | translate}} </ng-template>
