/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APICollection } from '../models/api-collection';
import { APIPhotoSet } from '../models/api-photo-set';
import { APIPlantPhotoSet } from '../models/api-plant-photo-set';
import { APITagDetails } from '../models/api-tag-details';
import { APIUserStats } from '../models/api-user-stats';

@Injectable({
  providedIn: 'root'
})
export class APICollectionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addCollection
   */
  static readonly AddCollectionPath = '/collection/custom';

  /**
   * Create new collection
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCollection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCollection$Response(params: {
    /**
     * Collection info
     */
    body: { name: string; description?: string };
  }): Observable<StrictHttpResponse<{ collectionId: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.AddCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collectionId: string }>;
        })
      );
  }

  /**
   * Create new collection
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCollection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCollection(params: {
    /**
     * Collection info
     */
    body: { name: string; description?: string };
  }): Observable<{ collectionId: string }> {
    return this.addCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ collectionId: string }>) => r.body as { collectionId: string })
    );
  }

  /**
   * Path part for operation addPlantToDefaultCollection
   */
  static readonly AddPlantToDefaultCollectionPath = '/collection/plant';

  /**
   * Add taxon to default user bucket collection
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPlantToDefaultCollection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPlantToDefaultCollection$Response(params: {
    /**
     * Taxon id to add to collection
     */
    body: { taxonId?: string };
  }): Observable<StrictHttpResponse<{ bucketId: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.AddPlantToDefaultCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ bucketId: string }>;
        })
      );
  }

  /**
   * Add taxon to default user bucket collection
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPlantToDefaultCollection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPlantToDefaultCollection(params: {
    /**
     * Taxon id to add to collection
     */
    body: { taxonId?: string };
  }): Observable<{ bucketId: string }> {
    return this.addPlantToDefaultCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ bucketId: string }>) => r.body as { bucketId: string })
    );
  }

  /**
   * Path part for operation addPlantToCollection
   */
  static readonly AddPlantToCollectionPath = '/collection/plant/{collectionId}';

  /**
   * Add taxon to given bucket collection
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPlantToCollection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPlantToCollection$Response(params: {
    /**
     * Id of the collection to add plant
     */
    collectionId: string;

    /**
     * Taxon id to add to collection
     */
    body: { taxonId?: string };
  }): Observable<StrictHttpResponse<{ bucketId: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.AddPlantToCollectionPath, 'post');
    if (params) {
      rb.path('collectionId', params.collectionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ bucketId: string }>;
        })
      );
  }

  /**
   * Add taxon to given bucket collection
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPlantToCollection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPlantToCollection(params: {
    /**
     * Id of the collection to add plant
     */
    collectionId: string;

    /**
     * Taxon id to add to collection
     */
    body: { taxonId?: string };
  }): Observable<{ bucketId: string }> {
    return this.addPlantToCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ bucketId: string }>) => r.body as { bucketId: string })
    );
  }

  /**
   * Path part for operation getUserCollection
   */
  static readonly GetUserCollectionPath = '/collection/custom/{collectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection$Response(params: {
    /**
     * CollectionId
     */
    collectionId: string;
  }): Observable<StrictHttpResponse<{ collection: APICollection }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserCollectionPath, 'get');
    if (params) {
      rb.path('collectionId', params.collectionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collection: APICollection }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserCollection(params: {
    /**
     * CollectionId
     */
    collectionId: string;
  }): Observable<{ collection: APICollection }> {
    return this.getUserCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ collection: APICollection }>) => r.body as { collection: APICollection })
    );
  }

  /**
   * Path part for operation editCollection
   */
  static readonly EditCollectionPath = '/collection/custom/{collectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCollection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editCollection$Response(params: {
    /**
     * CollectionId
     */
    collectionId: string;

    /**
     * Collection info
     */
    body: { name: string; description?: string };
  }): Observable<StrictHttpResponse<{ collectionId: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.EditCollectionPath, 'put');
    if (params) {
      rb.path('collectionId', params.collectionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collectionId: string }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editCollection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editCollection(params: {
    /**
     * CollectionId
     */
    collectionId: string;

    /**
     * Collection info
     */
    body: { name: string; description?: string };
  }): Observable<{ collectionId: string }> {
    return this.editCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ collectionId: string }>) => r.body as { collectionId: string })
    );
  }

  /**
   * Path part for operation deleteCollection
   */
  static readonly DeleteCollectionPath = '/collection/custom/{collectionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollection$Response(params: {
    /**
     * CollectionId
     */
    collectionId: string;
  }): Observable<StrictHttpResponse<{ collectionId: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.DeleteCollectionPath, 'delete');
    if (params) {
      rb.path('collectionId', params.collectionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collectionId: string }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollection(params: {
    /**
     * CollectionId
     */
    collectionId: string;
  }): Observable<{ collectionId: string }> {
    return this.deleteCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ collectionId: string }>) => r.body as { collectionId: string })
    );
  }

  /**
   * Path part for operation getUserAllPhotosSet
   */
  static readonly GetUserAllPhotosSetPath = '/collection/photo/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAllPhotosSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAllPhotosSet$Response(params?: {}): Observable<StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserAllPhotosSetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAllPhotosSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAllPhotosSet(params?: {}): Observable<{ photoSet: APIPlantPhotoSet }> {
    return this.getUserAllPhotosSet$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>) => r.body as { photoSet: APIPlantPhotoSet })
    );
  }

  /**
   * Path part for operation getCollectionOverview
   */
  static readonly GetCollectionOverviewPath = '/collection/overview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollectionOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollectionOverview$Response(params?: {}): Observable<StrictHttpResponse<{ collections: Array<APICollection> }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetCollectionOverviewPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collections: Array<APICollection> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCollectionOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollectionOverview(params?: {}): Observable<{ collections: Array<APICollection> }> {
    return this.getCollectionOverview$Response(params).pipe(
      map((r: StrictHttpResponse<{ collections: Array<APICollection> }>) => r.body as { collections: Array<APICollection> })
    );
  }

  /**
   * Path part for operation getUserDefaultCollection
   */
  static readonly GetUserDefaultCollectionPath = '/collection/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDefaultCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDefaultCollection$Response(params?: {}): Observable<StrictHttpResponse<{ collection: APICollection }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserDefaultCollectionPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ collection: APICollection }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserDefaultCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDefaultCollection(params?: {}): Observable<{ collection: APICollection }> {
    return this.getUserDefaultCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ collection: APICollection }>) => r.body as { collection: APICollection })
    );
  }

  /**
   * Path part for operation getUserIdentifiedPhotosSet
   */
  static readonly GetUserIdentifiedPhotosSetPath = '/collection/photo/identified';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserIdentifiedPhotosSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserIdentifiedPhotosSet$Response(params: {
    /**
     * Should be the set filtered by identified photos
     */
    identified: boolean;
  }): Observable<StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserIdentifiedPhotosSetPath, 'get');
    if (params) {
      rb.query('identified', params.identified, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserIdentifiedPhotosSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserIdentifiedPhotosSet(params: {
    /**
     * Should be the set filtered by identified photos
     */
    identified: boolean;
  }): Observable<{ photoSet: APIPlantPhotoSet }> {
    return this.getUserIdentifiedPhotosSet$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>) => r.body as { photoSet: APIPlantPhotoSet })
    );
  }

  /**
   * Path part for operation getPhotoSetByTag
   */
  static readonly GetPhotoSetByTagPath = '/collection/photo/byTag/{tag}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPhotoSetByTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoSetByTag$Response(params: {
    /**
     * Tag
     */
    tag: string;
  }): Observable<StrictHttpResponse<{ photoSet: APIPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetPhotoSetByTagPath, 'get');
    if (params) {
      rb.path('tag', params.tag, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPhotoSetByTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoSetByTag(params: {
    /**
     * Tag
     */
    tag: string;
  }): Observable<{ photoSet: APIPhotoSet }> {
    return this.getPhotoSetByTag$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPhotoSet }>) => r.body as { photoSet: APIPhotoSet })
    );
  }

  /**
   * Path part for operation getUserSpeciesPhotoSet
   */
  static readonly GetUserSpeciesPhotoSetPath = '/collection/plant/{latinName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSpeciesPhotoSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSpeciesPhotoSet$Response(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Collection Id
     */
    collectionId?: string;
  }): Observable<StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserSpeciesPhotoSetPath, 'get');
    if (params) {
      rb.path('latinName', params.latinName, {});
      rb.query('collectionId', params.collectionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserSpeciesPhotoSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSpeciesPhotoSet(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Collection Id
     */
    collectionId?: string;
  }): Observable<{ photoSet: APIPlantPhotoSet }> {
    return this.getUserSpeciesPhotoSet$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>) => r.body as { photoSet: APIPlantPhotoSet })
    );
  }

  /**
   * Path part for operation getUserVarietyPhotoSet
   */
  static readonly GetUserVarietyPhotoSetPath = '/collection/plant/{latinName}/{variety}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserVarietyPhotoSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserVarietyPhotoSet$Response(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Variety of the plant
     */
    variety: string;

    /**
     * Collection Id
     */
    collectionId?: string;
  }): Observable<StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetUserVarietyPhotoSetPath, 'get');
    if (params) {
      rb.path('latinName', params.latinName, {});
      rb.path('variety', params.variety, {});
      rb.query('collectionId', params.collectionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserVarietyPhotoSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserVarietyPhotoSet(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Variety of the plant
     */
    variety: string;

    /**
     * Collection Id
     */
    collectionId?: string;
  }): Observable<{ photoSet: APIPlantPhotoSet }> {
    return this.getUserVarietyPhotoSet$Response(params).pipe(
      map((r: StrictHttpResponse<{ photoSet: APIPlantPhotoSet }>) => r.body as { photoSet: APIPlantPhotoSet })
    );
  }

  /**
   * Path part for operation getStats
   */
  static readonly GetStatsPath = '/collection/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats$Response(params?: {}): Observable<StrictHttpResponse<APIUserStats>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetStatsPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<APIUserStats>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStats(params?: {}): Observable<APIUserStats> {
    return this.getStats$Response(params).pipe(map((r: StrictHttpResponse<APIUserStats>) => r.body as APIUserStats));
  }

  /**
   * Path part for operation getTagsCollection
   */
  static readonly GetTagsCollectionPath = '/collection/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTagsCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagsCollection$Response(params?: {}): Observable<StrictHttpResponse<{ tags: Array<APITagDetails> }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.GetTagsCollectionPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ tags: Array<APITagDetails> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTagsCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTagsCollection(params?: {}): Observable<{ tags: Array<APITagDetails> }> {
    return this.getTagsCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ tags: Array<APITagDetails> }>) => r.body as { tags: Array<APITagDetails> })
    );
  }

  /**
   * Path part for operation transferPhotoToCollection
   */
  static readonly TransferPhotoToCollectionPath = '/collection/photo/transfer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferPhotoToCollection()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferPhotoToCollection$Response(params: {
    /**
     * Transfer info
     */
    body: { photoId: number; collectionId: string };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APICollectionService.TransferPhotoToCollectionPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferPhotoToCollection$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferPhotoToCollection(params: {
    /**
     * Transfer info
     */
    body: { photoId: number; collectionId: string };
  }): Observable<{ success: boolean }> {
    return this.transferPhotoToCollection$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }
}
