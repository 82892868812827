import ShapeConfig from '../ShapeConfig.type';

const SHAPES = {
  compound: {
    tip: 94.5,
    tipHandle: [7, 86],
    middle: [
      { point: [1, 65], handlePrev: [10, 77], handleNext: [1, 65] },
      { point: [1, 62], handlePrev: [1, 62], handleNext: [10, 72] },
      {
        point: [32.5, 72.5],
        handlePrev: [27, 74],
        handleNext: [28.6, 61.5]
      },
      { point: [1, 60], handlePrev: [10.3, 57], handleNext: [1, 60] },
      { point: [1, 55], handlePrev: [1, 55], handleNext: [21, 60] },
      { point: [37, 53], handlePrev: [33.5, 57.5], handleNext: [27.5, 46.3] },
      {
        point: [1, 52],
        handlePrev: [14.5, 45.3],
        handleNext: [1, 52]
      },
      { point: [1, 45], handlePrev: [1, 45], handleNext: [13, 48] },
      {
        point: [36.3, 36.3],
        handlePrev: [31.6, 44.3],
        handleNext: [27.3, 32.3]
      },
      { point: [1, 43], handlePrev: [7.3, 38.3], handleNext: [1, 41] },
      {
        point: [1, 36],
        handlePrev: [1, 36],
        handleNext: [11.5, 36.3]
      },
      {
        point: [22.3, 22.3],
        handlePrev: [20.6, 32.3],
        handleNext: [12.5, 24]
      },
      {
        point: [1, 33],
        handlePrev: [6.5, 28],
        handleNext: [1, 33]
      }
    ],
    baseHandle: [1, 0]
  },
  digitate: {
    tip: 94.5,
    tipHandle: [7, 86],
    middle: [
      { point: [1, 55], handlePrev: [7.5, 72], handleNext: [10.5, 70] },
      { point: [31, 77.5], handlePrev: [18, 78], handleNext: [27, 66.5] },
      { point: [1, 54], handlePrev: [17.5, 62], handleNext: [19, 60] },
      {
        point: [42, 55.5],
        handlePrev: [33, 62.5],
        handleNext: [34, 48]
      },
      { point: [1, 53], handlePrev: [19, 49], handleNext: [17.5, 46.5] },
      { point: [31, 33], handlePrev: [28, 42], handleNext: [21.5, 32] },
      { point: [1, 52], handlePrev: [13.5, 39], handleNext: [12, 38] },
      {
        point: [16.5, 24],
        handlePrev: [19, 33],
        handleNext: [7, 26]
      },
      { point: [1, 51], handlePrev: [6, 34.5], handleNext: [1, 36] }
    ],
    baseHandle: [1, 0]
  },
  acicular: {
    tip: 98,
    tipHandle: [3, 93],
    middle: [
      {
        point: [2, 40],
        handlePrev: [2, 54],
        handleNext: [2, 14]
      }
    ],
    baseHandle: [2, 2]
  },
  lanceolate: {
    tip: 98,
    tipHandle: [3, 93],
    middle: [
      {
        point: [20, 50],
        handlePrev: [20, 75],
        handleNext: [20, 25]
      }
    ],
    baseHandle: [2, 2]
  },
  tiplanceolate: {
    tip: 98,
    tipHandle: [15, 97],
    middle: [
      {
        point: [27, 62.5],
        handlePrev: [27, 83],
        handleNext: [27, 37.5]
      }
    ],
    baseHandle: [9, 14]
  },
  baselanceolate: {
    tip: 98,
    tipHandle: [3, 93],
    middle: [
      {
        point: [20, 25],
        handlePrev: [20, 50],
        handleNext: [20, 0]
      }
    ],
    baseHandle: [2, 2]
  },
  hastate: {
    tip: 98,
    tipHandle: [3, 94],
    middle: [
      { point: [16, 56], handlePrev: [16, 71], handleNext: [16, 31] },
      { point: [11, 23], handlePrev: [11, 30], handleNext: [11, 20] },
      {
        point: [20, 5.5],
        handlePrev: [23, 8],
        handleNext: [19, 4]
      }
    ],
    baseHandle: [5.5, 14]
  },
  sagittate: {
    tip: 98,
    tipHandle: [3, 94],
    middle: [
      {
        point: [17, 47.5],
        handlePrev: [10.5, 66.5],
        handleNext: [23, 34.5]
      },
      {
        point: [36, 8],
        handlePrev: [38.5, 12],
        handleNext: [33, 5.5]
      },
      {
        point: [1, 18],
        handlePrev: [5, 21],
        handleNext: [1, 18]
      }
    ],
    baseHandle: [1, 1]
  },
  l: {
    tip: 98,
    tipHandle: [39.3, 68],
    middle: [{ point: [1.3, 13], handlePrev: [49, -30], handleNext: [1.4, -13] }],
    baseHandle: [2, 2]
  }
};

export default {
  ...ShapeConfig,
  selectType: {
    ...ShapeConfig.selectType,
    values: Object.values(SHAPES),
    renderValue: (): string => ''
  },
  name: 'foliage.leaf_shape',
  renderName: (): string => 'features.foliage.leaf_shape.'
};
