import { Injectable } from '@angular/core';

import { TaksoUnit, ThumbTaxon } from 'src/app/types';

import { LangName } from 'src/app/types/takso';
import { Stats } from 'src/app/types/stats';
import { HttpService } from '../httpService';

@Injectable({ providedIn: 'root' })
export class TaksonomyService {
  constructor(private http: HttpService) {}

  /** ad names in local languages */
  public async addLocalName(parentId: string, name: string, priority: number): Promise<LangName> {
    const data = await this.http.post('takso/name/add', { parentId, name, priority });
    return new LangName(data);
  }
  public async editLocalName(id: string, name: string, priority: number): Promise<string> {
    return await this.http.post('takso/name/edit', { id, name, priority });
  }
  public async addVariety(parentId: string, name: string, brand = '', pbr = false, registered = false): Promise<TaksoUnit> {
    const taxon = await this.http.post('takso/variety/add', { parentId, name, brand, pbr, registered });
    return new TaksoUnit(taxon);
  }
  public async multipleVarietiesAdd(
    names: { name: string; brand: string; pbr: boolean; registered: boolean }[],
    parentId: string
  ): Promise<any> {
    return await this.http.post('takso/variety/multi/add', { names, parentId });
  }

  public async editVariety(name: string, taxonId: string): Promise<TaksoUnit> {
    const taxon = await this.http.post('takso/variety/edit', { name, taxon_id: taxonId });
    return new TaksoUnit(taxon);
  }
  public async addInfo(parentId: string, text: string, category: number, source: string): Promise<string> {
    return await this.http.post('takso/info/add', { parentId, text, category, source });
  }
  public async editInfo(text: string, category: number, source: string, id: string): Promise<any> {
    return await this.http.post('takso/info/edit', { text, category, source, id });
  }

  public async addTakson(taxon: string): Promise<TaksoUnit> {
    const res = await this.http.post('takso/add', { taxon });
    return new TaksoUnit(res);
  }

  public async editTakson(taxonId: string, data: { brand_group: string; variety: string; registered: string; pbr: string }): Promise<any> {
    return await this.http.post('takso/edit', { taxon_id: taxonId, data });
  }

  public async editContrib(taxonId: string, data: { note: string; image: string }): Promise<any> {
    return await this.http.post('takso/contribute', { taxon_id: taxonId, note: data.note, image: data.image });
  }

  public async takeOwnership(takson: TaksoUnit, own: boolean): Promise<void> {
    await this.http.post('takso/takeOwnership', { taxon_id: takson.taxonId, own });
  }

  /** get collections */
  // public async getTaxons(filter: { name?: string, type?: string }): Promise<TaksoUnit[]> {
  //   const data = await this.http.get('takso/getAll', { filter });
  //   return data;
  // }
  public async getTaxon(species: string, variety: string | undefined): Promise<TaksoUnit> {
    const payload = variety ? { species, variety } : { species };
    const data = await this.http.get('takso/get', payload);

    if (typeof data === 'string') {
      return Promise.reject(data);
    }
    return new TaksoUnit(data);
  }
  public async getDetails(taxonId: string, types: ('names' | 'infos' | 'features' | 'contributes')[]): Promise<TaksoUnit> {
    const data = await this.http.get('takso/details', { taxonId, types });
    return new TaksoUnit(data);
  }
  public async getDetailsMulti(taxonId: string[], types: ('names' | 'infos' | 'features' | 'contributes')[]): Promise<TaksoUnit[]> {
    if (!taxonId || taxonId.length == 0) {
      return [];
    }
    const data = await this.http.get('takso/details', { taxonIds: taxonId, types });

    return data.map((d: any) => new TaksoUnit(d));
  }
  public async getThumbTaxons(taxonIds: string[]): Promise<ThumbTaxon[]> {
    return (await this.getDetailsMulti(taxonIds, [])).map(
      (t: TaksoUnit) =>
        new ThumbTaxon({
          taxonId: t.taxonId,
          latinName: t.latinName,
          localName: t.localName,
          thumb: t.thumb
        })
    );
  }

  public async getVarieties(taxonId: string): Promise<TaksoUnit[]> {
    const data = await this.http.get('takso/varieties', { taxonId });
    return data.map((d: any) => new TaksoUnit(d));
  }

  public async getSelection(type: 'new' | 'updated' | 'random' | 'info' | 'randImages' | 'jumbotron'): Promise<TaksoUnit[]> {
    const taksons = await this.http.get('takso/select', { type });
    return (taksons || []).map((t: any) => new TaksoUnit(t)).sort((a: TaksoUnit, b: TaksoUnit) => (a.latinName > b.latinName ? 1 : -1));
  }

  public async getOwnedTaksons(): Promise<TaksoUnit[]> {
    const taksons = await this.http.get('takso/owned', {});
    return (taksons || []).map((t: any) => new TaksoUnit(t)).sort((a: TaksoUnit, b: TaksoUnit) => (a.latinName > b.latinName ? 1 : -1));
  }

  public async getStatistics(): Promise<Stats[]> {
    const data = await this.http.get('takso/statistics');

    return data
      .map((e: any) => ({
        total: parseInt(e.total, 10),
        finished: parseInt(e.finished, 10),
        status: e.status,
        name: e.name
      }))
      .sort((a: Stats, b: Stats) => (a.total > b.total ? -1 : 1));
  }

  public async generateSilhouette(taxonId: string, src: string): Promise<string> {
    return await this.http.post('takso/silhouette/generate', { taxon_id: taxonId, src });
  }

  public async markFinished(taxonId: string, finished: boolean): Promise<string> {
    return await this.http.post('takso/finish', { taxon_id: taxonId, finished });
  }

  public async markReady(taxonId: string): Promise<string> {
    return await this.http.post('takso/ready', { taxon_id: taxonId });
  }
}
