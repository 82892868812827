import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FeatureConfig } from 'src/app/types/features/config';
import { OptionType, RangeType } from 'src/app/types/features/selectTypes';
import { optionType } from 'src/app/types/features/types';
import { Range } from 'src/app/types/utilities';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements OnInit {
  @Input() value: any;
  @Input() config!: FeatureConfig;
  @Output() changed = new EventEmitter<any>();

  rangeOptions?: number[];
  range?: Range;

  options?: optionType[];

  ngOnInit(): void {
    if (this.config.controlType === 'range') {
      const conf = this.config.selectType as RangeType;
      this.rangeOptions = conf.ticks([1, 1.5, 2, 3, 5, 8]);
      this.range = { min: conf.min, max: conf.max };
    }
    if (this.config.controlType === 'option') {
      const conf = this.config.selectType as OptionType;
      this.options = [...conf.values] as optionType[];
    }
  }
}
