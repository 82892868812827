<div class="range">
  <!-- <mat-label>{{featureName | translate}}</mat-label> -->
  <!-- <label>
    <mat-checkbox *ngIf="rangeConfig.months" [(checked)]="reversed" (change)="reversed=$event.checked">
      {{'forms.features.reverse' | translate}}</mat-checkbox>
  </label> -->
  <!-- <ng5-slider [ngClass]="{'reversed':reversed, 'months':!!rangeConfig.months, 'untouched': default}"
    *ngIf="rangeConfig.range; else singleBlock" [options]="sliderOptions" [value]="value.min" [highValue]="value.max"
     (userChange)="value={min:$event.value, max:$event.highValue}"></ng5-slider>
  <ng-template #singleBlock>
    <ng5-slider [ngClass]="{'untouched': default}" [value]="value" (valueChange)="value=$event"
      [options]="sliderOptions"></ng5-slider>
  </ng-template> -->

  <ng-container *ngIf="labels">
    <app-range-field
      *ngIf="rangeConfig?.range; else singleBlock"
      [value]="value"
      [free]="true"
      [label]="featureName | translate"
      (changed)="value = $event"
      [options]="options"
      [render]="ren(labels)"
      [focused]="true"
    ></app-range-field>
    <ng-template #singleBlock>
      <app-option-field
        [value]="value"
        [multiple]="false"
        [label]="featureName | translate"
        (changed)="value = $event"
        [options]="options"
        [render]="ren(labels)"
      ></app-option-field>
    </ng-template>
  </ng-container>
</div>
