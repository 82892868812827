import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const INSOLATION_TYPES = ['sunny', 'halfshadow', 'shadow'] as const;
export type insolationType = typeof INSOLATION_TYPES[number];

const i18nName = (v: string) => 'features.position.insolation.' + v;

export default {
  ...defaultFeatureConfig('position.insolation'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor', 'company'],
  selectType: {
    values: INSOLATION_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,

  reduce: countedFrequencyReduce,
  renderValue: i18nName,
  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
