import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, skipWhile } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { KeycloakService } from 'keycloak-angular';

import { post } from '../helpers/api';
import { isDefined } from '../helpers/assertions';

import { User } from '../types';
import { environment } from 'src/environments/environment';

import { APIAuthService } from 'src/app/api/services';
import { APIBaseUser } from '../api/models';
import { UserIdleService } from 'angular-user-idle';

// eslint-disable-next-line no-var
declare var handleCredentialResponse: (resp: any) => void;

const storageObserve = new BehaviorSubject<any>(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
handleCredentialResponse = (response): void => {
  post('auth/one-tap-login', { credential: response.credential })
    .then((result) => {
      storageObserve.next(result);
    })
    .catch((e) => console.error('TODO error', e));
};

export interface Credentials {
  email: string;
  password: string;
  name?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject$ = new BehaviorSubject<User | null | undefined>(undefined);

  /** @deprecated */
  get owner(): User | null {
    return this.currentUserSubject$.getValue() || null;
  }

  get plan(): string | null {
    return this.currentUserSubject$.getValue()?.plan ?? null;
  }

  /** TODO: add $ et he end */
  public user: Observable<User | null> = this.currentUserSubject$.pipe(filter(isDefined));

  constructor(
    private cookieService: CookieService,
    //private externalAuthService: SocialAuthService,
    private authService: APIAuthService,
    private keycloakService: KeycloakService,
    private userIdle: UserIdleService
  ) {
    storageObserve.pipe(skipWhile((s) => s === null)).subscribe((data) => this.setUser(data));

    keycloakService
      .loadUserProfile()
      .then(() => {
        this.verify();
        //Start watching for user inactivity.
        this.userIdle.startWatching();

        this.userIdle.onTimerStart().subscribe(() => {
          this.userIdle.stopWatching();
          this.logout();
        });
      })
      .catch(() => {
        this.currentUserSubject$.next(null);
      });
  }

  private setUser(data: any, token?: string) {
    if (data && token) {
      const cookieOptions = {
        domain: environment.mainDomain.replace(/:[0-9]+/g, ''),
        path: '/',
        expires: 7
      };

      this.cookieService.set('authUser', JSON.stringify(data), cookieOptions);
      this.cookieService.set('authToken', token, cookieOptions);
      this.currentUserSubject$.next(new User(data));
    } else {
      this.cookieService.delete('authUser', '/');
      this.cookieService.delete('authToken', '/');
      this.currentUserSubject$.next(null);
    }
  }

  private verify() {
    firstValueFrom(this.authService.verifyToken())
      .then((data: APIBaseUser) => {
        if (data) {
          const user = new User(data);
          this.currentUserSubject$.next(user);
        } else {
          this.setUser(null);
        }
      })
      .catch((e) => console.error('TODO error', e));
  }

  redirectToLogin(): void {
    this.keycloakService.login().catch((e) => console.error('TODO error', e));
  }

  public logout(): void {
    this.keycloakService.logout().catch((e) => console.error('TODO error', e));
    this.setUser(null);
  }

  public update(user: User): void {
    const storedData = this.cookieService.get('authUser');
    if (storedData) {
      const data = JSON.parse(storedData);
      const newData = { ...data, ...user };
      this.setUser(newData);
    }
  }

  public updateField(field: 'name' | 'lang', value: string): void {
    const user = this.currentUserSubject$.value;
    if (user) {
      user[field] = value;
      this.update(user);
    }
  }

  public async delete(email: string): Promise<any> {
    return await post('auth/delete', { email });
  }
}
