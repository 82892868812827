<form class="elem" *ngIf="nameForm" [formGroup]="nameForm" (ngSubmit)="save()">
  <span *ngIf="!edit" (click)="edit = true">{{ name?.name }}</span>
  <mat-form-field *ngIf="edit">
    <mat-label *ngIf="!name"> {{ 'forms.taxon.name' | translate }}</mat-label>
    <input matInput type="text" formControlName="name" def />
  </mat-form-field>

  <!-- <app-action-button variant='iconed' type='accept' (click)="add()"></app-action-button>-->
  <app-action-button *ngIf="name" variant="iconed" customIcon="delete_forever" (click)="remove()"></app-action-button>
</form>
