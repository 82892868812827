import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

import { FeatureConfig } from 'src/app/types/features/config';
import { getShape } from 'src/app/helpers/shapes';
import { Shape, ShapeType } from 'src/app/types/features/selectTypes';

const round = (val: number) => parseFloat(val.toFixed(1));

@Component({
  selector: 'app-shape-select',
  templateUrl: './shape-select.component.html',
  styleUrls: ['./shape-select.component.scss']
})
export class ShapeSelectComponent implements OnInit {
  @Input() feature!: Feature<Shape>;
  @Input() saved?: BehaviorSubject<boolean>;

  @Output() changed = new EventEmitter<Feature<Shape>>();

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;

  /** user has changed the value, and it is scheduled to save */
  modified = false;
  /** user had set the value previously */
  default = true;

  templates: [string, string][] = [];

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }
  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set value(newVal: any) {
    if (!this.group || !this.name) {
      return;
    }
    this.privateVal = newVal;
    const info = this.feature
      ? {
          featureId: this.feature.featureId,
          owner: this.feature.ownerId
        }
      : {};

    this.changed.emit({
      group: this.group,
      name: this.name,
      groupName: this.feature.groupName,
      value: newVal,
      ...info
    });

    this.modified = true;
    this.default = false;
  }

  path?: string;
  mirrorPath?: string;
  moving?: string;

  code?: string;

  ngOnInit(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);
    const predefined = (this.featureConfig.selectType as ShapeType).values;

    const feature = this.feature;

    this.default = feature ? false : true;

    this.privateVal = feature ? feature.value : null;

    this.initVal = this.privateVal;
    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.modified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
    if (this.privateVal && !Array.isArray(this.privateVal)) {
      this.calculatePath(this.value);
    }

    if (predefined) {
      predefined.forEach((p) => {
        const l = getShape(p, 100);
        const r = getShape(p, 100, true);
        this.templates.push([l, r]);
      });
    }
  }

  calculatePath(val: Shape): void {
    this.code = JSON.stringify(val);
    this.path = getShape(val, 300);
    this.mirrorPath = getShape(val, 300, true);
  }

  startMove(name: string, e: Event): void {
    this.moving = this.moving ? undefined : name;
    e.stopPropagation();
  }
  // e:any instead of e:MouseEvent because of layerX is not in the spec of standard events https://mariani.life/projects/dommanual/dom/events/UIEvent.html
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  stopMoving(e: any): void {
    if (e.shiftKey) {
      const { layerX, layerY } = e;
      const x = round(layerX / 3);
      const y = round(layerY / 3);
      const newPoint = {
        point: [x, y],
        handlePrev: [x - 10, y],
        handleNext: [x + 10, y]
      };
      this.value.middle.push(newPoint);
      this.value = { ...this.value };
      this.calculatePath(this.value);
    } else {
      this.moving = undefined;
    }
  }
  // e:any instead of e:MouseEvent because of layerX is not in the spec of standard events https://mariani.life/projects/dommanual/dom/events/UIEvent.html
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setPoints(event: any): void {
    if (!this.moving) {
      return;
    }
    const { layerX, layerY } = event;
    const x = round(layerX / 3);
    const y = round(layerY / 3);
    if (this.moving === 'tip') {
      this.value = { ...this.value, tip: 100 - y };
    } else if (this.moving.includes('middle')) {
      const [, idx, type] = this.moving.split('-');
      if (type === 'point') {
        const last = this.value.middle[idx];
        const dx = last.point[0] - (50 - x);
        const dy = last.point[1] - (100 - y);
        this.value.middle[idx] = {
          point: [50 - x, 100 - y],
          handlePrev: [round(last.handlePrev[0] - dx), round(last.handlePrev[1] - dy)],
          handleNext: [round(last.handleNext[0] - dx), round(last.handleNext[1] - dy)]
        };
      } else {
        this.value.middle[idx][type] = [50 - x, 100 - y];
      }
      this.value = { ...this.value };
    } else {
      this.value = { ...this.value, [this.moving]: [50 - x, 100 - y] };
    }

    this.calculatePath(this.value);
  }

  selectTemplate(index: number): void {
    this.value = (this.featureConfig?.selectType as ShapeType).values[index];
    this.calculatePath(this.value);
  }
}
