import { OptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';

const FROST_TYPES = [
  '0a',
  '0b',
  '1a',
  '1b',
  '2a',
  '2b',
  '3a',
  '3b',
  '4a',
  '4b',
  '5a',
  '5b',
  '6a',
  '6b',
  '7a',
  '7b',
  '8a',
  '8b',
  '9a',
  '9b',
  '10a',
  '10b',
  '11a',
  '11b',
  '12a',
  '12b'
] as const;
export type frostType = typeof FROST_TYPES[number];

const val = (v: string) => v;

export default {
  ...defaultFeatureConfig('position.frost_resistance'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor'],
  selectType: {
    values: FROST_TYPES,
    multiple: false,
    renderValue: val
  } as OptionType,

  renderValue: val
} as FeatureConfig;
