/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIErrorMessage } from '../models/api-error-message';
import { APIPaymentStatus } from '../models/api-payment-status';

@Injectable({
  providedIn: 'root'
})
export class APIPaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation registerPayment
   */
  static readonly RegisterPaymentPath = '/payments/register/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerPayment$Response(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<StrictHttpResponse<{ token?: string; error?: APIErrorMessage }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPaymentService.RegisterPaymentPath, 'post');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ token?: string; error?: APIErrorMessage }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerPayment(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<{ token?: string; error?: APIErrorMessage }> {
    return this.registerPayment$Response(params).pipe(
      map((r: StrictHttpResponse<{ token?: string; error?: APIErrorMessage }>) => r.body as { token?: string; error?: APIErrorMessage })
    );
  }

  /**
   * Path part for operation paymentStatus
   */
  static readonly PaymentStatusPath = '/payments/status/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentStatus$Response(params: {
    /**
     * Session Id
     */
    sessionId: string;
  }): Observable<StrictHttpResponse<{ status: APIPaymentStatus }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPaymentService.PaymentStatusPath, 'get');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ status: APIPaymentStatus }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentStatus(params: {
    /**
     * Session Id
     */
    sessionId: string;
  }): Observable<{ status: APIPaymentStatus }> {
    return this.paymentStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{ status: APIPaymentStatus }>) => r.body as { status: APIPaymentStatus })
    );
  }

  /**
   * Path part for operation subscriptionPaymentStatus
   */
  static readonly SubscriptionPaymentStatusPath = '/payments/status/subscription/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionPaymentStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPaymentStatus$Response(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<StrictHttpResponse<{ status: APIPaymentStatus }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPaymentService.SubscriptionPaymentStatusPath, 'get');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ status: APIPaymentStatus }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionPaymentStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionPaymentStatus(params: {
    /**
     * Subscription Id
     */
    subscriptionId: number;
  }): Observable<{ status: APIPaymentStatus }> {
    return this.subscriptionPaymentStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{ status: APIPaymentStatus }>) => r.body as { status: APIPaymentStatus })
    );
  }

  /**
   * Path part for operation verifyPayment
   */
  static readonly VerifyPaymentPath = '/payments/verify/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyPayment$Response(params: {
    /**
     * Session Id
     */
    sessionId: string;

    /**
     * Response from payment provider
     */
    body: {
      merchantId: number;
      posId: number;
      sessionId: string;
      amount: number;
      originAmount: number;
      currency: string;
      orderId: number;
      methodId: number;
      statement: string;
      sign: string;
    };
  }): Observable<StrictHttpResponse<{ status?: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPaymentService.VerifyPaymentPath, 'post');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ status?: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyPayment(params: {
    /**
     * Session Id
     */
    sessionId: string;

    /**
     * Response from payment provider
     */
    body: {
      merchantId: number;
      posId: number;
      sessionId: string;
      amount: number;
      originAmount: number;
      currency: string;
      orderId: number;
      methodId: number;
      statement: string;
      sign: string;
    };
  }): Observable<{ status?: boolean }> {
    return this.verifyPayment$Response(params).pipe(map((r: StrictHttpResponse<{ status?: boolean }>) => r.body as { status?: boolean }));
  }
}
