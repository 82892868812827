import { Injectable } from '@angular/core';

import { Bucket, Image, TaksoUnit } from 'src/app/types';

import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';
import { HttpService } from '../httpService';

export interface SpeciesBucketGroup {
  name: string;
  latinName: string;
  buckets: Bucket[];
  showAll: boolean;
}

/**@deprecated */
@Injectable({ providedIn: 'root' })
export class BucketService {
  private bucketList$: BehaviorSubject<Bucket[] | null> = new BehaviorSubject<Bucket[] | null>(null);
  private selectedBucket$: BehaviorSubject<Bucket | null> = new BehaviorSubject<Bucket | null>(null);

  constructor(private http: HttpService) {}

  get ownedBuckets(): Observable<Bucket[] | null> {
    return this.bucketList$.asObservable();
  }
  get groupedBuckets(): Observable<SpeciesBucketGroup[]> {
    return this.bucketList$.pipe(
      skipWhile((e) => !e),
      map((list) => this.prepareBySpecies(list || []))
    );
  }

  get activeBucket(): Observable<Bucket> {
    return this.selectedBucket$
      .asObservable()
      .pipe(filter((el: Bucket | null) => el !== undefined) as OperatorFunction<Bucket | null, Bucket>);
  }

  public prepareBySpecies(buckets: Bucket[]): SpeciesBucketGroup[] {
    const bySpecies = [] as SpeciesBucketGroup[];

    // special bucket for images without taxons
    let general: SpeciesBucketGroup | undefined;

    buckets.forEach((bucket) => {
      const name = bucket.takson ? bucket.takson.localName || bucket.takson.latinName : null;

      const group = bySpecies.find((f) => name === f.name);
      if (group) {
        group.buckets.push(bucket);
      } else {
        const newGroup = {
          name,
          latinName: bucket.takson ? bucket.takson.latinName : '',
          buckets: [bucket],
          showAll: false
        } as SpeciesBucketGroup;
        // if no taxon name - leave it for later
        if (!name) {
          general = newGroup;
        } else {
          bySpecies.push(newGroup);
        }
      }
    });
    // sort by name
    bySpecies.sort((a, b) => (a.name > b.name ? 1 : -1));
    // add special bucket to the beginning of the list
    if (general) {
      bySpecies.unshift(general);
    }
    return bySpecies;
  }

  // create new bucket or return one that already exists
  public async createBucket(takson: TaksoUnit): Promise<Bucket | null> {
    const currentBuckets = this.bucketList$.getValue() || [];
    const foundBucket = currentBuckets.filter((b) => b.takson).find((b) => (b.takson ? b.takson.taxonId === takson.taxonId : false));

    if (foundBucket) {
      return foundBucket;
    } else {
      try {
        const data = await this.http.post('bucket/new', {
          taxonId: takson.taxonId
        });
        const newBucket = new Bucket(data);
        currentBuckets.push(newBucket);
        this.bucketList$.next(currentBuckets);
        return newBucket;
      } catch (e) {
        return null;
      }
    }
  }

  public async removeBucket(bucketId: string): Promise<boolean> {
    const currentBuckets = this.bucketList$.getValue() || [];
    this.bucketList$.next(currentBuckets.filter((b) => b.bucketId !== bucketId));
    return this.http.post('bucket/remove', { bucketId });
  }

  private async getBuckets(options: { species?: string; variety?: string }): Promise<Bucket[]> {
    try {
      const data = await this.http.get('bucket/getAll', { filter: options });
      return data.map((d: any) => new Bucket(d));
    } catch (e) {
      return [];
    }
  }

  public async getBucket(id: string): Promise<Bucket | null> {
    try {
      const data = await this.http.get('bucket/get', { id });
      return new Bucket(data);
    } catch (e) {
      return null;
    }
  }

  public async setActiveBucket(id: string): Promise<void> {
    const list = this.bucketList$.value;
    let foundBucket = list ? list.find((b) => b.bucketId === id) : null;
    if (!foundBucket) {
      foundBucket = await this.getBucket(id);
    }
    this.selectedBucket$.next(foundBucket);
  }

  public async getDefaultBucket(): Promise<Bucket | null> {
    try {
      const data = await this.http.get('bucket/getDefault', {});
      return new Bucket(data);
    } catch (e) {
      return null;
    }
  }

  public async getNamedGroup(group: string): Promise<Bucket[]> {
    try {
      const data = await this.http.get('bucket/get', { group });
      return data.map((b: any) => new Bucket(b));
    } catch (e) {
      return [];
    }
  }

  public async transfer(image: Image, bucket: Bucket): Promise<any> {
    return this.http.post('bucket/transfer', { imageId: image.imageId, bucketId: bucket.bucketId });
  }

  public async refresh(): Promise<void> {
    const buckets = await this.getBuckets({});
    this.bucketList$.next(buckets);

    const activeBucketId = this.selectedBucket$.value?.bucketId;
    this.selectedBucket$.next(buckets.find((b) => b.bucketId === activeBucketId) || null);
  }
}
