/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIStock } from '../models/api-stock';
import { APIThumbTaxon } from '../models/api-thumb-taxon';

@Injectable({
  providedIn: 'root'
})
export class APIShoppingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getShoppingAssortmentForTaxon
   */
  static readonly GetShoppingAssortmentForTaxonPath = '/shopping/assortment/{taxonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoppingAssortmentForTaxon()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingAssortmentForTaxon$Response(params: {
    /**
     * Taxon Id
     */
    taxonId: string;
  }): Observable<StrictHttpResponse<Array<APIStock>>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.GetShoppingAssortmentForTaxonPath, 'get');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<APIStock>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoppingAssortmentForTaxon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingAssortmentForTaxon(params: {
    /**
     * Taxon Id
     */
    taxonId: string;
  }): Observable<Array<APIStock>> {
    return this.getShoppingAssortmentForTaxon$Response(params).pipe(
      map((r: StrictHttpResponse<Array<APIStock>>) => r.body as Array<APIStock>)
    );
  }

  /**
   * Path part for operation getShoppingAssortment
   */
  static readonly GetShoppingAssortmentPath = '/shopping/assortment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoppingAssortment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingAssortment$Response(params?: {}): Observable<StrictHttpResponse<Array<APIStock>>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.GetShoppingAssortmentPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<APIStock>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoppingAssortment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingAssortment(params?: {}): Observable<Array<APIStock>> {
    return this.getShoppingAssortment$Response(params).pipe(map((r: StrictHttpResponse<Array<APIStock>>) => r.body as Array<APIStock>));
  }

  /**
   * Path part for operation getShoppingList
   */
  static readonly GetShoppingListPath = '/shopping/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoppingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingList$Response(params?: {}): Observable<StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.GetShoppingListPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoppingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingList(params?: {}): Observable<{ plants: Array<APIThumbTaxon> }> {
    return this.getShoppingList$Response(params).pipe(
      map((r: StrictHttpResponse<{ plants: Array<APIThumbTaxon> }>) => r.body as { plants: Array<APIThumbTaxon> })
    );
  }

  /**
   * Path part for operation getShoppingListDetailed
   */
  static readonly GetShoppingListDetailedPath = '/shopping/list/detailed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShoppingListDetailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingListDetailed$Response(params?: {}): Observable<StrictHttpResponse<Array<{ taxon: string; count: number }>>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.GetShoppingListDetailedPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<{ taxon: string; count: number }>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShoppingListDetailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShoppingListDetailed(params?: {}): Observable<Array<{ taxon: string; count: number }>> {
    return this.getShoppingListDetailed$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{ taxon: string; count: number }>>) => r.body as Array<{ taxon: string; count: number }>)
    );
  }

  /**
   * Path part for operation setShoppingItem
   */
  static readonly SetShoppingItemPath = '/shopping/item/{taxonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setShoppingItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  setShoppingItem$Response(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<StrictHttpResponse<{ added: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.SetShoppingItemPath, 'post');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
      rb.query('force', params.force, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ added: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setShoppingItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setShoppingItem(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * Should the state be set (force&#x3D;true) unset(force&#x3D;false) or toggled (force&#x3D;undefined)
     */
    force?: boolean;
  }): Observable<{ added: boolean }> {
    return this.setShoppingItem$Response(params).pipe(map((r: StrictHttpResponse<{ added: boolean }>) => r.body as { added: boolean }));
  }

  /**
   * Path part for operation setShoppingItemCount
   */
  static readonly SetShoppingItemCountPath = '/shopping/item/count/{taxonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setShoppingItemCount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShoppingItemCount$Response(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * nNumber of items
     */
    body: { count: number };
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIShoppingService.SetShoppingItemCountPath, 'post');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setShoppingItemCount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShoppingItemCount(params: {
    /**
     * Taxon Id
     */
    taxonId: string;

    /**
     * nNumber of items
     */
    body: { count: number };
  }): Observable<{ success: boolean }> {
    return this.setShoppingItemCount$Response(params).pipe(
      map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean })
    );
  }
}
