import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SORT_TYPES } from 'src/app/types/features/appearance/sort';
import { FLOWER_SHAPES } from 'src/app/types/features/flower/flower_shape';
import { INFLORESCENCE_TYPES } from 'src/app/types/features/flower/inflorescence';

import { optionType, sortType, inflorescenceType, flowerShape } from 'src/app/types/features/types';

@Component({
  selector: 'app-option-field',
  templateUrl: './option-field.component.html',
  styleUrls: ['./option-field.component.scss']
})
export class OptionFieldComponent {
  @Input() value!: any[];
  @Input() label!: string;
  @Input() options!: optionType[];
  @Input() multiple = true;

  @Output() changed = new EventEmitter<any>();

  @Input() render: (val: any) => string = (val: any) => val.toString();

  isOptionSort(val: optionType): val is sortType {
    return SORT_TYPES.includes(val as sortType);
  }
  isOptionInflorescence(val: optionType): val is inflorescenceType {
    return INFLORESCENCE_TYPES.includes(val as inflorescenceType);
  }
  isOptionFlowerShape(val: optionType): val is flowerShape {
    return FLOWER_SHAPES.includes(val as flowerShape);
  }
}
