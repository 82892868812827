/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIBaseUser } from '../models/api-base-user';

@Injectable({
  providedIn: 'root'
})
export class APIAuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation verifyToken
   */
  static readonly VerifyTokenPath = '/auth/verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyToken$Response(params?: {}): Observable<StrictHttpResponse<APIBaseUser>> {
    const rb = new RequestBuilder(this.rootUrl, APIAuthService.VerifyTokenPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<APIBaseUser>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyToken(params?: {}): Observable<APIBaseUser> {
    return this.verifyToken$Response(params).pipe(map((r: StrictHttpResponse<APIBaseUser>) => r.body as APIBaseUser));
  }
}
