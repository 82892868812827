/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIBaseTaxon } from '../models/api-base-taxon';
import { APIMarker } from '../models/api-marker';
import { APIPhoto } from '../models/api-photo';

@Injectable({
  providedIn: 'root'
})
export class APIPhotoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addPhoto
   */
  static readonly AddPhotoPath = '/photo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPhoto()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPhoto$Response(params: {
    /**
     * Optional taxon to associate the photo with
     */
    taxonId?: string;

    /**
     * Data of the photo to be added
     */
    body: {
      src: string;
      thumb: string;
      width: number;
      height: number;
      date?: string;
      description?: string;
      location?: string;
      public?: boolean;
      collectionId?: string;
    };
  }): Observable<StrictHttpResponse<{ photoId: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoService.AddPhotoPath, 'post');
    if (params) {
      rb.query('taxonId', params.taxonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoId: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPhoto$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPhoto(params: {
    /**
     * Optional taxon to associate the photo with
     */
    taxonId?: string;

    /**
     * Data of the photo to be added
     */
    body: {
      src: string;
      thumb: string;
      width: number;
      height: number;
      date?: string;
      description?: string;
      location?: string;
      public?: boolean;
      collectionId?: string;
    };
  }): Observable<{ photoId: number }> {
    return this.addPhoto$Response(params).pipe(map((r: StrictHttpResponse<{ photoId: number }>) => r.body as { photoId: number }));
  }

  /**
   * Path part for operation editPhoto
   */
  static readonly EditPhotoPath = '/photo/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPhoto()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPhoto$Response(params: {
    /**
     * Id of the photo
     */
    photoId: number;

    /**
     * Data of the photo to be added
     */
    body: {
      date?: string;
      description?: string;
      location?: string;
      public?: boolean;
      markers?: Array<APIMarker>;
      taxon?: string;
      collectionId?: string;
    };
  }): Observable<StrictHttpResponse<{ photoId: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoService.EditPhotoPath, 'put');
    if (params) {
      rb.path('photoId', params.photoId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photoId: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editPhoto$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPhoto(params: {
    /**
     * Id of the photo
     */
    photoId: number;

    /**
     * Data of the photo to be added
     */
    body: {
      date?: string;
      description?: string;
      location?: string;
      public?: boolean;
      markers?: Array<APIMarker>;
      taxon?: string;
      collectionId?: string;
    };
  }): Observable<{ photoId: number }> {
    return this.editPhoto$Response(params).pipe(map((r: StrictHttpResponse<{ photoId: number }>) => r.body as { photoId: number }));
  }

  /**
   * Path part for operation deletePhoto
   */
  static readonly DeletePhotoPath = '/photo/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePhoto()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePhoto$Response(params: {
    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<StrictHttpResponse<{ success: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoService.DeletePhotoPath, 'delete');
    if (params) {
      rb.path('photoId', params.photoId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ success: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePhoto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePhoto(params: {
    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<{ success: boolean }> {
    return this.deletePhoto$Response(params).pipe(map((r: StrictHttpResponse<{ success: boolean }>) => r.body as { success: boolean }));
  }

  /**
   * Path part for operation getPhotoDetails
   */
  static readonly GetPhotoDetailsPath = '/photo/details/{userId}/{photoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPhotoDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoDetails$Response(params: {
    /**
     * Id of the owner of the photo
     */
    userId: number;

    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<StrictHttpResponse<{ photo: APIPhoto; taxon?: APIBaseTaxon; taxons?: Array<APIBaseTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPhotoService.GetPhotoDetailsPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('photoId', params.photoId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photo: APIPhoto; taxon?: APIBaseTaxon; taxons?: Array<APIBaseTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPhotoDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPhotoDetails(params: {
    /**
     * Id of the owner of the photo
     */
    userId: number;

    /**
     * Id of the photo
     */
    photoId: number;
  }): Observable<{ photo: APIPhoto; taxon?: APIBaseTaxon; taxons?: Array<APIBaseTaxon> }> {
    return this.getPhotoDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ photo: APIPhoto; taxon?: APIBaseTaxon; taxons?: Array<APIBaseTaxon> }>) =>
          r.body as { photo: APIPhoto; taxon?: APIBaseTaxon; taxons?: Array<APIBaseTaxon> }
      )
    );
  }
}
