export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function isDefined<T>(value: T | null | undefined): value is T | null {
  return value !== undefined;
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value !== null;
}
