import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Bucket, Image } from 'src/app/types';

import { SnackbarService } from 'src/app/services/utilities/snackbar.service';
import { BucketService } from 'src/app/services/database/bucket.service';

@Component({
  selector: 'app-image-transfer-form',
  templateUrl: './image-transfer-form.component.html',
  styleUrls: ['./image-transfer-form.component.scss']
})
export class ImageTransferFormComponent {
  @Input() bucketId!: string;
  @Input() image!: Image;

  @Output() saved: EventEmitter<Image | null> = new EventEmitter<Image | null>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  buckets: Bucket[] = [];
  selectedBucket: string;

  loading = false;

  constructor(private snackbar: SnackbarService, private bucketService: BucketService) {
    this.selectedBucket = this.bucketId;
    this.bucketService.ownedBuckets.subscribe((e) => {
      this.buckets = e || [];
    });
  }

  onCancel(): void {
    this.cancel.emit(true);
  }

  async onTransfer(): Promise<void> {
    if (this.selectedBucket !== this.bucketId) {
      this.loading = true;
      const bucket = this.buckets.find((b) => b.bucketId === this.selectedBucket); // await this.bucketService.getBucket(this.selectedBucket);
      if (!bucket) {
        return;
      }
      const transfer = await this.bucketService.transfer(this.image, bucket);
      if (transfer) {
        this.snackbar.showSnackbar('imageControl.transfered', 'success');
        this.loading = false;
        this.saved.emit(this.image);
      } else {
        this.snackbar.showSnackbar('snackBar.response.error', 'warning');
        this.loading = false;
        this.saved.emit(null);
      }
    }
  }
}
