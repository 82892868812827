<app-navigation-bar [main]="true"></app-navigation-bar>
<div class="page">
  <app-contact-form *appIsLogged [formType]="'contact'"></app-contact-form>
  <div [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>
<app-footer></app-footer>

<!-- TODO: Uncomment when users will be allowed-->
<!-- <div *ngIf="!authUser" id="g_id_onload"
     [attr.data-client_id]="googleClientId"
     data-callback="handleCredentialResponse"
     [attr.data-referrer]="href"
     data-skip_prompt_cookie="authUser"
     [attr.data-state_cookie_domain]="mainDomain">
</div> -->
