import { Component, OnInit, Input, OnDestroy, HostBinding } from '@angular/core';
import { ImageService } from 'src/app/services/database/image.service';
import { LightboxService } from 'src/app/services/utilities/lightbox.service';
import { Image } from 'src/app/types';
import date from 'src/app/helpers/date';
import { TagService } from 'src/app/services/database/tag.service';
import { Marker } from 'src/app/types/image';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input() image?: Image;
  @Input() description?: string[];
  @Input() editable = false;

  @HostBinding('class.hasLightbox') @Input() lightbox = true;

  day?: string;
  month?: string;

  openForm?: 'delete' | 'details' | 'transfer' | 'markers' | 'map';

  markers?: Marker[];

  constructor(private lightboxService: LightboxService, private imageService: ImageService, private tagService: TagService) {}

  ngOnInit(): void {
    const d = this.image?.date ? date(this.image.date) : new Date();

    this.day = String(d.getDate());
    this.month = `months.date.${d.getMonth()}`;

    if (this.lightbox && this.image) {
      this.lightboxService.registerImage(this.image);
    }
    this.description = this.tagService.splitDescription(this.image?.description as string);

    this.markers = this.image?.markers;

    if (this.editable && this.image) {
      this.imageService.register(this.image);
      this.imageService.getImageObservable(this.image)?.subscribe((img) => (this.image = img));
    }
  }

  ngOnDestroy(): void {
    if (this.lightbox && this.image) {
      this.lightboxService.unregisterImage(this.image);
    }
    if (this.editable && this.image) {
      this.imageService.unregister(this.image);
    }
  }

  more(e: Event): void {
    e.stopPropagation();
  }

  close(): void {
    this.openForm = undefined;
  }

  openLightbox(): void {
    if (this.image) {
      this.lightboxService.open(this.image);
    }
  }

  isCompositionImage(image: Image): boolean {
    return image.markers !== undefined;
  }
}
