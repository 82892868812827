<div class="display" *ngIf="!focused" (click)="focused = true">
  {{ label | translate }}
  <div class="mat-select-arrow"></div>
</div>

<div class="options" *ngIf="focused">
  <label>{{ label | translate }}</label>
  <div class="field-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>Min</mat-label>
      <mat-select class="half min" [value]="range.min" (valueChange)="setRange($event, 'min')">
        <mat-option *ngFor="let value of options" [value]="value" [disabled]="!free && value > (range.max ? range.max : value)">
          {{ render(value) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-icon *ngIf="range.min !== undefined" (click)="setRange(undefined, 'min')">cancel</mat-icon>
  </div>
  <div class="field-wrapper">
    <mat-form-field appearance="outline">
      <mat-label>Max</mat-label>
      <mat-select class="half max" [value]="range.max" (valueChange)="setRange($event, 'max')">
        <mat-option *ngFor="let value of options" [value]="value" [disabled]="!free && (value < (range.min ? range.min : 0))">
          {{ render(value) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-icon *ngIf="range.max !== undefined" (click)="setRange(undefined, 'max')">cancel</mat-icon>
  </div>
</div>
