import { Component, Input, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  template: `<mat-icon
    [ngClass]="{ warn: warn == '', success: success == '', error: error == '' }"
    [matTooltip]="tooltip"
    matTooltipPosition="above"
  >
    <ng-content *ngIf="!warn && !success && !warn"></ng-content>
    <ng-container *ngIf="warn == ''">warning</ng-container>
    <ng-container *ngIf="success == ''">check_circle </ng-container>
    <ng-container *ngIf="error == ''">error</ng-container>
  </mat-icon>`,
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() tooltip = '';
  @Input() success?: string;
  @Input() error?: string;
  @Input() warn?: string;
  @Input() bg?: string;
  @Input() size = '1em';

  @HostBinding('attr.style')
  public get valueAsStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`
    --size-var: ${this.size};
    --bg-color: ${this.error == '' || this.success == '' || this.warn == '' ? 'transparent' : this.bg ?? '#fff'} 
    `);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
