import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getDefaultLang } from '../helpers/lang';

import { AuthService } from '../services/auth.service';

import { Role } from '../types/user';

class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private roles: Role[]) {}

  canActivate(): Observable<boolean> {
    return this.authService.user.pipe(
      // skipWhile((s) => s === null),
      map((s) => {
        // user is unlogged
        if (!s) {
          const location = window.location.href + '';
          // we are already on main app
          //if(location.includes(environment.mainDomain)){
          this.router
            .navigate([getDefaultLang(), 'auth'], {
              queryParams: {
                msg: 'notLogged',
                //    params:this.roles,
                url: encodeURIComponent(location)
                //    local:1
              }
            })
            .catch((e) => console.error('TODO error', e));
          //} else {
          // setTimeout(()=> {
          // window.location.href =
          //   environment.protocol +
          //   environment.mainDomain +
          //   '/' +
          //   getDefaultLang() +
          //   '/login?msg=notLogged&' +
          //   this.roles.map((r) => 'params=' + r).join('&') +
          //   '&local=0&url='+encodeURIComponent(location)}, 10000)
          //}

          return false;
        }

        // user is logged and has correct role
        if (s && this.roles.includes(s.role)) {
          return true;
        }

        // user is logged but with incorrect role
        this.router.navigate([getDefaultLang(), 401]).catch((e) => console.error('TODO error', e));
        return false;
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminGuard extends RoleGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router, ['admin']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserGuard extends RoleGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router, ['user', 'contributor', 'admin']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard extends RoleGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router, ['company']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard extends RoleGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router, ['manager']);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContributorGuard extends RoleGuard {
  constructor(authService: AuthService, router: Router) {
    super(authService, router, ['admin', 'contributor']);
  }
}
