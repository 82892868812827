import { OptionType } from '../selectTypes';

import { multipleOptionFeatureCompare, FeatureConfig, defaultFeatureConfig, countedFrequencyReduce } from '../config';

const SOIL_TYPES = ['sandy', 'loamy', 'sandy_loam', 'compost', 'garde_average', 'permeable', 'fertile'] as const;
export type soilType = typeof SOIL_TYPES[number];

const i18nName = (v: string) => 'features.position.soil.' + v;

export default {
  ...defaultFeatureConfig('position.soil'),
  controlType: 'option',
  contributorRoles: ['admin', 'contributor', 'company'],
  selectType: {
    values: SOIL_TYPES,
    multiple: true,
    renderValue: i18nName
  } as OptionType,
  constraints: [
    {
      name: 'groups.type',
      values: [
        'leefy_trees',
        'needle_trees',
        'leefy_bushes',
        'needle_bushes',
        'prostrate_shrubs',
        'herbaceous_plants',
        'grass',
        'creepers',
        'other'
      ]
    }
  ],
  reduce: countedFrequencyReduce,
  renderValue: i18nName,

  featureCompare: multipleOptionFeatureCompare
} as FeatureConfig;
