<button
  mat-icon-button
  [matMenuTriggerFor]="userMenu"
  [matBadge]="(comparables | async)?.length"
  matBadgeColor="accent"
  matBadgeSize="small"
  [matBadgeHidden]="(comparables | async)?.length === 0"
>
  <mat-icon> compare_arrows </mat-icon>
</button>

<mat-menu #userMenu="matMenu">
  <div class="menu">
    <ng-container *ngIf="(comparables | async)?.length; else noBlock">
      <app-takson
        *ngFor="let t of (comparables | async)"
        [takson]="t"
        [comparable]="true"
        [link]="t.taxonPageLink.url"
        [showComparable]="false"
        [showFav]="false"
      ></app-takson>
      <p>
        <a mat-flat-button color="primary" [routerLink]="'/compare' | urlLang" [queryParams]="{ _app: 'main' }"
          >{{ 'misc.compare' | translate }}</a
        >
        <button mat-flat-button (click)="clear()">{{ 'misc.clear' | translate }}</button>
      </p>
    </ng-container>
    <ng-template #noBlock>
      <p>{{ 'misc.noComparables' | translate }}</p>
    </ng-template>
  </div>
</mat-menu>
