import { ParametrizedOptionType } from '../selectTypes';

import { FeatureConfig, defaultFeatureConfig } from '../config';
import { Feature } from '..';

export const FLOWER_SHAPES = [
  'bud',
  'bell-single',
  'bell-double',
  'bell-full',
  'stellate-single',
  'stellate-double',
  'full',
  'urn',
  'pea',
  'slipper'
] as const;
export type flowerShape = typeof FLOWER_SHAPES[number];

const i18nName = (v: string) => 'features.flower.flower_shape.' + v;

export default {
  ...defaultFeatureConfig('flower.flower_shape'),
  controlType: 'option',
  selectType: {
    values: FLOWER_SHAPES,
    multiple: false,
    parameter: {
      'stellate-single': [3, 4, 5, 6],
      'stellate-double': [3, 4, 5, 6]
    },
    renderValue: i18nName
  } as ParametrizedOptionType,
  constraints: [{ name: 'groups.use', values: ['flowering'] }],

  reduce: (featureArray: Feature<any>[]): any => {
    if (!featureArray || featureArray.length === 0) {
      return null;
    }
    const mostFrequentFeature = featureArray.reduce((a, b, i, arr) =>
      arr.filter((v) => v.value === a.value).length >= arr.filter((v) => v.value === b.value).length ? a : b
    );

    return {
      value: Array.isArray(mostFrequentFeature.value) ? mostFrequentFeature.value[0] : mostFrequentFeature.value,
      parameter: mostFrequentFeature.min
    };
  },

  renderValue: i18nName
} as FeatureConfig;
