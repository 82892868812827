/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APISearchTaxon } from '../models/api-search-taxon';

@Injectable({
  providedIn: 'root'
})
export class APISearchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation searchTaxonByLatinName
   */
  static readonly SearchTaxonByLatinNamePath = '/search/byLatinName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTaxonByLatinName()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByLatinName$Response(params: {
    /**
     * Text to find in names
     */
    text: string;

    /**
     * Should search for taxons to add
     */
    withAll?: boolean;
  }): Observable<StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APISearchService.SearchTaxonByLatinNamePath, 'post');
    if (params) {
      rb.query('text', params.text, {});
      rb.query('withAll', params.withAll, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTaxonByLatinName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByLatinName(params: {
    /**
     * Text to find in names
     */
    text: string;

    /**
     * Should search for taxons to add
     */
    withAll?: boolean;
  }): Observable<{ taxons: Array<APISearchTaxon>; total: number }> {
    return this.searchTaxonByLatinName$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>) =>
          r.body as { taxons: Array<APISearchTaxon>; total: number }
      )
    );
  }

  /**
   * Path part for operation searchTaxonByLocalName
   */
  static readonly SearchTaxonByLocalNamePath = '/search/byLocalName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTaxonByLocalName()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByLocalName$Response(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APISearchService.SearchTaxonByLocalNamePath, 'post');
    if (params) {
      rb.query('text', params.text, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTaxonByLocalName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByLocalName(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<{ taxons: Array<APISearchTaxon>; total: number }> {
    return this.searchTaxonByLocalName$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>) =>
          r.body as { taxons: Array<APISearchTaxon>; total: number }
      )
    );
  }

  /**
   * Path part for operation searchTaxonByVariety
   */
  static readonly SearchTaxonByVarietyPath = '/search/byVariety';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTaxonByVariety()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByVariety$Response(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APISearchService.SearchTaxonByVarietyPath, 'post');
    if (params) {
      rb.query('text', params.text, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchTaxonByVariety$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchTaxonByVariety(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<{ taxons: Array<APISearchTaxon>; total: number }> {
    return this.searchTaxonByVariety$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ taxons: Array<APISearchTaxon>; total: number }>) =>
          r.body as { taxons: Array<APISearchTaxon>; total: number }
      )
    );
  }
}
