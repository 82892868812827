/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIPhotoSet } from '../models/api-photo-set';
import { APIUser } from '../models/api-user';
import { APIUserStats } from '../models/api-user-stats';

@Injectable({
  providedIn: 'root'
})
export class APIUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: {}): Observable<StrictHttpResponse<Array<APIUser>>> {
    const rb = new RequestBuilder(this.rootUrl, APIUserService.GetUsersPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<APIUser>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: {}): Observable<Array<APIUser>> {
    return this.getUsers$Response(params).pipe(map((r: StrictHttpResponse<Array<APIUser>>) => r.body as Array<APIUser>));
  }

  /**
   * Path part for operation getUserProfileImages
   */
  static readonly GetUserProfileImagesPath = '/users/profile/{slug}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileImages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileImages$Response(params: {
    /**
     * User slug
     */
    slug: string;

    /**
     * Page number
     */
    page: number;
  }): Observable<StrictHttpResponse<APIPhotoSet>> {
    const rb = new RequestBuilder(this.rootUrl, APIUserService.GetUserProfileImagesPath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<APIPhotoSet>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileImages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileImages(params: {
    /**
     * User slug
     */
    slug: string;

    /**
     * Page number
     */
    page: number;
  }): Observable<APIPhotoSet> {
    return this.getUserProfileImages$Response(params).pipe(map((r: StrictHttpResponse<APIPhotoSet>) => r.body as APIPhotoSet));
  }

  /**
   * Path part for operation getUserProfile
   */
  static readonly GetUserProfilePath = '/users/profile/{slug}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfile$Response(params: {
    /**
     * User slug
     */
    slug: string;
  }): Observable<StrictHttpResponse<{ user: APIUser; photoSet: APIPhotoSet; stats: APIUserStats }>> {
    const rb = new RequestBuilder(this.rootUrl, APIUserService.GetUserProfilePath, 'get');
    if (params) {
      rb.path('slug', params.slug, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ user: APIUser; photoSet: APIPhotoSet; stats: APIUserStats }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfile(params: {
    /**
     * User slug
     */
    slug: string;
  }): Observable<{ user: APIUser; photoSet: APIPhotoSet; stats: APIUserStats }> {
    return this.getUserProfile$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ user: APIUser; photoSet: APIPhotoSet; stats: APIUserStats }>) =>
          r.body as { user: APIUser; photoSet: APIPhotoSet; stats: APIUserStats }
      )
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {
    /**
     * User id
     */
    id: number;
  }): Observable<StrictHttpResponse<APIUser>> {
    const rb = new RequestBuilder(this.rootUrl, APIUserService.GetUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<APIUser>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {
    /**
     * User id
     */
    id: number;
  }): Observable<APIUser> {
    return this.getUser$Response(params).pipe(map((r: StrictHttpResponse<APIUser>) => r.body as APIUser));
  }
}
