/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIAssortmentProduct } from '../models/api-assortment-product';
import { APIAssortmentProductDetails } from '../models/api-assortment-product-details';

@Injectable({
  providedIn: 'root'
})
export class APIAssortmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addProductItem
   */
  static readonly AddProductItemPath = '/assortment/item/add/{shopId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addProductItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductItem$Response(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Product details to add
     */
    body: { item: APIAssortmentProductDetails };
  }): Observable<StrictHttpResponse<{ taxonId?: string; updatedAt?: string; price?: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.AddProductItemPath, 'post');
    if (params) {
      rb.path('shopId', params.shopId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxonId?: string; updatedAt?: string; price?: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addProductItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addProductItem(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Product details to add
     */
    body: { item: APIAssortmentProductDetails };
  }): Observable<{ taxonId?: string; updatedAt?: string; price?: number }> {
    return this.addProductItem$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ taxonId?: string; updatedAt?: string; price?: number }>) =>
          r.body as { taxonId?: string; updatedAt?: string; price?: number }
      )
    );
  }

  /**
   * Path part for operation checkShopAssortment
   */
  static readonly CheckShopAssortmentPath = '/assortment/check/{shopId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkShopAssortment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkShopAssortment$Response(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Taxon id to add to collection
     */
    body: { items?: Array<{ latinName: string; variety?: string }> };
  }): Observable<StrictHttpResponse<Array<APIAssortmentProductDetails>>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.CheckShopAssortmentPath, 'post');
    if (params) {
      rb.path('shopId', params.shopId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<APIAssortmentProductDetails>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkShopAssortment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkShopAssortment(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Taxon id to add to collection
     */
    body: { items?: Array<{ latinName: string; variety?: string }> };
  }): Observable<Array<APIAssortmentProductDetails>> {
    return this.checkShopAssortment$Response(params).pipe(
      map((r: StrictHttpResponse<Array<APIAssortmentProductDetails>>) => r.body as Array<APIAssortmentProductDetails>)
    );
  }

  /**
   * Path part for operation getNewShopAssortment
   */
  static readonly GetNewShopAssortmentPath = '/assortment/new/shop';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewShopAssortment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewShopAssortment$Response(params?: {
    /**
     * Shop ID
     */
    shopId?: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.GetNewShopAssortmentPath, 'get');
    if (params) {
      rb.query('shopId', params.shopId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNewShopAssortment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewShopAssortment(params?: {
    /**
     * Shop ID
     */
    shopId?: string;
  }): Observable<string> {
    return this.getNewShopAssortment$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getShopAssortment
   */
  static readonly GetShopAssortmentPath = '/assortment/get/{shopId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShopAssortment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShopAssortment$Response(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Type of paroducts
     */
    type?: 'all' | 'used' | 'notFound';
  }): Observable<StrictHttpResponse<Array<APIAssortmentProduct>>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.GetShopAssortmentPath, 'get');
    if (params) {
      rb.path('shopId', params.shopId, {});
      rb.query('type', params.type, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<APIAssortmentProduct>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShopAssortment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShopAssortment(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Type of paroducts
     */
    type?: 'all' | 'used' | 'notFound';
  }): Observable<Array<APIAssortmentProduct>> {
    return this.getShopAssortment$Response(params).pipe(
      map((r: StrictHttpResponse<Array<APIAssortmentProduct>>) => r.body as Array<APIAssortmentProduct>)
    );
  }

  /**
   * Path part for operation getShopsList
   */
  static readonly GetShopsListPath = '/assortment/shops';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShopsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShopsList$Response(params?: {}): Observable<StrictHttpResponse<Array<{ value: string; label: string }>>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.GetShopsListPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<{ value: string; label: string }>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShopsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShopsList(params?: {}): Observable<Array<{ value: string; label: string }>> {
    return this.getShopsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{ value: string; label: string }>>) => r.body as Array<{ value: string; label: string }>)
    );
  }

  /**
   * Path part for operation syncProductItem
   */
  static readonly SyncProductItemPath = '/assortment/item/sync/{shopId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncProductItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncProductItem$Response(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Product details to add
     */
    body: { itemId: string };
  }): Observable<StrictHttpResponse<{ taxonId?: string; updatedAt?: string }>> {
    const rb = new RequestBuilder(this.rootUrl, APIAssortmentService.SyncProductItemPath, 'post');
    if (params) {
      rb.path('shopId', params.shopId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxonId?: string; updatedAt?: string }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `syncProductItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  syncProductItem(params: {
    /**
     * Shop Id
     */
    shopId: string;

    /**
     * Product details to add
     */
    body: { itemId: string };
  }): Observable<{ taxonId?: string; updatedAt?: string }> {
    return this.syncProductItem$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxonId?: string; updatedAt?: string }>) => r.body as { taxonId?: string; updatedAt?: string })
    );
  }
}
