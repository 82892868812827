import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { SearchTaxon, ThumbTaxon } from 'src/app/types';
import { inflorescenceType } from 'src/app/types/features/types';
import { CompareService } from 'src/app/services/utilities/compare.service';
import { FavouritesService } from 'src/app/services/user/favourites.service';

import { environment } from 'src/environments/environment';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-takson',
  templateUrl: './takson.component.html',
  styleUrls: ['./takson.component.scss']
})
export class TaksonComponent implements OnInit {
  @Input() takson!: ThumbTaxon | SearchTaxon;
  @Input() link = '';

  @Input() customContent = false;
  @Input() headerType: 'grouped' | 'variety' | 'localName' = 'localName';

  @Input() comparable = false;

  @Input() showComparable = true;
  @Input() showMenu = false;
  @Input() showFav = true;

  API = environment.apiUrl;

  header?: string;
  name?: string;
  latinName?: string;
  silhouette?: inflorescenceType;
  colors: string[] = [];
  border = '';

  addedToCompare = false;
  favourite = false;

  @HostBinding('class.empty') empty = true;

  constructor(private compareService: CompareService, private favouritesService: FavouritesService) {}

  ngOnInit(): void {
    if (window.innerWidth < 649) {
      this.showMenu = true;
      this.showFav = false;
      this.showComparable = false;
    } else {
      this.showMenu = false;
      this.showFav = true;
      this.showComparable = true;
    }
    fromEvent(window, 'resize').subscribe(() => {
      if (window.innerWidth < 649) {
        this.showMenu = true;
        this.showFav = false;
        this.showComparable = false;
      } else {
        this.showMenu = false;
        this.showFav = true;
        this.showComparable = true;
      }
    });

    //this.empty = !this.takson?.mainImage;
    this.latinName = this.takson.latinName;
    this.name = this.takson.localName;
    this.header = this.takson.variety;

    if (!this.link) {
      this.link = this.takson.taxonPageLink.url;
    }

    this.compareService.comparables.subscribe(() => {
      this.addedToCompare = this.compareService.checkComparable(this.takson.taxonId);
    });

    // this.favouritesService.favouriteTaxon(this.takson.taxonId).subscribe({ next: (is) => (this.favourite = is) });

    if (this.headerType === 'grouped') {
      this.latinName = ''; // do not use latin name again
      this.name = ''; // local name was undefined
      // } else if (this.headerType === 'variety') {
      //   this.header = this.takson.variety ? this.takson.variety : '';
      //   this.name = name ? name : '';
      // } else if (this.headerType === 'localName') {
      //   this.header = this.name = name ? name : this.latinName;
      //   this.name = this.takson.variety ? this.takson.variety : '';
    }
  }

  toggleComparable(): void {
    if (this.compareService.checkComparable(this.takson.taxonId)) {
      this.compareService.removeFromFCompare(this.takson);
    } else {
      this.compareService.addToCompare(this.takson);
    }
  }

  isSearchTaxon(tbd: unknown): tbd is SearchTaxon {
    if ('matchType' in (tbd as SearchTaxon)) {
      return true;
    }
    return false;
  }

  // toggleFavourite() {
  //   this.favouritesService.toggleFavourite(this.takson.taxonId);
  // }
}
