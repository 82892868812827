<button
  *ngIf="!routerLink"
  mat-button
  [ngClass]="className + (loading ? ' loading' : '')"
  [disabled]="disabled"
  [attr.aria-label]="'buttons.select' | translate"
>
  <ng-container *ngTemplateOutlet="inner"></ng-container>
</button>

<a
  *ngIf="routerLink"
  mat-button
  [routerLink]="routerLink"
  [queryParams]="queryParams"
  [ngClass]="className + (loading ? ' loading' : '')"
  [disabled]="disabled"
  [attr.aria-label]="'buttons.select' | translate"
>
  <ng-container *ngTemplateOutlet="inner"></ng-container>
</a>

<ng-template #inner>
  <mat-progress-spinner
    *ngIf="loading && icon"
    diameter="26"
    strokeWidth="2"
    [ngClass]="variant === 'filled' ? 'white-loader' : 'custom-loader'"
    [mode]="progress ? 'determinate': 'indeterminate'"
    [value]="progress"
  ></mat-progress-spinner>
  <mat-progress-bar
    *ngIf="loading && !icon"
    mode="indeterminate"
    [ngClass]="variant === 'filled' ? 'white-loader' : 'custom-loader'"
  ></mat-progress-bar>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <span class="content">
    <ng-content></ng-content>
  </span>
</ng-template>
