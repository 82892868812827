<a
  [class]="'wrapper' + (!takson.thumb ? ' reverse' : '')"
  [routerLink]="link | urlLang"
  [queryParams]="{ _app: 'main' }"
  [attr.aria-label]="takson.getFullName()"
>
  <h3 *ngIf="this.header && this.header !== ''">
    <div>
      <span> {{ this.header }} </span>
    </div>
  </h3>

  <div class="images">
    <img *ngIf="takson.thumb" [src]="takson.thumb | urlStorage" [alt]="takson.getFullName()" />
  </div>
  <div class="silhouette" *ngIf="!takson.thumb" [ngStyle]="{ border: border }" loading="lazy">
    <img [src]="takson.silhouette" [alt]="takson.getFullName()" />
  </div>

  <div class="img-border"> </div>

  <div class="info">
    <ng-container *ngIf="customContent; else namesBlock">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #namesBlock>
      <strong>
        {{ name }}

        <app-icon
          class="matchInfo"
          *ngIf="isSearchTaxon(takson) && takson.matchType==='other' && takson.matchedNames"
          [tooltip]="takson.matchedNames"
          bg="transparent"
        >
          info
        </app-icon>
      </strong>
      <em>
        {{ latinName }}
        <app-icon
          class="matchInfo"
          *ngIf="isSearchTaxon(takson) && takson.matchType==='synonym' && takson.matchedSynonyms"
          [tooltip]="takson.matchedSynonyms"
          bg="transparent"
        >
          info
        </app-icon>
      </em>
    </ng-template>
    <span class="comp"></span>
  </div>

  <ng-container *ngIf="showMenu">
    <mat-icon class="menu" [matMenuTriggerFor]="userMenu">more_vert</mat-icon>
    <mat-menu #userMenu="matMenu">
      <div mat-menu-item class="dialog-item">
        <app-favourites-icon class="favourite" [taxon]="takson" [menuItem]="true"> </app-favourites-icon>
      </div>
      <div mat-menu-item class="dialog-item">
        <app-basket-icon class="basket" [taxon]="takson" [menuItem]="true"> </app-basket-icon>
      </div>
      <button mat-menu-item (click)="toggleComparable()" [attr.aria-label]="'aria.compare' | translate">
        <mat-icon>compare_arrows</mat-icon>
        <span>{{ (addedToCompare ? 'misc.compare.remove' : 'misc.compare.add') | translate }}</span>
      </button>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="showFav">
    <app-favourites-icon class="favourite" [taxon]="takson"></app-favourites-icon>
    <app-basket-icon *appIsLogged class="basket" [taxon]="takson"></app-basket-icon>
  </ng-container>

  <mat-icon
    *ngIf="showComparable"
    [ngClass]="{ compare: true, active: addedToCompare }"
    (click)="toggleComparable()"
    [matTooltip]="(addedToCompare ? 'misc.compare.remove' : 'misc.compare.add') | translate"
    [attr.aria-label]="'aria.compare' | translate"
  >
    compare_arrows
  </mat-icon>

  <span class="varietiesCount" *ngIf="isSearchTaxon(takson) && takson.varietiesCount">({{takson.varietiesCount}})</span>
</a>
