/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class APIUploadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation uploadFile
   */
  static readonly UploadFilePath = '/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadFile$Response(params: {
    /**
     * Info about file to add
     */
    body: { fileName: string; src: string; thumb: string; bucket: string };
  }): Observable<StrictHttpResponse<{ src: string; thumb: string; width: number; height: number }>> {
    const rb = new RequestBuilder(this.rootUrl, APIUploadService.UploadFilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ src: string; thumb: string; width: number; height: number }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadFile(params: {
    /**
     * Info about file to add
     */
    body: { fileName: string; src: string; thumb: string; bucket: string };
  }): Observable<{ src: string; thumb: string; width: number; height: number }> {
    return this.uploadFile$Response(params).pipe(
      map(
        (r: StrictHttpResponse<{ src: string; thumb: string; width: number; height: number }>) =>
          r.body as { src: string; thumb: string; width: number; height: number }
      )
    );
  }
}
