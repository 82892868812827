import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { RedirectService } from 'src/app/services/utilities/external.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-cookies-bar (closed)="onCookieBarClose()" *ngIf="cookieBar"></app-cookies-bar>
  `,
  styles: ['app-cookies-bar {position: fixed;  width: 90%;  margin: 0 5%;  bottom: 5px;  z-index: 999;}']
})
export class AppRootComponent implements OnInit {
  public href = '';
  public mainDomain?: string;
  public authUser: boolean;
  public googleClientId: string;
  public cookieBar = true;

  constructor(
    private router: Router,
    private redirectService: RedirectService,
    private cookieService: CookieService,
    private update: SwUpdate
  ) {
    this.authUser = this.cookieService.check('authUser');
    this.cookieBar = !this.cookieService.check('cookies_accepted');
    this.googleClientId = environment.googleClientId;

    this.update.available.subscribe(() => {
      update
        .activateUpdate()
        .then(() => document.location.reload())
        .catch((e) => console.error('TODO error', e));
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        // check if there is _app parameter in the url that indicates external app
        if (e.url.includes('?_app=')) {
          const base = e.url.replace(/_app=(main|user|admin|partner)[\&]?/, '');
          const [, app] = e.url.match(/_app=(main|user|admin|partner)/) ?? [];
          if (app) {
            this.redirectService.navigateToApp(base, app).catch((e) => console.error('TODO error', e));
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.href = window.location.href;
    this.mainDomain = environment.mainDomain;
  }

  onCookieBarClose(): void {
    this.cookieBar = false;
  }
}
