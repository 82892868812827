import { defaultFeatureConfig, FeatureConfig } from './config';
import { ShapeType } from './selectTypes';

export default {
  ...defaultFeatureConfig(''),
  controlType: 'shape',
  selectType: {
    values: [],
    renderValue: (): string => ''
  } as ShapeType
} as FeatureConfig;
