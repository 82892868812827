import { Component, HostBinding, Input } from '@angular/core';

import { Image } from 'src/app/types';

@Component({
  selector: 'app-image-thumb',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss']
})
export class ImageThumbComponent {
  @Input() image!: Image;
  @Input() fav?: '';
  @Input() withLink = true;

  @HostBinding('class.large') @Input() large = false;
}
