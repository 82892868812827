import { NgModule } from '@angular/core';

import { AccountPipe } from './account.pipe';
import { DashboardPipe } from './dashboard.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { DateWhenPipe } from './date-when';
import { LocalDatePipe } from './dateFormat.pipe';
import { MeterPipe } from './meter.pipe';
import { RoundPipe } from './round.pipe';
import { ShowFeaturePipe } from './show-feature';
import { StatsPipe } from './stats.pipe';
import { TrimLinkPipe } from './trim-link.pipe';
import { TruncateParaPipe } from './truncate-para.pipe';
import { UrlLangPipe } from './url-lang.pipe';
import { UrlStoragePipe } from './url-storage.pipe';
import { VarietyPipe } from './variety';

@NgModule({
  declarations: [
    AccountPipe,
    DashboardPipe,
    DateAgoPipe,
    DateWhenPipe,
    LocalDatePipe,
    MeterPipe,
    RoundPipe,
    ShowFeaturePipe,
    StatsPipe,
    TrimLinkPipe,
    TruncateParaPipe,
    UrlLangPipe,
    UrlStoragePipe,
    VarietyPipe
  ],
  imports: [],
  exports: [
    AccountPipe,
    DashboardPipe,
    DateAgoPipe,
    DateWhenPipe,
    LocalDatePipe,
    MeterPipe,
    RoundPipe,
    ShowFeaturePipe,
    StatsPipe,
    TrimLinkPipe,
    TruncateParaPipe,
    UrlLangPipe,
    UrlStoragePipe,
    VarietyPipe
  ]
})
export class PipeModule {}
