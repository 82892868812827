import { Component, Output, EventEmitter } from '@angular/core';
import { getDefaultLang } from 'src/app/helpers/lang';
import { DateTime } from 'luxon';
import { LightboxService } from 'src/app/services/utilities/lightbox.service';
import { map, Observable } from 'rxjs';
import { Image as _Image } from 'src/app/types';
import { TagService } from 'src/app/services/database/tag.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent {
  listLength$: Observable<number>;
  currentImage$: Observable<_Image | undefined>;

  caption$: Observable<{
    date?: string;
    tooltip?: string;
    author?: string;
    description?: string[];
    slug?: string;
  }>;

  @Output() closed = new EventEmitter();

  constructor(public lightboxService: LightboxService, private tagService: TagService) {
    this.currentImage$ = this.lightboxService.currentImage;
    this.caption$ = this.lightboxService.currentImage.pipe(
      map((image) =>
        this.getInfo(
          image?.date ? new Date(image.date.replace(/-/g, '/')) : new Date(),
          image?.author.name || image?.author.toString() || '',
          image?.description ?? '',
          image?.author.slug
        )
      )
    );
    this.listLength$ = this.lightboxService.imageList.pipe(map((list: _Image[]) => list.length || 0));
  }

  onNext(): void {
    this.lightboxService.next();
  }

  onBack(): void {
    this.lightboxService.prev();
  }

  onClose(): void {
    this.lightboxService.close();
  }

  private getInfo(
    date: Date,
    author: string,
    description: string,
    slug?: string
  ): { date?: string; tooltip?: string; author?: string; description?: string[]; slug?: string } {
    let lang: string;
    if (getDefaultLang() === 'pl') {
      lang = 'pl-PL';
    } else {
      lang = 'en-GB';
    }
    const localDate = new Intl.DateTimeFormat(lang, { month: 'long' }).format(date);
    return {
      date: localDate.charAt(0).toUpperCase() + localDate.slice(1),
      tooltip: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
      author,
      description: this.tagService.splitDescription(description),
      slug
    };
  }
}
