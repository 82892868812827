import { Component, Input } from '@angular/core';
import { ContactService, typeStatus } from 'src/app/services/forms/contact.service';

@Component({
  selector: 'app-contact-icon',
  template: `<mat-icon (click)="showForm()" [matTooltip]="'contactForm.header.' + formType | translate" matTooltipPosition="above"
    >error</mat-icon
  >`,
  styles: [
    `
      :host {
        position: relative;
      }
      mat-icon {
        cursor: pointer;
        position: absolute;
        z-index: 10;
        font-size: 1.2em !important;
        width: 1em !important;
        height: 1em !important;
        color: orange;
      }
    `
  ]
})
export class ContactIconComponent {
  @Input() formType!: typeStatus;

  constructor(private contactService: ContactService) {}

  showForm(): void {
    this.contactService.showForm(this.formType);
  }
}
