<svg width="300" height="300" viewbox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
  <defs *ngIf="leafShape">
    <!-- <g *ngFor="let p of patterns; let i = index">
      <radialGradient
        *ngFor="let g of p.spots; let idx = index"
        [id]="'display-' + id + '-grad-' + idx + '-' + i"
        [attr.cx]="0.5"
        [attr.cy]="0.5"
        [attr.r]="0.5"
      >
        <stop offset="0" [attr.stop-color]="getColor(g.color)" />
        <stop offset="1" [attr.stop-color]="getColor(g.color)" stop-opacity="0" />
      </radialGradient>
    </g> -->
    <clipPath *ngFor="let p of [leafShape]; let idx = index" [id]="'id-'+id+'-shape-' + idx">
      <path [attr.d]="getPath(p, 0)" opacity="0.5" stroke-width="1.5" stroke="#000" fill="#aaa" />
      <path [attr.d]="getPath(p, 1)" opacity="0.5" stroke-width="1.5" stroke="#000" fill="#aaa" />
    </clipPath>
  </defs>

  <g *ngFor="let p of leafPatterns; let i = index" [attr.clip-path]="'url(#id-'+id+'-shape-0)'">
    <rect [attr.fill]="getColor(p.base)" width="300" height="300"></rect>
    <g *ngFor="let g of p.spots; let idx = index">
      <ellipse
        [attr.fill]="getColor(g.color)"
        stroke-width="1"
        [attr.cx]="3 * g.center[0]"
        [attr.cy]="3 * g.center[1]"
        [attr.rx]="(3 * g.w) / 2"
        [attr.ry]="(3 * g.h) / 2"
        [attr.transform]="'rotate(' + g.angle + ')'"
        [attr.transform-origin]="3 * g.center[0] + ' ' + 3 * g.center[1]"
      ></ellipse>
    </g>
  </g>
</svg>
