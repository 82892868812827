import { Component, Input, OnInit } from '@angular/core';
import { getShape } from 'src/app/helpers/shapes';
import { Feature } from 'src/app/types/features';
import { COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';
import { Pattern, Shape } from 'src/app/types/features/selectTypes';
import { colorType, GENERAL_FEATURE_CONFIG as GFT, flowerShape, inflorescenceType } from 'src/app/types/features/types';

@Component({
  selector: 'app-flower-group-form',
  templateUrl: './flower-group-form.component.html',
  styleUrls: ['./flower-group-form.component.scss']
})
export class FlowerGroupFormComponent implements OnInit {
  @Input() features?: { [key: string]: Feature<any>[] };

  petalShapeFeature: Feature<Shape>[] = [];
  flowerShapeFeature: Feature<flowerShape>[] = [];
  colorFeature: Feature<colorType>[] = [];
  petalPatternFeature: Feature<Pattern>[] = [];
  inflorescenceFeature: Feature<inflorescenceType>[] = [];
  timeFeature: Feature<Range>[] = [];

  petalShape?: Shape;
  flowerShape?: flowerShape;
  colors: colorType[] = [];
  petalPatterns: Pattern[] = [];
  inflorescence?: inflorescenceType;
  times: Range[] = [];

  readonly id = Math.random().toString(36);

  ngOnInit(): void {
    if (!this.features) {
      return;
    }

    this.petalShapeFeature = this.features['petal_shape'];
    this.flowerShapeFeature = this.features['flower_shape'];
    this.colorFeature = this.features['color'];
    this.petalPatternFeature = this.features['pattern'];
    this.inflorescenceFeature = this.features['inflorescence'];
    this.timeFeature = this.features['time'];

    this.petalShape = GFT.get('flower.petal_shape').reduce(this.petalShapeFeature);
    this.flowerShape = GFT.get('flower.flower_shape').reduce(this.flowerShapeFeature);
    this.colors = GFT.get('flower.color').reduce(this.colorFeature);
    this.petalPatterns = GFT.get('flower.pattern').reduce(this.petalPatternFeature);
    this.inflorescence = GFT.get('flower.inflorescence').reduce(this.inflorescenceFeature);
    this.times = GFT.get('flower.time').reduce(this.timeFeature);
  }

  getColor(name: string): string {
    const col = COLOR_VALUES[name];
    return `hsl(${col.h} , ${col.s}%, ${col.l}%)`;
  }
  getPath(p: Shape, mirror: number): string {
    return getShape(p, 300, mirror === 1);
  }
}
