/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class APIPartnerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation setVendorClick
   */
  static readonly SetVendorClickPath = '/partner/click/{vendorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setVendorClick()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setVendorClick$Response(params: {
    /**
     * Vendor Id
     */
    vendorId: string;

    /**
     * Info about click
     */
    body: { taxonId: string; type: 'taxonPage' | 'userBasket' };
  }): Observable<StrictHttpResponse<{ reported: boolean }>> {
    const rb = new RequestBuilder(this.rootUrl, APIPartnerService.SetVendorClickPath, 'post');
    if (params) {
      rb.path('vendorId', params.vendorId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ reported: boolean }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setVendorClick$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setVendorClick(params: {
    /**
     * Vendor Id
     */
    vendorId: string;

    /**
     * Info about click
     */
    body: { taxonId: string; type: 'taxonPage' | 'userBasket' };
  }): Observable<{ reported: boolean }> {
    return this.setVendorClick$Response(params).pipe(
      map((r: StrictHttpResponse<{ reported: boolean }>) => r.body as { reported: boolean })
    );
  }
}
