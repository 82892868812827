import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

import { FeatureConfig } from 'src/app/types/features/config';

import { Pattern, PatternType, Spot } from 'src/app/types/features/selectTypes';

import { colorType, COLOR_TYPES, COLOR_VALUES } from 'src/app/types/features/ColorConfig.type';

@Component({
  selector: 'app-pattern-select',
  templateUrl: './pattern-select.component.html',
  styleUrls: ['./pattern-select.component.scss']
})
export class PatternSelectComponent implements OnInit {
  @Input() feature!: Feature<Pattern>;
  @Input() saved?: BehaviorSubject<boolean>;
  @Input() availbleColors: colorType[] = [...COLOR_TYPES];

  @Output() changed = new EventEmitter<Feature<Pattern>>();

  group?: string;
  name?: string;

  featureConfig?: FeatureConfig;
  predefined?: Pattern[];

  /** user has changed the value, and it is scheduled to save */
  modified = false;
  /** user had set the value previously */
  default = true;

  templates: [string, string][] = [];

  focals: number[][][] = [];

  private readonly DEFAULT_GRAD = {
    center: [0, 0],
    w: 100,
    h: 100,
    angle: 0,
    color: 'blue'
  };

  private initVal: any;
  private privateVal: any;
  get value(): any {
    return this.privateVal;
  }
  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set value(newVal: any) {
    this.privateVal = newVal;
    const info = this.feature
      ? {
          featureId: this.feature.featureId,
          owner: this.feature.ownerId
        }
      : {};

    this.changed.emit({
      group: this.group,
      name: this.name,
      value: newVal,
      ...info
    } as Feature<Pattern>);

    this.modified = true;
    this.default = false;
  }

  path?: string;
  mirrorPath?: string;
  moving?: string;
  selected?: number;
  shifted?: boolean;

  readonly id = Math.random().toString(36);

  ngOnInit(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.featureConfig = GFT.get(this.group, this.name);
    this.predefined = [...(this.featureConfig.selectType as PatternType).values];

    const feature = this.feature;

    this.default = feature ? false : true;

    this.privateVal = feature && !Array.isArray(feature.value) ? feature.value : undefined;

    this.initVal = this.privateVal;
    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.modified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }

    if (this.privateVal && this.privateVal.spots) {
      this.calculateFocals();
    }

    // if (this.predefined) {
    //   this.predefined.forEach((p) => {
    //     const l = getShape(p, 100);
    //     const r = getShape(p, 100, true);
    //     this.templates.push([l, r]);
    //   });
    // }
  }

  getRotatedCoordinates(p: [number, number], angle: number, center: [number, number]): number[] {
    const s = Math.sin((angle * 2 * Math.PI) / 360);
    const c = Math.cos((angle * 2 * Math.PI) / 360);
    const ox = 3 * (p[0] - center[0]);
    const oy = 3 * (p[1] - center[1]);
    const xnew = ox * c - oy * s;
    const ynew = ox * s + oy * c;
    return [xnew + 3 * center[0], ynew + 3 * center[1]];
  }

  calculateFocals(): void {
    if (!this.value.spots) {
      return;
    }
    this.value.spots.forEach((p: Spot, idx: number) => {
      if (!p.center) {
        return;
      }
      const f1 = this.getRotatedCoordinates([p.center[0] - p.w / 2, p.center[1]], p.angle, p.center);
      const f2 = this.getRotatedCoordinates([p.center[0], p.center[1] - p.h / 2], p.angle, p.center);
      this.focals[idx] = [f1, f2];
    });
  }

  startMove(name: string, e: Event): void {
    this.moving = this.moving ? undefined : name;
    e.stopPropagation();
  }

  // e:any instead of e:MouseEvent because of layerX is not in the spec of standard events https://mariani.life/projects/dommanual/dom/events/UIEvent.html
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  stopMoving(e: any): void {
    if (e.shiftKey) {
      const { layerX, layerY } = e;
      const newGrad = { ...this.DEFAULT_GRAD };
      newGrad.center = [layerX / 3, layerY / 3];
      this.value.spots.push(newGrad);
      this.value = { ...this.value };
      this.calculateFocals();
    } else {
      this.moving = undefined;
    }
  }
  // e:any instead of e:MouseEvent because of layerX is not in the spec of standard events https://mariani.life/projects/dommanual/dom/events/UIEvent.html
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setPoints(event: any): void {
    if (!this.moving) {
      return;
    }
    const { layerX, layerY } = event;
    const [base, idx] = this.moving.split('-');
    const point = this.value.spots[idx];
    const dx = 3 * point.center[0] - layerX;
    const dy = 3 * point.center[1] - layerY;

    if (base === 'center') {
      point.center = [layerX / 3, layerY / 3];
    } else if (base === 'focalX') {
      point.w = (2 * Math.sqrt(dx * dx + dy * dy)) / 3;
      point.angle = Math.round((Math.atan(dy / dx) * 360) / 2 / Math.PI);
    } else if (base === 'focalY') {
      point.h = (2 * Math.sqrt(dx * dx + dy * dy)) / 3;
    }
    this.value = { ...this.value };
    this.calculateFocals();
  }

  selectTemplate(index: number): void {
    this.value = JSON.parse(JSON.stringify((this.featureConfig?.selectType as PatternType).values[index]));
    this.calculateFocals();
  }

  mouseMoving(e: MouseEvent): void {
    this.shifted = e.shiftKey;
    if (this.moving) {
      this.setPoints(e);
    }
  }

  select(idx: number): void {
    this.selected = this.selected === idx ? undefined : idx;
  }

  delete(): void {
    this.value.spots = this.value.spots.filter((_: any, i: number) => i !== this.selected);
    this.value = { ...this.value };
    this.calculateFocals();
  }

  selectColor(e: string): void {
    if (this.selected !== undefined) {
      this.value.spots[this.selected].color = e;
    } else {
      this.value.base = e;
    }
    this.value = { ...this.value };
  }

  getColor(name: string): string {
    const col = COLOR_VALUES[name];
    return `hsl(${col.h} , ${col.s}%, ${col.l}%)`;
  }
}
