import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'urlStorage'
})
export class UrlStoragePipe implements PipeTransform {
  transform(url?: string): string {
    if (!url) {
      return '';
    }
    if (url.includes('http://') || url.includes('https://')) {
      return url;
    }
    return environment.storageUrl + url;
  }
}
