<ng-template #thumbBlock>
  <img [src]="image.mini | urlStorage" [alt]="image.description" />

  <div class="taxons" *ngIf="image.taxons?.length">
    <span *ngFor="let taxon of image.taxons">
      <em *ngIf="!taxon.localName">{{ taxon.latinName }}</em>
      <strong *ngIf="taxon.localName">{{ taxon.localName }}</strong>
      <ng-container *ngIf="taxon.variety">`{{taxon.variety}}`</ng-container>
    </span>
  </div>
  <div class="overlay">
    <div class="description">
      <span *ngIf="image.description">{{image.description}}</span>
    </div>
    <div class="location">
      <mat-icon *ngIf="image.location">location_on</mat-icon>
    </div>
  </div>
</ng-template>

<a *ngIf="withLink" [routerLink]="image.detailsLink | urlLang">
  <ng-container *ngTemplateOutlet="thumbBlock"></ng-container>
</a>
<div *ngIf="!withLink">
  <ng-container *ngTemplateOutlet="thumbBlock"></ng-container>
</div>

<ng-container *appIsLogged>
  <app-favourites-icon *ngIf="fav === '' && image.imageId" [photo]="image"></app-favourites-icon>
</ng-container>

<ng-content></ng-content>
